import { ReactComponent as FacebookIcon } from 'src/assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'src/assets/icons/instagram.svg';

export default function Footer() {
  const year = new Date().getFullYear().toString();

  return (
    <footer className='fixed bottom-0 flex h-[35px] w-full items-center justify-center bg-gray-light'>
      <div className='text-[10px] font-bold'>
        Copyright © Z.P.H. PILCH
        {year && <span>&nbsp;{year}</span>}
      </div>
      <div className='absolute right-[20px] py-[10px]'>
        <a
          href='https://www.facebook.com/nauczycielprzyszlosci'
          target='_blank'
          rel='noreferrer'
        >
          <FacebookIcon className='h-[24px] w-[24px] cursor-pointer' />
        </a>
        <a
          href='https://www.instagram.com/nauczyciel_przyszlosci'
          target='_blank'
          rel='noreferrer'
        >
          <InstagramIcon className='ml-[10px] h-[24px] w-[24px] cursor-pointer' />
        </a>
      </div>
    </footer>
  );
}
