import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C7_E1,
};

const step2 = {
  number: 2,
  description:
    'W ogrodzie tata Pawła, wykopał basen o wymiarach 350 cm x 250 cm',
  customMatrix: CustomMatrix.EXERCISE_C7_E1,
};

const step3 = {
  number: 3,
  description: 'Ułóżcie na swoich podkładkach z klocków niebieskich taki basen',
  customMatrix: CustomMatrix.EXERCISE_C7_E1,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C7_E1,
};

const step5 = {
  number: 5,
  description:
    'Tata z Pawłem postanowili ułożyć wokół basenu chodnik o szerokości 50 cm z płytek o wymiarach 50 cm x 50 cm. Tata poprosił Pawła, aby obliczył, ile płytek muszą kupić',
  customMatrix: CustomMatrix.EXERCISE_C7_E1,
};

const step6 = {
  number: 6,
  description: 'Ile tata musi kupić płytek i jak to policzyć?',
  customMatrix: CustomMatrix.EXERCISE_C7_E1,
};

const step7 = {
  number: 7,
  customMatrix: CustomMatrix.EXERCISE_C7_E1,
};

const step8 = {
  number: 8,
  customMatrix: CustomMatrix.EXERCISE_C7_E1,
};

const step9 = {
  number: 9,
  customMatrix: CustomMatrix.EXERCISE_C7_E1,
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.EXERCISE_C7_E1,
};
const step11 = {
  number: 11,
  customMatrix: CustomMatrix.EXERCISE_C7_E1,
};
const step12 = {
  number: 12,
  customMatrix: CustomMatrix.EXERCISE_C7_E1,
};
const step13 = {
  number: 13,
  customMatrix: CustomMatrix.EXERCISE_C7_E1,
};
const step14 = {
  number: 14,
  customMatrix: CustomMatrix.EXERCISE_C7_E1,
};

export const c7e1: Exercise = {
  number: 1,
  name: ExerciseName.EXERCISE_1,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
  ],
};
