import {
  CustomMatrix,
  Exercise,
  ExerciseName,
  ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
  description:
    'Wyciągnijcie z pudełka taką samą drewnianą podkładkę i połóżcie na niej taki sam klocek, jaki jest na obrazku na ekranie',
};

// 1
const step2 = {
  number: 2,
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

const step3 = {
  number: 3,
  description:
    'Wyobraźcie sobie, że podstawą ostrosłupa jest kwadrat. Ile taki ostrosłup ma krawędzi bocznych? Ile ma ścian bocznych?',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

const step4 = {
  number: 4,
  description:
    'Połóżcie na podstawce taki sam klocek, jaki jest na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

// 2
const step5 = {
  number: 5,
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

const step6 = {
  number: 6,
  description:
    'Obrysujcie w zeszytach modele kwadratu i prostokąta oraz podpisz je używając odpowiednich nazw',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

const step7 = {
  number: 7,
  description:
    'Połóżcie na podstawce taki sam klocek, jaki jest na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

// 3
const step8 = {
  number: 8,
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

const step9 = {
  number: 9,
  description:
    'Wyjmijcie z pudełka miarkę centymetrową i zmierzcie długości wszystkich boków równoległoboku. Co ciekawego zauważyliście?',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

const step11 = {
  number: 11,
  description:
    'Połóżcie na podstawce taki sam klocek, jaki jest na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

// 4
const step12 = {
  number: 12,
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

const step13 = {
  number: 13,
  description: 'Obrysujcie w zeszytach model trapezu oraz podpisz go',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

const step14 = {
  number: 14,
  description:
    'Połóżcie na podstawce taki sam klocek, jaki jest na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

// 5
const step15 = {
  number: 15,
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

const step16 = {
  number: 16,
  description: 'Obrysujcie w zeszytach model rombu oraz podpisz go',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

const step17 = {
  number: 17,
  description:
    'Połóżcie na podstawce taki sam klocek, jaki jest na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

// 6
const step18 = {
  number: 18,
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

const step19 = {
  number: 19,
  description: 'Obrysujcie w zeszytach model deltoidu oraz podpisz go',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

const step20 = {
  number: 20,
  description:
    'Wyobraźcie sobie, że podstawą ostrosłupa jest dowolny czworokąt. Ile taki ostrosłup ma krawędzi bocznych, a ile ścian bocznych?',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

const step21 = {
  number: 21,
  description:
    'Jakie są różnice, a jakie podobieństwa między poznanymi czworokątami?',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E2,
};

export const c6s4e2: Exercise = {
  number: 2,
  name: ExerciseName.EXERCISE_2,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
    step17,
    step18,
    step19,
    step20,
    step21,
  ],
};
