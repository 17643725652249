import {
    CustomMatrix,
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../../exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.BLANK_15x15,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.BLANK_15x15,
  description:
    'Postaw klocek ze strzałką na polu A1. Wykonaj kroki jak pokazano na ekranie',
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'A',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 1,
    },

    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 11,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 12,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 13,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 14,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 3,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 4,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 6,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 8,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 10,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 11,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 12,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 13,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 14,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  number: 4,
  description: 'Zapiszcie rozważany program w postaci programu z pętlami',
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'A',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 1,
    },

    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 11,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 12,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 13,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 14,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 3,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 4,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 6,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 8,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 10,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 11,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 12,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 13,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 14,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  number: 5,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'A',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 1,
    },

    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 11,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 12,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 13,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 14,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 3,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 4,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 6,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 8,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 10,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 11,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 12,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 13,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 14,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 'A',
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 1,
    },
  ],
};

const step6 = {
  number: 6,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'A',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 1,
    },

    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 11,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 12,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 13,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 14,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 3,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 4,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 6,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 8,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 10,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 11,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 12,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 13,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 14,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 'A',
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 1,
    },

    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
  ],
};

const step7 = {
  number: 7,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'A',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 1,
    },

    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 11,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 12,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 13,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 14,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 3,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 4,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 6,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 8,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 10,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 11,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 12,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 13,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 14,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 'A',
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 1,
    },

    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },

    {
      idx: 3,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 7,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
  ],
};

const step8 = {
  number: 8,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'A',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 1,
    },

    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 11,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 12,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 13,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 14,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 3,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 4,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 6,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 8,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 10,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 11,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 12,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 13,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 14,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 'A',
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 1,
    },

    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },

    {
      idx: 3,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 7,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },

    {
      idx: 3,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 5,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 7,
      idy: 5,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
  ],
};

const step9 = {
  number: 9,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'A',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 1,
    },

    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 11,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 12,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 13,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 14,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 3,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 4,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 6,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 8,
      idy: 11,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 10,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 11,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 12,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 13,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 14,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 'A',
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 1,
    },

    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },

    {
      idx: 3,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 7,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },

    {
      idx: 3,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 5,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 7,
      idy: 5,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },

    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 5,
      idy: 4,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 4,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 4,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
  ],
};

export const c8s5e7: Exercise = {
  number: 7,
  name: ExerciseName.EXERCISE_7,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [step1, step2, step3, step4, step5, step6, step7, step8, step9],
  lettersOnAxis: true,
};
