import {
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../../exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Ułóżcie na swoich siatkach figurę pokazaną na obrazku na ekranie i zapiszcie ją w zeszytach za pomocą kodów',
};

const step2 = {
  number: 2,
  blocks: [
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 2,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 4,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 4,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 5,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step3 = {
  number: 3,
  textInsteadMatrix:
    'Czerwone klocki: A1, B2, C3, H8, I9, J10. \n\n Żółte klocki: D4, E4, F4, G4, G5, G6, G7, F7, E7, D7, D6, D5. \n\n Niebieskie klocki: E5, F5, F6, E6. \n\n Zielone klocki: A10, B9, C8, H3, I2, J1.',
};

export const c8s1e6: Exercise = {
  number: 6,
  name: ExerciseName.EXERCISE_6,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: [step1, step2, step3],
  availability: 'FULL_ACCESS',
  lettersOnAxis: true,
};
