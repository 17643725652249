import {
    getBlocksAfterCount,
    getBlocksBeforeCount
} from 'src/database/category3/category3Util';
import {
    CustomMatrix,
    Exercise,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../exerciseTypes';
import { getRandomBlocks } from '../category4Util';

const blocksBeforeAdd = getRandomBlocks(28, ExerciseColor.ZIELONY);
const blocksAfterAdd = getRandomBlocks(52, ExerciseColor.ZIELONY, [
  ...blocksBeforeAdd,
]);
const blocksAfterSub = [...blocksAfterAdd.slice(0, 38)];

const step1 = {
  withDecimals: true,
  number: 1,
  description: 'Wyciągnijcie podstawki jak na ekranie',
  customMatrix: CustomMatrix.BLANK_10x10,
};

const step2 = {
  withDecimals: true,
  number: 2,
  customMatrix: CustomMatrix.BLANK_10x10,
  description:
    'Podstawka ilustruje jezioro, po którym mogą pływać łódki, reprezentowane u nas przez klocki zielone',
};

const step3 = {
  withDecimals: true,
  number: 3,
  description: 'Po jeziorze pływa 28 łódek',
  customMatrix: CustomMatrix.BLANK_10x10,
};

const step4 = {
  withDecimals: true,
  number: 4,
  description:
    'Połóżcie na podstawkach odpowiednią liczbę klocków w taki sposób, aby nie było widać cyfr wypisanych na ściankach',
  customMatrix: CustomMatrix.BLANK_10x10,
};

const step5 = {
  withDecimals: true,
  number: 5,
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: blocksBeforeAdd,
};

const step6 = {
  withDecimals: true,
  number: 6,
  description: 'Z przystani wypłynęło jeszcze 26 łódek',
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: blocksBeforeAdd,
};

const step7 = {
  withDecimals: true,
  number: 7,
  description: 'Dołóżcie zielone klocki na swoich podstawkach',
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: blocksBeforeAdd,
};

const step8 = {
  withDecimals: true,
  number: 8,
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: blocksAfterAdd,
};

const step9 = {
  withDecimals: true,
  number: 9,
  description: 'Do przystani odpłynęło 14 łódek',
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: blocksAfterAdd,
};

const step10 = {
  withDecimals: true,
  number: 10,
  description: 'Odejmijcie zielone klocki ze swoich podstawek',
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: blocksAfterAdd,
};

const step11 = {
  withDecimals: true,
  number: 11,
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: blocksAfterSub,
};

const step12 = {
  withDecimals: true,
  number: 12,
  description: 'Ile łódek pływa teraz po jeziorze?',
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: blocksAfterSub,
};

const step13 = {
  withDecimals: true,
  number: 13,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step14 = {
  withDecimals: true,
  number: 14,
  description:
    'Ułóżcie na nich odpowiednich klocków ilustrujących podane w historyjce liczby łódek',
};

const step15 = {
  withDecimals: true,
  number: 15,
  blocks: [...getBlocksBeforeCount(28, 26)],
};

const step16 = {
  withDecimals: true,
  number: 16,
  description: 'Posługując się klockami, dodajcie podane liczby',
  blocks: [...getBlocksBeforeCount(28, 26)],
};

const step17 = {
  withDecimals: true,
  number: 17,
  blocks: [...getBlocksAfterCount(54)],
};

const step18 = {
  withDecimals: true,
  number: 18,
  description: 'Posługując się klockami, odejmijcie podaną liczbę',
  blocks: [...getBlocksBeforeCount(54, 14)],
};

const step19 = {
  withDecimals: true,
  number: 19,
  blocks: [...getBlocksAfterCount(40)],
};

export const c4e4: Exercise = {
  number: 2,
  name: ExerciseName.EXERCISE_4,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
    step17,
    step18,
    step19,
  ],
};
