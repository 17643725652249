import { category1 } from './category1/category1';
import { category2 } from './category2/category2';
import { category3 } from './category3/category3';
import { category4 } from './category4/category4';
import { category5 } from './category5/category5';
import { category6 } from './category6/category6';
import { category7 } from './category7/category7';
import { category8 } from './category8/category8';
import { Category } from './exerciseTypes';

export const categories: Category[] = [
  category1,
  category2,
  category3,
  category4,
  category5,
  category6,
  category7,
  category8,
];
