import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as BackArrow } from 'src/assets/icons/arrow.svg';
import Button from 'src/components/Button';
import { label } from 'src/utils/labels';
import { ButtonSize, ButtonTypes } from 'src/utils/types';

type Props = {
  name: string | undefined;
  category: string | undefined;
  subcategory: string | undefined;
  setPreviousExercise: () => void;
  isPreviousExercise: boolean;
  setNextStep: () => void;
  setPreviousStep: () => void;
  isNextStep: boolean;
  isPreviousStep: boolean;
  exerciseStarted: boolean;
  setExerciseStarted: Dispatch<SetStateAction<boolean>>;
  setIsCloseConfirmation: (close: boolean) => void;
  isCloseConfirmation: boolean;
  goBackPath: string;
  ownVariantOption: boolean;
};
export default function LeftSection({
  name,
  category,
  subcategory,
  setPreviousExercise,
  isPreviousExercise,
  setNextStep,
  setPreviousStep,
  isNextStep,
  isPreviousStep,
  exerciseStarted,
  setExerciseStarted,
  setIsCloseConfirmation,
  isCloseConfirmation,
  goBackPath,
  ownVariantOption,
}: Props) {
  return (
    <>
      <div className='h-[370px] p-[20px] shadow shadow-gray-shadow md:h-full '>
        <Link
          to={goBackPath}
          reloadDocument={true}
          className='flex items-center text-black-default no-underline'
        >
          <BackArrow className='mr-[8px]' />
          <span className='cursor-pointer text-[18px] font-bold'>
            {label.dialog.backLabel}
          </span>
        </Link>
        <div className='mt-[20px] text-[21px]'>
          <div>{category}:</div>
          {subcategory && <div>{subcategory}:</div>}
          <div>{name}</div>
          {!exerciseStarted && (
            <Button
              variant={ButtonTypes.primary}
              buttonSize={ButtonSize.MEDIUM}
              customClassName='text-[16px] mt-[20px]'
              onClick={() => setExerciseStarted(true)}
            >
              ROZPOCZNIJ
            </Button>
          )}
          {exerciseStarted && !isCloseConfirmation && (
            <>
              <Button
                variant={
                  isPreviousStep ? ButtonTypes.secondary : ButtonTypes.disabled
                }
                buttonSize={ButtonSize.MEDIUM}
                customClassName='text-[16px] mt-[20px]'
                onClick={setPreviousStep}
                disabled={!isPreviousStep}
              >
                COFNIJ
              </Button>
              {!isCloseConfirmation && (
                <Button
                  variant={ButtonTypes.primary}
                  buttonSize={ButtonSize.MEDIUM}
                  customClassName='text-[16px] mt-[20px]'
                  onClick={
                    isNextStep
                      ? setNextStep
                      : () => setIsCloseConfirmation(true)
                  }
                >
                  {isNextStep ? <span>DALEJ</span> : <span>ZAKOŃCZ</span>}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      {!ownVariantOption && (
        <div
          className={clsx(
            'absolute bottom-0 mb-[20px] w-full px-[10px] text-center text-[18px] ',
            {
              'cursor-not-allowed text-gray-shadow': !isPreviousExercise,
              'cursor-pointer text-green-aquamarine underline':
                isPreviousExercise,
            }
          )}
          onClick={isPreviousExercise ? setPreviousExercise : () => null}
        >
          Poprzednie ćwiczenie
        </div>
      )}
    </>
  );
}
