import { getDownloadURL } from 'firebase/storage';
import { useState } from 'react';
import Button from 'src/components/Button';
import { HomepageMenu } from 'src/context/HomepageMenuContext';
import { downloadFile } from 'src/utils/file';
import { ButtonSize, ButtonTypes } from 'src/utils/types';

export default function MenuPresentations() {
  const [fileUrl, setFileUrl] = useState('');
  const { selectedPresentation } = HomepageMenu();

  if (typeof selectedPresentation !== 'undefined') {
    getDownloadURL(selectedPresentation).then((url) => {
      setFileUrl(url);
    });
  }

  return (
    <div className='relative flex h-full w-full justify-center'>
      {fileUrl && (
        <>
          <div className='h-[480px] w-full overflow-hidden rounded-lg border  md:h-full md:pb-[80px]'>
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
              width='100%'
              height='100%'
              frameBorder='0'
              title='prezentacje'
            />
          </div>
          <div className='absolute bottom-0 mt-[10px] w-full'>
            <Button
              buttonSize={ButtonSize.BIG}
              variant={ButtonTypes.primary}
              onClick={() => downloadFile(fileUrl)}
            >
              POBIERZ PREZENTACJĘ
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
