import { FormProvider } from 'react-hook-form';
import Footer from 'src/components/Footer';
import { useRecoverPassword } from './hooks';
import RecoverPasswordFirstStep from './RecoverPasswordFirstStep';
import RecoverPasswordSuccessStep from './RecoverPasswordSuccessStep';

export default function RecoverPassword() {
  const { form, handleRecoverPassword, step } = useRecoverPassword();

  return (
    <FormProvider {...form}>
      <form
        className='h-full'
        onSubmit={form.handleSubmit(handleRecoverPassword)}
        noValidate
        autoComplete='off'
      >
        {step === RecoverPasswordStepEnum.firstStep && (
          <RecoverPasswordFirstStep />
        )}
        {step === RecoverPasswordStepEnum.successStep && (
          <RecoverPasswordSuccessStep />
        )}
      </form>
      <Footer />
    </FormProvider>
  );
}

export enum RecoverPasswordStepEnum {
  firstStep = 1,
  secondStep = 2,
  thirdStep = 3,
  successStep = 4,
}
