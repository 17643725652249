import clsx from 'clsx';
import { ReactElement } from 'react';

type Props = {
  optionClassName?: string;
  onOptionSelect: () => void;
  isSelected: boolean;
  isDropdown?: boolean;
  markAsSelected: boolean;
  text: string;
  children?: ReactElement;
};

export default function MenuOption({
  optionClassName,
  onOptionSelect,
  isSelected,
  markAsSelected,
  isDropdown,
  text,
  children,
}: Props) {
  return (
    <>
      <div
        className={clsx(
          optionClassName,
          'mb-[20px] min-h-[60px] cursor-pointer text-[16px] shadow shadow-gray-shadow last:mb-0',
          {
            'bg-yellow-default': markAsSelected,
            'hover:bg-gray-default': !markAsSelected,
          }
        )}
        onClick={() => onOptionSelect()}
      >
        <div className='relative flex min-h-[60px] items-center'>
          <div className='overflow-hidden p-[16px]'>{text}</div>
          {isDropdown && isSelected && (
            <div className='absolute right-[10px]'>&#9650;</div>
          )}
          {isDropdown && !isSelected && (
            <div className='absolute right-[10px]'>&#9660;</div>
          )}
        </div>
      </div>
      {children}
    </>
  );
}
