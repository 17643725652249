import clsx from 'clsx';

type Props = {
  withNumbers: boolean | undefined;
};

export default function TopAxisAlternative({ withNumbers }: Props) {
  let elements: string[] = [];
  if (withNumbers) {
    elements = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  } else {
    elements = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
  }

  return (
    <div
      className={clsx('mx-[10px] ml-auto mb-[4px] flex h-[60px] w-[590px]', {
        'border border-solid border-black-default': !withNumbers,
      })}
    >
      {elements.map((element) => {
        return (
          <div
            className={clsx(
              'mx-[10px] flex h-[60px] min-w-[40px] items-center justify-center text-[24px]'
            )}
            key={element}
          >
            {element}
          </div>
        );
      })}
    </div>
  );
}
