import {
    getBlocksAfterCount,
    getBlocksBeforeCount
} from 'src/database/category3/category3Util';
import {
    CustomMatrix,
    Exercise,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../exerciseTypes';
import { getRandomBlocks } from '../category4Util';

const greenStartBlocks = getRandomBlocks(15, ExerciseColor.ZIELONY);
const yellowWithGreenStartBlocks = getRandomBlocks(25, ExerciseColor.ŻÓŁTY, [
  ...greenStartBlocks,
]);
const greenEndBlocks = getRandomBlocks(31, ExerciseColor.ZIELONY, [
  ...yellowWithGreenStartBlocks,
]);
const yellowWithGreenEndBlocks = getRandomBlocks(35, ExerciseColor.ŻÓŁTY, [
  ...greenEndBlocks,
]);

const step1 = {
  withDecimals: true,
  number: 1,
  description: 'Wyciągnijcie podstawki jak na ekranie',
  customMatrix: CustomMatrix.BLANK_10x10,
};

const step2 = {
  withDecimals: true,
  number: 2,
  customMatrix: CustomMatrix.BLANK_10x10,
  description:
    'Podstawka ilustruje jezioro, po którym mogą pływać łódki i kajaki, reprezentowane u nas przez klocki zielone i żółte',
};

const step3 = {
  withDecimals: true,
  number: 3,
  description: 'Po jeziorze pływa 15 łódek i 10 kajaków',
  customMatrix: CustomMatrix.BLANK_10x10,
};

const step4 = {
  withDecimals: true,
  number: 4,
  description:
    'Połóżcie na podstawkach odpowiednią liczbę klocków w taki sposób, aby nie było widać cyfr wypisanych na ściankach',
  customMatrix: CustomMatrix.BLANK_10x10,
};

const step5 = {
  withDecimals: true,
  number: 5,
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: yellowWithGreenStartBlocks,
};

const step6 = {
  withDecimals: true,
  number: 6,
  description: 'Z przystani wypłynęło jeszcze 6 łódek i 4 kajaki',
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: yellowWithGreenStartBlocks,
};

const step7 = {
  withDecimals: true,
  number: 7,
  description: 'Dołóżcie zielone i żółte klocki na swoich podstawkach',
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: yellowWithGreenStartBlocks,
};

const step8 = {
  withDecimals: true,
  number: 8,
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: yellowWithGreenEndBlocks,
};

const step9 = {
  withDecimals: true,
  number: 9,
  description:
    'Ile łódek pływa po jeziorze, a ile kajaków? Ile razem łódek i kajaków pływa po jeziorze?',
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: yellowWithGreenEndBlocks,
};

const step10 = {
  withDecimals: true,
  number: 10,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step11 = {
  withDecimals: true,
  number: 11,
  description:
    'Ułóżcie na nich odpowiednich klocków ilustrujących podane w historyjce liczby żaglówek',
};

const step12 = {
  withDecimals: true,
  number: 12,
  blocks: [...getBlocksBeforeCount(25, 10)],
};

const step13 = {
  withDecimals: true,
  number: 13,
  description: 'Posługując się klockami, dodajcie podane liczby',
  blocks: [...getBlocksBeforeCount(25, 10)],
};

const step14 = {
  withDecimals: true,
  number: 14,
  blocks: [...getBlocksAfterCount(35)],
};

export const c4e3: Exercise = {
  number: 3,
  name: ExerciseName.EXERCISE_3,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
  ],
};
