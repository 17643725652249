import { yupResolver } from '@hookform/resolvers/yup';
import { FirebaseError } from 'firebase/app';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import { UserAuth } from 'src/context/AuthContext';
import { label } from 'src/utils/labels';
import { FirebaseErrorCodes } from 'src/utils/types';
import { registerSchema } from 'src/utils/validation';
import { ExcelCodeRecord, useDatabase } from '../../database/database';

export type RegisterRequest = {
  login: string;
  password: string;
  passwordConfirm: string;
  agreementsConfirm: boolean;
  clientType: string;
  code?: string;
};

export function useRegister() {
  const { createUser, logout } = UserAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [successfullyCreated, setSuccessfullyCreated] = useState(false);
  const { saveUserInfo, getCodes, isCodeUsed } = useDatabase();

  const alert = useAlert();

  async function handleRegister(inputs: RegisterRequest) {
    if (!form.getValues('agreementsConfirm')) {
      form.setError('agreementsConfirm', {
        type: 'custom',
        message: 'Musisz zapoznać się z regulaminem.',
      });
      return;
    } else {
      form.clearErrors('agreementsConfirm');
    }
    setIsLoading(true);
    let foundCode: ExcelCodeRecord | undefined;
    if (inputs.code) {
      const fetchedCodesRecords = await getCodes();
      foundCode = fetchedCodesRecords.find((r) => r.KOD === inputs.code);
      if (!foundCode) {
        form.setError('code', {
          type: 'custom',
          message: 'Podany kod jest błędny',
        });
        setIsLoading(false);
        return;
      }
      const codeUsed = await isCodeUsed(inputs.code);
      if (codeUsed) {
        form.setError('code', {
          type: 'custom',
          message: 'Kod został już użyty',
        });
        setIsLoading(false);
        return;
      }
    }
    try {
      await createUser(inputs.login, inputs.password).then(() => {
        saveUserInfo(inputs.login, inputs.clientType, foundCode).then(() => {
          logout();
          setSuccessfullyCreated(true);
        });
      });
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        if (error.code === FirebaseErrorCodes.AUTH_EMAIL_ALREADY_IN_USE) {
          form.setError('login', {
            type: 'custom',
            message: 'Użytkownik o tym e-mailu już istnieje.',
          });
        } else {
          alert.error(label.alerts.error.common);
        }
      }
      setIsLoading(false);
    }
  }

  const form = useForm<RegisterRequest>({
    mode: 'onTouched',
    defaultValues: {
      clientType: 'Indywidualny',
    },
    resolver: yupResolver(registerSchema),
  });

  return {
    form,
    isLoading,
    successfullyCreated,
    handleRegister,
  };
}
