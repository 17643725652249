import clsx from 'clsx';

type Props = {
  stepNumber: number;
};

export default function CustomMatrixC6S2E2({ stepNumber }: Props) {
  function bigBlock(vertical: boolean) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[150px]': !vertical,
            'h-[150px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>d</div>
      </div>
    );
  }

  function mediumBlock(vertical: boolean) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[100px]': !vertical,
            'h-[100px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>s</div>
      </div>
    );
  }

  function smallBlock(vertical: boolean) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[50px]': !vertical,
            'h-[50px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>m</div>
      </div>
    );
  }

  if (stepNumber === 1) {
    return null;
  }

  if (stepNumber === 1 || stepNumber === 2) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/emptyBoard.png`}
            className='h-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 3 || stepNumber === 4) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s2e2blocks.png`}
            className='w-full'
          />
        </div>
      </div>
    );
  }

  if (
    stepNumber === 5 ||
    stepNumber === 6 ||
    stepNumber === 7 ||
    stepNumber === 8
  ) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='h-[500px] w-[400px] border border-solid bg-brown-block' />
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 9 || stepNumber === 10) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='h-[500px] w-[400px] border border-solid bg-brown-block' />
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 11) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative h-[500px] w-[400px] border border-solid bg-brown-block'>
              <div className='absolute left-[-1px] bottom-[-25px]'>
                {mediumBlock(false)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 12) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative h-[500px] w-[400px] border border-solid bg-brown-block'>
              <div className='absolute left-[-1px] bottom-[-25px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[99px]'>
                {mediumBlock(false)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 13) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative h-[500px] w-[400px] border border-solid bg-brown-block'>
              <div className='absolute left-[-1px] bottom-[-25px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[99px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[199px]'>
                {mediumBlock(false)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 14) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative h-[500px] w-[400px] border border-solid bg-brown-block'>
              <div className='absolute left-[-1px] bottom-[-25px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[99px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[199px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[299px]'>
                {mediumBlock(false)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 15) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative h-[500px] w-[400px] border border-solid bg-brown-block'>
              <div className='absolute left-[-1px] bottom-[-25px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[99px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[199px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[299px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-1px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 16) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative h-[500px] w-[400px] border border-solid bg-brown-block'>
              <div className='absolute left-[-1px] bottom-[-25px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[99px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[199px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[299px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-1px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
              <div className='absolute bottom-[99px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 17) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative h-[500px] w-[400px] border border-solid bg-brown-block'>
              <div className='absolute left-[-1px] bottom-[-25px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[99px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[199px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[299px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-1px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
              <div className='absolute bottom-[99px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
              <div className='absolute bottom-[199px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 18) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative h-[500px] w-[400px] border border-solid bg-brown-block'>
              <div className='absolute left-[-1px] bottom-[-25px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[99px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[199px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[299px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-1px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
              <div className='absolute bottom-[99px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
              <div className='absolute bottom-[199px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
              <div className='absolute bottom-[299px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (stepNumber === 19) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative h-[500px] w-[400px] border border-solid bg-brown-block'>
              <div className='absolute left-[-1px] bottom-[-25px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[99px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[199px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-25px] left-[299px]'>
                {mediumBlock(false)}
              </div>
              <div className='absolute bottom-[-1px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
              <div className='absolute bottom-[99px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
              <div className='absolute bottom-[199px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
              <div className='absolute bottom-[299px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
              <div className='absolute bottom-[399px] right-[-25px]'>
                {mediumBlock(true)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div></div>;
}
