import { NewVariant } from 'src/pages/NewVariant/hooks';
import { getExerciseCategory1 } from './category1/category1Util';
import { getExerciseCategory2 } from './category2/category2Util';
import { getExerciseCategory3 } from './category3/category3Util';
import { CategoryName } from './exerciseTypes';

export function getProperCategory(
  category: string | undefined = '',
  exercise: string | undefined = '',
  inputs: NewVariant,
  subcategory?: string
) {
  if (category === CategoryName.CATEGORY_1) {
    return getExerciseCategory1(exercise, inputs);
  }
  if (category === CategoryName.CATEGORY_2) {
    return getExerciseCategory2(exercise, inputs);
  }
  if (
    category === CategoryName.CATEGORY_3 &&
    typeof subcategory !== 'undefined'
  ) {
    return getExerciseCategory3(exercise, inputs, subcategory);
  }
  return [];
}

export function getCategoryDisplayName(category: string): string {
  category;
  if (category == CategoryName.CATEGORY_1)
    return '1. Orientacja na płaszczyźnie';
  if (category == CategoryName.CATEGORY_2) return '2. Liczenie';
  if (category == CategoryName.CATEGORY_3) return '3. Działania na liczbach';
  if (category == CategoryName.SUBCATEGORY_3_1) return '3.1. Dodawanie';
  if (category == CategoryName.SUBCATEGORY_3_2) return '3.2. Odejmowanie';
  if (category == CategoryName.SUBCATEGORY_3_3) return '3.3. Mnożenie';
  if (category == CategoryName.SUBCATEGORY_3_4) return '3.4. Dzielenie';
  if (category == CategoryName.CATEGORY_4) return '4. Zadania z treścią';
  if (category == CategoryName.CATEGORY_5) return '5. Mierzenie długości';
  if (category == CategoryName.CATEGORY_6) return '6. Geometria';
  if (category == CategoryName.SUBCATEGORY_6_1)
    return '6.1. Sześcian – kwadrat';
  if (category == CategoryName.SUBCATEGORY_6_2)
    return '6.2. Prostopadłościan – prostokąt';
  if (category == CategoryName.SUBCATEGORY_6_3)
    return '6.3. Graniastosłup – trójkąt';
  if (category == CategoryName.SUBCATEGORY_6_4)
    return '6.4. Ostrosłup – czworokąt';
  if (category == CategoryName.SUBCATEGORY_6_5) return '6.5. Walec – koło';
  if (category == CategoryName.SUBCATEGORY_6_6) return '6.6. Stożek – koło';
  if (category == CategoryName.SUBCATEGORY_6_7) return '6.7. Kula – koło';
  if (category == CategoryName.CATEGORY_7) return '7. Stosowanie matematyki';
  if (category == CategoryName.CATEGORY_8) return '8. Programowanie';
  if (category == CategoryName.SUBCATEGORY_8_1) return '8.1. Kodowanie';
  if (category == CategoryName.SUBCATEGORY_8_2)
    return '8.2. Programowanie proste';
  if (category == CategoryName.SUBCATEGORY_8_3)
    return '8.3. Programowanie z pętlą';
  if (category == CategoryName.SUBCATEGORY_8_4)
    return '8.4. Programowanie z obrotami';
  if (category == CategoryName.SUBCATEGORY_8_5)
    return '8.5. Programowanie w zagadkach';
  return '';
}
