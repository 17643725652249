import {
  Exercise,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseName,
  ExerciseType,
} from '../../exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Odliczcie ile pól trzeba przejść od niebieskiego do czerwonego klocka',
};

const step2 = {
  number: 2,
  blocks: [
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step3 = {
  number: 3,
  description:
    'Odliczcie ile pól trzeba przejść od niebieskiego do żółtego klocka',
  blocks: [
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  number: 4,
  blocks: [
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  number: 5,
  description:
    'Odliczcie ile pól trzeba przejść od niebieskiego do zielonego klocka',
  blocks: [
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step6 = {
  number: 6,
  blocks: [
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const steps = [step1, step2, step3, step4, step5, step6];

export const c1e22: Exercise = {
  number: 22,
  name: ExerciseName.EXERCISE_22,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  steps: steps,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'blockXShift',
      label: 'Odsunięcie klocka od pionowej osi',
      placeholder: 'Wybierz odsunięcie względem pionowej osi',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
    {
      name: 'blockYShift',
      label: 'Odsunięcie klocka od poziomej osi',
      placeholder: 'Wybierz odsunięcie względem poziomej osi',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
  ],
};
