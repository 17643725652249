import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from 'src/context/AuthContext';
import { customPath } from 'src/utils/paths';
import { changePasswordSchema } from 'src/utils/validation';

type ChangePassword = {
  password: string;
  passwordConfirm: string;
};

export function useAccountSettings() {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const { changePassword, deleteSignedUser, userAccessLevels } = UserAuth();
  const alert = useAlert();
  const navigate = useNavigate();

  async function handleChangePassword(inputs: ChangePassword) {
    setIsLoading(true);
    try {
      await changePassword(inputs.password)?.then(() => {
        navigate(customPath.homepage);
        alert.success('Hasło zostało zmienione');
      });
    } catch (error: unknown) {
      alert.error('Hasło nie zostało zmienione, spróbuj ponownie za chwilę.');
    }
    setIsLoading(false);
  }

  async function handleDeleteAccount() {
    setIsDeletingAccount(true);
    try {
      await deleteSignedUser()?.then(() => {
        navigate(customPath.homepage);
        alert.success('Konto zostało usunięte');
      });
    } catch (error: unknown) {
      alert.error(
        'Nie udało się usunąć konto, spróbuj ponownie za jakiś czas.'
      );
    }
    setIsDeletingAccount(false);
  }

  const form = useForm<ChangePassword>({
    mode: 'onTouched',
    resolver: yupResolver(changePasswordSchema),
  });

  return {
    form,
    handleChangePassword,
    handleDeleteAccount,
    isDeletingAccount,
    userAccessLevels,
    isLoading,
  };
}
