import clsx from 'clsx';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useLocation } from 'react-router-dom';
import { ReactComponent as LogoIcon } from 'src/assets/icons/logo.svg';
import { UserAuth } from 'src/context/AuthContext';
import { label } from 'src/utils/labels';
import { customPath } from 'src/utils/paths';
import { ButtonSize, ButtonTypes } from 'src/utils/types';
import AccountDropdown from '../AccountDropdown';
import Button from '../Button';
import ProductDropdown from '../ProductDropdown';

export default function Header() {
  const { user, logout } = UserAuth();
  const [isLoading, setIsLoading] = useState(false);

  const alert = useAlert();

  async function onLogout() {
    setIsLoading(true);
    try {
      await logout()?.then(() => {
        alert.success('Wylogowanie zakończyło się sukcesem.');
      });
    } catch (error: unknown) {
      alert.error('Nie udało się wylogować, spróbuj ponownie za chwilę.');
    }
    setIsLoading(false);
  }

  const location = useLocation();
  const showAccount =
    location.pathname === customPath.homepage ||
    location.pathname.startsWith('/zadania');
  return (
    <>
      <div
        className='flex w-full flex-col justify-center bg-brown-soft
      bg-opacity-70 py-[14px] px-[20px] text-[11px] md:text-[12px] lg:flex-row lg:items-center lg:text-[14px]'
      >
        <div>
          Dowiedz się więcej czym jest&nbsp;
          <a
            href='https://www.ekomatik.eu'
            target='_blank'
            rel='noreferrer'
            className='text-green-aquamarine'
          >
            Ekomatik »
          </a>
        </div>
        <div className='lg:ml-[30px]'>
          Zestawy Ekomatik oraz inne ekologiczne pomoce dydaktyczne kupisz w
          naszym&nbsp;
          <a
            href='https://www.zabawkipilch.pl'
            target='_blank'
            rel='noreferrer'
            className='text-green-aquamarine'
          >
            Sklepie »
          </a>
        </div>
      </div>
      <div className='mb-[40px] flex w-full flex-col px-[20px] pt-[20px] lg:flex-row lg:justify-between lg:px-[50px]'>
        <div className='order-3 mt-[20px] flex w-full min-w-[210px] lg:order-1 lg:mt-0 lg:w-auto'>
          <ProductDropdown />
        </div>
        <div className='order-1 flex h-[60px] items-center justify-center text-center text-[48px] lg:order-2'>
          <LogoIcon className='h-[50px]' />
        </div>
        <div
          className={clsx('order-2 flex justify-end lg:order-3', {
            'hidden md:flex': !showAccount,
          })}
        >
          <div className='mt-[20px] w-full lg:mt-0 lg:w-auto'>
            {user !== null && user.email ? (
              <AccountDropdown
                login={user.email}
                logout={onLogout}
                isLoading={isLoading}
              />
            ) : (
              <Button
                customClassName='py-[6px] px-[20px] text-[18px]'
                variant={ButtonTypes.primary}
                buttonSize={ButtonSize.BIG}
                to={customPath.singIn}
                isLoading={isLoading}
              >
                {label.homepage.header.buttons.loginLabel}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
