import {
  Exercise,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseName,
  ExerciseType
} from '../../exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jak pokazana na obrazku na ekranie i ułóżcie na niej takie figury, jak pokazane na obrazku na ekranie',
};

const step2 = {
  number: 2,
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 1,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 1,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 2,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 2,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 3,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 3,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 4,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 4,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 4,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step3 = {
  number: 3,
  description:
    'Napiszcie w zeszycie, ile jest czerwonych klocków, ile zielonych klocków, ile żółtych klocków, a ile niebieskich',
};

const step4 = {
  number: 4,
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: 1,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 1,
    },
    {
      idx: 3,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 5,
      idy: 1,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 6,
      idy: 1,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 2,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 2,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ŻÓŁTY,
      content: 7,
    },
    {
      idx: 5,
      idy: 3,
      color: ExerciseColor.ŻÓŁTY,
      content: 8,
    },
    {
      idx: 6,
      idy: 3,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 7,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: 1,
    },
    {
      idx: 8,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: 2,
    },
    {
      idx: 9,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: 3,
    },
    {
      idx: 10,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: 4,
    },
    {
      idx: 7,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: 5,
    },
    {
      idx: 8,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: 6,
    },
    {
      idx: 9,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: 7,
    },
    {
      idx: 10,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: 8,
    },
    {
      idx: 7,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: 9,
    },
    {
      idx: 8,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: 10,
    },
    {
      idx: 9,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: 11,
    },
    {
      idx: 10,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: 12,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.NIEBIESKI,
      content: 13,
    },
    {
      idx: 8,
      idy: 4,
      color: ExerciseColor.NIEBIESKI,
      content: 14,
    },
    {
      idx: 9,
      idy: 4,
      color: ExerciseColor.NIEBIESKI,
      content: 15,
    },
    {
      idx: 10,
      idy: 4,
      color: ExerciseColor.NIEBIESKI,
      content: 16,
    },
  ],
};

const step5 = {
  number: 5,
  description:
    'Zapiszcie w zeszytach, korzystając ze znaku dodawania „+”, jak policzyć wszystkie klocki',
};

const step6 = {
  number: 6,
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: 1,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 3,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: 5,
    },
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 5,
      idy: 1,
      color: ExerciseColor.ŻÓŁTY,
      content: 7,
    },
    {
      idx: 6,
      idy: 1,
      color: ExerciseColor.ŻÓŁTY,
      content: 8,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 5,
      idy: 2,
      color: ExerciseColor.ŻÓŁTY,
      content: 10,
    },
    {
      idx: 6,
      idy: 2,
      color: ExerciseColor.ŻÓŁTY,
      content: 11,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ŻÓŁTY,
      content: 12,
    },
    {
      idx: 5,
      idy: 3,
      color: ExerciseColor.ŻÓŁTY,
      content: 13,
    },
    {
      idx: 6,
      idy: 3,
      color: ExerciseColor.ŻÓŁTY,
      content: 14,
    },
    {
      idx: 7,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: 15,
    },
    {
      idx: 8,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: 16,
    },
    {
      idx: 9,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: 17,
    },
    {
      idx: 10,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: 18,
    },
    {
      idx: 7,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: 19,
    },
    {
      idx: 8,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: 20,
    },
    {
      idx: 9,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: 21,
    },
    {
      idx: 10,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: 22,
    },
    {
      idx: 7,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: 23,
    },
    {
      idx: 8,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: 24,
    },
    {
      idx: 9,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: 25,
    },
    {
      idx: 10,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: 26,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.NIEBIESKI,
      content: 27,
    },
    {
      idx: 8,
      idy: 4,
      color: ExerciseColor.NIEBIESKI,
      content: 28,
    },
    {
      idx: 9,
      idy: 4,
      color: ExerciseColor.NIEBIESKI,
      content: 29,
    },
    {
      idx: 10,
      idy: 4,
      color: ExerciseColor.NIEBIESKI,
      content: 30,
    },
  ],
};

const step7 = {
  number: 7,
  description:
    'Jaka była zasada w budowaniu kolejnych kwadratów na siatce? Gdybyś budował kolejne kwadraty, to ile klocków byłoby w piątym kwadracie? A w siódmym? Jaki jest związek między numerem kwadratu i liczbą klocków w tym kwadracie?',
};

const steps = [step1, step2, step3, step4, step5, step6, step7];

export const c2e7: Exercise = {
  number: 7,
  name: ExerciseName.EXERCISE_7,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: steps,
  availability: 'LIMITED_ACCESS',
};
