import {
  Exercise,
  ExerciseColor,
  ExerciseName,
  ExerciseType,
} from '../../../exerciseTypes';

const step1 = {
  numbersOnAxis: true,
  number: 1,
  description:
    'Wyjmijcie z pudełka dwie kostki czworościenne i jedną sześcienną',
  imageSrc: `${process.env.PUBLIC_URL}/emptyBoard.png`,
};

const step2 = {
  numbersOnAxis: true,
  number: 2,
  imageSrc: `${process.env.PUBLIC_URL}/dices.png`,
};

const step3 = {
  numbersOnAxis: true,
  number: 3,
  description:
    'Wybierzcie z pudełka te czerwone klocki, zielone oraz żółte, na których widoczne są liczby mniejsze lub równe 36. Ułóżcie je obok siatki',
};

const step4 = {
  numbersOnAxis: true,
  number: 4,
  description:
    'Ja będę wymieniał liczby (nauczyciel wymienia liczby z 6. kolumny), a waszym zadaniem będzie ułożenie klocków z odpowiednimi liczbami na siatce, przy czym klocki czerwone układajcie przy lewym brzegu siatki, zielone przy górnym brzegu siatki, a żółte na przekątnej siatki',
};

const step5 = {
  numbersOnAxis: true,
  number: 5,
  blocks: [
    {
      idx: 6,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 6,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 18,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 24,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: 30,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: 36,
    },
  ],
};

const step6 = {
  numbersOnAxis: true,
  number: 6,
  description:
    'Popatrzcie na ułożoną szóstką kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    {
      idx: 6,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 6,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 18,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 24,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: 30,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: 36,
    },
  ],
};

const step7 = {
  numbersOnAxis: true,
  number: 7,
  description: 'Co ciekawego możecie zauważyć patrząc na wypisane liczby?',
  blocks: [
    {
      idx: 6,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 6,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 18,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 24,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: 30,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: 36,
    },
  ],
};

const step8 = {
  numbersOnAxis: true,
  number: 8,
  description:
    'Popatrzcie na ułożoną piąta kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    {
      idx: 6,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 6,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 18,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 24,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: 30,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: 36,
    },
    {
      idx: 5,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 5,
    },
    {
      idx: 5,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 10,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 15,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 20,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: 25,
    },
    {
      idx: 5,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 30,
    },
  ],
};

const step9 = {
  numbersOnAxis: true,
  number: 9,
  description:
    'Popatrzcie na ułożoną czwartą kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    {
      idx: 6,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 6,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 18,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 24,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: 30,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: 36,
    },
    {
      idx: 5,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 5,
    },
    {
      idx: 5,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 10,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 15,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 20,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: 25,
    },
    {
      idx: 5,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 30,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 16,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: 20,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 24,
    },
  ],
};

const step10 = {
  numbersOnAxis: true,
  number: 10,
  description:
    'Popatrzcie na ułożoną trzecią kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    {
      idx: 6,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 6,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 18,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 24,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: 30,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: 36,
    },
    {
      idx: 5,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 5,
    },
    {
      idx: 5,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 10,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 15,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 20,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: 25,
    },
    {
      idx: 5,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 30,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 16,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: 20,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 24,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 3,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: 15,
    },
    {
      idx: 3,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 18,
    },
  ],
};

const step11 = {
  numbersOnAxis: true,
  number: 11,
  description:
    'Popatrzcie na ułożoną drugą kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    {
      idx: 6,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 6,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 18,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 24,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: 30,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: 36,
    },
    {
      idx: 5,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 5,
    },
    {
      idx: 5,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 10,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 15,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 20,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: 25,
    },
    {
      idx: 5,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 30,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 16,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: 20,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 24,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 3,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: 15,
    },
    {
      idx: 3,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 18,
    },
    {
      idx: 2,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 8,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: 10,
    },
    {
      idx: 2,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
  ],
};

const step12 = {
  numbersOnAxis: true,
  number: 12,
  description:
    'Popatrzcie na ułożoną pierwszą kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    {
      idx: 6,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 6,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 18,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 24,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: 30,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: 36,
    },
    {
      idx: 5,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 5,
    },
    {
      idx: 5,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 10,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 15,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 20,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: 25,
    },
    {
      idx: 5,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 30,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 16,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: 20,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 24,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 3,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: 15,
    },
    {
      idx: 3,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 18,
    },
    {
      idx: 2,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 8,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: 10,
    },
    {
      idx: 2,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 3,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 4,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: 5,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
  ],
};

export const c3s3e5: Exercise = {
  number: 5,
  name: ExerciseName.EXERCISE_5,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'blockXShift',
      label: 'Liczba kolumn',
      placeholder: 'Podaj liczbę kolumn',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
    {
      name: 'blockYShift',
      label: 'Liczba wierszy',
      placeholder: 'Podaj liczbę wierszy',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
  ],
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
  ],
};
