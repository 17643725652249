import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.EXERCISE_C6_S7_E2,
  description: 'Wyciągnijcie z pudełka taką samą drewnianą podkładkę',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.EXERCISE_C6_S7_E2,
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.EXERCISE_C6_S7_E2,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C6_S7_E2,
};

const step5 = {
  number: 5,
  customMatrix: CustomMatrix.EXERCISE_C6_S7_E2,
};

const step6 = {
  number: 6,
  description: 'Jaki kształt ma ta płaska powierzchnia?',
  customMatrix: CustomMatrix.EXERCISE_C6_S7_E2,
};

const step7 = {
  number: 7,
  description:
    'Obrysujcie w swoich zeszytach podstawę półkuli i dorysujcie w narysowanym kole średnicę',
  customMatrix: CustomMatrix.EXERCISE_C6_S7_E2,
};

export const c6s7e2: Exercise = {
  number: 2,
  name: ExerciseName.EXERCISE_2,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: [step1, step2, step3, step4, step5, step6, step7],
  availability: 'FULL_ACCESS',
};
