import {
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseStep,
  ExerciseStepBlock,
  getBlockColor,
  Position,
} from 'src/database/exerciseTypes';
import { getPluralColor } from 'src/database/exerciseUtil';

export function getC1E3VariantSteps(
  color: ExerciseColor,
  numberOfBlocks: number,
  alongTheSide: Position
): ExerciseStep[] {
  const blocksArray = Array.from({ length: numberOfBlocks }, (_, i) => i + 1);
  let blocks: ExerciseStepBlock[] = [];
  let asideDescription = '';

  if (alongTheSide === Position.LEFT) {
    blocks = blocksArray.map((element) => ({
      idx: 1,
      idy: element,
      color: getBlockColor(color),
      content: ExerciseBlockContent.BLANK,
    }));
    asideDescription = 'lewym';
  }
  if (alongTheSide === Position.RIGHT) {
    blocks = blocksArray.map((element) => ({
      idx: 10,
      idy: element,
      color: getBlockColor(color),
      content: ExerciseBlockContent.BLANK,
    }));
    asideDescription = 'prawym';
  }

  const colorWithBlocksDescription = getPluralColor(
    getBlockColor(color),
    numberOfBlocks,
    true
  );

  return [
    {
      number: 1,
      description: `Ułóż ${numberOfBlocks} ${colorWithBlocksDescription} przy ${asideDescription} brzegu siatki`,
    },
    {
      number: 2,
      blocks: blocks,
    },
  ];
}
