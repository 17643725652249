import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E3,
  description: 'Wyciągnijcie z pudełka taką samą drewnianą podkładkę',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E3,
};

const step3 = {
  number: 3,
  description:
    'Połóżcie prostopadłościan na jednej ścianie bocznej i przewracajcie go na kolejne ściany boczne. Obrysujcie ołówkiem na kartonie wszystkie ściany boczne prostopadłościanu. Pozostałe ścianki obrysujcie po przeciwnych stronach jednego z prostokątów. Zróbcie to tak, jak na obrazku na ekranie',

  customMatrix: CustomMatrix.EXERCISE_C6_S2_E3,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E3,
};

const step5 = {
  number: 5,
  description:
    'Wytnijcie z kartonu narysowaną siatkę i złóżcie ją tak, aby powstał prostopadłościan, a następnie sklejcie ścianki prostopadłościanu taśmą klejącą.',
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E3,
};

export const c6s2e3: Exercise = {
  number: 3,
  name: ExerciseName.EXERCISE_3,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: [step1, step2, step3, step4, step5],
  availability: 'FULL_ACCESS',
};
