import {
    Corner,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseStep,
    ExerciseStepBlock,
    getBlockColor
} from 'src/database/exerciseTypes';
import { getPluralColor } from 'src/database/exerciseUtil';

export function getC1E5VariantSteps(
  color: ExerciseColor,
  numberOfBlocks: number,
  blocksAcross: Corner
): ExerciseStep[] {
  let blocks: ExerciseStepBlock[] = [];
  let asideStartDescription = '';
  let asideEndDescription = '';
  if (blocksAcross === Corner.LOWER_LEFT) {
    const blocksArray = Array.from({ length: numberOfBlocks }, (_, i) => i + 1);
    blocks = blocksArray.map((element) => ({
      idx: element,
      idy: element,
      color: getBlockColor(color),
      content: ExerciseBlockContent.BLANK,
    }));
    asideStartDescription = 'lewego dolnego';
    asideEndDescription = 'prawego górnego';
  }
  if (blocksAcross === Corner.UPPER_LEFT) {
    const blocksArray = Array.from(
      { length: numberOfBlocks },
      (_, i) => 10 - i
    );
    blocks = blocksArray.map((element, index) => ({
      idx: index + 1,
      idy: element,
      color: getBlockColor(color),
      content: ExerciseBlockContent.BLANK,
    }));
    asideStartDescription = 'lewego górnego';
    asideEndDescription = 'prawego dolnego';
  }

  const colorWithBlocksDescription = getPluralColor(getBlockColor(color), numberOfBlocks, true);

  return [
    {
      number: 1,
      description: `Ułóż ${numberOfBlocks} ${colorWithBlocksDescription} zaczynając od ${asideStartDescription} do ${asideEndDescription} rogu siatki`,
    },
    {
      number: 2,
      blocks: blocks,
    },
  ];
}
