type Props = {
  stepNumber: number;
};

export default function CustomMatrixC7E2({ stepNumber }: Props) {
  if (stepNumber === 1 || stepNumber === 2 || stepNumber === 3) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]' />
        </div>
      </div>
    );
  }

  if (stepNumber === 4) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='text-center text-[10px]'>
              <div className='h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>środa</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 5) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='text-center text-[10px]'>
              <div className='h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>środa</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[50px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>czwartek</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 6) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='text-center text-[10px]'>
              <div className='mx-[50px] h-[50px] w-[50px] border border-solid bg-yellow-default text-center text-[10px]' />
              <span>środa</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[50px] h-[50px] w-[50px] border border-solid bg-yellow-default text-center text-[10px]' />
              <span>czwartek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[50px] h-[50px] w-[50px] border border-solid bg-yellow-default text-center text-[10px]' />
              <span>piątek</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 7) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='text-center text-[10px]'>
              <div className='mx-[50px] h-[50px] w-[50px] border border-solid bg-yellow-default text-center text-[10px]' />
              <span>środa</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[50px] h-[50px] w-[50px] border border-solid bg-yellow-default text-center text-[10px]' />
              <span>czwartek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[50px] h-[50px] w-[50px] border border-solid bg-yellow-default text-center text-[10px]' />
              <span>piątek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[50px] h-[50px] w-[50px] border border-solid bg-yellow-default text-center text-[10px]' />
              <span>sobota</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 10) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>czwartek</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 11) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>czwartek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>piątek</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 12) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>czwartek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>piątek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>sobota</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 13) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>czwartek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>piątek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>sobota</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>niedziela</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 14) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>czwartek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>piątek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>sobota</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>niedziela</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>poniedziałek</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 15) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>czwartek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>piątek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>sobota</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>niedziela</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>poniedziałek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>wtorek</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 16) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>czwartek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>piątek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>sobota</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>niedziela</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>poniedziałek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>wtorek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>środa</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 17) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>czwartek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>piątek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>sobota</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>niedziela</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>poniedziałek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>wtorek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>środa</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>czwartek</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 18) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>czwartek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>piątek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>sobota</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>niedziela</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>poniedziałek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>wtorek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>środa</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>czwartek</span>
            </div>
            <div className='text-center text-[10px]'>
              <div className='mx-[15px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <span>piątek</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div></div>;
}
