type Props = {
  stepNumber: number;
};

export default function CustomMatrixC643E2({ stepNumber }: Props) {
  if (stepNumber === 1) {
    return null;
  }

  if (stepNumber === 2 || stepNumber === 3 || stepNumber === 4) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s4e2_1.png`}
            className='h-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 5 || stepNumber === 6 || stepNumber === 7) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s4e2_2.png`}
            className='h-full'
          />
        </div>
      </div>
    );
  }

  if (
    stepNumber === 8 ||
    stepNumber === 9 ||
    stepNumber === 10 ||
    stepNumber === 11
  ) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s4e2_3.png`}
            className='h-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 12 || stepNumber === 13 || stepNumber === 14) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s4e2_4.png`}
            className='h-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 15 || stepNumber === 16 || stepNumber === 17) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s4e2_5.png`}
            className='h-full'
          />
        </div>
      </div>
    );
  }

  if (
    stepNumber === 18 ||
    stepNumber === 19 ||
    stepNumber === 20 ||
    stepNumber === 21
  ) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s4e2_6.png`}
            className='h-full'
          />
        </div>
      </div>
    );
  }

  return <div></div>;
}
