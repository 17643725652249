import {
    CustomMatrix,
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../../exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step2 = {
  number: 2,
  description: 'Ułóżcie zielony klocek na siatce',
};

const step3 = {
  number: 3,
  blocks: [
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  number: 4,
  description:
    'Wyjmijcie z pudełka klocek ze strzałką oraz połóżcie go na klocku zielonym, przy czym strzałka powinna pokazywać północ',
  blocks: [
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  number: 5,
  blocks: [
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.TRIANGLE,
    },
  ],
};

const step6 = {
  number: 6,
  description:
    'Ja będę wyświetlał kolejne wiersze programu, waszym zadaniem będzie poruszać klockiem ze strzałką tak, jak w poszczególnych komendach wiersza, przy czym położycie żółty klocek w tym miejscu, w którym znalazł się klocek ze strzałką',
  customMatrix: CustomMatrix.BLANK_15x15,
};

const step7 = {
  number: 7,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'C',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
  ],
};

const step8 = {
  number: 8,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'C',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_TO_DOWN_RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
  ],
};

const step9 = {
  number: 9,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'C',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_TO_DOWN_RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
    {
      idx: 3,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 5,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 7,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
  ],
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'C',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_TO_DOWN_RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
    {
      idx: 3,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 5,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 7,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_TO_DOWN_RIGHT_ARROW,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
  ],
};

const step11 = {
  number: 11,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'C',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_TO_DOWN_RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
    {
      idx: 3,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 5,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 7,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_TO_DOWN_RIGHT_ARROW,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_TO_DOWN_LEFT_ARROW,
    },
  ],
};

const step12 = {
  number: 12,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'C',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_TO_DOWN_RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
    {
      idx: 3,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 5,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 7,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_TO_DOWN_RIGHT_ARROW,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_TO_DOWN_LEFT_ARROW,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 7,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
  ],
};

const step13 = {
  number: 13,
  description: 'Porównajcie swoją drogę',
};

const step14 = {
  number: 14,
  blocks: [
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step15 = {
  number: 15,
  blocks: [
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step16 = {
  number: 16,
  blocks: [
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step17 = {
  number: 17,
  blocks: [
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};
export const c8s4e4: Exercise = {
  number: 4,
  name: ExerciseName.EXERCISE_4,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
    step17,
  ],
  lettersOnAxis: true,
};
