import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import './styles/tailwind.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// used to fake basic auth
import { configureFakeBackend } from './fakeBasicAuth/fakeBackend';
configureFakeBackend();

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
