import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Button from 'src/components/Button/Button';
import { ButtonSize, ButtonTypes } from 'src/utils/types';
import { useAccountSettings } from './hooks';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function DeleteAccountDialog({ isOpen, setIsOpen }: Props) {
  const { handleDeleteAccount, isDeletingAccount } = useAccountSettings();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='bg-black fixed inset-0 bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='max-w-[400px] border-solid border-red-default bg-white-default p-[20px]'>
                <Dialog.Title>Usuń konto</Dialog.Title>
                <Dialog.Description>
                  Czy na pewno chcesz usunąć konto? Po usunięciu konta dostęp do
                  aplikacji zostanie całkowicie zablokowany.
                </Dialog.Description>
                <div className='flex'>
                  <Button
                    variant={ButtonTypes.warning}
                    customClassName='w-full mr-[10px]'
                    buttonSize={ButtonSize.MEDIUM}
                    onClick={handleDeleteAccount}
                    isLoading={isDeletingAccount}
                  >
                    Usuń konto
                  </Button>
                  <Button
                    variant={ButtonTypes.secondary}
                    customClassName='w-full ml-[10px]'
                    buttonSize={ButtonSize.MEDIUM}
                    onClick={() => setIsOpen(false)}
                  >
                    Anuluj
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
