import { UserAuth } from 'src/context/AuthContext';
import { HomepageMenu } from 'src/context/HomepageMenuContext';
import { HomepageOption } from 'src/utils/types';
import LimitedAccessContent from './LimitedAccessContent';
import MenuCompetenceTests from './MenuCompetenceTests';
import MenuGuide from './MenuGuide';
import MenuKitContent from './MenuKitContent';
import MenuLoggedExercise from './MenuLoggedExercise/MenuLoggedExercise';
import MenuNotLogged from './MenuNotLogged/MenuNotLogged';
import MenuNotSelected from './MenuNotSelected';
import MenuPresentations from './MenuPresentation/MenuPresentations';
import MenuReport from './MenuReport';

export default function MenuRight() {
  const { selectedOption } = HomepageMenu();
  const { user, userAccessLevels, userProducts, selectedProduct } = UserAuth();

  let Component = <MenuNotSelected />;
  if (
    typeof selectedOption === 'undefined' ||
    selectedOption === HomepageOption.NONE
  ) {
    Component = <MenuNotSelected />;
  } else if (!user) {
    Component = <MenuNotLogged />;
  } else if (
    selectedOption === HomepageOption.EXERCISES &&
    selectedProduct === 'Szkoła'
  ) {
    Component = <MenuLoggedExercise />;
  } else if (selectedOption === HomepageOption.GUIDE) {
    Component = <MenuGuide />;
  } else if (selectedOption === HomepageOption.PRESENTATIONS) {
    if (
      userAccessLevels.includes('FULL_ACCESS') &&
      userProducts.includes(selectedProduct)
    ) {
      Component = <MenuPresentations />;
    } else {
      Component = (
        <LimitedAccessContent option={HomepageOption.PRESENTATIONS} />
      );
    }
  } else if (selectedOption === HomepageOption.REPORT) {
    Component = <MenuReport />;
  } else if (selectedOption === HomepageOption.KIT_CONTENT) {
    Component = <MenuKitContent />;
  } else if (selectedOption === HomepageOption.COMPETENCE_TESTS) {
    if (userAccessLevels.includes('FULL_ACCESS')) {
      Component = <MenuCompetenceTests />;
    } else {
      Component = (
        <LimitedAccessContent option={HomepageOption.COMPETENCE_TESTS} />
      );
    }
  }
  return <>{Component}</>;
}
