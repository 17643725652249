import {
  CustomMatrix,
  Exercise,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseName,
  ExerciseType
} from '../../../exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Uczeń 1.: wyjmij z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step2 = {
  number: 2,
  description:
    'Jaś wyruszył na poszukiwanie Małgosi. Swoją podróż zaczął na polance, na polu I7. Zakończył ją w lesie, przy domku Baby Jagi, na polu A5',
};

const step3 = {
  number: 3,
  description: 'Ułóż na siatce odpowiednie klocki (start i metę)',
};

const step4 = {
  number: 4,
  blocks: [
    {
      idx: 9,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  number: 5,
  blocks: [
    {
      idx: 9,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step6 = {
  number: 6,
  description:
    'Uczeń 2: wyjmij z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
  customMatrix: CustomMatrix.BLANK_15x15,
};

const step7 = {
  number: 7,
  description:
    'Ułóż na swojej siatce program, który odtworzy drogę Jasia od startu do mety',
  customMatrix: CustomMatrix.BLANK_15x15,
};

const step8 = {
  number: 8,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'I',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 7,
    },
  ],
};

const step9 = {
  number: 9,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'I',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 7,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 11,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 12,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
  ],
};

const step10 = {
  number: 10,
  description:
    'Przeczytaj głośno wyświetlony na ekranie program, pokazując jednocześnie najdłuższym klockiem drogę prowadzącą od startu do mety',
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'I',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 7,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 11,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 12,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
  ],
};

export const c8s2e2: Exercise = {
  number: 2,
  name: ExerciseName.EXERCISE_2,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'LIMITED_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
  ],
  lettersOnAxis: true,
};
