import { list, ref, StorageReference } from 'firebase/storage';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MenuOption from 'src/components/MenuOption';
import { storage } from 'src/config/firebase.config';
import { UserAuth } from 'src/context/AuthContext';
import { HomepageMenu } from 'src/context/HomepageMenuContext';
import { label } from 'src/utils/labels';
import { HomepageOption } from 'src/utils/types';
import { getPresentationDisplayName } from './PresentationsUtil';

type Props = {
  isSelected: boolean;
  onOptionSelect: (option: HomepageOption) => void;
};

const presentationDirReference = ref(
  storage,
  `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`
);

export default function Presentations({ isSelected, onOptionSelect }: Props) {
  const { setSelectedPresentation, selectedPresentation } = HomepageMenu();
  const [presentations, setPresentations] = useState<StorageReference[]>([]);
  const { option, element } = useParams();
  const navigate = useNavigate();
  const { user, userAccessLevels, userProducts, selectedProduct } = UserAuth();

  async function onPresentationOption() {
    if (isSelected) {
      navigate('/');
      onOptionSelect(HomepageOption.NONE);
      return;
    }
    onOptionSelect(HomepageOption.PRESENTATIONS);
    await list(presentationDirReference).then((reference) => {
      setPresentations(
        reference.items.filter((i) => {
          return userProducts.includes('Szkoła')
            ? !i.name.includes('przedszkole')
            : i.name.includes('przedszkole');
        })
      );
      if (element) {
        const presentation = reference.items.find(
          (p) => p.name === decodeURIComponent(element)
        );
        if (presentation) {
          navigate(`/prezentacje/${presentation.name}`);
          setSelectedPresentation(presentation);
        }
      } else {
        navigate('/prezentacje');
      }
    });
  }

  function onPresentationSelect(presentation: StorageReference) {
    setSelectedPresentation(presentation);
    navigate(`/prezentacje/${presentation.name}`);
  }

  useEffect(() => {
    if (option === 'prezentacje') {
      onPresentationOption();
    }
  }, []);

  return (
    <>
      <MenuOption
        isSelected={isSelected}
        markAsSelected={
          isSelected && typeof selectedPresentation === 'undefined'
        }
        text={label.homepage.content.menu.presentations.label}
        onOptionSelect={() => onPresentationOption()}
        isDropdown={
          !!user &&
          userAccessLevels.includes('FULL_ACCESS') &&
          userProducts.includes(selectedProduct)
        }
      />
      {isSelected &&
        user &&
        userAccessLevels.includes('FULL_ACCESS') &&
        userProducts.includes(selectedProduct) && (
          <>
            {presentations.map((presentation: StorageReference) => (
              <Fragment key={presentation.name}>
                <MenuOption
                  markAsSelected={
                    typeof selectedPresentation !== 'undefined' &&
                    presentation.name === selectedPresentation?.name
                  }
                  optionClassName='ml-[30px]'
                  onOptionSelect={() => onPresentationSelect(presentation)}
                  isSelected={presentation.name === selectedPresentation?.name}
                  text={getPresentationDisplayName(presentation.name)}
                />
              </Fragment>
            ))}
          </>
        )}
    </>
  );
}
