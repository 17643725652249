import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from 'src/context/AuthContext';
import { useDatabase } from 'src/database/database';
import { customPath } from 'src/utils/paths';
import { extendAccessSchema } from 'src/utils/validation';

type ExtendAccess = {
  code: string;
};

export function useExtendAccess() {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = UserAuth();
  const alert = useAlert();
  const navigate = useNavigate();
  const { extendAccess, getCodes, isCodeUsed } = useDatabase();

  async function handleExtendAccess(inputs: ExtendAccess) {
    setIsLoading(true);
    try {
      if (inputs.code && user?.email) {
        const fetchedCodesRecords = await getCodes();
        const foundCode = fetchedCodesRecords.find(
          (r) => r.KOD === inputs.code
        );
        if (!foundCode) {
          form.setError('code', {
            type: 'custom',
            message: 'Podany kod jest błędny',
          });
          setIsLoading(false);
          return;
        }
        const codeUsed = await isCodeUsed(inputs.code);
        if (codeUsed) {
          form.setError('code', {
            type: 'custom',
            message: 'Kod został już użyty',
          });
          setIsLoading(false);
          return;
        }
        extendAccess(user.email, foundCode).then(() => {
          navigate(customPath.homepage);
          navigate(0);
          alert.success('Dostęp do konta został rozszerzony');
        });
      }
    } catch (error: unknown) {
      alert.error(
        'Dostęp do konta nie został rozszerzony, spróbuj ponownie za chwilę.'
      );
    }
    setIsLoading(false);
  }
  const form = useForm<ExtendAccess>({
    mode: 'onTouched',
    resolver: yupResolver(extendAccessSchema),
  });

  return {
    form,
    handleExtendAccess,
    isLoading,
  };
}
