import { Link } from 'react-router-dom';
import Button from 'src/components/Button';
import { UserAuth } from 'src/context/AuthContext';
import { HomepageMenu } from 'src/context/HomepageMenuContext';
import { Category } from 'src/database/exerciseTypes';
import { getExercisePath } from 'src/database/exerciseUtil';
import { customPath } from 'src/utils/paths';
import { ButtonSize, ButtonTypes } from 'src/utils/types';

export default function MenuLoggedExercise() {
  const {
    selectedCategory,
    selectedSubCategory,
    onCategorySelect,
    onSubCategorySelect,
  } = HomepageMenu();
  const { userAccessLevels, userProducts, selectedProduct } = UserAuth();

  let category: Category | undefined;
  if (
    typeof selectedCategory !== 'undefined' &&
    selectedCategory.exercises.length > 0
  ) {
    category = selectedCategory;
  } else {
    category = selectedSubCategory;
  }

  if (
    typeof category === 'undefined' ||
    (selectedCategory?.subCategories &&
      typeof selectedSubCategory === 'undefined')
  ) {
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <img
          src={`${process.env.PUBLIC_URL}/zestaw-ekomatik.png`}
          className='h-full max-w-full'
        />
      </div>
    );
  }

  function handleClick() {
    onSubCategorySelect(undefined);
    onCategorySelect(undefined);
  }

  const showMessage =
    !userProducts.includes(selectedProduct) ||
    (userAccessLevels.length > 0 && !userAccessLevels.includes('FULL_ACCESS'));

  return (
    <div className='h-full w-full'>
      <div className='text-center text-[21px]'>{category?.name}</div>
      <div className='h-full w-full py-[20px]'>
        <div className='h-full overflow-auto'>
          {category?.exercises
            .filter((exercise) => {
              if (!userProducts.includes('Szkoła')) {
                return exercise.availability === 'LIMITED_ACCESS';
              }
              return userAccessLevels.includes(exercise.availability);
            })
            .map((exercise, index) => {
              const exercisePath = getExercisePath(
                customPath.exercise,
                exercise.name,
                category
              );
              const newVariantPath = getExercisePath(
                customPath.newVariant,
                exercise.name,
                category
              );
              return (
                <div
                  className='mb-[20px] ml-auto items-center justify-between  border-solid border-green-aquamarine p-[10px] text-center md:w-[full] lg:flex lg:text-left'
                  key={index}
                >
                  <div className='text-center text-[18px] text-green-aquamarine'>
                    {exercise.name}
                  </div>
                  <div className='lg:flex '>
                    {exercise.ownVariantOption ? (
                      <Link
                        to={newVariantPath}
                        className='lg:ml-[20px]'
                        onClick={handleClick}
                      >
                        <Button
                          variant={ButtonTypes.secondary}
                          buttonSize={ButtonSize.MEDIUM}
                          customClassName='lg:w-[200px text-[14px] mt-[10px] lg:mt-0'
                        >
                          STWÓRZ NOWY WARIANT
                        </Button>
                      </Link>
                    ) : (
                      <div className='ml-[20px] mt-[10px] lg:mt-0 lg:w-[200px]' />
                    )}
                    <Link
                      to={exercisePath}
                      className='lg:ml-[20px]'
                      onClick={handleClick}
                    >
                      <Button
                        variant={ButtonTypes.primary}
                        buttonSize={ButtonSize.MEDIUM}
                        customClassName='lg:w-[200px] text-[14px] mt-[10px] lg:mt-0'
                      >
                        OTWÓRZ
                      </Button>
                    </Link>
                  </div>
                </div>
              );
            })}
          {showMessage && (
            <div className='flex justify-center text-center text-red-default'>
              Pozostałe ćwiczenia dostępne są tylko dla użytkowników z
              wykupionym dostępem. Aby otrzymać dostęp przejdź do ustawień
              konta, a następnie podaj kod, który otrzymałeś przy zakupie
              zestawu Ekomatik
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
