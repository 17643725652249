import { NewVariant } from 'src/pages/NewVariant/hooks';
import {
  Corner,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseName,
  ExerciseStep,
  Figure,
  getBlockColor,
  Position,
} from '../exerciseTypes';
import { getC1E10VariantSteps } from './exercise10/c1e10v';
import { getC1E11VariantSteps } from './exercise11/c1e11v';
import { getC1E12VariantSteps } from './exercise12/c1e12v';
import { getC1E13VariantSteps } from './exercise13/c1e13v';
import { getC1E14VariantSteps } from './exercise14/c1e14v';
import { getC1E15VariantSteps } from './exercise15/c1e15v';
import { getC1E16VariantSteps } from './exercise16/c1e16v';
import { getC1E17VariantSteps } from './exercise17/c1e17v';
import { getC1E18VariantSteps } from './exercise18/c1e18v';
import { getC1E19VariantSteps } from './exercise19/c1e19v';
import { getC1E2VariantSteps } from './exercise2/c1e2v';
import { getC1E20VariantSteps } from './exercise20/c1e20v';
import { getC1E21VariantSteps } from './exercise21/c1e21v';
import { getC1E22VariantSteps } from './exercise22/c1e22v';
import { getC1E3VariantSteps } from './exercise3/c1e3v';
import { getC1E4VariantSteps } from './exercise4/c1e4v';
import { getC1E5VariantSteps } from './exercise5/c1e5v';
import { getC1E6VariantSteps } from './exercise6/c1e6v';
import { getC1E7VariantSteps } from './exercise7/c1e7v';
import { getC1E8VariantSteps } from './exercise8/c1e8v';
import { getC1E9VariantSteps } from './exercise9/c1e9v';

export function getExerciseCategory1(exercise: string, inputs: NewVariant) {
  if (exercise === ExerciseName.EXERCISE_2) {
    return getC1E2VariantSteps(
      inputs.blockCorner || Corner.LOWER_LEFT,
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.coloredPosition || false
    );
  }
  if (exercise === ExerciseName.EXERCISE_3) {
    return getC1E3VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.maxNumberOfBlocks || 10,
      inputs.alongTheSide || Position.LEFT
    );
  }
  if (exercise === ExerciseName.EXERCISE_4) {
    return getC1E4VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.maxNumberOfBlocks || 10,
      inputs.alongTheSide || Position.DOWN
    );
  }
  if (exercise === ExerciseName.EXERCISE_5) {
    return getC1E5VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.maxNumberOfBlocks || 10,
      inputs.blocksAcross || Corner.UPPER_LEFT
    );
  }
  if (exercise === ExerciseName.EXERCISE_6) {
    return getC1E6VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.maxNumberOfBlocks || 10,
      inputs.blocksAcross || Corner.UPPER_RIGHT
    );
  }
  if (exercise === ExerciseName.EXERCISE_7) {
    return getC1E7VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.figure || Figure.SQUARE,
      inputs.blockCorner || Corner.UPPER_LEFT
    );
  }
  if (exercise === ExerciseName.EXERCISE_8) {
    return getC1E8VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  if (exercise === ExerciseName.EXERCISE_9) {
    return getC1E9VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  if (exercise === ExerciseName.EXERCISE_10) {
    return getC1E10VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  if (exercise === ExerciseName.EXERCISE_11) {
    return getC1E11VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  if (exercise === ExerciseName.EXERCISE_12) {
    return getC1E12VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  if (exercise === ExerciseName.EXERCISE_13) {
    return getC1E13VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  if (exercise === ExerciseName.EXERCISE_14) {
    return getC1E14VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  if (exercise === ExerciseName.EXERCISE_15) {
    return getC1E15VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  if (exercise === ExerciseName.EXERCISE_16) {
    return getC1E16VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  if (exercise === ExerciseName.EXERCISE_17) {
    return getC1E17VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  if (exercise === ExerciseName.EXERCISE_18) {
    return getC1E18VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  if (exercise === ExerciseName.EXERCISE_19) {
    return getC1E19VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  if (exercise === ExerciseName.EXERCISE_20) {
    return getC1E20VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  if (exercise === ExerciseName.EXERCISE_21) {
    return getC1E21VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  if (exercise === ExerciseName.EXERCISE_22) {
    return getC1E22VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.blockXShift || 1,
      inputs.blockYShift || 1
    );
  }
  return [];
}

export function getShiftSteps(
  blockXShift: number,
  blockYShift: number,
  color: ExerciseColor
): ExerciseStep[] {
  const xShiftNumbers = Array.from({ length: blockXShift }, (_, i) => i + 1);
  const yShiftNumbers = Array.from({ length: blockYShift }, (_, i) => i + 1);
  let lastStep = 3;
  const xShiftSteps: ExerciseStep[] = xShiftNumbers.map((x) => {
    const step = {
      number: lastStep,
      axisBlocks: [
        {
          idx: x,
          idy: 0,
          color: getBlockColor(color),
          content: ExerciseBlockContent.BLANK,
        },
      ],
    };
    lastStep = lastStep + 1;
    return step;
  });
  const yShiftSteps = yShiftNumbers.map((y) => {
    const step = {
      number: lastStep,
      blocks: [
        {
          idx: blockXShift,
          idy: y,
          color: getBlockColor(color),
          content: ExerciseBlockContent.BLANK,
        },
      ],
    };
    lastStep = lastStep + 1;
    return step;
  });
  return [...xShiftSteps, ...yShiftSteps];
}
