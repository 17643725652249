import { ExerciseStep } from 'src/database/exerciseTypes';
import { getBlocksAfterCount, getUnitsBlocks } from '../../category3Util';

export function getC3S2E9VariantSteps(
  firstNumber: number,
  secondNumber: number
): ExerciseStep[] {
  const step1 = {
    withDecimals: true,
    number: 1,
    description:
      'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
  };

  const step2 = {
    withDecimals: true,
    number: 2,
    description: `Ułóżcie na lewo od czerwonego szlaczka liczbę ${firstNumber}`,
  };

  const step3 = {
    withDecimals: true,
    number: 3,
    blocks: [...getBlocksAfterCount(firstNumber)],
  };

  const step4 = {
    withDecimals: true,
    number: 4,
    description: `Ile klocków należy zabrać z ułożonych kolumn, aby zostało ich ${secondNumber}?`,
    blocks: [...getBlocksAfterCount(firstNumber)],
  };

  const step5 = {
    withDecimals: true,
    number: 5,
    blocks: [
      ...getBlocksAfterCount(Number(firstNumber) - Number(secondNumber), false),
      ...getUnitsBlocks(secondNumber, true),
    ],
  };

  return [step1, step2, step3, step4, step5];
}
