import {
    CustomMatrix,
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../../exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.BLANK_15x15,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.BLANK_15x15,
  description: 'Ułóżcie na siatce program wyświetlony na ekranie',
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 5,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 7,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
  ],
};

const step4 = {
  number: 4,
  description:
    'Wyjmijcie z pudełka drugą siatkę i połóżcie ją po prawej stronie poprzednio wyjętej',
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 5,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 7,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
  ],
};

const step5 = {
  number: 5,
  description:
    'Połóżcie na niej zielony klocek (start) i czerwony (meta) w takich miejscach, które wyznacza podany na ekranie program',
};

const step6 = {
  number: 6,
  blocks: [
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step7 = {
  number: 7,
  description:
    'Ułóżcie teraz na siatce po lewej stronie ten sam program, który macie już ułożony, ale z użyciem pętli i nawiasów',
  customMatrix: CustomMatrix.BLANK_15x15,
};

const step8 = {
  number: 8,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 5,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 7,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
  ],
};

const step9 = {
  number: 9,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 5,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 7,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
  ],
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 5,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 7,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
  ],
};

const step11 = {
  number: 11,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 5,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 7,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
    {
      idx: 3,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: 5,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 7,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
  ],
};

const step12 = {
  number: 12,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 5,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 7,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
    {
      idx: 3,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: 5,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 7,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
    {
      idx: 3,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: 7,
    },
    {
      idx: 5,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 7,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
  ],
};

const step13 = {
  number: 13,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 5,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 7,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
    {
      idx: 3,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: 5,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 7,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
    {
      idx: 3,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: 7,
    },
    {
      idx: 5,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 7,
      idy: 5,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 5,
      idy: 4,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 4,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
  ],
};

export const c8s3e4: Exercise = {
  number: 4,
  name: ExerciseName.EXERCISE_4,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
  ],
  lettersOnAxis: true,
};
