import clsx from 'clsx';

type Props = {
  stepNumber: number;
};

export default function CustomMatrixC5E1({ stepNumber }: Props) {
  function bigBlock(vertical: boolean) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[150px]': !vertical,
            'h-[150px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>d</div>
      </div>
    );
  }

  function mediumBlock(vertical: boolean) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[100px]': !vertical,
            'h-[100px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>s</div>
      </div>
    );
  }

  function smallBlock(vertical: boolean) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[50px]': !vertical,
            'h-[50px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>m</div>
      </div>
    );
  }

  if (stepNumber === 1 || stepNumber === 2) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'></div>
        </div>
      </div>
    );
  }
  
  if (stepNumber === 3 || stepNumber === 4) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='relative flex'>
              {[...Array(9)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-red-default' />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 5 || stepNumber === 6) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative flex'>
              {[...Array(9)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-red-default' />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 7) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className=' flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative flex'>
              {[...Array(9)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-red-default' />
              ))}
              <div className='absolute bottom-[-25px]'>
                {mediumBlock(false)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 8) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative flex'>
              {[...Array(9)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-red-default' />
              ))}
              <div className='absolute bottom-[-25px] ml-[100px]'>
                {mediumBlock(false)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 9) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative flex'>
              {[...Array(9)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-red-default' />
              ))}
              <div className='absolute bottom-[-25px] ml-[200px]'>
                {mediumBlock(false)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 10) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative flex'>
              {[...Array(9)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-red-default' />
              ))}
              <div className='absolute bottom-[-25px] ml-[300px]'>
                {mediumBlock(false)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 11) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative flex'>
              {[...Array(9)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-red-default' />
              ))}
              <div className='absolute bottom-[-25px] ml-[400px]'>
                {smallBlock(false)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 12) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative flex'>
              {[...Array(9)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-red-default' />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div></div>;
}
