import {
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseStepBlock,
} from '../exerciseTypes';

export function getRandomBlocks(
  numberOfBlocks: number,
  color: ExerciseColor,
  blocksBeforeAdd?: ExerciseStepBlock[]
) {
  const allBlocks: ExerciseStepBlock[] = blocksBeforeAdd || [];
  let x = 0;
  let y = 0;
  for (let i = 0; allBlocks.length < numberOfBlocks; i++) {
    x = Math.floor(Math.random() * 10) + 1;
    y = Math.floor(Math.random() * 10) + 1;
    const block = allBlocks.find((b) => b.idx === x && b.idy === y);
    if (typeof block === 'undefined') {
      allBlocks.push({
        idx: x,
        idy: y,
        color: color,
        content: ExerciseBlockContent.BLANK,
      });
    }
  }
  return allBlocks;
}
