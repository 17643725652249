import { getX, getY } from 'src/database/exerciseUtil';
import {
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseStep,
    ExerciseStepBlock,
    ExerciseType
} from '../../exerciseTypes';

function getStepBlocks(
  minX: number,
  maxX: number,
  minY: number,
  maxY: number,
  color: ExerciseColor,
  withCount?: boolean
): ExerciseStepBlock[] {
  const blocks = [];
  let count = 0;
  for (let y = minY; y <= maxY; y++) {
    for (let x = minX; x <= maxX; x++) {
      count = count + 1;
      blocks.push({
        idx: x,
        idy: y,
        color: color,
        content: withCount ? count : ExerciseBlockContent.BLANK,
      });
    }
  }
  return blocks;
}

function getColor(x: number, y: number) {
  if (x < 6 && y < 6) {
    return ExerciseColor.CZERWONY;
  }
  if (x < 6 && y > 5) {
    return ExerciseColor.ZIELONY;
  }
  if (x > 5 && y > 5) {
    return ExerciseColor.ŻÓŁTY;
  }
  if (x > 5 && y < 6) {
    return ExerciseColor.NIEBIESKI;
  }
}

function getBlocksAsc(number: number, allElements: number) {
  const blocks = [];
  for (let i = 1; i <= allElements; i++) {
    blocks.push({
      idx: getX(i),
      idy: getY(i),
      color: getColor(getX(i), getY(i)),
      content: i <= number && i % 3 === 0 ? i : ExerciseBlockContent.BLANK,
    });
  }
  return blocks;
}

function getStepAllBlocks(): ExerciseStep[] {
  const steps: ExerciseStep[] = [];
  let usedIndex = 6;
  for (let i = 1; i <= 100; i++) {
    if (i % 3 === 0) {
      steps.push({
        number: usedIndex,
        blocks: getBlocksAsc(i, 100),
      });
      usedIndex++;
    }
  }
  return steps;
}

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka pokazana jest na obrazku na ekranie, i ułóżcie na niej taką samą figurę, jak pokazana na obrazku na ekranie',
};

const step2 = {
  number: 2,
  blocks: getStepBlocks(1, 5, 6, 10, ExerciseColor.ZIELONY),
};

const step3 = {
  number: 3,
  blocks: [
    ...getStepBlocks(6, 10, 6, 10, ExerciseColor.ŻÓŁTY),
    ...getStepBlocks(1, 5, 6, 10, ExerciseColor.ZIELONY),
  ],
};

const step4 = {
  number: 4,
  blocks: [
    ...getStepBlocks(6, 10, 6, 10, ExerciseColor.ŻÓŁTY),
    ...getStepBlocks(1, 5, 6, 10, ExerciseColor.ZIELONY),
    ...getStepBlocks(1, 5, 1, 5, ExerciseColor.CZERWONY),
  ],
};

const step5 = {
  number: 5,
  blocks: [
    ...getStepBlocks(6, 10, 6, 10, ExerciseColor.ŻÓŁTY),
    ...getStepBlocks(1, 5, 6, 10, ExerciseColor.ZIELONY),
    ...getStepBlocks(1, 5, 1, 5, ExerciseColor.CZERWONY),
    ...getStepBlocks(6, 10, 1, 5, ExerciseColor.NIEBIESKI),
  ],
};

const steps = [step1, step2, step3, step4, step5, ...getStepAllBlocks()];

export const c2e10: Exercise = {
  number: 10,
  name: ExerciseName.EXERCISE_10,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: steps,
  availability: 'FULL_ACCESS',
};
