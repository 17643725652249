import {
  Exercise,
  ExerciseColor,
  ExerciseName,
  ExerciseStepBlock,
  ExerciseType,
} from '../../../exerciseTypes';

function getBlocks(
  idx: number,
  idyArray: number[],
  color: ExerciseColor,
  firstContentNumber: number,
  toAdd: number
): ExerciseStepBlock[] {
  return idyArray.map((idy, i) => {
    return {
      idx: idx,
      idy: idy,
      color: color,
      content: firstContentNumber + i * toAdd,
    };
  });
}

const step1 = {
  numbersOnAxis: true,
  number: 1,
  description:
    'Wyjmijcie z pudełka dwie kostki czworościenne i jedną sześcienną',
  imageSrc: `${process.env.PUBLIC_URL}/emptyBoard.png`,
};

const step2 = {
  numbersOnAxis: true,
  number: 2,
  imageSrc: `${process.env.PUBLIC_URL}/dices.png`,
};

const step3 = {
  numbersOnAxis: true,
  number: 3,
  description:
    'Wybierzcie z pudełka te czerwone klocki, zielone oraz żółte, na których widoczne są liczby mniejsze lub równe 64. Ułóżcie je obok siatki',
};

const step4 = {
  numbersOnAxis: true,
  number: 4,
  description:
    'Ja będę wymieniał liczby (nauczyciel wymienia liczby z 8. kolumny), a waszym zadaniem będzie ułożenie klocków z odpowiednimi liczbami na siatce, przy czym klocki czerwone układajcie przy lewym brzegu siatki, zielone przy górnym brzegu siatki, a żółte na przekątnej siatki',
};

const step5 = {
  numbersOnAxis: true,
  number: 5,
  blocks: [
    ...getBlocks(8, [10, 9, 8, 7, 6, 5, 4], ExerciseColor.ZIELONY, 8, 8),
    ...getBlocks(8, [3], ExerciseColor.ŻÓŁTY, 64, 0),
  ],
};

const step6 = {
  numbersOnAxis: true,
  number: 6,
  description:
    'Popatrzcie na ułożoną siódmą kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    ...getBlocks(8, [10, 9, 8, 7, 6, 5, 4], ExerciseColor.ZIELONY, 8, 8),
    ...getBlocks(8, [3], ExerciseColor.ŻÓŁTY, 64, 0),
    ...getBlocks(7, [10, 9, 8, 7, 6, 5], ExerciseColor.ZIELONY, 7, 7),
    ...getBlocks(7, [4], ExerciseColor.ŻÓŁTY, 49, 0),
    ...getBlocks(7, [3], ExerciseColor.CZERWONY, 56, 0),
  ],
};

const step7 = {
  numbersOnAxis: true,
  number: 7,
  description:
    'Popatrzcie na ułożoną szóstą kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    ...getBlocks(8, [10, 9, 8, 7, 6, 5, 4], ExerciseColor.ZIELONY, 8, 8),
    ...getBlocks(8, [3], ExerciseColor.ŻÓŁTY, 64, 0),
    ...getBlocks(7, [10, 9, 8, 7, 6, 5], ExerciseColor.ZIELONY, 7, 7),
    ...getBlocks(7, [4], ExerciseColor.ŻÓŁTY, 49, 0),
    ...getBlocks(7, [3], ExerciseColor.CZERWONY, 56, 0),
    ...getBlocks(6, [10, 9, 8, 7, 6], ExerciseColor.ZIELONY, 6, 6),
    ...getBlocks(6, [5], ExerciseColor.ŻÓŁTY, 36, 0),
    ...getBlocks(6, [4, 3], ExerciseColor.CZERWONY, 42, 6),
  ],
};

const step8 = {
  numbersOnAxis: true,
  number: 8,
  description:
    'Popatrzcie na ułożoną piątą kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    ...getBlocks(8, [10, 9, 8, 7, 6, 5, 4], ExerciseColor.ZIELONY, 8, 8),
    ...getBlocks(8, [3], ExerciseColor.ŻÓŁTY, 64, 0),
    ...getBlocks(7, [10, 9, 8, 7, 6, 5], ExerciseColor.ZIELONY, 7, 7),
    ...getBlocks(7, [4], ExerciseColor.ŻÓŁTY, 49, 0),
    ...getBlocks(7, [3], ExerciseColor.CZERWONY, 56, 0),
    ...getBlocks(6, [10, 9, 8, 7, 6], ExerciseColor.ZIELONY, 6, 6),
    ...getBlocks(6, [5], ExerciseColor.ŻÓŁTY, 36, 0),
    ...getBlocks(6, [4, 3], ExerciseColor.CZERWONY, 42, 6),
    ...getBlocks(5, [10, 9, 8, 7], ExerciseColor.ZIELONY, 5, 5),
    ...getBlocks(5, [6], ExerciseColor.ŻÓŁTY, 25, 0),
    ...getBlocks(5, [5, 4, 3], ExerciseColor.CZERWONY, 30, 5),
  ],
};

const step9 = {
  numbersOnAxis: true,
  number: 9,
  description:
    'Popatrzcie na ułożoną czwartą kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    ...getBlocks(8, [10, 9, 8, 7, 6, 5, 4], ExerciseColor.ZIELONY, 8, 8),
    ...getBlocks(8, [3], ExerciseColor.ŻÓŁTY, 64, 0),
    ...getBlocks(7, [10, 9, 8, 7, 6, 5], ExerciseColor.ZIELONY, 7, 7),
    ...getBlocks(7, [4], ExerciseColor.ŻÓŁTY, 49, 0),
    ...getBlocks(7, [3], ExerciseColor.CZERWONY, 56, 0),
    ...getBlocks(6, [10, 9, 8, 7, 6], ExerciseColor.ZIELONY, 6, 6),
    ...getBlocks(6, [5], ExerciseColor.ŻÓŁTY, 36, 0),
    ...getBlocks(6, [4, 3], ExerciseColor.CZERWONY, 42, 6),
    ...getBlocks(5, [10, 9, 8, 7], ExerciseColor.ZIELONY, 5, 5),
    ...getBlocks(5, [6], ExerciseColor.ŻÓŁTY, 25, 0),
    ...getBlocks(5, [5, 4, 3], ExerciseColor.CZERWONY, 30, 5),
    ...getBlocks(4, [10, 9, 8], ExerciseColor.ZIELONY, 4, 4),
    ...getBlocks(4, [7], ExerciseColor.ŻÓŁTY, 16, 0),
    ...getBlocks(4, [6, 5, 4, 3], ExerciseColor.CZERWONY, 20, 4),
  ],
};

const step10 = {
  numbersOnAxis: true,
  number: 10,
  description:
    'Popatrzcie na ułożoną trzecią kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    ...getBlocks(8, [10, 9, 8, 7, 6, 5, 4], ExerciseColor.ZIELONY, 8, 8),
    ...getBlocks(8, [3], ExerciseColor.ŻÓŁTY, 64, 0),
    ...getBlocks(7, [10, 9, 8, 7, 6, 5], ExerciseColor.ZIELONY, 7, 7),
    ...getBlocks(7, [4], ExerciseColor.ŻÓŁTY, 49, 0),
    ...getBlocks(7, [3], ExerciseColor.CZERWONY, 56, 0),
    ...getBlocks(6, [10, 9, 8, 7, 6], ExerciseColor.ZIELONY, 6, 6),
    ...getBlocks(6, [5], ExerciseColor.ŻÓŁTY, 36, 0),
    ...getBlocks(6, [4, 3], ExerciseColor.CZERWONY, 42, 6),
    ...getBlocks(5, [10, 9, 8, 7], ExerciseColor.ZIELONY, 5, 5),
    ...getBlocks(5, [6], ExerciseColor.ŻÓŁTY, 25, 0),
    ...getBlocks(5, [5, 4, 3], ExerciseColor.CZERWONY, 30, 5),
    ...getBlocks(4, [10, 9, 8], ExerciseColor.ZIELONY, 4, 4),
    ...getBlocks(4, [7], ExerciseColor.ŻÓŁTY, 16, 0),
    ...getBlocks(4, [6, 5, 4, 3], ExerciseColor.CZERWONY, 20, 4),
    ...getBlocks(3, [10, 9], ExerciseColor.ZIELONY, 3, 3),
    ...getBlocks(3, [8], ExerciseColor.ŻÓŁTY, 9, 0),
    ...getBlocks(3, [7, 6, 5, 4, 3], ExerciseColor.CZERWONY, 12, 3),
  ],
};

const step11 = {
  numbersOnAxis: true,
  number: 11,
  description:
    'Popatrzcie na ułożoną drugą kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    ...getBlocks(8, [10, 9, 8, 7, 6, 5, 4], ExerciseColor.ZIELONY, 8, 8),
    ...getBlocks(8, [3], ExerciseColor.ŻÓŁTY, 64, 0),
    ...getBlocks(7, [10, 9, 8, 7, 6, 5], ExerciseColor.ZIELONY, 7, 7),
    ...getBlocks(7, [4], ExerciseColor.ŻÓŁTY, 49, 0),
    ...getBlocks(7, [3], ExerciseColor.CZERWONY, 56, 0),
    ...getBlocks(6, [10, 9, 8, 7, 6], ExerciseColor.ZIELONY, 6, 6),
    ...getBlocks(6, [5], ExerciseColor.ŻÓŁTY, 36, 0),
    ...getBlocks(6, [4, 3], ExerciseColor.CZERWONY, 42, 6),
    ...getBlocks(5, [10, 9, 8, 7], ExerciseColor.ZIELONY, 5, 5),
    ...getBlocks(5, [6], ExerciseColor.ŻÓŁTY, 25, 0),
    ...getBlocks(5, [5, 4, 3], ExerciseColor.CZERWONY, 30, 5),
    ...getBlocks(4, [10, 9, 8], ExerciseColor.ZIELONY, 4, 4),
    ...getBlocks(4, [7], ExerciseColor.ŻÓŁTY, 16, 0),
    ...getBlocks(4, [6, 5, 4, 3], ExerciseColor.CZERWONY, 20, 4),
    ...getBlocks(3, [10, 9], ExerciseColor.ZIELONY, 3, 3),
    ...getBlocks(3, [8], ExerciseColor.ŻÓŁTY, 9, 0),
    ...getBlocks(3, [7, 6, 5, 4, 3], ExerciseColor.CZERWONY, 12, 3),
    ...getBlocks(2, [10], ExerciseColor.ZIELONY, 2, 0),
    ...getBlocks(2, [9], ExerciseColor.ŻÓŁTY, 4, 0),
    ...getBlocks(2, [8, 7, 6, 5, 4, 3], ExerciseColor.CZERWONY, 6, 2),
  ],
};

const step12 = {
  numbersOnAxis: true,
  number: 12,
  description:
    'Popatrzcie na ułożoną pierwszą kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    ...getBlocks(8, [10, 9, 8, 7, 6, 5, 4], ExerciseColor.ZIELONY, 8, 8),
    ...getBlocks(8, [3], ExerciseColor.ŻÓŁTY, 64, 0),
    ...getBlocks(7, [10, 9, 8, 7, 6, 5], ExerciseColor.ZIELONY, 7, 7),
    ...getBlocks(7, [4], ExerciseColor.ŻÓŁTY, 49, 0),
    ...getBlocks(7, [3], ExerciseColor.CZERWONY, 56, 0),
    ...getBlocks(6, [10, 9, 8, 7, 6], ExerciseColor.ZIELONY, 6, 6),
    ...getBlocks(6, [5], ExerciseColor.ŻÓŁTY, 36, 0),
    ...getBlocks(6, [4, 3], ExerciseColor.CZERWONY, 42, 6),
    ...getBlocks(5, [10, 9, 8, 7], ExerciseColor.ZIELONY, 5, 5),
    ...getBlocks(5, [6], ExerciseColor.ŻÓŁTY, 25, 0),
    ...getBlocks(5, [5, 4, 3], ExerciseColor.CZERWONY, 30, 5),
    ...getBlocks(4, [10, 9, 8], ExerciseColor.ZIELONY, 4, 4),
    ...getBlocks(4, [7], ExerciseColor.ŻÓŁTY, 16, 0),
    ...getBlocks(4, [6, 5, 4, 3], ExerciseColor.CZERWONY, 20, 4),
    ...getBlocks(3, [10, 9], ExerciseColor.ZIELONY, 3, 3),
    ...getBlocks(3, [8], ExerciseColor.ŻÓŁTY, 9, 0),
    ...getBlocks(3, [7, 6, 5, 4, 3], ExerciseColor.CZERWONY, 12, 3),
    ...getBlocks(2, [10], ExerciseColor.ZIELONY, 2, 0),
    ...getBlocks(2, [9], ExerciseColor.ŻÓŁTY, 4, 0),
    ...getBlocks(2, [8, 7, 6, 5, 4, 3], ExerciseColor.CZERWONY, 6, 2),
    ...getBlocks(1, [10], ExerciseColor.ŻÓŁTY, 1, 0),
    ...getBlocks(1, [9, 8, 7, 6, 5, 4, 3], ExerciseColor.CZERWONY, 2, 1),
  ],
};

export const c3s3e6: Exercise = {
  number: 6,
  name: ExerciseName.EXERCISE_6,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'blockXShift',
      label: 'Liczba kolumn',
      placeholder: 'Podaj liczbę kolumn',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
    {
      name: 'blockYShift',
      label: 'Liczba wierszy',
      placeholder: 'Podaj liczbę wierszy',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
  ],
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
  ],
};
