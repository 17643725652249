import { AccessLevel } from 'src/context/AuthContext';

export enum ExerciseType {
  DEFAULT,
  CUSTOM,
}

export enum ExerciseColor {
  CZERWONY = '#ff0200',
  ZIELONY = '#00b050',
  NIEBIESKI = '#0070c0',
  ŻÓŁTY = '#fdff00',
  BRĄZOWY = '#f8cbad',
}

export function getBlockColor(color: string) {
  if (color === 'CZERWONY') {
    return ExerciseColor.CZERWONY;
  }
  if (color === 'NIEBIESKI') {
    return ExerciseColor.NIEBIESKI;
  }
  if (color === 'ŻÓŁTY') {
    return ExerciseColor.ŻÓŁTY;
  }
  if (color === 'ZIELONY') {
    return ExerciseColor.ZIELONY;
  }
  if (color === 'BRĄZOWY') {
    return ExerciseColor.BRĄZOWY;
  }
  return ExerciseColor.CZERWONY;
}

export enum LineStructure {
  INTERMITTENT,
}

export enum ExerciseBlockContent {
  BLANK = 'BLANK',
  LEFT_ARROW = 'LEFT_ARROW',
  UP_ARROW = 'UP_ARROW',
  RIGHT_ARROW = 'RIGHT_ARROW',
  DOWN_ARROW = 'DOWN_ARROW',
  CIRCLE_ARROW = 'CIRCLE_ARROW',
  FOUR_ARROWS = 'FOUR_ARROWS',
  UP_TO_DOWN_RIGHT_ARROW = 'UP_TO_DOWN_RIGHT_ARROW',
  UP_TO_DOWN_LEFT_ARROW = 'UP_TO_DOWN_LEFT_ARROW',
  TRIANGLE = 'TRIANGLE',
  TRIANGLE_RIGHT = 'TRIANGLE_RIGHT',
  BRACKET_LEFT = 'BRACKET_LEFT',
  BRACKET_RIGHT = 'BRACKET_RIGHT',
  EQUALS = 'EQUALS',
}

export enum Position {
  LEFT = 'Lewy brzeg',
  RIGHT = 'Prawy brzeg',
  UP = 'Górny brzeg',
  DOWN = 'Dolny brzeg',
}

export enum Corner {
  UPPER_LEFT = 'Lewy Górny',
  UPPER_RIGHT = 'Prawy Górny',
  LOWER_LEFT = 'Lewy Dolny',
  LOWER_RIGHT = 'Prawy Dolny',
}

export enum MiddleLine {
  HORIZONTAL = 'Pozioma linia',
  VERTICAL = 'Pionowa linia',
}

export type ExerciseStepBlock = {
  idx: number;
  idy: number;
  color?: ExerciseColor;
  content: ExerciseBlockContent | number | string;
  pulse?: boolean;
  className?: string;
  coloredPosition?: boolean;
  moved?: boolean;
};

export enum MatrixPart {
  UPPER_LEFT = 'Lewy Górny',
  UPPER_RIGHT = 'Prawy Górny',
  LOWER_LEFT = 'Lewy Dolny',
  LOWER_RIGHT = 'Prawy Dolny',
  LEFT = 'Lewy',
  RIGHT = 'Prawy',
  DOWN = 'Dolny',
  UP = 'Górny',
}

export type ExerciseStep = {
  number: number;
  description?: string;
  blocks?: ExerciseStepBlock[];
  leftHelpMatrix?: ExerciseStepBlock[];
  rightHelpMatrix?: ExerciseStepBlock[];
  axisBlocks?: ExerciseStepBlock[];
  markBorders?: Position[];
  markAxisDescription?: Position[];
  markCorner?: Corner;
  markArrow?: Position[];
  markMiddleLine?: MiddleLine[];
  markMatrixParts?: MatrixPart[];
  customMatrix?: CustomMatrix;
  textInsteadMatrix?: string;
  matrixWidth?: number;
  matrixHeight?: number;
  blocksWithoutPaddings?: boolean;
  imageSrc?: string;
  withDecimals?: boolean;
  numbersOnAxis?: boolean;
};

export enum Figure {
  SQUARE = 'Kwadrat',
  RECTANGLE = 'Prostokąt',
  TRIANGLE = 'Trójkąt',
}

// export type VariantChanges = {
//   blocksColor?: boolean;
//   description?: boolean;
//   cornerBlock?: Corner[];
//   coloredPosition?: boolean;
//   maxNumberOfBlocks?: number;
//   blocksAcross?: Corner[];
//   alongTheSide?: Position[];
//   maxBlockXShift?: number;
//   maxBlockYShift?: number;
//   reverse?: boolean;
//   figure?: Figure[];
//   count?: boolean;
//   maxFirstNumber?: number;
//   maxSecondNumber?: number;
//   numberToDivide?: boolean;
//   blocks?: {
//     description: string;
//     idx: number;
//     idy: number;
//   };
// };

export type VariantChanges = {
  name: string;
  label: string;
  placeholder?: string;
  options?: string[] | number[];
  checkbox?: boolean;
};

export enum CustomMatrix {
  EXERCISE_C2_E8,
  EXERCISE_C5_E1,
  EXERCISE_C5_E2,
  EXERCISE_C5_E3,
  EXERCISE_C5_E4,
  EXERCISE_C5_E5,
  EXERCISE_C5_E6,
  EXERCISE_C5_E7,
  EXERCISE_C5_E8,
  EXERCISE_C6_S1_E1,
  EXERCISE_C6_S1_E2,
  EXERCISE_C6_S1_E3,
  EXERCISE_C6_S2_E1,
  EXERCISE_C6_S2_E2,
  EXERCISE_C6_S2_E3,
  EXERCISE_C6_S2_E4,
  EXERCISE_C6_S3_E1,
  EXERCISE_C6_S3_E2,
  EXERCISE_C6_S4_E1,
  EXERCISE_C6_S4_E2,
  EXERCISE_C6_S5_E1,
  EXERCISE_C6_S5_E2,
  EXERCISE_C6_S6_E1,
  EXERCISE_C6_S6_E2,
  EXERCISE_C6_S7_E1,
  EXERCISE_C6_S7_E2,
  EXERCISE_C7_E1,
  EXERCISE_C7_E2,
  EXERCISE_C7_E4,
  EXERCISE_C7_E5,
  BLANK_10x10,
  BLANK_15x15,
}

export type Exercise = {
  number: number;
  name: string;
  ownVariantOption: boolean;
  type: ExerciseType;
  steps: ExerciseStep[];
  lettersOnAxis?: boolean;
  variant?: VariantChanges[];
  availability: AccessLevel;
};

export type Category = {
  number: number;
  categoryName?: string;
  name: string;
  subCategories?: Category[];
  exercises: Exercise[];
};

export enum CategoryName {
  CATEGORY_1 = 'Orientacja na płaszczyźnie',
  CATEGORY_2 = 'Liczenie',
  CATEGORY_3 = 'Działania na liczbach',
  SUBCATEGORY_3_1 = 'Dodawanie',
  SUBCATEGORY_3_2 = 'Odejmowanie',
  SUBCATEGORY_3_3 = 'Mnożenie',
  SUBCATEGORY_3_4 = 'Dzielenie',
  CATEGORY_4 = 'Zadania z treścią',
  CATEGORY_5 = 'Mierzenie długości',
  CATEGORY_6 = 'Geometria',
  SUBCATEGORY_6_1 = 'Sześcian – kwadrat',
  SUBCATEGORY_6_2 = 'Prostopadłościan – prostokąt',
  SUBCATEGORY_6_3 = 'Graniastosłup – trójkąt',
  SUBCATEGORY_6_4 = 'Ostrosłup – czworokąt',
  SUBCATEGORY_6_5 = 'Walec – koło',
  SUBCATEGORY_6_6 = 'Stożek – koło',
  SUBCATEGORY_6_7 = 'Kula – koło',
  CATEGORY_7 = 'Stosowanie matematyki',
  CATEGORY_8 = 'Programowanie',
  SUBCATEGORY_8_1 = 'Kodowanie',
  SUBCATEGORY_8_2 = 'Programowanie proste',
  SUBCATEGORY_8_3 = 'Programowanie z pętlą',
  SUBCATEGORY_8_4 = 'Programowanie z obrotami',
  SUBCATEGORY_8_5 = 'Programowanie w zagadkach',
}

export enum ExerciseName {
  EXERCISE_1 = 'Ćwiczenie 1.',
  EXERCISE_2 = 'Ćwiczenie 2.',
  EXERCISE_3 = 'Ćwiczenie 3.',
  EXERCISE_4 = 'Ćwiczenie 4.',
  EXERCISE_5 = 'Ćwiczenie 5.',
  EXERCISE_6 = 'Ćwiczenie 6.',
  EXERCISE_7 = 'Ćwiczenie 7.',
  EXERCISE_8 = 'Ćwiczenie 8.',
  EXERCISE_9 = 'Ćwiczenie 9.',
  EXERCISE_10 = 'Ćwiczenie 10.',
  EXERCISE_11 = 'Ćwiczenie 11.',
  EXERCISE_12 = 'Ćwiczenie 12.',
  EXERCISE_13 = 'Ćwiczenie 13.',
  EXERCISE_14 = 'Ćwiczenie 14.',
  EXERCISE_15 = 'Ćwiczenie 15.',
  EXERCISE_16 = 'Ćwiczenie 16.',
  EXERCISE_17 = 'Ćwiczenie 17.',
  EXERCISE_18 = 'Ćwiczenie 18.',
  EXERCISE_19 = 'Ćwiczenie 19.',
  EXERCISE_20 = 'Ćwiczenie 20.',
  EXERCISE_21 = 'Ćwiczenie 21.',
  EXERCISE_22 = 'Ćwiczenie 22.',
}
