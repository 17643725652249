type Props = {
  stepNumber: number;
};

export default function CustomMatrixC7E5({ stepNumber }: Props) {
  if (stepNumber === 1) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/emptyBoard.png`}
            className='h-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber > 1) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img src={`${process.env.PUBLIC_URL}/c7e5.png`} className='h-full' />
        </div>
      </div>
    );
  }
  return <div></div>;
}
