type Props = {
  stepNumber: number;
};

export default function CustomMatrixC6S5E1({ stepNumber }: Props) {
  if (stepNumber === 1) {
    return null;
  }

  if (stepNumber === 1 || stepNumber === 2) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/emptyBoard.png`}
            className='h-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 3 || stepNumber === 4) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s5e1_1.png`}
            className='w-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 5 || stepNumber === 6) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s5e1_2.png`}
            className='w-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 7) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s5e1_3.png`}
            className='w-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 8) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img src={`${process.env.PUBLIC_URL}/c6s5e1_4.png`} />
        </div>
      </div>
    );
  }

  if (stepNumber === 9) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img src={`${process.env.PUBLIC_URL}/c6s5e1_5.png`} />
        </div>
      </div>
    );
  }

  if (stepNumber === 10) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img src={`${process.env.PUBLIC_URL}/c6s5e1_6.png`} />
        </div>
      </div>
    );
  }

  if (stepNumber === 11) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img src={`${process.env.PUBLIC_URL}/c6s5e1_7.png`} />
        </div>
      </div>
    );
  }

  return <div></div>;
}
