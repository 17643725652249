import {
    Corner,
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka pokazana jest na obrazku na ekranie, i ułóżcie na niej z klocków zielonych figurę, która pokazana jest na obrazku na ekranie. Policzcie ułożone klocki. Ile ich jest? W jaki sposób można je policzyć?',
};

const step2 = {
  number: 2,
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step3 = {
  number: 3,
  description: 'licząc klocki kolejno',
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 1,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: 5,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
  ],
};

const step4 = {
  number: 4,
  description:
    '2 kolumny, w każdej kolumnie są 3 klocki, czyli 2 • 3, otrzymujemy 6 klocków',
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 1,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  number: 5,
  description:
    '3 wiersze w każdym są 2 klocki, czyli 3 • 2, otrzymujemy 6 klocków',
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 1,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step6 = {
  number: 6,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka pokazana jest na obrazku na ekranie, i ułóżcie na niej z klocków zielonych figurę, która pokazana jest na obrazku na ekranie. Policzcie ułożone klocki. Ile ich jest? W jaki sposób można je policzyć?',
};

const step7 = {
  number: 7,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step8 = {
  number: 8,
  description: 'licząc klocki kolejno',
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 5,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 7,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 1,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
  ],
};

const step9 = {
  number: 9,
  description:
    '4 kolumny, w każdej kolumnie są 2 klocki, czyli 2 • 4, otrzymujemy 8 klocków',
  blocks: [
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 1,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step10 = {
  number: 10,
  description:
    '2 wiersze w każdym są 4 klocki, czyli 4 • 2, otrzymujemy 8 klocków',
  blocks: [
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 1,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step11 = {
  number: 11,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka pokazana jest na obrazku na ekranie, i ułóżcie na niej z klocków zielonych figurę, która pokazana jest na obrazku na ekranie. Policzcie ułożone klocki. Ile ich jest? W jaki sposób można je policzyć?',
};

const step12 = {
  number: 12,
  blocks: [
    {
      idx: 7,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step13 = {
  number: 13,
  description: 'licząc klocki kolejno',
  blocks: [
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 1,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 7,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 5,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 7,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 7,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 9,
    },
    {
      idx: 7,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 10,
    },

    {
      idx: 8,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 11,
    },

    {
      idx: 9,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
  ],
};

const step14 = {
  number: 14,
  description:
    '3 kolumny, w każdej kolumnie są 4 klocki, czyli 3 • 4, otrzymujemy 12 klocków',
  blocks: [
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 1,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 7,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },

    {
      idx: 8,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 9,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step15 = {
  number: 15,
  description:
    '4 wiersze w każdym są 3 klocki, czyli 4 • 3, otrzymujemy 12 klocków',
  blocks: [
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 1,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 7,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },

    {
      idx: 8,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 9,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step16 = {
  number: 16,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka pokazana jest na obrazku na ekranie, i ułóżcie na niej z klocków zielonych figurę, która pokazana jest na obrazku na ekranie. Policzcie ułożone klocki. Ile ich jest? W jaki sposób można je policzyć?',
};

const step17 = {
  number: 17,
  blocks: [
    {
      idx: 7,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step18 = {
  number: 18,
  description: 'licząc klocki kolejno',
  blocks: [
    {
      idx: 7,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: 1,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 7,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: 7,
    },
    {
      idx: 7,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: 10,
    },
    {
      idx: 7,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 13,
    },
    {
      idx: 8,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 8,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: 5,
    },
    {
      idx: 8,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 8,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: 11,
    },
    {
      idx: 8,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 14,
    },
    {
      idx: 9,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 9,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 9,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: 9,
    },
    {
      idx: 9,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 9,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 15,
    },
  ],
};

const step19 = {
  number: 19,
  description:
    '3 kolumny, w każdej kolumnie jest 5 klocków, czyli 3 • 5, otrzymujemy 15 klocków',
  blocks: [
    {
      idx: 7,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 1,
    },
    {
      idx: 8,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 9,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 7,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 8,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 8,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 8,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: 5,
    },

    {
      idx: 8,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step20 = {
  number: 20,
  description:
    '5 wierszy w każdym są 3 klocki, czyli 5 • 3, otrzymujemy 15 klocków',
  blocks: [
    {
      idx: 7,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 1,
    },
    {
      idx: 8,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 9,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 7,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 8,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 8,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 8,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: 5,
    },

    {
      idx: 8,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const steps = [
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  step9,
  step10,
  step11,
  step12,
  step13,
  step14,
  step15,
  step16,
  step17,
  step18,
  step19,
  step20,
];

export const c2e5: Exercise = {
  number: 5,
  name: ExerciseName.EXERCISE_5,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  steps: steps,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'maxNumberOfBlocks',
      label: 'Ilość klocków',
      placeholder: 'Wybierz ilość klocków',
      options: Array.from({ length: 25 }, (_, i) => i + 1),
    },
    {
      name: 'blockCorner',
      label: 'W tym rogu będzie klocek',
      placeholder: 'Wybierz, w którym rogu ma być klocek',
      options: [
        Corner.LOWER_LEFT,
        Corner.LOWER_RIGHT,
        Corner.UPPER_LEFT,
        Corner.UPPER_RIGHT,
      ],
    },
  ],
};
