import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'src/components/Button';
import Dialog from 'src/components/Dialog';
import Footer from 'src/components/Footer';
import Input from 'src/components/Input';
import { UserAuth } from 'src/context/AuthContext';
import { HomepageMenu } from 'src/context/HomepageMenuContext';
import { useDatabase } from 'src/database/database';
import { Exercise } from 'src/database/exerciseTypes';
import { customPath } from 'src/utils/paths';
import { ButtonSize, ButtonTypes } from 'src/utils/types';
import { useNewVariant } from './hooks';

export default function NewVariant() {
  const { categories } = HomepageMenu();
  const { category, subcategory, name } = useParams();
  const { form, createNewVariant } = useNewVariant(category, subcategory, name);
  const [exercise, setExercise] = useState<Exercise>();
  const title = subcategory ? `${category}: ${subcategory}` : category || '';
  const { getUserAccessLevels } = useDatabase();
  const { user } = UserAuth();
  const alert = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      const userAccessLevels = await getUserAccessLevels(user);
      const foundCategory = categories.find((c) => c.name === category);
      const categoryExercises = foundCategory?.exercises || [];
      const currentCategory =
        categoryExercises.length > 0
          ? foundCategory
          : foundCategory?.subCategories?.find((sc) => sc.name === subcategory);

      const availableExercises = currentCategory?.exercises.filter(
        (exercise) => {
          return userAccessLevels.includes(exercise.availability);
        }
      );

      if (
        typeof availableExercises === 'undefined' ||
        !availableExercises.find((e) => e.name === name)
      ) {
        alert.error('Nie masz dostępu do tego zadania');
        navigate(customPath.homepage);
      } else {
        setExercise(availableExercises.find((e) => e.name === name));
      }
    };
    init();
  }, []);

  return (
    <div className='h-full'>
      <Dialog title={title} withClose>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit((i) => createNewVariant(i, name, exercise?.availability))}>
            <div className='h-full p-[30px] shadow shadow-gray-shadow'>
              <div className='text-center text-[24px]'>
                {name} - stwórz nowy wariant
              </div>
              {exercise?.variant?.map((v, index) => (
                <Input
                  key={index}
                  name={v.name}
                  label={v.label}
                  placeholder={v.placeholder}
                  wrapperClassName='mt-[20px]'
                  options={v.options}
                  isSelect={typeof v.options !== 'undefined'}
                  checkbox={v.checkbox === true}
                />
              ))}
              <Button
                variant={ButtonTypes.primary}
                buttonSize={ButtonSize.BIG}
                customClassName='text-[16px] mt-[20px]'
                type='submit'
              >
                ROZPOCZNIJ ĆWICZENIE
              </Button>
            </div>
          </form>
        </FormProvider>
      </Dialog>
      <Footer />
    </div>
  );
}
