import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};

const step2 = {
  number: 2,
  description:
    'Pewien rolnik miał dwie działki różnych kształtów i postanowił je ogrodzić siatką. Zastanawiał się, na którą działkę zużyje więcej siatki. Czy możecie pomóc rolnikowi rozwiązać jego problem?',
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};

const step3 = {
  number: 3,
  description: 'Ułóżcie na swoich podkładkach figury z klocków',
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};

const step5 = {
  number: 5,
  description: 'Co ciekawego możecie powiedzieć o tych figurach?',
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};

const step6 = {
  number: 6,
  description:
    'Jak myślicie, na ogrodzenie której z działek potrzeba więcej siatki?',
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};

const step7 = {
  number: 7,
  description:
    'Wyjmijcie z pudełka najmniejszy fioletowy klocek oznaczony literką m, którym będziecie dokonywali pomiarów',
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};

const step8 = {
  number: 8,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};

const step9 = {
  number: 9,
  description:
    'Zmierzcie długości boków poszczególnych figur, a wyniki zapiszcie w zeszytach.',
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step11 = {
  number: 11,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step12 = {
  number: 12,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step13 = {
  number: 13,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step14 = {
  number: 14,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};

const step15 = {
  number: 15,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step16 = {
  number: 16,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step17 = {
  number: 17,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step18 = {
  number: 18,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step19 = {
  number: 19,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step20 = {
  number: 20,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step21 = {
  number: 21,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step22 = {
  number: 22,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step23 = {
  number: 23,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step24 = {
  number: 24,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step25 = {
  number: 25,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step26 = {
  number: 26,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step27 = {
  number: 27,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step28 = {
  number: 28,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step29 = {
  number: 29,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step30 = {
  number: 30,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step31 = {
  number: 31,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step32 = {
  number: 32,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step33 = {
  number: 33,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step34 = {
  number: 34,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step35 = {
  number: 35,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step36 = {
  number: 36,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step37 = {
  number: 37,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step38 = {
  number: 38,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step39 = {
  number: 39,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step40 = {
  number: 40,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};
const step41 = {
  number: 41,
  customMatrix: CustomMatrix.EXERCISE_C7_E4,
};

export const c7e4: Exercise = {
  number: 4,
  name: ExerciseName.EXERCISE_4,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
    step17,
    step18,
    step19,
    step20,
    step21,
    step22,
    step23,
    step24,
    step25,
    step26,
    step27,
    step28,
    step29,
    step30,
    step31,
    step32,
    step33,
    step34,
    step35,
    step36,
    step37,
    step38,
    step39,
    step40,
    step41,
  ],
};
