import clsx from 'clsx';

type Props = {
  stepNumber: number;
};

export default function CustomMatrixC5E8({ stepNumber }: Props) {
  function bigBlock(vertical: boolean) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[150px]': !vertical,
            'h-[150px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>d</div>
      </div>
    );
  }

  function mediumBlock(vertical: boolean) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[100px]': !vertical,
            'h-[100px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>s</div>
      </div>
    );
  }

  function smallBlock(vertical: boolean, number?: number) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[50px]': !vertical,
            'h-[50px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>{number || 'm'}</div>
      </div>
    );
  }

  if (stepNumber === 1 || stepNumber === 2) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'></div>
        </div>
      </div>
    );
  }

  if (stepNumber === 3 || stepNumber === 4 || stepNumber === 5) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='relative flex'>
              {[...Array(8)].map((e) => (
                <div key={e}>
                  {[...Array(5)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-default'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 6) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative flex'>
              {[...Array(8)].map((e) => (
                <div key={e}>
                  {[...Array(5)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-default'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber > 6) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative flex'>
              {[...Array(8)].map((e) => (
                <div key={e}>
                  {[...Array(5)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-default'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              {stepNumber > 7 && (
                <div className='absolute bottom-[-25px] left-[50px]'>
                  {smallBlock(false, 2)}
                </div>
              )}
              {stepNumber > 8 && (
                <div className='absolute bottom-[-25px] left-[100px]'>
                  {smallBlock(false, 3)}
                </div>
              )}
              {stepNumber > 9 && (
                <div className='absolute bottom-[-25px] left-[150px]'>
                  {smallBlock(false, 4)}
                </div>
              )}
              {stepNumber > 10 && (
                <div className='absolute bottom-[-25px] left-[200px]'>
                  {smallBlock(false, 5)}
                </div>
              )}
              {stepNumber > 11 && (
                <div className='absolute bottom-[-25px] left-[250px]'>
                  {smallBlock(false, 6)}
                </div>
              )}
              {stepNumber > 12 && (
                <div className='absolute bottom-[-25px] left-[300px]'>
                  {smallBlock(false, 7)}
                </div>
              )}
              {stepNumber > 13 && (
                <div className='absolute bottom-[-25px] left-[350px]'>
                  {smallBlock(false, 8)}
                </div>
              )}
              {stepNumber > 14 && (
                <div className='absolute bottom-0 right-[-25px]'>
                  {smallBlock(true, 1)}
                </div>
              )}
              {stepNumber > 15 && (
                <div className='absolute bottom-[50px] right-[-25px]'>
                  {smallBlock(true, 2)}
                </div>
              )}
              {stepNumber > 16 && (
                <div className='absolute bottom-[100px] right-[-25px]'>
                  {smallBlock(true, 3)}
                </div>
              )}
              {stepNumber > 17 && (
                <div className='absolute bottom-[150px] right-[-25px]'>
                  {smallBlock(true, 4)}
                </div>
              )}
              {stepNumber > 18 && (
                <div className='absolute bottom-[200px] right-[-25px]'>
                  {smallBlock(true, 5)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div></div>;
}
