import {
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../../exerciseTypes';

const step1 = {
  number: 1,
};

const step2 = {
  number: 2,
  textInsteadMatrix:
    'Zielone klocki: A1, B2, I9, J10, \n\n Czerwone klocki: C3, D4, G7, H8, \n\n Żółte klocki: E5, F6.',
  description:
    'Zapiszcie kody w zeszycie oraz policzcie, ile jest zielonych, czerwonych i żółtych klocków, a następnie wyjmijcie z pudełka odpowiednią liczbę klocków',
};

const step3 = {
  number: 3,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step4 = {
  number: 4,
  description: 'Ułóżcie wyjęte klocki według podanych kodów',
};

const step5 = {
  number: 5,
  blocks: [
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step6 = {
  number: 6,
  blocks: [
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step7 = {
  number: 7,
  blocks: [
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step8 = {
  number: 8,
  blocks: [
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step9 = {
  number: 9,
  blocks: [
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step10 = {
  number: 10,
  blocks: [
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step11 = {
  number: 11,
  blocks: [
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step12 = {
  number: 12,
  blocks: [
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step13 = {
  number: 13,
  blocks: [
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step14 = {
  number: 14,
  blocks: [
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step15 = {
  number: 15,
  textInsteadMatrix: 'Zielone klocki: B7, B8, B9, C7, C8, C9, D7, D8, D9.',
  description:
    'Zapiszcie kody w zeszycie oraz policzcie, ile jest zielonych klocków, a następnie wyjmijcie z pudełka odpowiednią liczbę klocków',
};

const step16 = {
  number: 16,
  description: 'Ułóżcie wyjęte klocki według podanych kodów',
};

const step17 = {
  number: 17,
  blocks: [
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step18 = {
  number: 18,
  blocks: [
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step19 = {
  number: 19,
  blocks: [
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step20 = {
  number: 20,
  blocks: [
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step21 = {
  number: 21,
  blocks: [
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step22 = {
  number: 22,
  blocks: [
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step23 = {
  number: 23,
  blocks: [
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step24 = {
  number: 24,
  blocks: [
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step25 = {
  number: 25,
  blocks: [
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

export const c8s1e2: Exercise = {
  number: 2,
  name: ExerciseName.EXERCISE_2,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
    step17,
    step18,
    step19,
    step20,
    step21,
    step22,
    step23,
    step24,
    step25,
  ],
  lettersOnAxis: true,
};
