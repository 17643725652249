type Props = {
  stepNumber: number;
};

export default function CustomMatrixC2E8({ stepNumber }: Props) {
  if (stepNumber === 1 || stepNumber > 7) {
    return <div className='w-full' />;
  }

  return (
    <div className='flex h-full items-center justify-center border border-solid p-[20px]'>
      <div className='flex items-end overflow-auto text-[26px]'>
        <div className='text-center'>
          <div className='h-[35px] w-[35px] border border-solid bg-yellow-default xl:h-[60px] xl:w-[60px]'></div>
          <div className='mt-[20px] h-[35px]'>{stepNumber >= 4 && 1}</div>
        </div>
        <div className='ml-[35px] mr-[25px] xl:ml-[60px]'>
          <div className='ml-[20px] h-[35px] w-[35px] border border-solid bg-red-default xl:ml-[35px] xl:h-[60px] xl:w-[60px]'></div>
          <div className='text-center'>
            <div className='flex'>
              <div className='h-[35px] w-[35px] border border-solid bg-yellow-default xl:h-[60px] xl:w-[60px]'></div>
              <div className='h-[35px] w-[35px] border border-solid bg-yellow-default xl:h-[60px] xl:w-[60px]'></div>
            </div>
            <div className='mt-[20px] h-[35px]'>{stepNumber >= 5 && 3}</div>
          </div>
        </div>
        <div className='mx-[25px]'>
          <div className='ml-[35px] h-[35px] w-[35px] border border-solid bg-green-standard xl:ml-[50px] xl:h-[60px] xl:w-[60px]'></div>
          <div className='flex'>
            <div className='ml-[20px] h-[35px] w-[35px] border border-solid bg-red-default xl:h-[60px] xl:w-[60px]'></div>
            <div className='h-[35px] w-[35px] border border-solid bg-red-default xl:h-[60px] xl:w-[60px]'></div>
          </div>
          <div className='text-center'>
            <div className='flex'>
              <div className='h-[35px] w-[35px] border border-solid bg-yellow-default xl:h-[60px] xl:w-[60px]'></div>
              <div className='h-[35px] w-[35px] border border-solid bg-yellow-default xl:h-[60px] xl:w-[60px]'></div>
              <div className='h-[35px] w-[35px] border border-solid bg-yellow-default xl:h-[60px] xl:w-[60px]'></div>
            </div>
            <div className='mt-[20px] h-[35px]'>{stepNumber >= 6 && 6}</div>
          </div>
        </div>
        <div className='ml-[25px]'>
          <div className='ml-[60px] h-[35px] w-[35px] border border-solid bg-blue-default xl:ml-[90px] xl:h-[60px] xl:w-[60px]'></div>
          <div className='flex'>
            <div className='ml-[35px] h-[35px] w-[35px] border border-solid bg-green-standard xl:ml-[60px] xl:h-[60px] xl:w-[60px]'></div>
            <div className='h-[35px] w-[35px] border border-solid bg-green-standard xl:h-[60px] xl:w-[60px]'></div>
          </div>
          <div className='flex'>
            <div className='ml-[20px] h-[35px] w-[35px] border border-solid bg-red-default xl:ml-[35px] xl:h-[60px] xl:w-[60px]'></div>
            <div className='h-[35px] w-[35px] border border-solid bg-red-default xl:h-[60px] xl:w-[60px]'></div>
            <div className='h-[35px] w-[35px] border border-solid bg-red-default xl:h-[60px] xl:w-[60px]'></div>
          </div>
          <div className='text-center'>
            <div className='flex'>
              <div className='h-[35px] w-[35px] border border-solid bg-yellow-default xl:h-[60px] xl:w-[60px]'></div>
              <div className='h-[35px] w-[35px] border border-solid bg-yellow-default xl:h-[60px] xl:w-[60px]'></div>
              <div className='h-[35px] w-[35px] border border-solid bg-yellow-default xl:h-[60px] xl:w-[60px]'></div>
              <div className='h-[35px] w-[35px] border border-solid bg-yellow-default xl:h-[60px] xl:w-[60px]'></div>
            </div>
            <div className='mt-[20px] h-[35px]'>{stepNumber >= 7 && 10}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
