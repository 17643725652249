import {
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseStep,
} from 'src/database/exerciseTypes';

export function getC2E12VariantSteps(
  thousands: number,
  hundreds: number,
  dozens: number,
  units: number
): ExerciseStep[] {
  function getIterations(number: number) {
    return Array.from({ length: number }, (_, i) => i + 1);
  }

  function getBlocks(elements: number[], color: ExerciseColor, idx: number) {
    return elements.map((e) => ({
      idx: idx,
      idy: e,
      color: color,
      content: ExerciseBlockContent.BLANK,
    }));
  }

  const step1 = {
    number: 1,
    withDecimals: true,
    description: `Wprowadź tysięcy: ${thousands}, setek: ${hundreds}, dziesiątek: ${dozens}, jedności: ${units}`,
  };

  const step2 = {
    number: 2,
    withDecimals: true,
    blocks: [
      ...getBlocks(getIterations(thousands), ExerciseColor.ŻÓŁTY, 1),
      ...getBlocks(getIterations(hundreds), ExerciseColor.NIEBIESKI, 2),
      ...getBlocks(getIterations(dozens), ExerciseColor.CZERWONY, 3),
      ...getBlocks(getIterations(units), ExerciseColor.ZIELONY, 4),
    ],
  };

  return [step1, step2];
}
