import { Category, CategoryName } from '../exerciseTypes';
import { c5e1 } from './exercise1/c5e1';
import { c5e2 } from './exercise2/c5e2';
import { c5e3 } from './exercise3/c5e3';
import { c5e4 } from './exercise4/c5e4';
import { c5e5 } from './exercise5/c5e5';
import { c5e6 } from './exercise6/c5e6';
import { c5e7 } from './exercise7/c5e7';
import { c5e8 } from './exercise8/c5e8';

export const category5: Category = {
  number: 5,
  name: CategoryName.CATEGORY_5,
  exercises: [c5e1, c5e2, c5e3, c5e4, c5e5, c5e6, c5e7, c5e8],
};
