import { Link, useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import Matrix from 'src/components/Matrix';
import BlankMatrix10x10 from 'src/components/Matrix/MatrixTypes/BlankMatrix10x10';
import BlankMatrix15x15 from 'src/components/Matrix/MatrixTypes/BlankMatrix15x15';
import CustomMatrixC2E8 from 'src/components/Matrix/MatrixTypes/CustomMatrixC2E8';
import CustomMatrixC5E1 from 'src/components/Matrix/MatrixTypes/CustomMatrixC5E1';
import CustomMatrixC5E2 from 'src/components/Matrix/MatrixTypes/CustomMatrixC5E2';
import CustomMatrixC5E3 from 'src/components/Matrix/MatrixTypes/CustomMatrixC5E3';
import CustomMatrixC5E4 from 'src/components/Matrix/MatrixTypes/CustomMatrixC5E4';
import CustomMatrixC5E5 from 'src/components/Matrix/MatrixTypes/CustomMatrixC5E5';
import CustomMatrixC5E6 from 'src/components/Matrix/MatrixTypes/CustomMatrixC5E6';
import CustomMatrixC5E7 from 'src/components/Matrix/MatrixTypes/CustomMatrixC5E7';
import CustomMatrixC5E8 from 'src/components/Matrix/MatrixTypes/CustomMatrixC5E8';
import CustomMatrixC6S1E1 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S1E1';
import CustomMatrixC6S1E2 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S1E2';
import CustomMatrixC6S1E3 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S1E3';
import CustomMatrixC6S2E1 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S2E1';
import CustomMatrixC6S2E2 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S2E2';
import CustomMatrixC6S2E3 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S2E3';
import CustomMatrixC6S2E4 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S2E4';
import CustomMatrixC6S3E1 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S3E1';
import CustomMatrixC6S3E2 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S3E2';
import CustomMatrixC6S4E1 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S4E1';
import CustomMatrixC6S4E2 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S4E2';
import CustomMatrixC6S5E1 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S5E1';
import CustomMatrixC6S5E2 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S5E2';
import CustomMatrixC6S6E1 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S6E1';
import CustomMatrixC6S6E2 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S6E2';
import CustomMatrixC6S7E1 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S7E1';
import CustomMatrixC6S7E2 from 'src/components/Matrix/MatrixTypes/CustomMatrixC6S7E2';
import CustomMatrixC7E1 from 'src/components/Matrix/MatrixTypes/CustomMatrixC7E1';
import CustomMatrixC7E2 from 'src/components/Matrix/MatrixTypes/CustomMatrixC7E2';
import CustomMatrixC7E4 from 'src/components/Matrix/MatrixTypes/CustomMatrixC7E4';
import CustomMatrixC7E5 from 'src/components/Matrix/MatrixTypes/CustomMatrixC7E5';
import { CustomMatrix, ExerciseStep } from 'src/database/exerciseTypes';
import { ButtonSize, ButtonTypes } from 'src/utils/types';

type Props = {
  currentStep: ExerciseStep | undefined;
  isNextStep: boolean;
  setIsCloseConfirmation: (close: boolean) => void;
  isCloseConfirmation: boolean;
  goBackPath: string;
  lettersOnAxis: boolean | undefined;
  exerciseStarted: boolean | undefined;
  newVariantPath?: string;
};

export default function MiddleSection({
  currentStep,
  isCloseConfirmation,
  setIsCloseConfirmation,
  goBackPath,
  lettersOnAxis,
  exerciseStarted,
  newVariantPath,
}: Props) {
  const navigate = useNavigate();

  if (isCloseConfirmation) {
    return (
      <>
        <div className='flex h-full w-full items-center justify-center'>
          <div className='w-[400px] p-[20px] text-[16px]'>
            <Button
              variant={ButtonTypes.secondary}
              buttonSize={ButtonSize.BIG}
              customClassName='max-w-[400px]'
              onClick={() => setIsCloseConfirmation(false)}
            >
              ANULUJ
            </Button>
            {newVariantPath && (
              <Link to={newVariantPath}>
                <Button
                  variant={ButtonTypes.secondary}
                  buttonSize={ButtonSize.BIG}
                  customClassName='mt-[20px] max-w-[400px]'
                >
                  STWÓRZ NOWY WARIANT
                </Button>
              </Link>
            )}
            <Button
              variant={ButtonTypes.primary}
              buttonSize={ButtonSize.BIG}
              customClassName='mt-[20px] max-w-[400px]'
              onClick={() => navigate(goBackPath)}
            >
              ZAKOŃCZ
            </Button>
          </div>
        </div>
      </>
    );
  }

  if (typeof currentStep?.imageSrc !== 'undefined') {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img src={currentStep.imageSrc} className='max-h-full w-full' />
        </div>
      </div>
    );
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C2_E8) {
    return <CustomMatrixC2E8 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C5_E1) {
    return <CustomMatrixC5E1 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C5_E2) {
    return <CustomMatrixC5E2 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C5_E3) {
    return <CustomMatrixC5E3 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C5_E4) {
    return <CustomMatrixC5E4 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C5_E5) {
    return <CustomMatrixC5E5 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C5_E6) {
    return <CustomMatrixC5E6 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C5_E7) {
    return <CustomMatrixC5E7 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C5_E8) {
    return <CustomMatrixC5E8 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S1_E1) {
    return <CustomMatrixC6S1E1 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S1_E2) {
    return <CustomMatrixC6S1E2 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S1_E3) {
    return <CustomMatrixC6S1E3 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S2_E1) {
    return <CustomMatrixC6S2E1 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S2_E2) {
    return <CustomMatrixC6S2E2 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S2_E3) {
    return <CustomMatrixC6S2E3 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S2_E4) {
    return <CustomMatrixC6S2E4 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S3_E1) {
    return <CustomMatrixC6S3E1 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S3_E2) {
    return <CustomMatrixC6S3E2 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S4_E1) {
    return <CustomMatrixC6S4E1 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S4_E2) {
    return <CustomMatrixC6S4E2 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S5_E1) {
    return <CustomMatrixC6S5E1 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S5_E2) {
    return <CustomMatrixC6S5E2 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S6_E1) {
    return <CustomMatrixC6S6E1 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S6_E2) {
    return <CustomMatrixC6S6E2 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S7_E1) {
    return <CustomMatrixC6S7E1 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C6_S7_E2) {
    return <CustomMatrixC6S7E2 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C7_E1) {
    return <CustomMatrixC7E1 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C7_E2) {
    return <CustomMatrixC7E2 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C7_E4) {
    return <CustomMatrixC7E4 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.EXERCISE_C7_E5) {
    return <CustomMatrixC7E5 stepNumber={currentStep.number} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.BLANK_15x15) {
    return <BlankMatrix15x15 step={currentStep} />;
  }

  if (currentStep?.customMatrix === CustomMatrix.BLANK_10x10) {
    return (
      <BlankMatrix10x10 step={currentStep} exerciseStarted={exerciseStarted} />
    );
  }

  if (typeof currentStep?.textInsteadMatrix === 'string') {
    return (
      <>
        <div className='flex h-full items-center p-[20px] text-[30px]'>
          <div className='whitespace-pre-line'>
            {currentStep.textInsteadMatrix}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Matrix
        step={currentStep}
        lettersOnAxis={lettersOnAxis}
        exerciseStarted={exerciseStarted}
      />
    </>
  );
}
