import { Link } from 'react-router-dom';
import { ReactComponent as BackArrow } from 'src/assets/icons/arrow.svg';
import { label } from 'src/utils/labels';
import { customPath } from 'src/utils/paths';

type Props = {
  backUrl?: string;
  onGoBack?: () => void;
  children: React.ReactNode;
  title: string;
  withClose?: boolean;
  description?: string;
  secondDescription?: string;
};
export default function Dialog({
  backUrl,
  onGoBack,
  children,
  title,
  withClose,
  description,
  secondDescription,
}: Props) {
  return (
    <div className='flex h-full w-full items-center justify-center px-[20px]'>
      <div className='mx-auto min-h-[600px] overflow-auto bg-white-default px-[20px] shadow shadow-gray-shadow md:h-[80%] lg:w-[90%]'>
        <div className='flex justify-between p-[20px]'>
          {backUrl && (
            <Link to={backUrl} className='text-black-default no-underline flex items-center'>
              <BackArrow className='mr-[8px]' />
              <span className='cursor-pointer text-[18px] font-bold'>
                {label.dialog.backLabel}
              </span>
            </Link>
          )}
          {onGoBack && (
            <span
              className='cursor-pointer text-[18px] font-bold'
              onClick={onGoBack}
            >
              <BackArrow className='mr-[8px]' />
              {label.dialog.backLabel}
            </span>
          )}
          {withClose && (
            <Link
              to={customPath.homepage}
              className='ml-auto text-[18px] text-black-default no-underline'
            >
              X
            </Link>
          )}
        </div>
        <div className='mx-auto max-w-[800px] pb-[20px]'>
          <div className='mb-[20px] text-center text-[20px] font-bold md:mb-[40px] md:text-[24px]'>
            {title}
          </div>
          {description && (
            <div className='mb-[20px] text-[16px]'>{description}</div>
          )}
          {secondDescription && (
            <div className='mb-[20px] text-[16px]'>{secondDescription}</div>
          )}
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}
