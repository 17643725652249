import {
  ExerciseColor,
  ExerciseStep,
  ExerciseStepBlock,
} from 'src/database/exerciseTypes';

export function getC3S3E2VariantSteps(
  blockXShift: number,
  blockYShift: number
): ExerciseStep[] {
  function getStepBlocks(): ExerciseStepBlock[] {
    const xShiftNumbers = Array.from({ length: blockXShift }, (_, i) => i + 1);
    const yShiftNumbers = Array.from({ length: blockYShift }, (_, i) => 10 - i);

    const blocks: ExerciseStepBlock[] = [];

    xShiftNumbers.forEach((x, ix) => {
      yShiftNumbers.forEach((y, iy) => {
        let color;
        if (ix === iy) {
          color = ExerciseColor.ŻÓŁTY;
        } else if (ix > iy) {
          color = ExerciseColor.ZIELONY;
        } else {
          color = ExerciseColor.CZERWONY;
        }
        blocks.push({
          idx: x,
          idy: y,
          color: color,
          content: (ix + 1) * (iy + 1),
        });
      });
    });

    return blocks;
  }

  const step1 = {
    number: 1,
    numbersOnAxis: true,
    description:
      'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
  };

  const step2 = {
    number: 2,
    numbersOnAxis: true,
    description: `Wybierzcie z pudełka te klocki, na których widoczne są liczby mniejsze lub równe ${
      blockXShift * blockYShift
    }. Ułóżcie je obok siatki`,
  };

  const step3 = {
    number: 3,
    numbersOnAxis: true,
    description:
      'Ja będę wymieniać liczby, a waszym zadaniem będzie ułożenie klocków z odpowiednimi liczbami na siatce, przy czym czerwone klocki układajcie przy lewym brzegu siatki, zielone przy górnym brzegu siatki, a żółte na przekątnej siatki',
  };

  const step4 = {
    number: 4,
    numbersOnAxis: true,
    blocks: [...getStepBlocks()],
  };

  return [step1, step2, step3, step4];
}
