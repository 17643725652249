import {
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana jest na obrazku na ekranie, i połóżcie czerwony klocek w takim miejscu jak na obrazku na ekranie',
};

const step2 = {
  number: 2,
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step3 = {
  number: 3,
  description:
    'Ułóżcie z zielonych klocków taką samą figurę, jak pokazana na obrazku na ekranie. Policzcie zielone klocki. Ile ich jest? Ile jest razem klocków na siatce?',
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  number: 4,
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  number: 5,
  description:
    'Ułóżcie z żółtych klocków taką samą figurę, jaka jest pokazana na ekranie. Policzcie żółte klocki. Ile ich jest? Ile jest razem klocków na siatce? Zapiszcie w zeszytach, korzystając ze znaku dodawania „+” , jak policzyć wszystkie klocki',
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step6 = {
  number: 6,
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 1,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 1,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 1,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 2,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 2,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 2,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 3,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 3,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 3,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const steps = [step1, step2, step3, step4, step5, step6];

export const c2e6: Exercise = {
  number: 6,
  name: ExerciseName.EXERCISE_6,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: steps,
  availability: 'FULL_ACCESS',
};
