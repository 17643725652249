import clsx from 'clsx';
import { useState } from 'react';
import Button from 'src/components/Button';
import { ButtonSize, ButtonTypes } from 'src/utils/types';

type Props = {
  setNextExercise: () => void;
  isNextExercise: boolean;
  exerciseStepDescription: string | undefined;
  exerciseStarted: boolean;
  ownVariantOption: boolean;
};
export default function RightSection({
  setNextExercise,
  isNextExercise,
  exerciseStepDescription,
  exerciseStarted,
  ownVariantOption,
}: Props) {
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(true);

  return (
    <div className='relative flex h-full items-center justify-center'>
      <div className='h-[370px] md:h-full'></div>
      <div className='absolute top-0 mt-[40px] w-full px-[20px]'>
        <Button
          variant={
            exerciseStepDescription && exerciseStarted
              ? ButtonTypes.secondary
              : ButtonTypes.disabled
          }
          buttonSize={ButtonSize.MEDIUM}
          customClassName='text-[16px]'
          onClick={() =>
            exerciseStepDescription && exerciseStarted
              ? setIsDescriptionVisible(!isDescriptionVisible)
              : null
          }
        >
          {isDescriptionVisible ? (
            <span>SCHOWAJ POLECENIE</span>
          ) : (
            <span>POKAŻ POLECENIE</span>
          )}
        </Button>
      </div>
      {isDescriptionVisible && exerciseStarted && exerciseStepDescription && (
        <div className='max-h-[500px] overflow-y-auto p-[20px] text-[18px]'>
          <div className='whitespace-pre-line'>{exerciseStepDescription}</div>
        </div>
      )}
      {!ownVariantOption && (
        <div
          className={clsx(
            'absolute bottom-0 mb-[20px] w-full px-[10px] text-center text-[18px] underline',
            {
              'cursor-not-allowed text-gray-shadow': !isNextExercise,
              'cursor-pointer text-green-aquamarine': isNextExercise,
            }
          )}
          onClick={isNextExercise ? setNextExercise : () => null}
        >
          Następne ćwiczenie
        </div>
      )}
    </div>
  );
}
