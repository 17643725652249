import { Category, CategoryName } from '../exerciseTypes';
import { c7e1 } from './exercise1/c7e1';
import { c7e2 } from './exercise2/c7e2';
import { c7e3 } from './exercise3/c7e3';
import { c7e4 } from './exercise4/c7e4';
import { c7e5 } from './exercise5/c7e5';
import { c7e6 } from './exercise6/c7e6';

export const category7: Category = {
  number: 7,
  name: CategoryName.CATEGORY_7,
  exercises: [c7e1, c7e2, c7e3, c7e4, c7e5, c7e6],
};
