import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E2,
  description: 'Wyciągnijcie z pudełka taką samą drewnianą podkładkę',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E2,
};

const step3 = {
  number: 3,
  description:
    'Ułóżcie na niej w lewym górnym rogu taki sześcian, jaki jest pokazany na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E2,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E2,
};

const step5 = {
  number: 5,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E2,
  description:
    'Wyszukajcie w pudełku figurę, która ma kształt podobny do ścian sześcianu, i połóżcie ją na podkładce',
};

const step6 = {
  number: 6,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E2,
};

const step7 = {
  number: 7,
  description: 'Czy ktoś wie, jak nazywa się taka figura?',
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E2,
};

const step8 = {
  number: 8,
  description:
    'Pokażcie wierzchołki kwadratu. \n Ile wierzchołków ma kwadrat? \n Wyjmijcie z pudełka fioletowe klocki i zmierzcie nimi długość wszystkich boków kwadratu',
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E2,
};

const step9 = {
  number: 9,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E2,
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E2,
};

const step11 = {
  number: 11,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E2,
};

const step12 = {
  number: 12,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E2,
};

const step13 = {
  number: 13,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E2,
};

export const c6s1e2: Exercise = {
  number: 2,
  name: ExerciseName.EXERCISE_2,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
  ],
};
