import { useState } from 'react';
import { Exercise, ExerciseStep } from 'src/database/exerciseTypes';

export function useExercise() {
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const [currentExercise, setCurrentExercise] = useState<Exercise>();
  const [currentStep, setCurrentStep] = useState<ExerciseStep>();
  const [exerciseStarted, setExerciseStarted] = useState(false);
  const [isCloseConfirmation, setIsCloseConfirmation] = useState(false);

  function setPreviousExercise() {
    if (typeof currentExercise !== 'undefined') {
      const previousExerciseNumber = Math.max(
        ...exercises
          .map((e) => e.number)
          .filter((num) => num < currentExercise.number)
      );
      const exercise = exercises.find(
        (e) => e.number === previousExerciseNumber
      );
      setCurrentExercise(exercise);
      setCurrentStep(exercise?.steps[0]);
      setExerciseStarted(false);
      setIsCloseConfirmation(false);
    }
  }

  function setNextExercise() {
    if (typeof currentExercise !== 'undefined') {
      const nextExerciseNumber = Math.min(
        ...exercises
          .map((e) => e.number)
          .filter((num) => num > currentExercise.number)
      );
      const exercise = exercises.find((e) => e.number === nextExerciseNumber);
      setCurrentExercise(exercise);
      setCurrentStep(exercise?.steps[0]);
      setExerciseStarted(false);
      setIsCloseConfirmation(false);
    }
  }

  function setNextStep() {
    if (typeof currentStep !== 'undefined') {
      const nextStep = currentExercise?.steps[currentStep.number];
      setCurrentStep(nextStep);
    }
  }

  function setPreviousStep() {
    if (typeof currentStep !== 'undefined') {
      const previousStep = currentExercise?.steps[currentStep.number - 2];
      setCurrentStep(previousStep);
    }
  }

  return {
    setExercises,
    setCurrentExercise,
    setCurrentStep,
    currentExercise,
    setPreviousExercise,
    setNextExercise,
    setNextStep,
    setPreviousStep,
    currentStep,
    exerciseStarted,
    setExerciseStarted,
    isCloseConfirmation,
    setIsCloseConfirmation,
    exercises,
  };
}
