import clsx from 'clsx';
import {
  ExerciseStep,
  ExerciseStepBlock,
  MatrixPart,
  MiddleLine,
} from 'src/database/exerciseTypes';
import Block from './Block';

type Props = {
  width: number[];
  step: ExerciseStep | undefined;
  height: number[];
  wrapperClassName?: string;
  blocksWithoutBorders?: boolean;
  leftHelpMatrix?: boolean;
  rightHelpMatrix?: boolean;
  withoutLines?: boolean;
  blocksWithoutPaddings?: boolean;
  exerciseStarted: boolean | undefined;
  withoutMatrixBorder?: boolean;
};

export default function Blocks({
  width,
  height,
  step,
  wrapperClassName,
  blocksWithoutBorders,
  leftHelpMatrix,
  rightHelpMatrix,
  withoutLines,
  blocksWithoutPaddings,
  exerciseStarted,
  withoutMatrixBorder,
}: Props) {
  function findBlock(idx: number, idy: number): ExerciseStepBlock | undefined {
    if (exerciseStarted === false) {
      return undefined;
    }
    if (
      typeof step?.leftHelpMatrix !== 'undefined' &&
      typeof leftHelpMatrix !== 'undefined'
    ) {
      return step.leftHelpMatrix.find(
        (block) => block.idx == idx && block.idy == idy
      );
    }
    if (
      typeof step?.rightHelpMatrix !== 'undefined' &&
      typeof rightHelpMatrix !== 'undefined'
    ) {
      return step.rightHelpMatrix.find(
        (block) => block.idx == idx && block.idy == idy
      );
    }
    if (typeof step?.blocks !== 'undefined') {
      return step.blocks.find((block) => block.idx == idx && block.idy == idy);
    }

    return undefined;
  }

  return (
    <div
      className={clsx(wrapperClassName, 'relative flex h-full justify-center', {
        'border border-solid':
          withoutLines &&
          !leftHelpMatrix &&
          !rightHelpMatrix &&
          !withoutMatrixBorder,
      })}
    >
      {!withoutLines && (
        <>
          <div
            className={clsx(
              'absolute h-full w-[2px] border-[1px] border-dashed border-red-line',
              {
                'border-none bg-blue-default': step?.markMiddleLine?.includes(
                  MiddleLine.VERTICAL
                ),
              }
            )}
          />
          <div
            className={clsx(
              'absolute mt-[51.4%] h-[2px] w-full border-[1px] border-dashed border-green-block',
              {
                'border-none bg-blue-default': step?.markMiddleLine?.includes(
                  MiddleLine.HORIZONTAL
                ),
              }
            )}
          />
        </>
      )}

      {typeof step?.markMatrixParts !== 'undefined' && (
        <div
          className={clsx(
            'absolute border-4 border-solid border-blue-default bg-blue-default bg-opacity-20',
            {
              'top-0 left-0 h-[50%] w-[50%] ': step?.markMatrixParts?.includes(
                MatrixPart.UPPER_LEFT
              ),
              'top-0 right-0 h-[50%] w-[50%]': step?.markMatrixParts?.includes(
                MatrixPart.UPPER_RIGHT
              ),
              'bottom-0 left-0 h-[50%] w-[50%]':
                step?.markMatrixParts?.includes(MatrixPart.LOWER_LEFT),
              'bottom-0 right-0 h-[50%] w-[50%] ':
                step?.markMatrixParts?.includes(MatrixPart.LOWER_RIGHT),
              'top-0 left-0 h-full w-[50%]': step?.markMatrixParts?.includes(
                MatrixPart.LEFT
              ),
              'top-0 right-0 h-full w-[50%]': step?.markMatrixParts?.includes(
                MatrixPart.RIGHT
              ),
              'top-0 right-0 h-[50%] w-full ': step?.markMatrixParts?.includes(
                MatrixPart.UP
              ),
              'bottom-0 right-0 h-[50%] w-full':
                step?.markMatrixParts?.includes(MatrixPart.DOWN),
            }
          )}
        />
      )}

      {width.map((idx) => (
        <div key={idx}>
          {height.map((idy) => {
            const block = findBlock(idx, idy);
            return (
              <div
                className={clsx('h-[40px] w-[40px]', {
                  'border border-solid border-black-default':
                    !blocksWithoutBorders || (block && blocksWithoutPaddings),
                  'mx-[10px] my-[20px]': !blocksWithoutPaddings,
                })}
                key={`${idx}-${idy}`}
              >
                <Block block={block} />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}
