import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserAuth } from 'src/context/AuthContext';
import { HomepageMenu } from 'src/context/HomepageMenuContext';
import {
  Exercise as ExerciseT,
  ExerciseType,
} from 'src/database/exerciseTypes';
import { getExercisePath } from 'src/database/exerciseUtil';
import { customPath } from 'src/utils/paths';
import { useExercise } from './hooks';
import LeftSection from './LeftSection';
import MiddleSection from './MiddleSection';
import RightSection from './RightSection';

interface propState {
  variantExercise?: ExerciseT;
}

export default function Exercise() {
  const { categories } = HomepageMenu();
  const { name, category, subcategory } = useParams();
  const location = useLocation();
  const { user, userProducts, userAccessLevels } = UserAuth();
  const alert = useAlert();
  const navigate = useNavigate();

  const {
    setExercises,
    setCurrentExercise,
    setCurrentStep,
    currentExercise,
    setNextStep,
    setPreviousExercise,
    setPreviousStep,
    currentStep,
    exerciseStarted,
    setExerciseStarted,
    isCloseConfirmation,
    setIsCloseConfirmation,
    setNextExercise,
    exercises,
  } = useExercise();

  useEffect(() => {
    const state = location?.state as propState;
    if (typeof state?.variantExercise !== 'undefined') {
      if (
        !userProducts.includes('Szkoła') &&
        state.variantExercise.availability === 'FULL_ACCESS'
      ) {
        alert.error('Nie masz dostępu do tego zadania');
        navigate(customPath.homepage);
      } else if (
        !userAccessLevels.includes(state.variantExercise.availability)
      ) {
        alert.error('Nie masz dostępu do tego zadania');
        navigate(customPath.homepage);
      } else {
        setCurrentExercise(state.variantExercise);
        setExercises([state.variantExercise]);
        setCurrentStep(state.variantExercise.steps[0]);
        return;
      }
    }

    const foundCategory = categories.find((c) => c.name === category);
    const categoryExercises = foundCategory?.exercises || [];
    const currentCategory =
      categoryExercises.length > 0
        ? foundCategory
        : foundCategory?.subCategories?.find((sc) => sc.name === subcategory);

    const availableExercises = currentCategory?.exercises.filter((exercise) => {
      if (!userProducts.includes('Szkoła')) {
        return exercise.availability === 'LIMITED_ACCESS';
      }
      return userAccessLevels.includes(exercise.availability);
    });

    if (
      typeof availableExercises === 'undefined' ||
      !availableExercises.find((e) => e.name === name)
    ) {
      alert.error('Nie masz dostępu do tego zadania');
      navigate(customPath.homepage);
    } else {
      setExercises(availableExercises);
      setCurrentExercise(availableExercises.find((e) => e.name === name));
      setCurrentStep(availableExercises.find((e) => e.name === name)?.steps[0]);
    }
  }, [user, userProducts]);

  const goBackPath = subcategory
    ? `/zadania/${category}/${subcategory}`
    : `/zadania/${category}`;

  const exercisePath = getExercisePath(
    customPath.newVariant,
    currentExercise?.name || '',
    {
      name: subcategory || category,
      categoryName: subcategory ? category : undefined,
    }
  );

  return (
    <div className='h-full w-full items-center justify-center lg:flex'>
      <div className='flex min-h-[600px] w-full flex-col p-[30px] md:shadow md:shadow-gray-shadow lg:h-[95%] lg:w-[98%] lg:flex-row lg:p-0'>
        <div className='relative order-1 w-full bg-white-default lg:w-[400px]'>
          <LeftSection
            name={currentExercise?.name}
            category={category}
            subcategory={subcategory}
            goBackPath={goBackPath}
            setPreviousExercise={setPreviousExercise}
            isPreviousExercise={
              typeof currentExercise !== 'undefined' &&
              exercises.some((e) => e.number < currentExercise.number)
            }
            setNextStep={setNextStep}
            setPreviousStep={setPreviousStep}
            isNextStep={
              typeof currentExercise !== 'undefined' &&
              typeof currentStep !== 'undefined' &&
              currentStep?.number < currentExercise.steps.length
            }
            isPreviousStep={
              typeof currentExercise !== 'undefined' &&
              typeof currentStep !== 'undefined' &&
              currentStep?.number > 1
            }
            exerciseStarted={exerciseStarted}
            setExerciseStarted={setExerciseStarted}
            setIsCloseConfirmation={setIsCloseConfirmation}
            isCloseConfirmation={isCloseConfirmation}
            ownVariantOption={currentExercise?.type === ExerciseType.CUSTOM}
          />
        </div>
        <div className='order-3 mt-[20px] w-full overflow-auto bg-white-default shadow shadow-gray-shadow md:order-2 lg:mt-0'>
          <MiddleSection
            currentStep={currentStep}
            isNextStep={
              typeof currentExercise !== 'undefined' &&
              typeof currentStep !== 'undefined' &&
              currentStep?.number < currentExercise.steps.length
            }
            isCloseConfirmation={isCloseConfirmation}
            setIsCloseConfirmation={setIsCloseConfirmation}
            goBackPath={goBackPath}
            lettersOnAxis={currentExercise?.lettersOnAxis}
            exerciseStarted={exerciseStarted}
            newVariantPath={
              currentExercise?.type == ExerciseType.DEFAULT &&
              currentExercise?.ownVariantOption
                ? exercisePath
                : undefined
            }
          />
        </div>
        <div className='order-2 mt-[20px] w-full overflow-x-auto bg-white-default shadow shadow-gray-shadow md:order-3 lg:mt-0 lg:w-[400px]'>
          <RightSection
            setNextExercise={setNextExercise}
            isNextExercise={
              typeof currentExercise !== 'undefined' &&
              exercises.some((e) => e.number > currentExercise.number)
            }
            exerciseStarted={exerciseStarted}
            exerciseStepDescription={currentStep?.description}
            ownVariantOption={currentExercise?.type === ExerciseType.CUSTOM}
          />
        </div>
      </div>
    </div>
  );
}
