import {
  CustomMatrix,
  Exercise,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseName,
  ExerciseType
} from '../../../exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step2 = {
  number: 2,
  description: 'Ułóżcie klocki według danych, które usłyszycie',
};

const step3 = {
  number: 3,
  description:
    'Jaś podąża tropem leśnych zwierząt. Swoją wędrówkę rozpoczął na polance (D3). Wyciągnął lornetkę i spojrzał na las, który znajdował się na wschodzie. Postanowił, że pójdzie w jego kierunku. Doszedł do lasu (I3), przeszedł obok paśników dla saren (J4). Idąc dalej, po lewej stronie minął rodzinę zajęcy (I5), potem ominął wielkie bagno, w którym kąpały się dziki (G4) i doszedł do chaty leśniczego (F5)',
};

const step4 = {
  number: 4,
  blocks: [
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  number: 5,
  blocks: [
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step6 = {
  number: 6,
  blocks: [
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step7 = {
  number: 7,
  blocks: [
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step8 = {
  number: 8,
  blocks: [
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step9 = {
  number: 9,
  blocks: [
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.BLANK_15x15,
  description:
    'Wyjmijcie z pudełka siatkę, którą używamy do układania programów',
};

const step11 = {
  number: 11,
  customMatrix: CustomMatrix.BLANK_15x15,
  description: 'Patrząc na swój układ klocków opiszcie trasę Jasia',
};

const step12 = {
  number: 12,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 6,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
    {
      idx: 3,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_TO_DOWN_RIGHT_ARROW,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_TO_DOWN_RIGHT_ARROW,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 7,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },

    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_TO_DOWN_LEFT_ARROW,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_TO_DOWN_RIGHT_ARROW,
    },
    {
      idx: 3,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 7,
      idy: 6,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
  ],
};

export const c8s4e2: Exercise = {
  number: 2,
  name: ExerciseName.EXERCISE_2,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'LIMITED_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
  ],
  lettersOnAxis: true,
};
