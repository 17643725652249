import {
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseStepBlock,
} from './exerciseTypes';

export function getX(index: number): number {
  if (index > 90) return 10;
  if (index > 80) return 9;
  if (index > 70) return 8;
  if (index > 60) return 7;
  if (index > 50) return 6;
  if (index > 40) return 5;
  if (index > 30) return 4;
  if (index > 20) return 3;
  if (index > 10) return 2;
  return 1;
}

export function getY(index: number): number {
  if (index > 90) return index - 90;
  if (index > 80) return index - 80;
  if (index > 70) return index - 70;
  if (index > 60) return index - 60;
  if (index > 50) return index - 50;
  if (index > 40) return index - 40;
  if (index > 30) return index - 30;
  if (index > 20) return index - 20;
  if (index > 10) return index - 10;
  return index;
}

export function getBlocksInGivenIdx(
  numberOfBlocks: number,
  idx: number,
  color: ExerciseColor
): ExerciseStepBlock[] {
  const blocksArray: number[] = Array.from(
    { length: numberOfBlocks },
    (_, i) => i + 1
  );
  return blocksArray.map((element) => {
    return {
      idx: idx,
      idy: element,
      color: color,
      content: ExerciseBlockContent.BLANK,
    };
  });
}

export function getPluralColor(
  color: ExerciseColor,
  number: number,
  withBlocks?: boolean
): string {
  if (color == ExerciseColor.CZERWONY) {
    if (number < 2) return withBlocks ? 'czerwony klocek' : 'czerwony';
    return number > 4 && number < 22
      ? `${withBlocks ? 'czerwonych klocków' : 'czerwonych'}`
      : `${withBlocks ? 'czerwone klocki' : 'czerwone'}`;
  }
  if (color == ExerciseColor.ZIELONY) {
    if (number < 2) return withBlocks ? 'zielony klocek' : 'zielony';
    return number > 4 && number < 22
      ? `${withBlocks ? 'zielonych klocków' : 'zielonych'}`
      : `${withBlocks ? 'zielone klocki' : 'zielone'}`;
  }
  if (color == ExerciseColor.NIEBIESKI) {
    if (number < 2) return withBlocks ? 'niebieski klocek' : 'niebieski';
    return number > 4 && number < 22
      ? `${withBlocks ? 'niebieskich klocków' : 'niebieskich'}`
      : `${withBlocks ? 'niebieskie klocki' : 'niebieskie'}`;
  }
  if (color == ExerciseColor.ŻÓŁTY) {
    if (number < 2) return withBlocks ? 'żółty klocek' : 'żółty';
    return number > 4 && number < 22
      ? `${withBlocks ? 'żółtych klocków' : 'żółtych'}`
      : `${withBlocks ? 'żółte klocki' : 'żółte'}`;
  }
  if (color == ExerciseColor.BRĄZOWY) {
    if (number < 2) return withBlocks ? 'brązowy klocek' : 'brązowy';
    return number > 4 && number < 22
      ? `${withBlocks ? 'brązowych klocków' : 'brązowych'}`
      : `${withBlocks ? 'brązowe klocki' : 'brązowe'}`;
  }
  return '';
}

export function getExercisePath(
  basicPath: string,
  name: string,
  category?: { categoryName?: string; name?: string }
): string {
  if (typeof category === 'undefined') {
    return '';
  }
  if (typeof category.categoryName === 'undefined') {
    return `${basicPath}/${category.name}/${name}`;
  } else {
    return `${basicPath}/${category.categoryName}/${category.name}/${name}`;
  }
}
