import {
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../exerciseTypes';

const step1 = {
  number: 1,
  description: 'Policzcie głośno razem ze mną pokazane na ekranie kwadraciki',
};

const step2 = {
  number: 2,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step3 = {
  number: 3,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  number: 4,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  number: 5,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step6 = {
  number: 6,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step7 = {
  number: 7,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step8 = {
  number: 8,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step9 = {
  number: 9,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 7,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step10 = {
  number: 10,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 7,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 8,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step11 = {
  number: 11,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 7,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 8,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step12 = {
  number: 12,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 7,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 8,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 10,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step13 = {
  number: 13,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 7,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 8,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 10,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: 11,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step14 = {
  number: 14,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 7,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 8,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 10,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: 11,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: 12,
    },
  ],
};

const step15 = {
  number: 15,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 7,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 8,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 10,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: 11,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step16 = {
  number: 16,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 7,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 8,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 10,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step17 = {
  number: 17,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 7,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 8,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step18 = {
  number: 18,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 7,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 8,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step19 = {
  number: 19,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 7,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step20 = {
  number: 20,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 6,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step21 = {
  number: 21,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 5,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step22 = {
  number: 22,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step23 = {
  number: 23,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 3,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step24 = {
  number: 24,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 2,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step25 = {
  number: 25,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};
const step26 = {
  number: 26,
  blocks: [
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const steps = [
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  step9,
  step10,
  step11,
  step12,
  step13,
  step14,
  step15,
  step16,
  step17,
  step18,
  step19,
  step20,
  step21,
  step22,
  step23,
  step24,
  step25,
  step26,
];

export const c2e1: Exercise = {
  number: 1,
  name: ExerciseName.EXERCISE_1,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  steps: steps,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'maxNumberOfBlocks',
      label: 'Ilość klocków',
      placeholder: 'Wybierz ilość klocków',
      options: Array.from({ length: 100 }, (_, i) => i + 1),
    },
    {
      name: 'reverse',
      label: 'Liczenie od końca',
      checkbox: true,
    },
  ],
};
