import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step2 = {
  number: 2,
  description:
    'Ułóżcie z klocków 4 kolumny takie same, jak na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
  description:
    'Która kolumna jest najniższa? A która najwyższa? \n Wymieńcie kolejno kolumny od najniższej do najwyższej. \n Wymieńcie kolejno kolumny od najwyższej do najniższej. \n Która kolumna jest 4 razy wyższa od kolumny czerwonej? \n Która kolumna jest o 3 klocki wyższa od kolumny żółtej? \n Która kolumna jest o 6 klocków wyższa od kolumny żółtej? \n Która kolumna jest o 9 klocków wyższa od kolumny czerwonej?',
};

const step5 = {
  number: 5,
  description:
    'Wyjmijcie z pudełka fioletowe klocki, które posłużą nam do mierzenia wysokości kolumn',
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step6 = {
  number: 6,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step7 = {
  number: 7,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
  description:
    'Najpierw zmierzymy długość poszczególnych kolumn za pomocą klocka oznaczonego literą d',
};

const step8 = {
  number: 8,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step9 = {
  number: 9,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step11 = {
  number: 11,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step12 = {
  number: 12,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step13 = {
  number: 13,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step14 = {
  number: 14,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step15 = {
  number: 15,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step16 = {
  number: 16,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step17 = {
  number: 17,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step18 = {
  number: 18,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step19 = {
  number: 19,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step20 = {
  number: 20,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step21 = {
  number: 21,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
};

const step22 = {
  number: 22,
  customMatrix: CustomMatrix.EXERCISE_C5_E6,
  description: 'Co można powiedzieć o otrzymanych liczbach?',
};

export const c5e6: Exercise = {
  number: 6,
  name: ExerciseName.EXERCISE_6,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
    step17,
    step18,
    step19,
    step20,
    step21,
    step22,
  ],
};
