import { User } from 'firebase/auth';
import {
  collection,
  deleteDoc,
  doc,
  FieldValue,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore';
import {
  getBytes,
  getDownloadURL,
  getMetadata,
  listAll,
  ref,
  StorageReference,
  uploadBytes,
} from 'firebase/storage';
import { useAlert } from 'react-alert';
import { db, storage } from 'src/config/firebase.config';
import { AccessLevel } from 'src/context/AuthContext';
import { label } from 'src/utils/labels';
import * as XLSX from 'xlsx';
import { categories } from './exercises';
import { Category } from './exerciseTypes';

const UsersCollection = 'users';
const ReportCollection = 'report';

type UserInfo = {
  login: string;
  createDate: FieldValue;
  accessLevel: AccessLevel;
  clientType: string;
  code?: ExcelCodeRecord | null;
};

type Report = {
  report: string;
  reporter: string;
  createDate: FieldValue;
  fileUrl?: string;
};

export type ExcelCodeRecord = {
  ['lp.']: string;
  KOD: string;
  Produkt: string;
  ['kod ważny od']: string;
  ['kod ważny do']: string;
  ['Nazwa klienta']: string;
  ['Typ klienta']: string;
  ['Adres wysyłki']: string;
  ['Nr telefonu']: string;
  ['E-mail']: string;
  ['Uwagi']: string;
  ['Nr faktury']: string;
  ['Wartość faktury [NETTO]']: string;
};

export type Product = 'Szkoła' | 'Przedszkole';

export function useDatabase() {
  const alert = useAlert();

  async function saveUserInfo(
    login: string,
    clientType: string,
    code?: ExcelCodeRecord
  ) {
    const userInfo: UserInfo = {
      login: login.toLowerCase(),
      createDate: serverTimestamp(),
      accessLevel: code ? 'FULL_ACCESS' : 'LIMITED_ACCESS',
      clientType: clientType,
      code: code || null,
    };

    try {
      const docRef = doc(db, UsersCollection, login);
      await setDoc(docRef, userInfo);
    } catch (e) {
      alert.error(label.alerts.error.common);
    }
  }

  async function extendAccess(login: string, code: ExcelCodeRecord) {
    if (!login) {
      alert.error(label.alerts.error.common);
      return;
    }
    try {
      const docRef = doc(db, UsersCollection, login);
      const docSnap = await getDoc(docRef);
      await setDoc(docRef, {
        ...docSnap.data(),
        accessLevel: 'FULL_ACCESS',
        code: code,
      });
    } catch (e) {
      alert.error(label.alerts.error.common);
    }
  }

  async function getUserProducts(user: User | null): Promise<Product[]> {
    if (user === null || user.email === null) {
      return [];
    }
    try {
      const docRef = doc(db, UsersCollection, user.email.toLowerCase());
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const product = docSnap.data()?.code?.Produkt;
        if (typeof product === 'undefined') {
          return [];
        }
        if (product === 'Szkoła + Przedszkole') {
          return ['Szkoła', 'Przedszkole'];
        }
        return [product];
      } else {
        alert.error(label.alerts.error.common);
      }
    } catch (e) {
      alert.error(label.alerts.error.common);
    }
    return [];
  }

  async function isCodeUsed(code: string): Promise<boolean | undefined> {
    try {
      const q = query(
        collection(db, UsersCollection),
        where('code.code', '==', code)
      );

      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    } catch (e) {
      alert.error(label.alerts.error.common);
    }
  }

  async function deleteUser(login: string) {
    try {
      const docRef = doc(db, UsersCollection, login);
      await deleteDoc(docRef);
    } catch (e) {
      alert.error(label.alerts.error.common);
    }
  }

  async function isLoginUsed(login: string) {
    try {
      const docRef = doc(db, UsersCollection, login.toLowerCase());
      const docSnap = await getDoc(docRef);
      return docSnap.exists();
    } catch (e) {
      alert.error(label.alerts.error.common);
    }
  }

  async function getUserAccessLevels(
    user: User | null
  ): Promise<AccessLevel[]> {
    if (user === null || user.email === null) {
      return [];
    }
    try {
      const docRef = doc(db, UsersCollection, user.email.toLowerCase());
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const accessLevel = docSnap.data().accessLevel as AccessLevel;
        if (accessLevel === 'FULL_ACCESS') {
          return ['FULL_ACCESS', 'LIMITED_ACCESS'];
        } else if (accessLevel === 'LIMITED_ACCESS') {
          return ['LIMITED_ACCESS'];
        }
      } else {
        alert.error(label.alerts.error.common);
      }
    } catch (e) {
      alert.error(label.alerts.error.common);
    }
    return [];
  }

  async function getQuestionFromUser(login: string) {
    try {
      const docRef = doc(db, UsersCollection, login.toLowerCase());
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data().question;
      } else {
        alert.error(label.alerts.error.common);
      }
    } catch (e) {
      alert.error(label.alerts.error.common);
    }
  }

  async function getCodes(): Promise<ExcelCodeRecord[]> {
    const codesRef = ref(
      storage,
      `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/kody`
    );
    let codes: ExcelCodeRecord[] = [];
    await listAll(codesRef).then(async (reference) => {
      let newestCodesFile:
        | { updated: Date; item: StorageReference }
        | undefined;
      await Promise.all(
        reference.items.map(async (item) => {
          const metaData = await getMetadata(item);
          if (
            typeof newestCodesFile === 'undefined' ||
            newestCodesFile.updated < new Date(metaData.updated)
          ) {
            newestCodesFile = {
              updated: new Date(metaData.updated),
              item: item,
            };
          }
        })
      );

      if (typeof newestCodesFile !== 'undefined') {
        await getBytes(newestCodesFile?.item).then((bytes) => {
          const wb = XLSX.read(bytes, {
            type: 'array',
          });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          codes = XLSX.utils.sheet_to_json(ws);
        });
      }
    });
    return codes;
  }

  async function saveReport(report: string, login: string, fileUrl?: string) {
    const reportData: Report = {
      report: report,
      reporter: login.toLowerCase(),
      createDate: serverTimestamp(),
      fileUrl: fileUrl || '',
    };
    try {
      const docRef = doc(db, ReportCollection, login + ' - ' + new Date());
      await setDoc(docRef, reportData);
    } catch (e) {
      alert.error(label.alerts.error.common);
    }
  }

  async function uploadFile(file: File, login: string) {
    const imageRef = ref(
      storage,
      `zgłoszenia/${login}/${new Date().toString()}`
    );
    return uploadBytes(imageRef, file).then((snapshot) => {
      return getDownloadURL(snapshot.ref).then((url) => {
        return url;
      });
    });
  }

  async function getCategories(): Promise<Category[]> {
    return categories;
  }

  return {
    saveUserInfo,
    isLoginUsed,
    getQuestionFromUser,
    saveReport,
    uploadFile,
    getCategories,
    getUserAccessLevels,
    deleteUser,
    getCodes,
    extendAccess,
    isCodeUsed,
    getUserProducts,
  };
}
