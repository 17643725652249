export const label: LABEL = {
  homepage: {
    header: {
      appName: 'EKOMATIK',
      buttons: {
        loginLabel: 'ZALOGUJ SIĘ',
        changePassword: 'Zmień hasło',
        logout: 'Wyloguj się',
      },
    },
    content: {
      menu: {
        exercises: {
          label: 'Ćwiczenia z przewodnika',
        },
        guide: {
          label: 'Przewodnik',
          openInNewTab: 'OTWÓRZ PRZEWODNIK W NOWEJ KARCIE',
          downloadGuide: 'POBIERZ PRZEWODNIK',
        },
        presentations: {
          label: 'Prezentacje',
        },
        report: {
          label: 'Zgłoś błąd/dodaj opinię',
        },
      },
      exercises: {
        notLogged: {
          description: 'Opcja dostępna tylko dla zalogowanych użytkowników.',
          button: {
            signIn: 'ZALOGUJ SIĘ',
            register: 'ZAŁÓŻ KONTO',
          },
        },
      },
    },
  },
  singIn: {
    title: 'LOGOWANIE',
    login: {
      label: 'E-mail',
      placeholder: 'Wpisz adres e-mail',
      validationMessagePattern: 'Format e-mailu jest niepoprawny',
    },
    password: {
      label: 'Hasło',
      placeholder: 'Wpisz hasło',
      validationMessagePattern: 'Format loginu jest niepoprawny',
    },
    buttons: {
      loginLabel: 'ZALOGUJ SIĘ',
      registerLabel: 'ZAREJESTRUJ SIĘ',
      remindPasswordLabel: 'ODZYSKAJ HASŁO',
    },
  },
  registerFirstStep: {
    title: 'REJESTRACJA',
    login: {
      label: 'E-mail',
      placeholder: 'Wpisz adres e-mail',
      validationMessageUsed: 'Konto z tym adresem e-mail jest już istnieje',
      validationMessagePattern: 'Format e-mailu jest niepoprawny',
    },
    password: {
      label: 'Hasło',
      placeholder: 'Wpisz hasło',
      validationMessagePattern: 'Format hasła jest niepoprawny',
    },
    passwordConfirm: {
      label: 'Powtórz hasło',
      placeholder: 'Wpisz hasło jeszcze raz',
      validationMessagePattern: 'Format hasła jest niepoprawny',
    },
    buttons: {
      nextStep: 'DALEJ',
    },
  },
  registerSecondStep: {
    title: 'REJESTRACJA krok 2 z 2',
    description:
      'Wybierz jedno z poniższych pytań kontrolnych, które wyświetli ci się, jeśli będziesz korzystał z opcji zmiany hasła.',
    question: {
      label: 'Pytanie kontrolne',
      placeholder: 'Wybierz',
    },
    answer: {
      label: 'Odpowiedz na pytanie',
      placeholder: 'Wpisz odpowiedź na pytanie',
      validationMessagePattern: 'Format odpowiedzi jest niepoprawny',
    },
    buttons: {
      register: 'ZAŁÓŻ NOWE KONTO',
    },
  },
  registerSuccessStep: {
    title: 'Rejestracja przebiegła pomyślnie.',
    description: 'Na Twój adres e-mail został wysłany link aktywacyjny.',
    secondDescription:
      'Jeśli w tym momencie nie chcesz się zalogować, kliknij w przycisk w prawym górnym rogu okna.',
    buttons: {
      signIn: 'ZALOGUJ SIĘ',
    },
  },
  recoverPasswordFirstStep: {
    title: 'ODZYSKAJ HASŁO',
    login: {
      label: 'E-mail powiązany z kontem',
      placeholder: 'Wpisz adres e-mail',
      validationMessageUsed: 'Konto z tym adresem e-mail jest już istnieje',
      validationMessagePattern: 'Format e-mailu jest niepoprawny',
    },
    buttons: {
      changePassword: 'WYŚLIJ LINK ODZYSKUJĄCY HASŁO',
    },
  },
  recoverPasswordSecondStep: {
    title: 'ODZYSKAJ HASŁO krok 2 z 3',
    question: {
      label: 'Pytanie kontrolne',
      placeholder: 'Wybierz',
    },
    answer: {
      label: 'Odpowiedz na pytanie',
      placeholder: 'Wpisz odpowiedź na pytanie',
      validationMessagePattern: 'Format odpowiedzi jest niepoprawny',
    },
    buttons: {
      changePassword: 'ODZYSKAJ HASŁO',
    },
  },
  recoverPasswordThirdStep: {
    title: 'ODZYSKAJ HASŁO krok 3 z 3',
    password: {
      label: 'Nowe hasło',
      placeholder: 'Wpisz nowe hasło',
      validationMessagePattern: 'Format hasła jest niepoprawny',
    },
    passwordConfirm: {
      label: 'Powtórz nowe hasło',
      placeholder: 'Wpisz nowe hasło jeszcze raz',
      validationMessagePattern: 'Format hasła jest niepoprawny',
    },
    buttons: {
      changePassword: 'USTAW NOWE HASŁO',
    },
  },
  recoverPasswordSuccessStep: {
    title: 'Wysłaliśmy link na twój adres email!',
    description: 'Kliknij w przycisk poniżej, aby się zalogować.',
    secondDescription:
      'Jeśli w tym momencie nie chcesz się zalogować, kliknij w przycisk w prawym górnym rogu okna.',
    buttons: {
      signIn: 'ZALOGUJ SIĘ',
    },
  },
  changePassword: {
    title: 'ZMIEŃ HASŁO',
    password: {
      label: 'Nowe hasło',
      placeholder: 'Wpisz nowe hasło',
      validationMessagePattern: 'Format hasła jest niepoprawny',
    },
    passwordConfirm: {
      label: 'Powtórz nowe hasło',
      placeholder: 'Wpisz nowe hasło jeszcze raz',
      validationMessagePattern: 'Format hasła jest niepoprawny',
    },
    buttons: {
      changePassword: 'USTAW NOWE HASŁO',
    },
  },
  dialog: {
    backLabel: 'WRÓĆ',
  },
  page404: {
    title: '404',
    description: 'Taka strona nie istnieje',
  },
  alerts: {
    error: {
      authOrUserNotFound: 'Użytkownik z takim adresem e-mail nie istnieje',
      wrongPassword: 'Podane hasło jest nieprawidłowe.',
      common: 'Coś poszło nie tak, spróbuj ponownie za chwilę',
    },
    success: {
      signIn: 'Logowanie zakończyło się sukcesem.',
    },
  },
};

type LABEL = {
  homepage: {
    header: {
      appName: string;
      buttons: {
        loginLabel: string;
        changePassword: string;
        logout: string;
      };
    };
    content: {
      menu: {
        exercises: {
          label: string;
        };
        guide: {
          label: string;
          openInNewTab: string;
          downloadGuide: string;
        };
        presentations: {
          label: string;
        };
        report: {
          label: string;
        };
      };
      exercises: {
        notLogged: {
          description: string;
          button: {
            signIn: string;
            register: string;
          };
        };
      };
    };
  };
  singIn: {
    title: string;
    login: {
      label: string;
      placeholder: string;
      validationMessagePattern: string;
    };
    password: {
      label: string;
      placeholder: string;
      validationMessagePattern: string;
    };
    buttons: {
      loginLabel: string;
      registerLabel: string;
      remindPasswordLabel: string;
    };
  };
  registerFirstStep: {
    title: string;
    login: {
      label: string;
      placeholder: string;
      validationMessageUsed: string;
      validationMessagePattern: string;
    };
    password: {
      label: string;
      placeholder: string;
      validationMessagePattern: string;
    };
    passwordConfirm: {
      label: string;
      placeholder: string;
      validationMessagePattern: string;
    };
    buttons: {
      nextStep: string;
    };
  };
  registerSecondStep: {
    title: string;
    description: string;
    question: {
      label: string;
      placeholder: string;
    };
    answer: {
      label: string;
      placeholder: string;
      validationMessagePattern: string;
    };
    buttons: {
      register: string;
    };
  };
  registerSuccessStep: {
    title: string;
    description: string;
    secondDescription: string;
    buttons: {
      signIn: string;
    };
  };
  recoverPasswordFirstStep: {
    title: string;
    login: {
      label: string;
      placeholder: string;
      validationMessageUsed: string;
      validationMessagePattern: string;
    };
    buttons: {
      changePassword: string;
    };
  };
  recoverPasswordSecondStep: {
    title: string;
    question: {
      label: string;
      placeholder: string;
    };
    answer: {
      label: string;
      placeholder: string;
      validationMessagePattern: string;
    };
    buttons: {
      changePassword: string;
    };
  };
  recoverPasswordThirdStep: {
    title: string;
    password: {
      label: string;
      placeholder: string;
      validationMessagePattern: string;
    };
    passwordConfirm: {
      label: string;
      placeholder: string;
      validationMessagePattern: string;
    };
    buttons: {
      changePassword: string;
    };
  };
  recoverPasswordSuccessStep: {
    title: string;
    description: string;
    secondDescription: string;
    buttons: {
      signIn: string;
    };
  };
  changePassword: {
    title: string;
    password: {
      label: string;
      placeholder: string;
      validationMessagePattern: string;
    };
    passwordConfirm: {
      label: string;
      placeholder: string;
      validationMessagePattern: string;
    };
    buttons: {
      changePassword: string;
    };
  };
  dialog: {
    backLabel: string;
  };
  page404: {
    title: string;
    description: string;
  };
  alerts: {
    error: {
      authOrUserNotFound: string;
      wrongPassword: string;
      common: string;
    };
    success: {
      signIn: string;
    };
  };
};
