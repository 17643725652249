import {
    getBlocksAfterCount,
    getBlocksBeforeCount
} from 'src/database/category3/category3Util';
import {
    CustomMatrix,
    Exercise,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../exerciseTypes';
import { getRandomBlocks } from '../category4Util';

const blocksBeforeAdd = getRandomBlocks(36, ExerciseColor.ZIELONY);
const blocksAfterAdd = getRandomBlocks(53, ExerciseColor.ZIELONY, [
  ...blocksBeforeAdd,
]);

const step1 = {
  withDecimals: true,
  number: 1,
  description: 'Wyciągnijcie podstawki jak na ekranie',
  customMatrix: CustomMatrix.BLANK_10x10,
};

const step2 = {
  withDecimals: true,
  number: 2,
  customMatrix: CustomMatrix.BLANK_10x10,
  description:
    'Podstawka ilustruje jezioro, po którym mogą pływać żaglówki, reprezentowane u nas przez klocki zielone',
};

const step3 = {
  withDecimals: true,
  number: 3,
  description: 'Na jeziorze pływa 36 zielonych żaglówek',
  customMatrix: CustomMatrix.BLANK_10x10,
};

const step4 = {
  withDecimals: true,
  number: 4,
  description:
    'Połóżcie na podstawkach odpowiednią liczbę klocków w taki sposób, aby nie było widać cyfr wypisanych na ściankach',
  customMatrix: CustomMatrix.BLANK_10x10,
};

const step5 = {
  withDecimals: true,
  number: 5,
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: blocksBeforeAdd,
};

const step6 = {
  withDecimals: true,
  number: 6,
  description: 'Z przystani wypłynęło jeszcze 17 zielonych żaglówek',
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: blocksBeforeAdd,
};

const step7 = {
  withDecimals: true,
  number: 7,
  description: 'Dołóżcie zielone klocki na swoich podstawkach',
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: blocksBeforeAdd,
};

const step8 = {
  withDecimals: true,
  number: 8,
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: blocksAfterAdd,
};

const step9 = {
  withDecimals: true,
  number: 9,
  description: 'Ile żaglówek pływa teraz po jeziorze?',
  customMatrix: CustomMatrix.BLANK_10x10,
  blocks: blocksAfterAdd,
};

const step10 = {
  withDecimals: true,
  number: 10,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step11 = {
  withDecimals: true,
  number: 11,
  description:
    'Ułóżcie na nich odpowiednich klocków ilustrujących podane w historyjce liczby żaglówek',
};

const step12 = {
  withDecimals: true,
  number: 12,
  blocks: [...getBlocksBeforeCount(36, 17)],
};

const step13 = {
  withDecimals: true,
  number: 13,
  description: 'Posługując się klockami, dodajcie podane liczby',
  blocks: [...getBlocksBeforeCount(36, 17)],
};

const step14 = {
  withDecimals: true,
  number: 14,
  blocks: [...getBlocksAfterCount(53)],
};

export const c4e2: Exercise = {
  number: 2,
  name: ExerciseName.EXERCISE_2,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
  ],
};
