import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import Button from 'src/components/Button';
import { storage } from 'src/config/firebase.config';
import { UserAuth } from 'src/context/AuthContext';
import { downloadFile } from 'src/utils/file';
import { label } from 'src/utils/labels';
import { ButtonSize, ButtonTypes } from 'src/utils/types';

export default function MenuGuide() {
  const [guideUrl, setGuideUrl] = useState('');
  const { selectedProduct, userAccessLevels } = UserAuth();

  useEffect(() => {
    let guideRef;
    if (
      userAccessLevels.length > 0 &&
      userAccessLevels.includes('FULL_ACCESS')
    ) {
      guideRef = ref(
        storage,
        `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/przewodnik/${selectedProduct}/Przewodnik.pdf`
      );
    } else {
      guideRef = ref(
        storage,
        `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/przewodnik/Przewodnik - wprowadzenie.pdf`
      );
    }

    getDownloadURL(guideRef).then((url) => {
      setGuideUrl(url);
    });
  }, [selectedProduct]);

  return (
    <div className='relative flex h-full w-full justify-center'>
      <div className='h-[410px] w-full overflow-hidden rounded-lg border md:h-full md:pb-[80px]'>
        <iframe
          id='frame'
          src={guideUrl}
          className='h-full w-full'
          frameBorder='0'
          title='przewodnik'
        />
      </div>
      <div className='absolute bottom-0 mt-[10px] w-full md:mt-0 md:flex'>
        <a
          target='_blank'
          href={guideUrl}
          className='mt-[10px] md:mr-[10px] md:mt-0 md:w-[50%]'
        >
          <Button buttonSize={ButtonSize.BIG} variant={ButtonTypes.secondary}>
            {label.homepage.content.menu.guide.openInNewTab}
          </Button>
        </a>
        <Button
          customClassName='md:w-[50%] md:ml-[10px] mt-[10px] md:mt-0'
          buttonSize={ButtonSize.BIG}
          variant={ButtonTypes.primary}
          onClick={() =>
            downloadFile(
              `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/przewodnik/${selectedProduct}/Przewodnik.pdf`
            )
          }
        >
          {label.homepage.content.menu.guide.downloadGuide}
        </Button>
      </div>
    </div>
  );
}
