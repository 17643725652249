import clsx from 'clsx';
import { ExerciseStep, ExerciseStepBlock } from 'src/database/exerciseTypes';
import Block from '../BlockTypes/Block';

type Props = {
  height: number[];
  step: ExerciseStep | undefined;
};

export default function LeftAxis({ height, step }: Props) {
  function findBlock(idy: number): ExerciseStepBlock | undefined {
    if (typeof step?.axisBlocks === 'undefined') {
      return undefined;
    }
    return step.axisBlocks.find((block) => block.idy == idy);
  }

  return (
    <div className='mt-[10px] w-[40px]'>
      {height.map((idy) => {
        const block = findBlock(idy);
        return (
          <div key={idy} className='relative'>
            <div
              className='flex h-[60px] items-center justify-center'
              key={idy}
            >
              {block && <Block block={block} />}
              <div className='h-[60px] min-w-[4px] bg-black-default'>
                {idy === 1 && (
                  <div className='absolute h-[80px] w-[4px] bg-black-default' />
                )}
                {idy === 10 && (
                  <div className='absolute top-[-33px] ml-[-13px] text-[30px]'>
                    &#9650;
                  </div>
                )}
              </div>
              <div className='absolute right-[15px] h-[10px] w-[10px] rounded-[50%] bg-black-default' />
            </div>
            <div
              className={clsx(
                'absolute top-0 ml-[-10px] flex h-[60px] items-center justify-end ',
                { 'ml-[-20px]': idy === 10 }
              )}
            >
              {idy}
            </div>
          </div>
        );
      })}
    </div>
  );
}
