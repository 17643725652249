import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AccessLevel } from 'src/context/AuthContext';
import { getProperCategory } from 'src/database/categoriesUtil';
import {
  CategoryName,
  Corner,
  Exercise,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseName,
  ExerciseType,
  Figure,
  Position,
} from 'src/database/exerciseTypes';
import { customPath } from 'src/utils/paths';
import {
  getAddNumbersSchema,
  getDivideNumbersSchema,
  getSubtractionNumbersSchema,
  newVariantSchema,
} from 'src/utils/validation';

export type NewVariant = {
  blocksColor?: ExerciseColor;
  blockCorner?: Corner;
  blockContent?: ExerciseBlockContent;
  coloredPosition?: boolean;
  maxNumberOfBlocks?: number;
  blocksAcross?: Corner;
  alongTheSide?: Position;
  figure?: Figure;
  blockXShift?: number;
  blockYShift?: number;
  reverse?: boolean;
  thousands?: number;
  hundreds?: number;
  dozens?: number;
  units?: number;
  firstNumber?: number;
  secondNumber?: number;
  numberToDivide?: number;
  numberDivide?: number;
};

export function useNewVariant(
  category: string | undefined,
  subcategory: string | undefined,
  exerciseName: string | undefined
) {
  const navigate = useNavigate();

  function createNewVariant(
    inputs: NewVariant,
    name: string | undefined,
    availability: AccessLevel | undefined
  ) {
    const steps = getProperVariantSteps(inputs, category, subcategory, name);

    const exercise: Exercise = {
      number: 1,
      name: `${name} - Nowy wariant`,
      ownVariantOption: true,
      type: ExerciseType.CUSTOM,
      steps: steps,
      availability: availability || 'LIMITED_ACCESS',
    };

    if (typeof subcategory === 'undefined') {
      navigate(`${customPath.exercise}/${category}/${name}`, {
        state: { variantExercise: exercise },
      });
    } else {
      navigate(`${customPath.exercise}/${category}/${subcategory}/${name}`, {
        state: { variantExercise: exercise },
      });
    }
  }

  const form = useForm<NewVariant>({
    mode: 'onTouched',
    resolver: yupResolver(
      getVariantSchema(category, subcategory, exerciseName)
    ),
  });

  return {
    form,
    createNewVariant,
  };
}

function getProperVariantSteps(
  inputs: NewVariant,
  category: string | undefined = '',
  subcategory: string | undefined = '',
  name: string | undefined = ''
) {
  return getProperCategory(category, name, inputs, subcategory);
}

function getVariantSchema(
  category: string | undefined,
  subcategory: string | undefined,
  exerciseName: string | undefined
) {
  if (
    category === CategoryName.CATEGORY_3 &&
    subcategory === CategoryName.SUBCATEGORY_3_1
  ) {
    if (exerciseName === ExerciseName.EXERCISE_1)
      return getAddNumbersSchema(10, 10);
    if (exerciseName === ExerciseName.EXERCISE_2)
      return getAddNumbersSchema(10, 10);
    if (exerciseName === ExerciseName.EXERCISE_3)
      return getAddNumbersSchema(100, 100);
    if (exerciseName === ExerciseName.EXERCISE_4)
      return getAddNumbersSchema(100, 100);
    if (exerciseName === ExerciseName.EXERCISE_5)
      return getAddNumbersSchema(100, 100);
    if (exerciseName === ExerciseName.EXERCISE_6)
      return getAddNumbersSchema(100, 100);
    if (exerciseName === ExerciseName.EXERCISE_7)
      return getAddNumbersSchema(100, 100);
    if (exerciseName === ExerciseName.EXERCISE_8)
      return getAddNumbersSchema(1000, 1000);
  }
  if (
    category === CategoryName.CATEGORY_3 &&
    subcategory === CategoryName.SUBCATEGORY_3_2
  ) {
    if (exerciseName === ExerciseName.EXERCISE_1)
      return getSubtractionNumbersSchema(10, 10);
    if (exerciseName === ExerciseName.EXERCISE_2)
      return getSubtractionNumbersSchema(100, 10);
    if (exerciseName === ExerciseName.EXERCISE_3)
      return getSubtractionNumbersSchema(100, 100);
    if (exerciseName === ExerciseName.EXERCISE_4)
      return getSubtractionNumbersSchema(100, 10);
    if (exerciseName === ExerciseName.EXERCISE_5)
      return getSubtractionNumbersSchema(1000, 100);
    if (exerciseName === ExerciseName.EXERCISE_6)
      return getSubtractionNumbersSchema(9999, 1000);
    if (exerciseName === ExerciseName.EXERCISE_7)
      return getSubtractionNumbersSchema(100, 100);
    if (exerciseName === ExerciseName.EXERCISE_8)
      return getSubtractionNumbersSchema(1000, 1000);
    if (exerciseName === ExerciseName.EXERCISE_9)
      return getSubtractionNumbersSchema(1000, 1000);
  }

  if (
    category === CategoryName.CATEGORY_3 &&
    subcategory === CategoryName.SUBCATEGORY_3_4
  ) {
    if (exerciseName === ExerciseName.EXERCISE_1)
      return getDivideNumbersSchema();
  }
  return newVariantSchema;
}
