import clsx from 'clsx';

type Props = {
  stepNumber: number;
};

export default function CustomMatrixC7E4({ stepNumber }: Props) {
  function smallBlock(vertical: boolean, number?: number) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[50px]': !vertical,
            'h-[50px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>{number || 'm'}</div>
      </div>
    );
  }

  if (stepNumber === 1 || stepNumber === 2 || stepNumber === 3) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'></div>
        </div>
      </div>
    );
  }

  if (
    stepNumber === 4 ||
    stepNumber === 5 ||
    stepNumber === 6 ||
    stepNumber === 7
  ) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 8 || stepNumber == 9) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 10) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 11) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 12) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 13) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 14) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 15) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 16) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 17) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 18) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 19) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 20) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 21) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 22) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 23) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 24) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 25) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 26) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 27) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 28) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] right-[50px]'>
                {smallBlock(false, 3)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 29) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] right-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] right-0'>
                {smallBlock(false, 4)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 30) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] right-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] right-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 right-[-25px]'>
                {smallBlock(true, 1)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 31) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] right-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] right-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 right-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] right-[-25px]'>
                {smallBlock(true, 2)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 32) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] right-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] right-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 right-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] right-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] right-0'>
                {smallBlock(false, 1)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 33) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] right-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] right-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 right-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] right-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] right-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[100px] right-[50px]'>
                {smallBlock(false, 2)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 34) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] right-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] right-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 right-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] right-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] right-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[100px] right-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[100px] right-[75px]'>
                {smallBlock(true, 1)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 35) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] right-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] right-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 right-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] right-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] right-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[100px] right-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[100px] right-[75px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[150px] right-[75px]'>
                {smallBlock(true, 2)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 36) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] right-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] right-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 right-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] right-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] right-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[100px] right-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[100px] right-[75px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[150px] right-[75px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[200px] right-[100px]'>
                {smallBlock(false, 1)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 37) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] right-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] right-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 right-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] right-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] right-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[100px] right-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[100px] right-[75px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[150px] right-[75px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[200px] right-[100px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] right-[150px]'>
                {smallBlock(false, 2)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 38) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] right-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] right-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 right-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] right-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] right-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[100px] right-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[100px] right-[75px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[150px] right-[75px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[200px] right-[100px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] right-[150px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[150px] right-[200px]'>
                {smallBlock(true, 1)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 39) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] right-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] right-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 right-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] right-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] right-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[100px] right-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[100px] right-[75px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[150px] right-[75px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[200px] right-[100px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] right-[150px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[150px] right-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] right-[200px]'>
                {smallBlock(true, 2)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 40) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] right-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] right-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 right-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] right-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] right-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[100px] right-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[100px] right-[75px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[150px] right-[75px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[200px] right-[100px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] right-[150px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[150px] right-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] right-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] right-[200px]'>
                {smallBlock(true, 3)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 41) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative h-full max-h-[700px] w-full max-w-[700px] border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mt-[100px] ml-[100px] flex'>
              {[...Array(4)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-red-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] left-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] left-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] left-[100px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] left-[150px]'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 left-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] left-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] left-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-[150px] left-[200px]'>
                {smallBlock(true, 4)}
              </div>
              <div className='absolute bottom-[200px] left-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] left-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[200px] left-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[200px] left-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-[150px] left-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] left-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] left-[-25px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 left-[-25px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
            <div className='relative mr-[100px] mt-[100px] flex items-end justify-end'>
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(4)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              {[...Array(2)].map((e) => (
                <div key={e}>
                  {[...Array(2)].map((f) => (
                    <div
                      key={f}
                      className='mt-auto h-[50px] w-[50px] border border-solid bg-green-block'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-25px] right-[150px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[-25px] right-[100px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[-25px] right-[50px]'>
                {smallBlock(false, 3)}
              </div>
              <div className='absolute bottom-[-25px] right-0'>
                {smallBlock(false, 4)}
              </div>
              <div className='absolute bottom-0 right-[-25px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[50px] right-[-25px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[100px] right-0'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[100px] right-[50px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[100px] right-[75px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[150px] right-[75px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[200px] right-[100px]'>
                {smallBlock(false, 1)}
              </div>
              <div className='absolute bottom-[200px] right-[150px]'>
                {smallBlock(false, 2)}
              </div>
              <div className='absolute bottom-[150px] right-[200px]'>
                {smallBlock(true, 1)}
              </div>
              <div className='absolute bottom-[100px] right-[200px]'>
                {smallBlock(true, 2)}
              </div>
              <div className='absolute bottom-[50px] right-[200px]'>
                {smallBlock(true, 3)}
              </div>
              <div className='absolute bottom-0 right-[200px]'>
                {smallBlock(true, 4)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div></div>;
}
