import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import Button from 'src/components/Button';
import Dialog from 'src/components/Dialog';
import Input from 'src/components/Input';
import { storage } from 'src/config/firebase.config';
import { label } from 'src/utils/labels';
import { ButtonSize, ButtonTypes } from 'src/utils/types';

type Props = {
  setStep: (s: number) => void;
  isLoading: boolean;
};

export default function RegisterSecondStep({ setStep, isLoading }: Props) {
  const [regulationsUrl, setRegulationsUrl] = useState('');

  const regulationsRef = ref(
    storage,
    `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/regulaminy/Regulamin_ekomatik.pdf`
  );

  useEffect(() => {
    if (regulationsUrl === '') {
      getDownloadURL(regulationsRef).then((url) => {
        setRegulationsUrl(url);
      });
    }
  }, []);

  return (
    <Dialog
      title='Rejestracja krok 2 z 2'
      onGoBack={() => setStep(1)}
      description='Aby otrzymać dostęp do pełnej wersji aplikacji podaj kod, który otrzymałeś przy zakupie zestawu Ekomatik'
      withClose
    >
      <Input
        name='code'
        label='Kod z zestawu Ekomatik'
        placeholder='Wpisz kod'
        wrapperClassName='mb-[20px]'
      />
      <Input name='clientType' radioOptions={['Indywidualny', 'Placówka']} />
      <Input name='agreementsConfirm' wrapperClassName='mt-[20px]' checkbox>
        <span>Zapoznałem się i akceptuję&nbsp;</span>
        <a
          href={regulationsUrl}
          target='_blank'
          className='font-bold text-green-aquamarine'
        >
          regulamin.
        </a>
      </Input>
      <Button
        type='submit'
        variant={ButtonTypes.primary}
        customClassName='w-full mt-[20px]'
        buttonSize={ButtonSize.BIG}
        isLoading={isLoading}
      >
        <span>{label.registerSecondStep.buttons.register}</span>
      </Button>
    </Dialog>
  );
}
