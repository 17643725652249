import { HomepageOption } from 'src/utils/types';

type Props = {
  option: HomepageOption;
};

export default function LimitedAccessContent({ option }: Props) {
  let text;
  if (option === HomepageOption.GUIDE) {
    text =
      'Przewodnik jest dostępny tylko dla użytkowników z wykupionym dostępem. Aby otrzymać dostęp przejdź do ustawień konta, a następnie podaj kod, który otrzymałeś przy zakupie zestawu Ekomatik';
  } else if (option === HomepageOption.PRESENTATIONS) {
    text =
      'Prezentacje są dostępne tylko dla użytkowników z wykupionym dostępem. Aby otrzymać dostęp przejdź do ustawień konta, a następnie podaj kod, który otrzymałeś przy zakupie zestawu Ekomatik';
  } else if (option === HomepageOption.EXERCISES) {
    text = 'Pracujemy nad zadaniami dla przedszkola';
  } else if (option === HomepageOption.COMPETENCE_TESTS) {
    text =
      'Testy kompetencji są dostępne tylko dla użytkowników z wykupionym dostępem. Aby otrzymać dostęp przejdź do ustawień konta, a następnie podaj kod, który otrzymałeś przy zakupie zestawu Ekomatik';
  }
  return (
    <div className='flex items-center justify-center text-center text-red-default'>
      {text}
    </div>
  );
}
