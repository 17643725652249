import { FormProvider } from 'react-hook-form';
import Button from 'src/components/Button';
import Input from 'src/components/Input';
import { ButtonSize, ButtonTypes } from 'src/utils/types';
import { useExtendAccess } from './hooks';

export default function ExtendAccess() {
  const { form, handleExtendAccess, isLoading } = useExtendAccess();
  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleExtendAccess)}
        noValidate
        autoComplete='off'
      >
        <div className='relative mt-[40px] p-[10px] pt-[30px] shadow shadow-gray-shadow'>
          <div className='absolute top-[-12px] bg-white-default px-[10px]'>
            Rozszerz dostęp do aplikacji
          </div>
          <div className='mb-[10px]'>
            Aby otrzymać pełny dostęp do aplikacji wprowadź kod, który
            otrzymałeś przy zakupie zestawu Ekomatik
          </div>
          <Input
            name='code'
            type='text'
            label='Kod ekomatik'
            placeholder='Wprowadź kod'
            wrapperClassName='mb-[20px]'
          />
          <Button
            type='submit'
            variant={ButtonTypes.primary}
            customClassName='w-full md:max-w-[300px]'
            buttonSize={ButtonSize.BIG}
            isLoading={isLoading}
          >
            <span>ROZSZERZ DOSTĘP</span>
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
