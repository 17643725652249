import {
  Exercise,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseName,
  ExerciseType,
} from '../../exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step2 = {
  number: 2,
  axisBlocks: [
    {
      idx: 0,
      idy: 0,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step3 = {
  number: 3,
  description:
    'Przesuńcie teraz klocek w prawo od pionowej strzałki o 9 kroków i 8 kroków w górę od poziomej strzałki',
  axisBlocks: [
    {
      idx: 0,
      idy: 0,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  number: 4,
  axisBlocks: [
    {
      idx: 1,
      idy: 0,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  number: 5,
  axisBlocks: [
    {
      idx: 2,
      idy: 0,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step6 = {
  number: 6,
  axisBlocks: [
    {
      idx: 3,
      idy: 0,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step7 = {
  number: 7,
  axisBlocks: [
    {
      idx: 4,
      idy: 0,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step8 = {
  number: 8,
  axisBlocks: [
    {
      idx: 5,
      idy: 0,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step9 = {
  number: 9,
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step10 = {
  number: 10,
  axisBlocks: [
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step11 = {
  number: 11,
  axisBlocks: [
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step12 = {
  number: 12,
  axisBlocks: [
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step13 = {
  number: 13,
  blocks: [
    {
      idx: 9,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step14 = {
  number: 14,
  blocks: [
    {
      idx: 9,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step15 = {
  number: 15,
  blocks: [
    {
      idx: 9,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step16 = {
  number: 16,
  blocks: [
    {
      idx: 9,
      idy: 4,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step17 = {
  number: 17,
  blocks: [
    {
      idx: 9,
      idy: 5,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step18 = {
  number: 18,
  blocks: [
    {
      idx: 9,
      idy: 6,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step19 = {
  number: 19,
  blocks: [
    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step20 = {
  number: 20,
  blocks: [
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step21 = {
  number: 21,
  blocks: [
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step22 = {
  number: 22,
  blocks: [
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
  ],
};

const step23 = {
  number: 23,
  blocks: [
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 5,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
  ],
};

const step24 = {
  number: 24,
  blocks: [
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 5,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 6,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: 8,
    },
  ],
};

const step25 = {
  number: 25,
  blocks: [
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 5,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 6,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: 8,
    },
    {
      idx: 7,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
  ],
};

const step26 = {
  number: 26,
  blocks: [
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: 9,
    },
    {
      idx: 5,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 6,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: 8,
    },
    {
      idx: 7,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 8,
      idy: 10,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const steps = [
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  step9,
  step10,
  step11,
  step12,
  step13,
  step14,
  step15,
  step16,
  step17,
  step18,
  step19,
  step20,
  step21,
  step22,
  step23,
  step24,
  step25,
  step26,
];

export const c1e12: Exercise = {
  number: 12,
  name: ExerciseName.EXERCISE_12,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  steps: steps,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'blockXShift',
      label: 'Odsunięcie klocka od pionowej osi',
      placeholder: 'Wybierz odsunięcie względem pionowej osi',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
    {
      name: 'blockYShift',
      label: 'Odsunięcie klocka od poziomej osi',
      placeholder: 'Wybierz odsunięcie względem poziomej osi',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
  ],
};
