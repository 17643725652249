import clsx from 'clsx';

type Props = {
  withNumbers: boolean | undefined;
};

export default function LeftAxisAlternative({ withNumbers }: Props) {
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div
      className={clsx('mr-[-1px] mt-[-5px] h-[605px] min-w-[60px]', {
        'border border-solid border-black-default': !withNumbers,
      })}
    >
      {numbers.map((number) => {
        return (
          <div key={number} className='relative'>
            <div
              className='mt-[20px] flex h-[40px] w-[60px] items-center justify-center'
              key={number}
            >
              {number}
            </div>
          </div>
        );
      })}
    </div>
  );
}
