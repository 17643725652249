import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Na tej lekcji nie będą korzystać z zestawu klocków, a tylko obserwować rysunek na ekranie i odpowiadać na zadawane pytania',
  customMatrix: CustomMatrix.EXERCISE_C7_E5,
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.EXERCISE_C7_E5,
};

const step3 = {
  number: 3,
  description: 'Ile zużyli płytek żółtych?',
  customMatrix: CustomMatrix.EXERCISE_C7_E5,
};

const step4 = {
  number: 4,
  description: 'Ile zużyli płytek zielonych?',
  customMatrix: CustomMatrix.EXERCISE_C7_E5,
};

const step5 = {
  number: 5,
  description: 'Ile zużyli płytek czerwonych?',
  customMatrix: CustomMatrix.EXERCISE_C7_E5,
};

const step6 = {
  number: 6,
  description: 'Ile zużyli płytek niebieskich?',
  customMatrix: CustomMatrix.EXERCISE_C7_E5,
};

const step7 = {
  number: 7,
  description: 'Z ilu płytek składa się fragment pokazanej mozaiki?',
  customMatrix: CustomMatrix.EXERCISE_C7_E5,
};

export const c7e5: Exercise = {
  number: 5,
  name: ExerciseName.EXERCISE_5,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [step1, step2, step3, step4, step5, step6, step7],
};
