export default function TopAxis() {
  return (
    <div className='absolute top-[30px] flex text-center text-[11px]'>
      <div className=' w-[60px] text-yellow-block drop-shadow'>Tysiące</div>
      <div className=' w-[60px] text-blue-block drop-shadow'>Setki</div>
      <div className=' w-[60px] text-red-block drop-shadow'>Dziesiątki</div>
      <div className=' w-[60px] text-green-block drop-shadow'>Jedności</div>
      <div className=' w-[60px] drop-shadow' />
      <div className=' w-[60px] drop-shadow' />
      <div className=' w-[60px] text-yellow-block drop-shadow'>Tysiące</div>
      <div className=' w-[60px] text-blue-block drop-shadow'>Setki</div>
      <div className=' w-[60px] text-red-block drop-shadow'>Dziesiątki</div>
      <div className='w-[60px] text-green-block drop-shadow'>Jedności</div>
    </div>
  );
}
