import { Category, CategoryName } from '../exerciseTypes';
import { c2e1 } from './exercise1/c2e1';
import { c2e10 } from './exercise10/c2e10';
import { c2e11 } from './exercise11/c2e11';
import { c2e12 } from './exercise12/c2e12';
import { c2e13 } from './exercise13/c2e13';
import { c2e14 } from './exercise14/c2e14';
import { c2e15 } from './exercise15/c2e15';
import { c2e16 } from './exercise16/c2e16';
import { c2e17 } from './exercise17/c2e17';
import { c2e2 } from './exercise2/c2e2';
import { c2e3 } from './exercise3/c2e3';
import { c2e4 } from './exercise4/c2e4';
import { c2e5 } from './exercise5/c2e5';
import { c2e6 } from './exercise6/c2e6';
import { c2e7 } from './exercise7/c2e7';
import { c2e8 } from './exercise8/c2e8';
import { c2e9 } from './exercise9/c2e9';

export const category2: Category = {
  number: 2,
  name: CategoryName.CATEGORY_2,
  exercises: [
    c2e1,
    c2e2,
    c2e3,
    c2e4,
    c2e5,
    c2e6,
    c2e7,
    c2e8,
    c2e9,
    c2e10,
    c2e11,
    c2e12,
    c2e13,
    c2e14,
    c2e15,
    c2e16,
    c2e17,
  ],
};
