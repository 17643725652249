import {
    CustomMatrix,
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseStepBlock,
    ExerciseType
} from '../../exerciseTypes';

function getRandomBlocks(
  numberOfBlocks: number,
  color: ExerciseColor,
  blocksBeforeAdd?: ExerciseStepBlock[]
) {
  const allBlocks: ExerciseStepBlock[] = blocksBeforeAdd || [];
  let x = 0;
  let y = 0;
  for (let i = 0; allBlocks.length < numberOfBlocks; i++) {
    x = Math.floor(Math.random() * 14) + 1;
    y = Math.floor(Math.random() * 6) + 4;
    const block = allBlocks.find((b) => b.idx === x && b.idy === y);
    if (typeof block === 'undefined') {
      allBlocks.push({
        idx: x,
        idy: y,
        color: color,
        content: ExerciseBlockContent.BLANK,
      });
    }
  }
  return allBlocks;
}

const people = getRandomBlocks(54, ExerciseColor.ZIELONY);

const step1 = {
  withDecimals: true,
  number: 1,
  description: 'Wyciągnijcie podstawki jak na ekranie',
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
  blocksWithoutPaddings: true,
};

const step2 = {
  withDecimals: true,
  number: 2,
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
  blocksWithoutPaddings: true,
  description: 'Podstawka ilustruje salę gimnastyczną',
};

const step3 = {
  withDecimals: true,
  number: 3,
  description:
    'Dorota policzyła, że na sali gimnastycznej siedzą 54 osoby w 6 rzędach. W każdym rzędzie siedzi tyle samo osób. Ile osób siedzi w jednym rzędzie?',
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
  blocksWithoutPaddings: true,
};

const step4 = {
  withDecimals: true,
  number: 4,
  description:
    'Połóżcie na podstawkach odpowiednią liczbę klocków w taki sposób, aby nie było widać cyfr wypisanych na ściankach',
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
  blocksWithoutPaddings: true,
};

const step5 = {
  withDecimals: true,
  number: 5,
  blocksWithoutPaddings: true,
  blocks: people,
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
};

export const c4e8: Exercise = {
  number: 8,
  name: ExerciseName.EXERCISE_8,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: [step1, step2, step3, step4, step5],
  availability: 'FULL_ACCESS',
};
