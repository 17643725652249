import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  description: 'Połóżcie przed sobą czyste kartki formatu A4',
  customMatrix: CustomMatrix.EXERCISE_C5_E7,
};

const step2 = {
  number: 2,
  description:
    'Wyciągnijcie fioletowe klocki, za pomocą których będziecie mierzyć boki kartki',
  customMatrix: CustomMatrix.EXERCISE_C5_E7,
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.EXERCISE_C5_E7,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C5_E7,
};

const step5 = {
  number: 5,
  customMatrix: CustomMatrix.EXERCISE_C5_E7,
};

const step6 = {
  number: 6,
  customMatrix: CustomMatrix.EXERCISE_C5_E7,
};

const step7 = {
  number: 7,
  customMatrix: CustomMatrix.EXERCISE_C5_E7,
};

const step8 = {
  number: 8,
  customMatrix: CustomMatrix.EXERCISE_C5_E7,
};

const step9 = {
  number: 9,
  customMatrix: CustomMatrix.EXERCISE_C5_E7,
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.EXERCISE_C5_E7,
};

const step11 = {
  number: 11,
  customMatrix: CustomMatrix.EXERCISE_C5_E7,
};

const step12 = {
  number: 12,
  customMatrix: CustomMatrix.EXERCISE_C5_E7,
};

const step13 = {
  number: 13,
  customMatrix: CustomMatrix.EXERCISE_C5_E7,
};

const step14 = {
  number: 14,
  customMatrix: CustomMatrix.EXERCISE_C5_E7,
};

export const c5e7: Exercise = {
  number: 7,
  name: ExerciseName.EXERCISE_7,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
  ],
};
