import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E4,
  description: 'Wyciągnijcie z pudełka taką samą drewnianą podkładkę',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E4,
};

const step3 = {
  number: 3,
  description: 'Połóżcie na niej prostopadłościan i sześcian',
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E4,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E4,
};

const step5 = {
  number: 5,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E4,
};

const step6 = {
  number: 6,
  description:
    'Jaki kształt mają ściany prostopadłościanu? A jaki sześcianu? Ile ścian ma prostopadłościan? A ile sześcian? Ile krawędzi ma prostopadłościan? A ile sześcian Ile wierzchołków posiada prostopadłościan? A ile sześcian? Ile krawędzi „wychodzi” z każdego wierzchołka prostopadłościanu? A ile z każdego wierzchołka sześcianu?',
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E4,
};

export const c6s2E4: Exercise = {
  number: 4,
  name: ExerciseName.EXERCISE_4,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: [step1, step2, step3, step4, step5, step6],
  availability: 'FULL_ACCESS',
};
