import { ExerciseStep } from 'src/database/exerciseTypes';
import { getBlocksAfterCount, getUnitsBlocks } from '../../category3Util';

export function getC3S2E2VariantSteps(
  firstNumber: number,
  secondNumber: number
): ExerciseStep[] {
  const step1 = {
    withDecimals: true,
    number: 1,
    description:
      'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
  };

  const step2 = {
    withDecimals: true,
    number: 2,
    description:
      'Ułóżcie takie same kolumny, w tym samym miejscu, jak na obrazku na ekranie',
  };

  const step3 = {
    withDecimals: true,
    number: 3,
    blocks: [...getUnitsBlocks(firstNumber, true)],
  };

  const step4 = {
    withDecimals: true,
    number: 4,
    description: 'Policzcie klocki w lewej kolumnie',
    blocks: [...getUnitsBlocks(firstNumber, true)],
  };

  const step5 = {
    withDecimals: true,
    number: 5,
    description: `Od liczby ${firstNumber} odejmijcie liczbę ${secondNumber} i wynik zapiszcie w zeszytach`,
    blocks: [...getUnitsBlocks(firstNumber, true)],
  };

  const step6 = {
    withDecimals: true,
    number: 6,
    blocks: [
      ...getBlocksAfterCount(Number(firstNumber) - Number(secondNumber)),
      ...getUnitsBlocks(secondNumber, false),
    ],
  };

  return [step1, step2, step3, step4, step5, step6];
}
