import { StorageReference } from 'firebase/storage';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { categories } from 'src/database/exercises';
import { Category } from 'src/database/exerciseTypes';
import { HomepageOption } from 'src/utils/types';

type HomepageMenuContextType = {
  onCategorySelect: (category: Category | undefined) => void;
  onSubCategorySelect: (category: Category | undefined) => void;
  onOptionSelect: (option: HomepageOption) => void;
  setSelectedPresentation: (
    selectedPresentation: StorageReference | undefined
  ) => void;
  setSelectedCompetenceTest: (
    selectedCompetenceTest: StorageReference | undefined
  ) => void;
  selectedOption: HomepageOption | undefined;
  selectedCategory: Category | undefined;
  selectedSubCategory: Category | undefined;
  selectedCompetenceTest: StorageReference | undefined;
  selectedPresentation: StorageReference | undefined;
  categories: Category[];
};

export const HomepageMenuContext = createContext<HomepageMenuContextType>(
  {} as HomepageMenuContextType
);

type Props = {
  children: ReactNode;
};

export function HomepageMenuContextProvider({ children }: Props) {
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState<
    Category | undefined
  >();
  const [selectedSubCategory, setSelectedSubCategory] = useState<
    Category | undefined
  >();
  const [selectedPresentation, setSelectedPresentation] =
    useState<StorageReference>();
  const [selectedCompetenceTest, setSelectedCompetenceTest] =
    useState<StorageReference>();
  const [selectedOption, setSelectedOption] = useState<
    HomepageOption | undefined
  >();

  const localCategories = categories;

  function onCategorySelect(category: Category | undefined) {
    if (typeof category === 'undefined') {
      setSelectedCategory(undefined);
      return;
    }
    if (category.name === selectedCategory?.name) {
      setSelectedCategory(undefined);
      setSelectedSubCategory(undefined);
      navigate('/zadania');
    } else {
      setSelectedSubCategory(undefined);
      setSelectedCategory(category);
      navigate(`/zadania/${category.name}`);
    }
  }

  function onSubCategorySelect(subcategory: Category | undefined) {
    if (typeof subcategory === 'undefined') {
      setSelectedSubCategory(undefined);
      return;
    }
    if (
      typeof subcategory !== 'undefined' &&
      typeof selectedCategory !== 'undefined'
    ) {
      subcategory.categoryName = selectedCategory.name;
    }
    setSelectedSubCategory(subcategory);
    navigate(`/zadania/${subcategory.categoryName}/${subcategory.name}`);
  }

  function onOptionSelect(option: HomepageOption) {
    unselectAllOptions();
    if (selectedOption === option) {
      setSelectedOption(HomepageOption.NONE);
    } else {
      setSelectedOption(option);
    }
  }

  function unselectAllOptions() {
    onCategorySelect(undefined);
    onSubCategorySelect(undefined);
    setSelectedPresentation(undefined);
    setSelectedCompetenceTest(undefined);
  }

  return (
    <HomepageMenuContext.Provider
      value={{
        selectedCategory,
        selectedSubCategory,
        selectedPresentation,
        selectedCompetenceTest,
        selectedOption,
        onOptionSelect,
        onCategorySelect,
        onSubCategorySelect,
        setSelectedPresentation,
        setSelectedCompetenceTest,
        categories: localCategories,
      }}
    >
      {children}
    </HomepageMenuContext.Provider>
  );
}

export function HomepageMenu() {
  return useContext(HomepageMenuContext);
}
