import {
  CustomMatrix,
  Exercise,
  ExerciseName,
  ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E1,
  description: 'Wyciągnijcie z pudełka taką samą drewnianą podkładkę',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E1,
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E1,
};

const step4 = {
  number: 4,
  description: 'Czy wiecie, jak nazywa się taka figura?',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E1,
};

const step5 = {
  number: 5,
  description:
    'Czy wszystkie ściany boczne ostrosłupa są trójkątami? Ile nasz ostrosłup ma ścian bocznych?',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E1,
};

const step6 = {
  number: 6,
  description: 'Jaki kształt ma podstawa ostrosłupa?',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E1,
};

const step7 = {
  number: 7,
  description:
    'To jest krawędź boczna ostrosłupa. Pokażcie ją na swoich klockach i policzcie, ile ostrosłup ma krawędzi bocznych',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E1,
};

const step8 = {
  number: 8,
  description:
    'Wyjmijcie z pudełka miarkę centymetrową i zmierzcie krawędzie boczne ostrosłupa. Co ciekawego zauważyliście?',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E1,
};

const step9 = {
  number: 9,
  description: 'To jest wierzchołek ostrosłupa. Pokażcie go na swoich klockach',
  customMatrix: CustomMatrix.EXERCISE_C6_S4_E1,
};

export const c6s4e1: Exercise = {
  number: 1,
  name: ExerciseName.EXERCISE_1,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: [step1, step2, step3, step4, step5, step6, step7, step8, step9],
  availability: 'LIMITED_ACCESS'
};
