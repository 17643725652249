type Props = {
  stepNumber: number;
};

export default function CustomMatrixC6S2E4({ stepNumber }: Props) {
  if (stepNumber === 1) {
    return null;
  }

  if (stepNumber === 2 || stepNumber === 3) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/emptyBoard.png`}
            className='h-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 4) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s2e4block.png`}
            className='w-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber > 4) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s2e4blocks.png`}
            className='w-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 6 || stepNumber === 7) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s2e1blockLine.png`}
            className='w-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 8) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s2e1blockCorner.png`}
            className='w-full'
          />
        </div>
      </div>
    );
  }

  return <div></div>;
}
