import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { Fragment, useEffect } from 'react';
import { UserAuth } from 'src/context/AuthContext';
import { Product } from 'src/database/database';
import { ButtonSize, ButtonTypes } from 'src/utils/types';
import Button from '../Button';

export default function ProductDropdown() {
  const { setSelectedProduct, selectedProduct } = UserAuth();

  useEffect(() => {
    const cookieProduct = Cookies.get('ekomatik-selected-product');
    if (typeof cookieProduct !== 'undefined') {
      setSelectedProduct(cookieProduct as Product);
    }
  }, []);

  function handleProductClick(product: Product) {
    Cookies.set('ekomatik-selected-product', product);
    setSelectedProduct(product);
  }

  return (
    <Menu
      as='div'
      className='relative w-full min-w-[210px] text-left lg:w-auto'
    >
      {({ open }) => (
        <>
          <Menu.Button as='div'>
            <Button variant={ButtonTypes.primary} buttonSize={ButtonSize.BIG}>
              <div className='flex'>
                <div className='mx-[4px] leading-[24px]'>
                  {selectedProduct.toUpperCase()}
                </div>
                {open && <div className='right-0 mt-[-2px]'>&#9650;</div>}
                {!open && <div className='right-0 mt-[-2px]'>&#9660;</div>}
              </div>
            </Button>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute right-0 z-10 w-full text-[15px] focus:outline-none'>
              <div className='rounded border-2 border-solid border-green-aquamarine bg-white-default'>
                <Menu.Item>
                  <div
                    className={clsx(
                      'flex h-[50px] cursor-pointer items-center p-[12px] text-black-default'
                    )}
                    onClick={() => handleProductClick('Szkoła')}
                  >
                    SZKOŁA
                  </div>
                </Menu.Item>
                <div className='mx-[12px] h-[1px] bg-gray-default' />
                <Menu.Item>
                  <div
                    className={clsx(
                      'flex h-[50px] cursor-pointer items-center p-[12px] text-black-default'
                    )}
                    onClick={() => handleProductClick('Przedszkole')}
                  >
                    PRZEDSZKOLE
                  </div>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
