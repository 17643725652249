import { Route, Routes, useNavigate } from 'react-router-dom';
import { UserAuth } from './context/AuthContext';
import { BasicAuth } from './fakeBasicAuth/fakeBasicAuth';
import './index.css';
import ChangePassword from './pages/AccountSettings/AccountSettings';
import Exercise from './pages/Exercise';
import Homepage from './pages/HomePage';
import NewVariant from './pages/NewVariant';
import Page404 from './pages/Page404';
import RecoverPassword from './pages/RecoverPassword/RecoverPassword';
import Register from './pages/Register/Register';
import SignIn from './pages/SingIn/SignIn';
import { customPath } from './utils/paths';

export default function AppRoutes() {
  const navigate = useNavigate();
  const { user } = UserAuth();
  return (
    <Routes>
      <Route path={customPath.homepage} element={<Homepage />}>
        <Route path=':option' element={<Homepage />} />
        <Route path=':option/:element' element={<Homepage />} />
        <Route path=':option/:element/:subElement' element={<Homepage />} />
      </Route>
      <Route path={customPath.singIn} element={<SignIn />} />
      <Route
        path={customPath.basicAuth}
        element={<BasicAuth navigate={navigate} />}
      />
      <Route path={customPath.recoverPassword} element={<RecoverPassword />} />
      {!!user && (
        <Route path={customPath.accountSettings} element={<ChangePassword />} />
      )}
      {!!user && (
        <Route path={customPath.exercise}>
          <Route path=':category/:name' element={<Exercise />} />
          <Route path=':category/:subcategory/:name' element={<Exercise />} />
        </Route>
      )}
      {!!user && (
        <Route path={customPath.newVariant}>
          <Route path=':category/:name' element={<NewVariant />} />
          <Route path=':category/:subcategory/:name' element={<NewVariant />} />
        </Route>
      )}
      <Route path={customPath.register} element={<Register />} />
      <Route path='*' element={<Page404 />} />
    </Routes>
  );
}
