import {
  CustomMatrix,
  Exercise,
  ExerciseName,
  ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E1,
  description: 'Wyciągnijcie z pudełka taką samą drewnianą podkładkę',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E1,
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E1,
};

const step4 = {
  number: 4,
  description: 'Czy wiecie, jak nazywa się taka figura?',
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E1,
};

const step5 = {
  number: 5,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E1,
  description:
    'To jest ściana sześcianu. Pokażcie ją na swoich klockach i policzcie, ile sześcian ma ścian. Widzicie, dlaczego ta kostka nazywa się sześcianem?',
};

const step6 = {
  number: 6,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E1,
};

const step7 = {
  number: 7,
  description:
    'Ścianka, na której położony jest sześcian – podstawa dolna (bo jest na dole). \n Ścianka górna sześcianu – podstawa górna (bo jest na górze). \n Pozostałe ścianki sześcianu – ścianki boczne (bo są z boku)',
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E1,
};

const step8 = {
  number: 8,
  description:
    'Pokażcie wierzchołki kwadratu. \n Ile wierzchołków ma kwadrat? \n Wyjmijcie z pudełka fioletowe klocki i zmierzcie nimi długość wszystkich boków kwadratu',
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E1,
};

const step9 = {
  number: 9,
  description: 'To jest krawędź sześcianu. Pokażcie ją na swoich klockach',
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E1,
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E1,
};

const step11 = {
  number: 11,
  description:
    'Pokażcie wszystkie krawędzie podstawy górnej. Ile ich jest? \n Pokażcie wszystkie krawędzie podstawy dolnej. Ile ich jest? \n Pokażcie wszystkie krawędzie boczne. Ile ich jest? \n Policzcie, ile sześcian ma krawędzi',
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E1,
};

const step12 = {
  number: 12,
  description:
    'Wyjmijcie z pudełka miarkę centymetrową i zmierzcie krawędzie sześcianu. Zapiszcie wyniki pomiarów w zeszytach. Co ciekawego zauważyliście?',
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E1,
};

const step13 = {
  number: 13,
  description:
    'To jest wierzchołek sześcianu. Pokażcie go na swoich klockach i policzcie, ile wierzchołków ma sześcian',
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E1,
};

const step14 = {
  number: 14,
  description:
    'Jaki kształt ma każda ściana sześcianu? \n Co możecie powiedzieć o długościach krawędzi sześcianu? \n Ile krawędzi wychodzi z każdego wierzchołka sześcianu?',
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E1,
};

export const c6s1e1: Exercise = {
  number: 1,
  name: ExerciseName.EXERCISE_1,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'LIMITED_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
  ],
};
