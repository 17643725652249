import {
  Corner,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseStep,
  ExerciseStepBlock,
  getBlockColor,
} from 'src/database/exerciseTypes';

export function getC2E5VariantSteps(
  color: ExerciseColor,
  maxNumberOfBlocks: number,
  cornerBlock: Corner
): ExerciseStep[] {
  let emptyBlocks: ExerciseStepBlock[] = [];
  let blocks: ExerciseStepBlock[] = [];

  const blocksArray = Array.from(
    { length: maxNumberOfBlocks },
    (_, i) => i + 1
  );

  function getXLeftLower(index: number) {
    if (index > 20) return 5;
    if (index > 15) return 4;
    if (index > 10) return 3;
    if (index > 5) return 2;
    return 1;
  }

  function getXRightLower(index: number) {
    if (index > 25) return 10;
    if (index > 20) return 9;
    if (index > 15) return 8;
    if (index > 10) return 7;
    return 6;
  }

  function getYLeftLower(index: number) {
    if (index > 20) return index - 20;
    if (index > 15) return index - 15;
    if (index > 10) return index - 10;
    if (index > 5) return index - 5;
    return index;
  }

  function getYLeftUpper(index: number) {
    if (index > 25) return index - 20;
    if (index > 20) return index - 15;
    if (index > 15) return index - 10;
    if (index > 10) return index - 5;
    return index;
  }

  function getYRightLower(index: number) {
    if (index > 20) return index - 20;
    if (index > 15) return index - 15;
    if (index > 10) return index - 10;
    if (index > 5) return index - 5;
    return index;
  }

  if (cornerBlock === Corner.LOWER_LEFT) {
    blocks = blocksArray.map((element, index) => {
      return {
        idx: getXLeftLower(index + 1),
        idy: getYLeftLower(index + 1),
        color: getBlockColor(color),
        content: element,
      };
    });
    emptyBlocks = blocksArray.map((_element, index) => {
      return {
        idx: getXLeftLower(index + 1),
        idy: getYLeftLower(index + 1),
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      };
    });
  }

  if (cornerBlock === Corner.LOWER_RIGHT) {
    blocks = blocksArray.map((element, index) => {
      return {
        idx: getXRightLower(index + 6),
        idy: getYRightLower(index + 1),
        color: getBlockColor(color),
        content: element,
      };
    });
    emptyBlocks = blocksArray.map((_element, index) => {
      return {
        idx: getXRightLower(index + 6),
        idy: getYRightLower(index + 1),
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      };
    });
  }

  if (cornerBlock === Corner.UPPER_LEFT) {
    blocks = blocksArray.map((element, index) => {
      return {
        idx: getXLeftLower(index + 1),
        idy: getYLeftUpper(index + 6),
        color: getBlockColor(color),
        content: element,
      };
    });
    emptyBlocks = blocksArray.map((_element, index) => {
      return {
        idx: getXLeftLower(index + 1),
        idy: getYLeftUpper(index + 6),
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      };
    });
  }

  if (cornerBlock === Corner.UPPER_RIGHT) {
    blocks = blocksArray.map((element, index) => {
      return {
        idx: getXRightLower(index + 6),
        idy: getYLeftUpper(index + 6),
        color: getBlockColor(color),
        content: element,
      };
    });
    emptyBlocks = blocksArray.map((_element, index) => {
      return {
        idx: getXRightLower(index + 6),
        idy: getYLeftUpper(index + 6),
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      };
    });
  }

  return [
    {
      number: 1,
      blocks: emptyBlocks,
    },
    {
      number: 2,
      blocks: blocks,
    },
  ];
}
