import { Link } from 'react-router-dom';
import Button from 'src/components/Button';
import { label } from 'src/utils/labels';
import { customPath } from 'src/utils/paths';
import { ButtonSize, ButtonTypes } from 'src/utils/types';

export default function MenuNotLogged() {
  return (
    <div className='flex h-full w-full items-center justify-center'>
      <div>
        <div className='mb-[20px] text-center text-[16px]'>
          {label.homepage.content.exercises.notLogged.description}
        </div>
        <Link to={customPath.singIn}>
          <Button
            variant={ButtonTypes.primary}
            buttonSize={ButtonSize.BIG}
            customClassName='mb-[10px] text-[18px]'
          >
            {label.homepage.content.exercises.notLogged.button.signIn}
          </Button>
        </Link>
        <Link to={customPath.register}>
          <Button
            variant={ButtonTypes.secondary}
            buttonSize={ButtonSize.BIG}
            customClassName='text-[18px]'
          >
            {label.homepage.content.exercises.notLogged.button.register}
          </Button>
        </Link>
      </div>
    </div>
  );
}
