import {
    CustomMatrix,
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../../exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.BLANK_15x15,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.BLANK_15x15,
  description: 'Ułóżcie na swoich siatkach odpowiedni obraz',
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'F',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },

    {
      idx: 3,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 5,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 11,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 11,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 9,
      idy: 11,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },

    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.CIRCLE_ARROW,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 5,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_LEFT,
    },
    {
      idx: 6,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 8,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 9,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.BRACKET_RIGHT,
    },
  ],
};

const step4 = {
  number: 4,
  blocks: [
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.TRIANGLE_RIGHT,
    },
  ],
};

const step5 = {
  number: 5,
  blocks: [
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 4,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 4,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

export const c8s5e9: Exercise = {
  number: 9,
  name: ExerciseName.EXERCISE_9,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: [step1, step2, step3, step4, step5],
  availability: 'LIMITED_ACCESS',
  lettersOnAxis: true,
};
