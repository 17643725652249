import {
    CustomMatrix,
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../../exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.BLANK_15x15,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.BLANK_15x15,
  description:
    'Postaw klocek ze strzałką na polu A2. Wykonaj krok w prawo, narysuj (postaw) czerwony klocek. Wykonaj krok w prawo, narysuj (postaw) zielony klocek. Wykonaj krok w prawo, narysuj (postaw) niebieski klocek i wykonaj ostatni krok w prawo, narysuj (postaw) żółty klocek',
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'A',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 2,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  number: 4,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step5 = {
  number: 5,
  description:
    'Postaw najdłuższy pionek na polu A2. Wykonaj nim krok w prawo (na pole B2) i postaw w tym miejscu czerwony klocek. Następnie wykonaj pionkiem krok w prawo (na pole C2) i postaw w tym miejscu zielony klocek. Wykonaj pionkiem krok w prawo (na pole D2) i postaw w tym miejscu niebieski klocek. Teraz wykonaj krok w prawo i postaw klocek żółty (pole E2)',
};

const step6 = {
  number: 6,
  blocks: [
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.TRIANGLE_RIGHT,
    },
  ],
};

const step7 = {
  number: 7,
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step8 = {
  number: 8,
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step9 = {
  number: 9,
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step10 = {
  number: 10,
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 9,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

export const c8s5e1: Exercise = {
  number: 1,
  name: ExerciseName.EXERCISE_1,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
  ],
  lettersOnAxis: true,
};
