import {
  CustomMatrix,
  Exercise,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseName,
  ExerciseType,
} from '../../../exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.BLANK_15x15,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.BLANK_15x15,
  description:
    'Postaw klocek ze strzałką na polu D3. Wykonaj kroki jak pokazano na ekranie',
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.BLANK_15x15,
  blocks: [
    {
      idx: 3,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.FOUR_ARROWS,
    },
    {
      idx: 4,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.EQUALS,
    },
    {
      idx: 5,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 'D',
    },
    {
      idx: 6,
      idy: 13,
      color: ExerciseColor.BRĄZOWY,
      content: 3,
    },
    {
      idx: 3,
      idy: 12,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 5,
      idy: 12,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 7,
      idy: 12,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.DOWN_ARROW,
    },
    {
      idx: 9,
      idy: 12,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 11,
      idy: 12,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 12,
      idy: 12,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.LEFT_ARROW,
    },
    {
      idx: 13,
      idy: 12,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  number: 4,
  description:
    'Postaw najdłuższy pionek na polu A2 po czym wykonaj żółtymi, niebieski, czerwonymi i zielonymi klockami odpowiednie kroki',
};

const step5 = {
  number: 5,
  blocks: [
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.TRIANGLE_RIGHT,
    },
  ],
};

const step6 = {
  number: 6,
  blocks: [
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step7 = {
  number: 7,
  blocks: [
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step8 = {
  number: 8,
  blocks: [
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step9 = {
  number: 9,
  blocks: [
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step10 = {
  number: 10,
  blocks: [
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step11 = {
  number: 11,
  blocks: [
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 7,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 7,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

export const c8s5e5: Exercise = {
  number: 5,
  name: ExerciseName.EXERCISE_5,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
  ],
  lettersOnAxis: true,
};
