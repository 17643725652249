import {
  Corner,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseStep,
  getBlockColor,
} from 'src/database/exerciseTypes';

export function getC1E2VariantSteps(
  blockCorner: Corner,
  color: ExerciseColor,
  coloredPosition: boolean
): ExerciseStep[] {
  let idx = 0;
  let idy = 0;
  let cornerDescription = '';
  if (blockCorner === Corner.LOWER_LEFT) {
    idx = 1;
    idy = 1;
    cornerDescription = 'lewym dolnym';
  }

  if (blockCorner === Corner.LOWER_RIGHT) {
    idx = 10;
    idy = 1;
    cornerDescription = 'prawym dolnym';
  }

  if (blockCorner === Corner.UPPER_LEFT) {
    idx = 1;
    idy = 10;
    cornerDescription = 'lewym górnym';
  }

  if (blockCorner === Corner.UPPER_RIGHT) {
    idx = 10;
    idy = 10;
    cornerDescription = 'prawym górnym';
  }

  return [
    {
      number: 1,
      description: `Połóż ${color.toLowerCase()} klocek w ${cornerDescription} rogu siatki`,
    },
    {
      number: 2,
      blocks: [
        {
          idx: idx,
          idy: idy,
          color: getBlockColor(color),
          content: ExerciseBlockContent.BLANK,
          coloredPosition: coloredPosition,
        },
      ],
    },
  ];
}
