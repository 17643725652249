import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseStep,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1: ExerciseStep = {
  number: 1,
  description:
    'Połóżcie przed sobą białą kartkę i ułóżcie na niej takie figury, jakie pokazane są na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C2_E8,
};

const step2: ExerciseStep = {
  number: 2,
  customMatrix: CustomMatrix.EXERCISE_C2_E8,
};

const step3: ExerciseStep = {
  number: 3,
  description:
    'Pod każdą figurą napiszcie cyfrę, która informuje o liczbie klocków w poszczególnych figurach',
  customMatrix: CustomMatrix.EXERCISE_C2_E8,
};

const step4: ExerciseStep = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C2_E8,
};

const step5: ExerciseStep = {
  number: 5,
  customMatrix: CustomMatrix.EXERCISE_C2_E8,
};

const step6: ExerciseStep = {
  number: 6,
  customMatrix: CustomMatrix.EXERCISE_C2_E8,
};

const step7: ExerciseStep = {
  number: 7,
  customMatrix: CustomMatrix.EXERCISE_C2_E8,
};

const step8: ExerciseStep = {
  number: 8,
  description:
    'Jak obliczyliście liczbę klocków w trzeciej figurze? Zapiszcie w zeszytach wykonywane czynności',
  customMatrix: CustomMatrix.EXERCISE_C2_E8,
};

export const c2e8: Exercise = {
  number: 8,
  name: ExerciseName.EXERCISE_8,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: [step1, step2, step3, step4, step5, step6, step7, step8],
  availability: 'FULL_ACCESS',
};
