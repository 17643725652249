import clsx from 'clsx';
import { ExerciseStep, ExerciseStepBlock } from 'src/database/exerciseTypes';
import Block from '../BlockTypes/Block';

type Props = {
  width: number[];
  step: ExerciseStep | undefined;
};

export default function BottomAxis({ width, step }: Props) {
  function findBlock(idx: number): ExerciseStepBlock | undefined {
    if (typeof step?.axisBlocks === 'undefined') {
      return undefined;
    }
    return step.axisBlocks.find((block) => block.idx == idx);
  }

  return (
    <div className='relative mt-[-10px] ml-[20px] flex h-[40px] w-full justify-center text-[24px]'>
      {width.map((idx) => {
        const block = findBlock(idx);
        return (
          <div key={idx} className='relative flex'>
            <div className='flex h-[40px] w-[10px]  items-center justify-center'>
              <div className=' h-[4px] w-full bg-black-default' />
            </div>
            <div className='relative flex h-[40px] w-[40px] items-center justify-center'>
              {block && <Block block={block} />}
              {!block && (
                <div className='min-h-[4px] w-[40px] bg-black-default' />
              )}
              {idx === 1 && (
                <>
                  <div className='absolute pr-[140px] pt-[36px] text-[24px]'>
                    <div>0</div>
                  </div>
                  <div className='absolute left-[-32px] h-[4px] w-[36px] bg-black-default' />
                </>
              )}
              {idx === 10 && (
                <div className='absolute right-[-36px] top-[-1px] rotate-90 text-[30px]'>
                  &#9650;
                </div>
              )}
              {!block && (
                <div
                  className={clsx(
                    'absolute mt-[-1px] h-[10px] w-[10px] rounded-[50%] bg-black-default'
                  )}
                />
              )}
            </div>
            <div className='flex h-[40px] w-[10px] items-center justify-center'>
              <div className=' h-[4px] w-full bg-black-default' />
            </div>
            <div className='absolute bottom-[-16px] w-[60px] text-center'>
              {idx}
            </div>
          </div>
        );
      })}
    </div>
  );
}
