import { FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from 'src/components/Button';
import Dialog from 'src/components/Dialog';
import Footer from 'src/components/Footer';
import Input from 'src/components/Input';
import { label } from 'src/utils/labels';
import { customPath } from 'src/utils/paths';
import { ButtonSize, ButtonTypes } from 'src/utils/types';
import { useLogin } from './hooks';

export default function SignIn() {
  const { form, handleLogin, isLoading } = useLogin();
  return (
    <div className='h-screen'>
      <div className='h-full'>
        <Dialog title={label.singIn.title} withClose>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleLogin)}>
              <Input
                name='login'
                label={label.singIn.login.label}
                placeholder={label.singIn.login.placeholder}
                wrapperClassName='mb-[20px]'
              />
              <Input
                name='password'
                type='password'
                label={label.singIn.password.label}
                placeholder={label.singIn.password.placeholder}
              />
              <Link to={customPath.recoverPassword}>
                <div className='my-[20px] cursor-pointer text-right font-bold text-green-aquamarine underline'>
                  {label.singIn.buttons.remindPasswordLabel}
                </div>
              </Link>
              <Button
                type='submit'
                variant={ButtonTypes.primary}
                customClassName='w-full mb-[10px]'
                buttonSize={ButtonSize.BIG}
                isLoading={isLoading}
              >
                <span>{label.singIn.buttons.loginLabel}</span>
              </Button>
              <Link to={customPath.register}>
                <Button
                  type='button'
                  variant={ButtonTypes.secondary}
                  customClassName='w-full'
                  buttonSize={ButtonSize.BIG}
                >
                  <span>{label.singIn.buttons.registerLabel}</span>
                </Button>
              </Link>
            </form>
          </FormProvider>
        </Dialog>
      </div>
      <Footer />
    </div>
  );
}
