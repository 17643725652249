type Props = {
  stepNumber: number;
};

export default function CustomMatrixC6S1E3({ stepNumber }: Props) {
  if (stepNumber === 1) {
    return null;
  }

  if (stepNumber === 2 || stepNumber === 3) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'></div>
        </div>
      </div>
    );
  }

  if (stepNumber === 4 || stepNumber === 5) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='w-[200px]'>
              <div className='ml-auto h-[50px] w-[50px] border border-solid bg-brown-block' />
              <div className='flex w-full'>
                <div className='h-[50px] w-[50px] border border-solid bg-brown-block' />
                <div className='h-[50px] w-[50px] border border-solid bg-brown-block' />
                <div className='h-[50px] w-[50px] border border-solid bg-brown-block' />
                <div className='h-[50px] w-[50px] border border-solid bg-brown-block' />
              </div>
              <div className='ml-auto h-[50px] w-[50px] border border-solid bg-brown-block' />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div></div>;
}
