import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E2,
  description: 'Wyciągnijcie z pudełka taką samą drewnianą podkładkę',
};

const step2 = {
  number: 2,
  description:
    'Połóżcie na niej kartkę papieru, a na niej w pozycji „leżącej” model walca',
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E2,
};

const step3 = {
  number: 3,
  description:
    'Wytnijcie z papieru pasek o szerokości równej wysokości walca i owińcie nim walec tak, aby brzegi kartki nie zachodziły na siebie. Odetnijcie niepotrzebne części kartki',
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E2,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E2,
};

const step5 = {
  number: 5,
  description: 'Jaki kształt ma wycięta z papieru figura?',
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E2,
};
export const c6s5e2: Exercise = {
  number: 2,
  name: ExerciseName.EXERCISE_2,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: [step1, step2, step3, step4, step5],
  availability: 'FULL_ACCESS',
};
