import clsx from 'clsx';
import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { GridLoader } from 'react-spinners';
import { ButtonSize, ButtonTypes } from 'src/utils/types';

type Props = {
  variant: keyof typeof ButtonTypes;
  disabled?: boolean;
  children?: React.ReactNode;
  label?: string;
  type?: 'button' | 'submit';
  customClassName?: string;
  buttonSize?: ButtonSize;
  isSelect?: boolean;
  to?: string;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> &
    MouseEventHandler<HTMLAnchorElement>;
};

export default function Button({
  variant,
  disabled,
  children,
  label,
  type,
  customClassName,
  buttonSize,
  isSelect,
  to,
  isLoading,
  onClick,
  ...props
}: Props) {
  const buttonHeight = getButtonSize(buttonSize);
  const className = clsx(
    'font-bold rounded w-full font-arial',
    buttonHeight,
    customClassName,
    {
      'border-gray-default border-solid bg-white-default text-gray-shadow cursor-not-allowed':
        variant === ButtonTypes.disabled,
      'bg-green-aquamarine text-white-default border-none cursor-pointer':
        variant === ButtonTypes.primary,
      'bg-white-default text-green-aquamarine outline-none border-green-aquamarine border-solid cursor-pointer':
        variant === ButtonTypes.secondary,
      'bg-red-block text-white-default outline-none border-red border-solid cursor-pointer':
        variant === ButtonTypes.warning,
    }
  );
  if (typeof to !== 'undefined') {
    return (
      <Link to={to}>
        <button
          type={type}
          disabled={disabled || isLoading}
          className={className}
          onClick={onClick}
          {...props}
        >
          <div className='relative flex items-center justify-center'>
            <span className={clsx({ 'opacity-10': isLoading })}>
              {children}
            </span>
            {isLoading && (
              <GridLoader
                color={variant === ButtonTypes.primary ? '#fff' : '#00999B'}
                size={10}
                className='absolute'
              />
            )}
          </div>
        </button>
      </Link>
    );
  }

  return (
    <button
      type={type}
      disabled={disabled || isLoading}
      className={className}
      onClick={onClick}
      {...props}
    >
      <div className='relative flex items-center justify-center'>
        <div className={clsx({ 'opacity-10': isLoading })}>{children}</div>
        {isLoading && (
          <GridLoader
            color={variant === ButtonTypes.primary ? '#fff' : '#00999B'}
            size={10}
            className='absolute'
          />
        )}
      </div>
    </button>
  );
}

function getButtonSize(buttonSize?: ButtonSize) {
  if (typeof buttonSize === 'undefined') {
    return 'h-[40px]';
  }
  if (buttonSize === ButtonSize.BIG) {
    return 'h-[60px] text-[18px]';
  }
  if (buttonSize === ButtonSize.MEDIUM) {
    return 'h-[50px]';
  }
  if (buttonSize === ButtonSize.SMALL) {
    return 'h-[40px]';
  }
  return 'h-[40px]';
}
