import Button from 'src/components/Button';
import Dialog from 'src/components/Dialog';
import Input from 'src/components/Input';
import { label } from 'src/utils/labels';
import { customPath } from 'src/utils/paths';
import { ButtonSize, ButtonTypes } from 'src/utils/types';

export default function RecoverPasswordFirstStep() {
  return (
    <Dialog
      title={label.recoverPasswordFirstStep.title}
      backUrl={customPath.singIn}
      withClose
    >
      <div>
        <Input
          name='login'
          label={label.recoverPasswordFirstStep.login.label}
          placeholder={label.recoverPasswordFirstStep.login.placeholder}
          wrapperClassName='mb-[20px]'
        />

        <div>
          <Button
            type='submit'
            variant={ButtonTypes.primary}
            customClassName='w-full'
            buttonSize={ButtonSize.BIG}
          >
            <span>{label.recoverPasswordFirstStep.buttons.changePassword}</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
