import {
  ExerciseColor,
  ExerciseStep,
  ExerciseStepBlock,
  getBlockColor,
} from 'src/database/exerciseTypes';
import { getX, getY } from 'src/database/exerciseUtil';

export function getC2E2VariantSteps(
  color: ExerciseColor,
  numberOfBlocks: number,
  reverse: boolean
): ExerciseStep[] {
  let blocksArray: number[] = Array.from(
    { length: numberOfBlocks },
    (_, i) => i + 1
  );

  if (reverse) {
    blocksArray = blocksArray.reverse();
  }

  const step1 = {
    number: 1,
    description:
      'Policzcie głośno razem ze mną pokazane na ekranie kwadraciki',
  };

  const steps = blocksArray.map((element, index) => {
    const blocks: ExerciseStepBlock[] = [];
    Array.from(Array(element).keys()).forEach((i) => {
      blocks.push({
        idx: getX(i + 1),
        idy: getY(i + 1),
        color: getBlockColor(color),
        content: i + 1,
      });
    });
    return {
      number: index + 2,
      blocks: blocks,
    };
  });

  return [step1, ...steps];
}
