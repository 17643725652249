import clsx from 'clsx';
import { AlertTemplateProps, AlertTransition, transitions } from 'react-alert';

type AlertConfigType = {
  offset: string;
  timeout: number;
  transition: AlertTransition;
};
export const alertConfig: AlertConfigType = {
  offset: '5px',
  timeout: 3000,
  transition: transitions.FADE,
};

export default function CustomAlert({
  message,
  options,
  style,
  close,
}: AlertTemplateProps) {
  const className = clsx(
    'h-[60px] z-10, left-0 right-0, w-screen transform-none top-[-5px] m-0 border-white-default border-solid text-white-default',
    {
      'bg-red-default': options.type === 'error',
      'bg-green-aquamarine': options.type === 'success',
      'bg-yellow-default': options.type === 'info',
    }
  );
  return (
    <div style={style} className={className}>
      <div className='flex h-full w-full items-center justify-center'>
        <div>{message}</div>
        <div
          className='absolute right-0 mr-[20px] cursor-pointer'
          onClick={close}
        >
          X
        </div>
      </div>
    </div>
  );
}
