import clsx from 'clsx';
import { ReactComponent as RightArrowIcon } from 'src/assets/icons/blockArrow.svg';
import { ReactComponent as BracketIcon } from 'src/assets/icons/bracket.svg';
import { ReactComponent as CrossIcon } from 'src/assets/icons/cross.svg';
import { ReactComponent as EqualsIcon } from 'src/assets/icons/equal.svg';
import { ReactComponent as TriangleIcon } from 'src/assets/icons/play.svg';
import { ReactComponent as RotationIcon } from 'src/assets/icons/rotation.svg';
import { ReactComponent as RotationLeftIcon } from 'src/assets/icons/rotation_left.svg';
import { ReactComponent as RotationRightIcon } from 'src/assets/icons/rotation_right.svg';

import {
  ExerciseBlockContent,
  ExerciseStepBlock,
} from 'src/database/exerciseTypes';

type Props = {
  block: ExerciseStepBlock | undefined;
};

export default function Block({ block }: Props) {
  if (typeof block === 'undefined') {
    return <></>;
  }

  if (block.content === ExerciseBlockContent.BLANK) {
    return (
      <div
        className={clsx('relative z-10 h-full w-full', { 'z-20': block.moved })}
      >
        <div
          style={{ backgroundColor: block.color }}
          className={clsx(block.className, 'h-full w-full', {
            'absolute ml-[50px] mt-[-10px] max-h-[40px] w-[40px] border border-solid':
              block.moved,
          })}
        />
      </div>
    );
  }

  if (
    block.content === ExerciseBlockContent.LEFT_ARROW ||
    block.content === ExerciseBlockContent.RIGHT_ARROW ||
    block.content === ExerciseBlockContent.UP_ARROW ||
    block.content === ExerciseBlockContent.DOWN_ARROW
  ) {
    return (
      <div className='z-10 h-full w-full'>
        <div
          className='flex h-full w-full items-center justify-center'
          style={{ backgroundColor: block.color }}
        >
          <RightArrowIcon
            className={clsx('h-[26px] w-[26px]', {
              'rotate-180': block.content === ExerciseBlockContent.LEFT_ARROW,
              'rotate-90': block.content === ExerciseBlockContent.DOWN_ARROW,
              '-rotate-90': block.content === ExerciseBlockContent.UP_ARROW,
            })}
          />
        </div>
      </div>
    );
  }

  if (block.content === ExerciseBlockContent.CIRCLE_ARROW) {
    return (
      <div className='z-10 h-full w-full'>
        <div
          style={{ backgroundColor: block.color }}
          className={clsx(
            block.className,
            'flex h-full w-full items-center justify-center text-center align-middle'
          )}
        >
          <RotationIcon className='h-full w-full' />
        </div>
      </div>
    );
  }

  if (block.content === ExerciseBlockContent.FOUR_ARROWS) {
    return (
      <div className='z-10 h-full w-full'>
        <div
          style={{ backgroundColor: block.color }}
          className={clsx(
            block.className,
            'flex h-full w-full items-center justify-center '
          )}
        >
          <CrossIcon className='h-full w-full' />
        </div>
      </div>
    );
  }

  if (block.content === ExerciseBlockContent.UP_TO_DOWN_RIGHT_ARROW) {
    return (
      <div className='z-10 h-full w-full'>
        <div
          style={{ backgroundColor: block.color }}
          className={clsx(
            block.className,
            'flex h-full w-full items-center justify-center text-center align-middle'
          )}
        >
          <RotationRightIcon className='h-full w-full ' />
        </div>
      </div>
    );
  }

  if (block.content === ExerciseBlockContent.UP_TO_DOWN_LEFT_ARROW) {
    return (
      <div className='z-10 h-full w-full'>
        <div
          style={{ backgroundColor: block.color }}
          className={clsx(
            block.className,
            'flex h-full w-full items-center justify-center text-center align-middle'
          )}
        >
          <RotationLeftIcon className='h-full w-full rotate-180' />
        </div>
      </div>
    );
  }

  if (
    block.content === ExerciseBlockContent.TRIANGLE ||
    block.content === ExerciseBlockContent.TRIANGLE_RIGHT
  ) {
    return (
      <div className='z-10 h-full w-full'>
        <div
          style={{ backgroundColor: block.color }}
          className={clsx(
            block.className,
            'flex h-full w-full items-center justify-center text-center align-middle'
          )}
        >
          <TriangleIcon
            className={clsx('h-[30px] w-[30px]', {
              '-rotate-90': block.content === ExerciseBlockContent.TRIANGLE,
            })}
          />
        </div>
      </div>
    );
  }

  if (
    block.content === ExerciseBlockContent.BRACKET_LEFT ||
    block.content === ExerciseBlockContent.BRACKET_RIGHT
  ) {
    return (
      <div className='z-10 h-full w-full'>
        <div
          style={{ backgroundColor: block.color }}
          className={clsx(
            block.className,
            'flex h-full w-full items-center justify-center text-center align-middle'
          )}
        >
          <BracketIcon
            className={clsx('h-full w-full', {
              'rotate-180':
                block.content === ExerciseBlockContent.BRACKET_RIGHT,
            })}
          />
        </div>
      </div>
    );
  }

  if (block.content === ExerciseBlockContent.EQUALS) {
    return (
      <div className='z-10 h-full w-full'>
        <div
          style={{ backgroundColor: block.color }}
          className={clsx(
            block.className,
            'flex h-full w-full items-center justify-center text-center align-middle'
          )}
        >
          <EqualsIcon className='h-full w-full' />
        </div>
      </div>
    );
  }

  if (typeof block.content === 'number' || typeof block.content === 'string') {
    return (
      <div className='z-10 h-full w-full text-[18px] xl:text-[24px]'>
        <div
          style={{ backgroundColor: block.color }}
          className={clsx('flex h-full w-full items-center justify-center', {
            'animate-pulse': block.pulse === true,
          })}
        >
          {block.content}
        </div>
      </div>
    );
  }

  return <></>;
}
