import {
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseStep,
  ExerciseStepBlock,
  getBlockColor,
} from 'src/database/exerciseTypes';
import { getPluralColor } from 'src/database/exerciseUtil';

export function getC3S3E1VariantSteps(
  color: ExerciseColor,
  blockXShift: number,
  blockYShift: number
): ExerciseStep[] {
  function getStepBlocks(): ExerciseStepBlock[] {
    const xShiftNumbers = Array.from({ length: blockXShift }, (_, i) => i + 1);
    const yShiftNumbers = Array.from({ length: blockYShift }, (_, i) => i + 1);

    const blocks: ExerciseStepBlock[] = [];

    xShiftNumbers.forEach((x) => {
      yShiftNumbers.forEach((y) => {
        blocks.push({
          idx: x,
          idy: y,
          color: getBlockColor(color),
          content: ExerciseBlockContent.BLANK,
        });
      });
    });

    return blocks;
  }

  const step1 = {
    number: 1,
    numbersOnAxis: true,
    description:
      'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
  };

  const step2 = {
    number: 2,
    numbersOnAxis: true,
    description: `Ułóżcie z ${getPluralColor(
      getBlockColor(color),
      blockXShift * blockYShift,
      true
    )} figurę składającą się z ${blockYShift} wierszy, a każdy wiersz z ${blockXShift} klocków`,
  };

  const step3 = {
    number: 3,
    numbersOnAxis: true,
    blocks: [...getStepBlocks()],
    description: '',
  };

  const step4 = {
    number: 4,
    numbersOnAxis: true,
    description:
      'Z ilu klocków składa się zbudowana figura? Jak można obliczyć liczbę klocków?',
    blocks: [...getStepBlocks()],
  };

  return [step1, step2, step3, step4];
}
