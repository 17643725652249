import { ExerciseStep, ExerciseStepBlock } from 'src/database/exerciseTypes';
import Block from '../BlockTypes/Block';

type Props = {
  step: ExerciseStep | undefined;
};

export default function BlankMatrix15x15({ step }: Props) {
  const width = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const height = [15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
  function findBlock(idx: number, idy: number): ExerciseStepBlock | undefined {
    if (typeof step?.blocks !== 'undefined') {
      return step.blocks.find((block) => block.idx == idx && block.idy == idy);
    }
    return undefined;
  }

  return (
    <div className='flex h-full justify-center'>
      <div className='relative flex h-full w-full items-center justify-center'>
        <div className='flex h-[675px]'>
          {width.map((idx) => (
            <div className='h-[45px] w-[45px]' key={idx}>
              {height.map((idy) => {
                const block = findBlock(idx, idy);
                return (
                  <div
                    className='h-full w-full border border-solid'
                    key={`${idx}-${idy}`}
                  >
                    <Block block={block} />
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
