import { Category, CategoryName } from '../exerciseTypes';
import { c4e1 } from './exercise1/c4e1';
import { c4e2 } from './exercise2/c4e2';
import { c4e3 } from './exercise3/c4e3';
import { c4e4 } from './exercise4/c4e4';
import { c4e5 } from './exercise5/c4e5';
import { c4e6 } from './exercise6/c4e6';
import { c4e7 } from './exercise7/c4e7';
import { c4e8 } from './exercise8/c4e8';

export const category4: Category = {
  number: 4,
  name: CategoryName.CATEGORY_4,
  exercises: [c4e1, c4e2, c4e3, c4e4, c4e5, c4e6, c4e7, c4e8],
};
