import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { UserAuth } from 'src/context/AuthContext';
import { useDatabase } from 'src/database/database';
import { recoverPasswordSchema } from 'src/utils/validation';
import { RecoverPasswordStepEnum } from './RecoverPassword';

export type RecoverPasswordRequest = {
  login: string;
};

export function useRecoverPassword() {
  const { recoverPassword } = UserAuth();
  const [step, setStep] = useState<RecoverPasswordStepEnum>(
    RecoverPasswordStepEnum.firstStep
  );
  const { isLoginUsed } = useDatabase();

  async function handleRecoverPassword(inputs: RecoverPasswordRequest) {
    await isLoginUsed(inputs.login).then((isUsed) => {
      if (isUsed) {
        recoverPassword(inputs.login)?.then(() => {
          form.clearErrors();
          setStep(RecoverPasswordStepEnum.successStep);
        });
      } else {
        form.setError('login', {
          type: 'custom',
          message: 'Użytkownik z podanym adresem e-mail nie istnieje.',
        });
      }
    });
  }

  const form = useForm<RecoverPasswordRequest>({
    mode: 'onTouched',
    resolver: yupResolver(recoverPasswordSchema),
  });

  return {
    form,
    handleRecoverPassword,
    step,
  };
}
