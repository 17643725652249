import {
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseStep,
  ExerciseStepBlock,
  getBlockColor,
  Position,
} from 'src/database/exerciseTypes';
import { getPluralColor } from 'src/database/exerciseUtil';

export function getC1E4VariantSteps(
  color: ExerciseColor,
  numberOfBlocks: number,
  alongTheSide: Position
): ExerciseStep[] {
  const blocksArray = Array.from({ length: numberOfBlocks }, (_, i) => i + 1);
  let blocks: ExerciseStepBlock[] = [];
  let asideDescription = '';

  if (alongTheSide === Position.UP) {
    blocks = blocksArray.map((element) => ({
      idx: element,
      idy: 10,
      color: getBlockColor(color),
      content: ExerciseBlockContent.BLANK,
    }));
    asideDescription = 'górnym';
  }
  if (alongTheSide === Position.DOWN) {
    blocks = blocksArray.map((element) => ({
      idx: element,
      idy: 1,
      color: getBlockColor(color),
      content: ExerciseBlockContent.BLANK,
    }));
    asideDescription = 'dolnym';
  }

  const colorWithBlocksDescription = getPluralColor(getBlockColor(color), numberOfBlocks, true);

  return [
    {
      number: 1,
      description: `Ułóż ${numberOfBlocks} ${colorWithBlocksDescription} przy ${asideDescription} brzegu siatki`,
    },
    {
      number: 2,
      blocks: blocks,
    },
  ];
}
