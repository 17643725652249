export const customPath: Path = {
  homepage: '/',
  singIn: '/logowanie',
  register: '/rejestracja',
  recoverPassword: '/odzyskaj-haslo',
  accountSettings: '/ustawienia',
  basicAuth: '/basic-auth',
  exercise: '/cwiczenie',
  newVariant: '/nowy-wariant',
};

type Path = {
  homepage: string;
  singIn: string;
  register: string;
  recoverPassword: string;
  accountSettings: string;
  basicAuth: string;
  exercise: string;
  newVariant: string;
};
