import {
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseStep,
    getBlockColor,
  } from 'src/database/exerciseTypes';
  import { getPluralColor } from 'src/database/exerciseUtil';
  import { getDividedBlocks } from '../../category3Util';
  
  export function getC3S4E12VariantSteps(
    color: ExerciseColor,
    numberOfBlocks: number,
    numberOfCorners: number
  ): ExerciseStep[] {
    function getLeftBlocks() {
      const rest = numberOfBlocks % numberOfCorners;
      if (rest === 0) {
        return [];
      }
      const numbers = Array.from({ length: rest }, (_, i) => i + 1);
      return numbers.map((n) => ({
        idx: 1,
        idy: n,
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      }));
    }
  
    const step1 = {
      number: 1,
      description:
        'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
    };
  
    const step2 = {
      number: 2,
      description: `Wyjmijcie z pudełka ${getPluralColor(
        color,
        numberOfBlocks
      )} i połóżcie je obok siatki tak, aby cyfry na klockach nie były widoczne`,
    };
  
    const step3 = {
      number: 3,
      description: `Rozłóżcie te klocki po równo w ${numberOfCorners} kwadratach/kwadracie`,
    };
  
    const step4: ExerciseStep = {
      number: 4,
      blocks: [...getDividedBlocks(color, numberOfBlocks, numberOfCorners)],
      leftHelpMatrix:
        getLeftBlocks().length > 0 ? [...getLeftBlocks()] : undefined,
    };
  
    return [step1, step2, step3, step4];
  }
  