import {
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseStep,
  getBlockColor,
} from 'src/database/exerciseTypes';
import { getShiftSteps } from '../category1Util';

export function getC1E9VariantSteps(
  color: ExerciseColor,
  blockXShift: number,
  blockYShift: number
): ExerciseStep[] {
  const step1 = {
    number: 1,
    description: `Przesuńcie ${color.toLowerCase()} klocek w prawo od pionowej strzałki o ${blockYShift} kroki/kroków i ${blockXShift} kroki/kroków w górę od poziomej strzałki`,
  };

  const step2 = {
    number: 2,
    axisBlocks: [
      {
        idx: 0,
        idy: 0,
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      },
    ],
  };

  return [step1, step2, ...getShiftSteps(blockXShift, blockYShift, color)];
}
