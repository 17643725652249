import { ExerciseStep } from 'src/database/exerciseTypes';
import Blocks from '../BlockTypes/Blocks';

type Props = {
  step: ExerciseStep | undefined;
  exerciseStarted: boolean | undefined;
};

export default function BlankMatrix10x10({ step, exerciseStarted }: Props) {
  const width = Array.from(
    { length: step?.matrixWidth || 10 },
    (_, i) => i + 1
  );

  const height = Array.from(
    { length: step?.matrixHeight || 10 },
    (_, i) => i + 1
  ).reverse();

  return (
    <div className='relative h-full w-full p-[20px]'>
      <div className='flex h-full w-full items-center justify-center p-[20px]'>
        <div className='border border-solid p-[20px]'>
          <Blocks
            width={width}
            height={height}
            step={step}
            wrapperClassName='px-[20px] items-center'
            blocksWithoutBorders
            withoutLines
            withoutMatrixBorder
            blocksWithoutPaddings={step?.blocksWithoutPaddings}
            exerciseStarted={exerciseStarted}
          />
        </div>
      </div>
    </div>
  );
}
