import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E3,
  description: 'Wyciągnijcie z pudełka taką samą drewnianą podkładkę',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E3,
};

const step3 = {
  number: 3,
  description:
    'Obrysujcie ołówkiem na kartonie wszystkie ściany sześcianu tak, aby cztery ścianki stykały się ze sobą jedną krawędzią i były narysowane w szeregu, a dwie ścianki były narysowane po przeciwnych stronach jednego z kwadratów. Zróbcie to tak, jak na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E3,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E3,
};

const step5 = {
  number: 5,
  customMatrix: CustomMatrix.EXERCISE_C6_S1_E3,
  description:
    'Wytnijcie z kartonu narysowaną siatkę i poskładajcie ją tak, aby powstał sześcian, a następnie sklejcie ścianki sześcianu taśmą klejącą',
};

export const c6s1e3: Exercise = {
  number: 3,
  name: ExerciseName.EXERCISE_3,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: [step1, step2, step3, step4, step5],
  availability: 'FULL_ACCESS',
};
