import { Category, CategoryName } from '../exerciseTypes';
import { c1e1 } from './exercise1/c1e1';
import { c1e10 } from './exercise10/c1e10';
import { c1e11 } from './exercise11/c1e11';
import { c1e12 } from './exercise12/c1e12';
import { c1e13 } from './exercise13/c1e13';
import { c1e14 } from './exercise14/c1e14';
import { c1e15 } from './exercise15/c1e15';
import { c1e16 } from './exercise16/c1e16';
import { c1e17 } from './exercise17/c1e17';
import { c1e18 } from './exercise18/c1e18';
import { c1e19 } from './exercise19/c1e19';
import { c1e2 } from './exercise2/c1e2';
import { c1e20 } from './exercise20/c1e20';
import { c1e21 } from './exercise21/c1e21';
import { c1e22 } from './exercise22/c1e22';
import { c1e3 } from './exercise3/c1e3';
import { c1e4 } from './exercise4/c1e4';
import { c1e5 } from './exercise5/c1e5';
import { c1e6 } from './exercise6/c1e6';
import { c1e7 } from './exercise7/c1e7';
import { c1e8 } from './exercise8/c1e8';
import { c1e9 } from './exercise9/c1e9';

export const category1: Category = {
  number: 1,
  name: CategoryName.CATEGORY_1,
  exercises: [
    c1e1,
    c1e2,
    c1e3,
    c1e4,
    c1e5,
    c1e6,
    c1e7,
    c1e8,
    c1e9,
    c1e10,
    c1e11,
    c1e12,
    c1e13,
    c1e14,
    c1e15,
    c1e16,
    c1e17,
    c1e18,
    c1e19,
    c1e20,
    c1e21,
    c1e22,
  ],
};
