import {
  createUserWithEmailAndPassword,
  deleteUser,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  User,
  UserCredential,
} from 'firebase/auth';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { auth } from 'src/config/firebase.config';
import { Product, useDatabase } from 'src/database/database';

export type AccessLevel = 'FULL_ACCESS' | 'LIMITED_ACCESS';

type Props = {
  children: ReactNode;
};

type UserContextType = {
  createUser: (login: string, password: string) => Promise<UserCredential>;
  signIn: (login: string, password: string) => Promise<UserCredential>;
  logout: () => Promise<void> | undefined;
  deleteSignedUser: () => Promise<void> | undefined;
  user: User | null;
  userAccessLevels: AccessLevel[];
  selectedProduct: Product;
  userProducts: Product[];
  setSelectedProduct: (product: Product) => void;
  changePassword: (password: string) => Promise<void> | undefined;
  recoverPassword: (email: string) => Promise<void> | undefined;
};

// const actionCodeSettings = {
//   url: 'https://www.ekomatik.eu/',
// };

export const UserContext = createContext<UserContextType>(
  {} as UserContextType
);

export function AuthContextProvider({ children }: Props) {
  const {
    getUserAccessLevels,
    deleteUser: deleteUserDoc,
    getUserProducts,
  } = useDatabase();
  const [user, setUser] = useState<User | null>(null);
  const [userAccessLevels, setUserAccessLevels] = useState<AccessLevel[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product>('Szkoła');
  const [userProducts, setUserProducts] = useState<Product[]>([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      getUserAccessLevels(currentUser).then((accessLevel) => {
        setUserAccessLevels(accessLevel);
      });
      getUserProducts(currentUser).then((products) => {
        setUserProducts(products);
      });
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  function createUser(
    login: string,
    password: string
  ): Promise<UserCredential> {
    const createdUser = createUserWithEmailAndPassword(
      auth,
      login,
      password
    ).then((userCredentials) => {
      sendEmailVerification(userCredentials.user);
      return userCredentials;
    });
    return createdUser;
  }

  function deleteSignedUser() {
    if (user !== null) {
      return deleteUser(user).then(() => {
        if (user.email) {
          return deleteUserDoc(user.email);
        }
      });
    }
  }

  function signIn(login: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(auth, login, password).then((uc) => {
      if (!uc.user.emailVerified) {
        logout();
      }
      return uc;
    });
  }

  function logout() {
    return signOut(auth);
  }

  function changePassword(newPassword: string) {
    if (user !== null) {
      return updatePassword(user, newPassword.trim());
    }
  }

  function recoverPassword(email: string) {
    if (email !== null) {
      return sendPasswordResetEmail(auth, email);
    }
  }

  return (
    <UserContext.Provider
      value={{
        createUser,
        logout,
        signIn,
        user,
        userAccessLevels,
        selectedProduct,
        userProducts,
        setSelectedProduct,
        deleteSignedUser,
        changePassword,
        recoverPassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function UserAuth() {
  return useContext(UserContext);
}
