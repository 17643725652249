import {
  Exercise,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseName,
  ExerciseType,
} from '../../exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Przesuńcie teraz klocek w prawo od pionowej strzałki o 4 kroki i 4 kroki w górę od poziomej strzałki',
};

const step2 = {
  number: 2,
  axisBlocks: [
    {
      idx: 0,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step3 = {
  number: 3,
  axisBlocks: [
    {
      idx: 4,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  number: 4,
  blocks: [
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  number: 5,
  blocks: [
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.RIGHT_ARROW,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: 4,
    },
    {
      idx: 5,
      idy: 10,
      color: ExerciseColor.BRĄZOWY,
      content: ExerciseBlockContent.UP_ARROW,
    },
    {
      idx: 6,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const steps = [step1, step2, step3, step4, step5];

export const c1e18: Exercise = {
  number: 18,
  name: ExerciseName.EXERCISE_18,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  steps: steps,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'blockXShift',
      label: 'Odsunięcie klocka od pionowej osi',
      placeholder: 'Wybierz odsunięcie względem pionowej osi',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
    {
      name: 'blockYShift',
      label: 'Odsunięcie klocka od poziomej osi',
      placeholder: 'Wybierz odsunięcie względem poziomej osi',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
  ],
};
