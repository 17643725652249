import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import Button from 'src/components/Button';
import Dialog from 'src/components/Dialog';
import Footer from 'src/components/Footer';
import Input from 'src/components/Input';
import { label } from 'src/utils/labels';
import { ButtonSize, ButtonTypes } from 'src/utils/types';
import DeleteAccountDialog from './DeleteAccountDialog';
import ExtendAccess from './ExtendAccess';
import { useAccountSettings } from './hooks';

export default function AccountSettings() {
  const { handleChangePassword, form, isLoading, userAccessLevels } =
    useAccountSettings();
  const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] =
    useState(false);

  const showCodeInput =
    userAccessLevels.length > 0 && !userAccessLevels.includes('FULL_ACCESS');

  return (
    <>
      <Dialog title='Ustawienia konta' withClose>
        <DeleteAccountDialog
          isOpen={isDeleteAccountDialogOpen}
          setIsOpen={setIsDeleteAccountDialogOpen}
        />
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(handleChangePassword)}
            noValidate
            autoComplete='off'
          >
            <div className='relative p-[10px] pt-[30px] shadow shadow-gray-shadow'>
              <div className='absolute top-[-12px] bg-white-default px-[10px]'>
                Zmiana hasła
              </div>
              <Input
                name='password'
                type='password'
                label={label.changePassword.password.label}
                placeholder={
                  label.recoverPasswordThirdStep.password.placeholder
                }
                wrapperClassName='mb-[10px]'
              />
              <Input
                name='passwordConfirm'
                type='password'
                label={label.changePassword.passwordConfirm.label}
                placeholder={
                  label.recoverPasswordThirdStep.passwordConfirm.placeholder
                }
                wrapperClassName='mb-[20px]'
              />
              <Button
                type='submit'
                variant={ButtonTypes.primary}
                customClassName='w-full md:max-w-[300px]'
                buttonSize={ButtonSize.BIG}
                isLoading={isLoading}
              >
                <span>{label.changePassword.buttons.changePassword}</span>
              </Button>
            </div>
          </form>
        </FormProvider>
        {showCodeInput && <ExtendAccess />}
        <div className='relative mt-[40px] p-[10px] pt-[30px] shadow shadow-gray-shadow'>
          <div className='absolute top-[-12px] bg-white-default px-[10px]'>
            Usuwanie konta
          </div>
          <Button
            variant={ButtonTypes.warning}
            customClassName='w-full md:max-w-[300px]'
            buttonSize={ButtonSize.BIG}
            onClick={() => setIsDeleteAccountDialogOpen(true)}
          >
            <span>Usuń konto</span>
          </Button>
        </div>
      </Dialog>
      <Footer />
    </>
  );
}
