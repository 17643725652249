import {
  Exercise,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseName,
  ExerciseStepBlock,
  ExerciseType,
} from '../../exerciseTypes';

const step1 = {
  number: 1,
  withDecimals: true,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie. Pooglądajcie ją i powiedźcie, co ciekawego zauważyliście',
};

const step2 = {
  number: 2,
  withDecimals: true,
  description:
    'Wyjmijcie z pudełka 12 zielonych klocków i połóżcie je obok siatki tak, aby nie były widoczne wypisane na nich cyfry',
};

function getLeftMatrixBlocks(
  firstIdxBlocks: number,
  secondIdxBlocks: number
): ExerciseStepBlock[] {
  const blocks = [];
  for (let i = 1; i <= firstIdxBlocks; i++) {
    blocks.push({
      idx: 1,
      idy: i,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    });
  }
  for (let i = 1; i <= secondIdxBlocks; i++) {
    blocks.push({
      idx: 2,
      idy: i,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    });
  }
  return blocks;
}

const step3 = {
  number: 3,
  withDecimals: true,
  leftHelpMatrix: getLeftMatrixBlocks(2, 10),
};

const step4 = {
  number: 4,
  withDecimals: true,
  description:
    'Układajcie razem ze mną zielone klocki w kolumnie z napisem Jedności',
  leftHelpMatrix: getLeftMatrixBlocks(2, 10),
};

const step5 = {
  number: 5,
  withDecimals: true,
  blocks: [
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  leftHelpMatrix: getLeftMatrixBlocks(2, 9),
};

const step6 = {
  number: 6,
  withDecimals: true,
  blocks: [
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  leftHelpMatrix: getLeftMatrixBlocks(2, 8),
};

const step7 = {
  number: 7,
  withDecimals: true,
  blocks: [
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  leftHelpMatrix: getLeftMatrixBlocks(2, 7),
};

const step8 = {
  number: 8,
  withDecimals: true,
  blocks: [
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  leftHelpMatrix: getLeftMatrixBlocks(2, 6),
};

const step9 = {
  number: 9,
  withDecimals: true,
  blocks: [
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  leftHelpMatrix: getLeftMatrixBlocks(2, 5),
};

const step10 = {
  number: 10,
  withDecimals: true,
  blocks: [
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  leftHelpMatrix: getLeftMatrixBlocks(2, 4),
};

const step11 = {
  number: 11,
  withDecimals: true,
  blocks: [
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  leftHelpMatrix: getLeftMatrixBlocks(2, 3),
};

const step12 = {
  number: 12,
  withDecimals: true,
  blocks: [
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  leftHelpMatrix: getLeftMatrixBlocks(2, 2),
};

const step13 = {
  number: 13,
  withDecimals: true,
  blocks: [
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  leftHelpMatrix: getLeftMatrixBlocks(2, 1),
};

const step14 = {
  number: 14,
  withDecimals: true,
  blocks: [
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  leftHelpMatrix: getLeftMatrixBlocks(2, 0),
};

const step15 = {
  number: 15,
  withDecimals: true,
  description:
    'Gdy ustawimy kolumnę, która ma 10 zielonych klocków, i już nie ma miejsca na dalsze klocki, całą kolumnę 10 klocków zamieniamy na jeden czerwony klocek, który oznacza, że mamy jedną dziesiątkę. Czerwony klocek układamy w kolumnie Dziesiątki. Pozostałe zielone klocki układamy znowu w kolumnie Jedności. Teraz możemy zobaczyć, jak wygląda liczba 12. Taką liczbę zapisujemy',
  leftHelpMatrix: getLeftMatrixBlocks(2, 0),
};

const step16 = {
  number: 16,
  withDecimals: true,
  blocks: [
    {
      idx: 3,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  leftHelpMatrix: getLeftMatrixBlocks(2, 0),
};

const step17 = {
  number: 17,
  withDecimals: true,
  blocks: [
    {
      idx: 3,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  leftHelpMatrix: getLeftMatrixBlocks(1, 0),
};

const step18 = {
  number: 18,
  withDecimals: true,
  blocks: [
    {
      idx: 3,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const steps = [
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  step9,
  step10,
  step11,
  step12,
  step13,
  step14,
  step15,
  step16,
  step17,
  step18,
];

export const c2e12: Exercise = {
  number: 12,
  name: ExerciseName.EXERCISE_12,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  steps: steps,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'thousands',
      label: 'Liczba tysięcy',
      placeholder: 'Wybierz liczbę tysięcy',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
    {
      name: 'hundreds',
      label: 'Liczba setek',
      placeholder: 'Wybierz liczbę setek',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
    {
      name: 'dozens',
      label: 'Liczba dziesiątek',
      placeholder: 'Wybierz liczbę dziesiątek',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
    {
      name: 'units',
      label: 'Liczba jedności',
      placeholder: 'Wybierz liczbę jedności',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
  ],
};
