import {
    Exercise,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../../exerciseTypes';

const step1 = {
  numbersOnAxis: true,
  number: 1,
  description:
    'Wyjmijcie z pudełka dwie kostki czworościenne i jedną sześcienną',
  imageSrc: `${process.env.PUBLIC_URL}/emptyBoard.png`,
};

const step2 = {
  numbersOnAxis: true,
  number: 2,
  imageSrc: `${process.env.PUBLIC_URL}/dices.png`,
};

const step3 = {
  numbersOnAxis: true,
  number: 3,
  description:
    '- Jakiego kształtu są ściany kostek czworościennych i ile ich jest? \n\n - Ile ścian zbiega się w każdym wierzchołku kostki czworościennej? \n\n - Jakie cyfry znajdują się na ściankach kostki czworościennej?',
};

const step4 = {
  numbersOnAxis: true,
  number: 4,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step5 = {
  numbersOnAxis: true,
  number: 5,
  description:
    'Dobierzcie się parami. Najpierw każdy z was rzuca jeden raz kostką sześcienną i odczytuje na górnej ściance otrzymaną liczbę oczek. Grę rozpoczyna ten, kto wyrzuci większą liczbę. W przypadku otrzymania takiej samej liczby, powtarzamy rzuty kostką',
};

const step6 = {
  numbersOnAxis: true,
  number: 6,
  description:
    'Jedno z was wybiera z pudełka takie czerwone klocki, na których widoczne są liczby mniejsze lub równe 16 i układa je obok siatki, Drugie wybiera zielone, na których widoczne są liczby mniejsze lub równe 16 i także układa je obok siatki, Osobno połóżcie żółte klocki, na których widoczne są liczby mniejsze lub równe 16',
};

const step7 = {
  numbersOnAxis: true,
  number: 7,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
  ],
};

const step8 = {
  numbersOnAxis: true,
  number: 8,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
  ],
};

const step9 = {
  numbersOnAxis: true,
  number: 9,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
  ],
};

const step10 = {
  numbersOnAxis: true,
  number: 10,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
  ],
};

const step11 = {
  numbersOnAxis: true,
  number: 11,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: 2,
    },
  ],
};

const step12 = {
  numbersOnAxis: true,
  number: 12,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
  ],
};

const step13 = {
  numbersOnAxis: true,
  number: 13,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 4,
    },
  ],
};

const step14 = {
  numbersOnAxis: true,
  number: 14,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
  ],
};

const step15 = {
  numbersOnAxis: true,
  number: 15,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 4,
      pulse: true,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
  ],
};

const step16 = {
  numbersOnAxis: true,
  number: 16,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 6,
      pulse: true,
    },
  ],
};

const step17 = {
  numbersOnAxis: true,
  number: 17,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 8,
    },
  ],
};

const step18 = {
  numbersOnAxis: true,
  number: 18,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 8,
    },

    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
  ],
};

const step19 = {
  numbersOnAxis: true,
  number: 19,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 8,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 3,
    },
  ],
};

export const c3s3e2: Exercise = {
  number: 2,
  name: ExerciseName.EXERCISE_2,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'blockXShift',
      label: 'Liczba kolumn',
      placeholder: 'Podaj liczbę kolumn',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
    {
      name: 'blockYShift',
      label: 'Liczba wierszy',
      placeholder: 'Podaj liczbę wierszy',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
  ],
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
    step17,
    step18,
    step19,
  ],
};
