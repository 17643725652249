import { ExerciseStep } from 'src/database/exerciseTypes';
import { getBlocksAfterCount, getBlocksBeforeCount } from '../../category3Util';

export function getC3S1E4VariantSteps(
  firstNumber: number,
  secondNumber: number
): ExerciseStep[] {
  const step1 = {
    withDecimals: true,
    number: 1,
    description:
      'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
  };

  const step2 = {
    withDecimals: true,
    number: 2,
    description:
      'Ułóżcie takie same kolumny, w tym samym miejscu, jak na obrazku na ekranie',
  };

  const step3 = {
    withDecimals: true,
    number: 3,
    blocks: [...getBlocksBeforeCount(firstNumber, secondNumber)],
  };

  const step4 = {
    withDecimals: true,
    number: 4,
    description: 'Policzcie klocki w lewej kolumnie oraz w prawej kolumnie',
    blocks: [...getBlocksBeforeCount(firstNumber, secondNumber)],
  };

  const step5 = {
    withDecimals: true,
    number: 5,
    description: `Dodajcie do liczby ${firstNumber} liczbę ${secondNumber} i wynik zapiszcie w zeszytach`,
    blocks: [...getBlocksBeforeCount(firstNumber, secondNumber)],
  };

  const step6 = {
    withDecimals: true,
    number: 6,
    blocks: [
      ...getBlocksAfterCount(Number(firstNumber) + Number(secondNumber)),
    ],
  };

  return [step1, step2, step3, step4, step5, step6];
}
