import { getBlocksInGivenIdx } from 'src/database/exerciseUtil';
import {
  Exercise,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseName,
  ExerciseType,
} from '../../exerciseTypes';

const step1 = {
  withDecimals: true,
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie. Pooglądajcie ją i powiedźcie, co ciekawego zauważyliście',
};

const step2 = {
  withDecimals: true,
  number: 2,
  description:
    'Wyjmijcie z pudełka 45 zielonych klocków i połóżcie je obok siatki tak, aby nie były widoczne wypisane na nich cyfry',
};

const step3 = {
  withDecimals: true,
  number: 3,
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  withDecimals: true,
  number: 4,
  description:
    'Układajcie razem ze mną zielone klocki w kolumnie z napisem Jedności',
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  withDecimals: true,
  number: 5,
  blocks: getBlocksInGivenIdx(1, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(9, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step6 = {
  withDecimals: true,
  number: 6,
  blocks: getBlocksInGivenIdx(2, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(8, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step7 = {
  withDecimals: true,
  number: 7,
  blocks: getBlocksInGivenIdx(3, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(7, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step8 = {
  withDecimals: true,
  number: 8,
  blocks: getBlocksInGivenIdx(4, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(6, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step9 = {
  withDecimals: true,
  number: 9,
  blocks: getBlocksInGivenIdx(5, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(5, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step10 = {
  withDecimals: true,
  number: 10,
  blocks: getBlocksInGivenIdx(6, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(4, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step11 = {
  withDecimals: true,
  number: 11,
  blocks: getBlocksInGivenIdx(7, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(3, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step12 = {
  withDecimals: true,
  number: 12,
  blocks: getBlocksInGivenIdx(8, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(2, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step13 = {
  withDecimals: true,
  number: 13,
  blocks: getBlocksInGivenIdx(9, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(1, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step14 = {
  withDecimals: true,
  number: 14,
  blocks: getBlocksInGivenIdx(10, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step15 = {
  withDecimals: true,
  number: 15,
  blocks: getBlocksInGivenIdx(10, 4, ExerciseColor.ZIELONY),
  description:
    'Gdy ustawimy kolumnę, która ma 10 klocków zielonych, i już nie ma miejsca na dalsze klocki, całą kolumnę 10 klocków zamieniamy na jeden czerwony klocek, który oznacza, że mamy jedną dziesiątkę. Czerwony klocek układamy w kolumnie Dziesiątki. Pozostałe klocki zielone układamy znowu w kolumnie Jedności. Gdy ustawimy kolumnę składającą się z 10 klocków zielonych, całą kolumnę zamieniamy na jeden czerwony klocek, który oznacza, że mamy drugą dziesiątkę. Pozostałe klocki zielone układamy znowu w kolumnie Jedności. Teraz możemy zobaczyć, jak wygląda liczba 25. Taką liczbę zapisujemy',
  leftHelpMatrix: [...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step16 = {
  withDecimals: true,
  number: 16,
  blocks: [...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY)],
  leftHelpMatrix: [...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step17 = {
  withDecimals: true,
  number: 17,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(1, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(9, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step18 = {
  withDecimals: true,
  number: 18,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(2, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(8, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step19 = {
  withDecimals: true,
  number: 19,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(3, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(7, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step20 = {
  withDecimals: true,
  number: 20,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(4, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(6, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step21 = {
  withDecimals: true,
  number: 21,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(5, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step22 = {
  withDecimals: true,
  number: 22,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(6, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(4, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step23 = {
  withDecimals: true,
  number: 23,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(7, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(3, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step24 = {
  withDecimals: true,
  number: 24,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(8, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(2, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step25 = {
  withDecimals: true,
  number: 25,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(9, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(1, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step26 = {
  withDecimals: true,
  number: 26,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(10, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step27 = {
  withDecimals: true,
  number: 27,
  blocks: [...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY)],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step28 = {
  withDecimals: true,
  number: 28,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(1, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(9, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step29 = {
  withDecimals: true,
  number: 29,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(2, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(8, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step30 = {
  withDecimals: true,
  number: 30,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(3, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(7, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step31 = {
  withDecimals: true,
  number: 31,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(4, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(6, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step32 = {
  withDecimals: true,
  number: 32,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(5, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step33 = {
  withDecimals: true,
  number: 33,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(6, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(4, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step34 = {
  withDecimals: true,
  number: 34,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(7, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(3, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step35 = {
  withDecimals: true,
  number: 35,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(8, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(2, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step36 = {
  withDecimals: true,
  number: 36,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(9, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(1, 1, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step37 = {
  withDecimals: true,
  number: 37,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(10, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY)],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step38 = {
  withDecimals: true,
  number: 38,
  blocks: [...getBlocksInGivenIdx(3, 3, ExerciseColor.CZERWONY)],
  rightHelpMatrix: [...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY)],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step39 = {
  withDecimals: true,
  number: 39,
  blocks: [
    ...getBlocksInGivenIdx(3, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(1, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [...getBlocksInGivenIdx(9, 2, ExerciseColor.ZIELONY)],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step40 = {
  withDecimals: true,
  number: 40,
  blocks: [
    ...getBlocksInGivenIdx(3, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(2, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [...getBlocksInGivenIdx(8, 2, ExerciseColor.ZIELONY)],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step41 = {
  withDecimals: true,
  number: 41,
  blocks: [
    ...getBlocksInGivenIdx(3, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(3, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [...getBlocksInGivenIdx(7, 2, ExerciseColor.ZIELONY)],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step42 = {
  withDecimals: true,
  number: 42,
  blocks: [
    ...getBlocksInGivenIdx(3, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(4, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [...getBlocksInGivenIdx(6, 2, ExerciseColor.ZIELONY)],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step43 = {
  withDecimals: true,
  number: 43,
  blocks: [
    ...getBlocksInGivenIdx(3, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(5, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [...getBlocksInGivenIdx(5, 2, ExerciseColor.ZIELONY)],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step44 = {
  withDecimals: true,
  number: 44,
  blocks: [
    ...getBlocksInGivenIdx(3, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(6, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [...getBlocksInGivenIdx(4, 2, ExerciseColor.ZIELONY)],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step45 = {
  withDecimals: true,
  number: 45,
  blocks: [
    ...getBlocksInGivenIdx(3, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(7, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [...getBlocksInGivenIdx(3, 2, ExerciseColor.ZIELONY)],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step46 = {
  withDecimals: true,
  number: 46,
  blocks: [
    ...getBlocksInGivenIdx(3, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(8, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [...getBlocksInGivenIdx(2, 2, ExerciseColor.ZIELONY)],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step47 = {
  withDecimals: true,
  number: 47,
  blocks: [
    ...getBlocksInGivenIdx(3, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(9, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: [...getBlocksInGivenIdx(1, 2, ExerciseColor.ZIELONY)],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step48 = {
  withDecimals: true,
  number: 48,
  blocks: [
    ...getBlocksInGivenIdx(3, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(10, 4, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step49 = {
  withDecimals: true,
  number: 49,
  blocks: [...getBlocksInGivenIdx(4, 3, ExerciseColor.CZERWONY)],
  axisBlocks: [
    {
      idx: 6,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step50 = {
  withDecimals: true,
  number: 50,
  blocks: [
    ...getBlocksInGivenIdx(4, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(1, 4, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 7,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step51 = {
  withDecimals: true,
  number: 51,
  blocks: [
    ...getBlocksInGivenIdx(4, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(2, 4, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 8,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step52 = {
  withDecimals: true,
  number: 52,
  blocks: [
    ...getBlocksInGivenIdx(4, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(3, 4, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 9,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step53 = {
  withDecimals: true,
  number: 53,
  blocks: [
    ...getBlocksInGivenIdx(4, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(4, 4, ExerciseColor.ZIELONY),
  ],
  axisBlocks: [
    {
      idx: 10,
      idy: 0,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step54 = {
  withDecimals: true,
  number: 54,
  blocks: [
    ...getBlocksInGivenIdx(4, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(5, 4, ExerciseColor.ZIELONY),
  ],
};

const steps = [
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  step9,
  step10,
  step11,
  step12,
  step13,
  step14,
  step15,
  step16,
  step17,
  step18,
  step19,
  step20,
  step21,
  step22,
  step23,
  step24,
  step25,
  step26,
  step27,
  step28,
  step29,
  step30,
  step31,
  step32,
  step33,
  step34,
  step35,
  step36,
  step37,
  step38,
  step39,
  step40,
  step41,
  step42,
  step43,
  step44,
  step45,
  step46,
  step47,
  step48,
  step49,
  step50,
  step51,
  step52,
  step53,
  step54,
];

export const c2e14: Exercise = {
  number: 14,
  name: ExerciseName.EXERCISE_14,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  steps: steps,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'thousands',
      label: 'Liczba tysięcy',
      placeholder: 'Wybierz liczbę tysięcy',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
    {
      name: 'hundreds',
      label: 'Liczba setek',
      placeholder: 'Wybierz liczbę setek',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
    {
      name: 'dozens',
      label: 'Liczba dziesiątek',
      placeholder: 'Wybierz liczbę dziesiątek',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
    {
      name: 'units',
      label: 'Liczba jedności',
      placeholder: 'Wybierz liczbę jedności',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
  ],
};
