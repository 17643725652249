export enum HomepageOption {
  EXERCISES,
  GUIDE,
  PRESENTATIONS,
  REPORT,
  COMPETENCE_TESTS,
  KIT_CONTENT,
  NONE,
}

export const ButtonTypes = {
  primary: 'primary',
  secondary: 'secondary',
  disabled: 'disabled',
  warning: 'warning',
} as const;

export enum ButtonSize {
  SMALL,
  MEDIUM,
  BIG,
}

export enum FirebaseErrorCodes {
  AUTH_OR_USER_NOT_FOUND = 'auth/user-not-found',
  WRONG_PASSWORD = 'auth/wrong-password',
  AUTH_EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
}
