import {
  CustomMatrix,
  Exercise,
  ExerciseName,
  ExerciseType,
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};

const step2 = {
  number: 2,
  description:
    'Ułóżcie na swoich podkładkach taką samą figurę, jaka pokazana jest na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
  description: 'Czy ktoś z was wie, jak nazywa się zbudowana przez was figura?',
};

const step5 = {
  number: 5,
  description: 'Ile prostokąt ma boków? Ile wierzchołków?',
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};

const step6 = {
  number: 6,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};

const step7 = {
  number: 7,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};

const step8 = {
  number: 8,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};

const step9 = {
  number: 9,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};
const step11 = {
  number: 11,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};
const step12 = {
  number: 12,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};
const step13 = {
  number: 13,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};
const step14 = {
  number: 14,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};
const step15 = {
  number: 15,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};
const step16 = {
  number: 16,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};
const step17 = {
  number: 17,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};
const step18 = {
  number: 18,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};
const step19 = {
  number: 19,
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};
const step20 = {
  number: 20,
  description:
    'Jaką długość mają niezmierzone boki? Co ciekawego możecie powiedzieć o długościach boków w prostokącie? Z ilu klocków jest zbudowany prostokąt?',
  customMatrix: CustomMatrix.EXERCISE_C5_E8,
};

export const c5e8: Exercise = {
  number: 8,
  name: ExerciseName.EXERCISE_8,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
    step17,
    step18,
    step19,
    step20,
  ],
};
