type Props = {
  stepNumber: number;
};

export default function CustomMatrixC7E1({ stepNumber }: Props) {
  if (stepNumber === 1 || stepNumber === 2 || stepNumber === 3) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'></div>
        </div>
      </div>
    );
  }

  if (stepNumber === 4 || stepNumber === 5 || stepNumber === 6) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='relative flex'>
              {[...Array(7)].map((e) => (
                <div key={e}>
                  {[...Array(5)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-blue-default'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 7) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='relative flex'>
              <div className='absolute top-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              {[...Array(7)].map((e) => (
                <div key={e}>
                  {[...Array(5)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-blue-default'
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 8) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='relative flex'>
              <div className='absolute top-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              {[...Array(7)].map((e) => (
                <div key={e}>
                  {[...Array(5)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-blue-default'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 9) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='relative flex'>
              <div className='absolute top-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              {[...Array(7)].map((e) => (
                <div key={e}>
                  {[...Array(5)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-blue-default'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-0 left-[-50px]'>
                {[...Array(5)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 10) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='relative flex'>
              <div className='absolute top-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              {[...Array(7)].map((e) => (
                <div key={e}>
                  {[...Array(5)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-blue-default'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-0 left-[-50px]'>
                {[...Array(5)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-0 right-[-50px]'>
                {[...Array(5)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 11) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='relative flex'>
              <div className='absolute top-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              {[...Array(7)].map((e) => (
                <div key={e}>
                  {[...Array(5)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-blue-default'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-0 left-[-50px]'>
                {[...Array(5)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-0 right-[-50px]'>
                {[...Array(5)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-[-50px] left-[-50px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 12) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='relative flex'>
              <div className='absolute top-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              {[...Array(7)].map((e) => (
                <div key={e}>
                  {[...Array(5)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-blue-default'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-0 left-[-50px]'>
                {[...Array(5)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-0 right-[-50px]'>
                {[...Array(5)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-[-50px] left-[-50px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <div className='absolute top-[-50px] right-[-50px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 13) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='relative flex'>
              <div className='absolute top-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              {[...Array(7)].map((e) => (
                <div key={e}>
                  {[...Array(5)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-blue-default'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-0 left-[-50px]'>
                {[...Array(5)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-0 right-[-50px]'>
                {[...Array(5)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-[-50px] left-[-50px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <div className='absolute top-[-50px] right-[-50px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <div className='absolute bottom-[-50px] right-[-50px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 14) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'>
            <div className='relative flex'>
              <div className='absolute top-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              {[...Array(7)].map((e) => (
                <div key={e}>
                  {[...Array(5)].map((f) => (
                    <div
                      key={f}
                      className='h-[50px] w-[50px] border border-solid bg-blue-default'
                    />
                  ))}
                </div>
              ))}
              <div className='absolute bottom-[-50px] flex'>
                {[...Array(7)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-0 left-[-50px]'>
                {[...Array(5)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-0 right-[-50px]'>
                {[...Array(5)].map((f) => (
                  <div
                    key={f}
                    className='h-[50px] w-[50px] border border-solid bg-yellow-default'
                  />
                ))}
              </div>
              <div className='absolute top-[-50px] left-[-50px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <div className='absolute top-[-50px] right-[-50px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <div className='absolute bottom-[-50px] right-[-50px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
              <div className='absolute bottom-[-50px] left-[-50px] h-[50px] w-[50px] border border-solid bg-yellow-default' />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div></div>;
}
