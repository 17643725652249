import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import MenuLeft from './MenuLeft';
import MenuRight from './MenuRight';

export default function Homepage() {
  return (
    <div className='h-[100vh]'>
      <Header />
      <div className='flex h-[65vh] w-full justify-center px-[20px] lg:px-[50px]'>
        <div className='mt-auto w-full justify-center md:mt-0 md:flex'>
          <div className='mb-[20px] h-[500px] w-full bg-white-default shadow shadow-gray-shadow md:mr-[10px] md:mb-0 md:w-[40%] lg:h-full'>
            <div className='flex h-full items-center justify-center overflow-y-auto'>
              <MenuLeft />
            </div>
          </div>
          <div className='mb-[20px] h-[500px] w-full bg-white-default p-[20px] shadow shadow-gray-shadow md:mb-0 md:ml-[10px] lg:h-full'>
            <div className='flex h-full items-center justify-center'>
              <MenuRight />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
