import { Category, CategoryName } from '../exerciseTypes';
import { c3s1e1 } from './subcategory1/exercise1/c3s1e1';
import { c3s1e2 } from './subcategory1/exercise2/c3s1e2';
import { c3s1e3 } from './subcategory1/exercise3/c3s1e3';
import { c3s1e4 } from './subcategory1/exercise4/c3s1e4';
import { c3s1e5 } from './subcategory1/exercise5/c3s1e5';
import { c3s1e6 } from './subcategory1/exercise6/c3s1e6';
import { c3s1e7 } from './subcategory1/exercise7/c3s1e7';
import { c3s1e8 } from './subcategory1/exercise8/c3s1e8';
import { c3s1e9 } from './subcategory1/exercise9/c3s1e9';
import { c3s2e1 } from './subcategory2/exercise1/c3s2e1';
import { c3s2e2 } from './subcategory2/exercise2/c3s2e2';
import { c3s2e3 } from './subcategory2/exercise3/c3s2e3';
import { c3s2e4 } from './subcategory2/exercise4/c3s2e4';
import { c3s2e5 } from './subcategory2/exercise5/c3s2e5';
import { c3s2e6 } from './subcategory2/exercise6/c3s2e6';
import { c3s2e7 } from './subcategory2/exercise7/c3s2e7';
import { c3s2e8 } from './subcategory2/exercise8/c3s2e8';
import { c3s2e9 } from './subcategory2/exercise9/c3s2e9';
import { c3s3e1 } from './subcategory3/exercise1/c3s3e1';
import { c3s3e2 } from './subcategory3/exercise2/c3s3e2';
import { c3s3e3 } from './subcategory3/exercise3/c3s3e3';
import { c3s3e4 } from './subcategory3/exercise4/c3s3e4';
import { c3s3e5 } from './subcategory3/exercise5/c3s3e5';
import { c3s3e6 } from './subcategory3/exercise6/c3s3e6';
import { c3s3e7 } from './subcategory3/exercise7/c3s3e7';
import { c3s4e1 } from './subcategory4/exercise1/c3s4e1';
import { c3s4e10 } from './subcategory4/exercise10/c3s4e10';
import { c3s4e11 } from './subcategory4/exercise11/c3s4e11';
import { c3s4e12 } from './subcategory4/exercise12/c3s4e12';
import { c3s4e13 } from './subcategory4/exercise13/c3s4e13';
import { c3s4e14 } from './subcategory4/exercise14/c3s4e14';
import { c3s4e15 } from './subcategory4/exercise15/c3s4e15';
import { c3s4e2 } from './subcategory4/exercise2/c3s4e2';
import { c3s4e3 } from './subcategory4/exercise3/c3s4e3';
import { c3s4e4 } from './subcategory4/exercise4/c3s4e4';
import { c3s4e5 } from './subcategory4/exercise5/c3s4e5';
import { c3s4e6 } from './subcategory4/exercise6/c3s4e6';
import { c3s4e7 } from './subcategory4/exercise7/c3s4e7';
import { c3s4e8 } from './subcategory4/exercise8/c3s4e8';
import { c3s4e9 } from './subcategory4/exercise9/c3s4e9';

export const category3: Category = {
  number: 3,
  name: CategoryName.CATEGORY_3,
  exercises: [],
  subCategories: [
    {
      number: 1,
      name: CategoryName.SUBCATEGORY_3_1,
      exercises: [
        c3s1e1,
        c3s1e2,
        c3s1e3,
        c3s1e4,
        c3s1e5,
        c3s1e6,
        c3s1e7,
        c3s1e8,
        c3s1e9,
      ],
    },
    {
      number: 2,
      name: CategoryName.SUBCATEGORY_3_2,
      exercises: [
        c3s2e1,
        c3s2e2,
        c3s2e3,
        c3s2e4,
        c3s2e5,
        c3s2e6,
        c3s2e7,
        c3s2e8,
        c3s2e9,
      ],
    },
    {
      number: 3,
      name: CategoryName.SUBCATEGORY_3_3,
      exercises: [c3s3e1, c3s3e2, c3s3e3, c3s3e4, c3s3e5, c3s3e6, c3s3e7],
    },
    {
      number: 4,
      name: CategoryName.SUBCATEGORY_3_4,
      exercises: [
        c3s4e1,
        c3s4e2,
        c3s4e3,
        c3s4e4,
        c3s4e5,
        c3s4e6,
        c3s4e7,
        c3s4e8,
        c3s4e9,
        c3s4e10,
        c3s4e11,
        c3s4e12,
        c3s4e13,
        c3s4e14,
        c3s4e15,
      ],
    },
  ],
};
