import {
  Corner,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseStep,
  ExerciseStepBlock,
  getBlockColor,
} from 'src/database/exerciseTypes';
import { getPluralColor } from 'src/database/exerciseUtil';

export function getC1E6VariantSteps(
  color: ExerciseColor,
  numberOfBlocks: number,
  blocksAcross: Corner
): ExerciseStep[] {
  let blocks: ExerciseStepBlock[] = [];
  let asideStartDescription = '';
  let asideEndDescription = '';

  if (blocksAcross === Corner.UPPER_RIGHT) {
    const blocksArray = Array.from(
      { length: numberOfBlocks },
      (_, i) => 10 - i
    );

    blocks = blocksArray.map((element) => ({
      idx: element,
      idy: element,
      color: getBlockColor(color),
      content: ExerciseBlockContent.BLANK,
    }));
    asideStartDescription = 'prawego górnego';
    asideEndDescription = 'lewego dolnego';
  }
  if (blocksAcross === Corner.LOWER_RIGHT) {
    const blocksArray = Array.from(
      { length: numberOfBlocks },
      (_, i) => 10 - i
    );
    blocks = blocksArray.map((element, index) => ({
      idx: element,
      idy: index + 1,
      color: getBlockColor(color),
      content: ExerciseBlockContent.BLANK,
    }));
    asideStartDescription = 'prawego dolnego';
    asideEndDescription = 'lewego górnego';
  }

  const colorWithBlocksDescription = getPluralColor(getBlockColor(color), numberOfBlocks, true);

  return [
    {
      number: 1,
      description: `Ułóż ${numberOfBlocks} ${colorWithBlocksDescription} zaczynając od ${asideStartDescription} do ${asideEndDescription} rogu siatki`,
    },
    {
      number: 2,
      blocks: blocks,
    },
  ];
}
