import { Category, CategoryName } from '../exerciseTypes';
import { c8s1e1 } from './subcategory1/exercise1/c8s1e1';
import { c8s1e2 } from './subcategory1/exercise2/c8s1e2';
import { c8s1e3 } from './subcategory1/exercise3/c8s1e3';
import { c8s1e4 } from './subcategory1/exercise4/c8s1e4';
import { c8s1e5 } from './subcategory1/exercise5/c8s1e5';
import { c8s1e6 } from './subcategory1/exercise6/c8s1e6';
import { c8s1e7 } from './subcategory1/exercise7/c8s1e7';
import { c8s1e8 } from './subcategory1/exercise8/c8s1e8';
import { c8s1e9 } from './subcategory1/exercise9/c8s1e9';
import { c8s2e1 } from './subcategory2/exercise1/c8s2e1';
import { c8s2e2 } from './subcategory2/exercise2/c8s2e2';
import { c8s2e3 } from './subcategory2/exercise3/c8s2e3';
import { c8s2e4 } from './subcategory2/exercise4/c8s2e4';
import { c8s2e5 } from './subcategory2/exercise5/c8s2e5';
import { c8s3e1 } from './subcategory3/exercise1/c8s3e1';
import { c8s3e2 } from './subcategory3/exercise2/c8s3e2';
import { c8s3e3 } from './subcategory3/exercise3/c8s3e3';
import { c8s3e4 } from './subcategory3/exercise4/c8s3e4';
import { c8s3e5 } from './subcategory3/exercise5/c8s3e5';
import { c8s3e6 } from './subcategory3/exercise6/c8s3e6';
import { c8s3e7 } from './subcategory3/exercise7/c8s3e7';
import { c8s4e1 } from './subcategory4/exercise1/c8s4e1';
import { c8s4e2 } from './subcategory4/exercise2/c8s4e2';
import { c8s4e3 } from './subcategory4/exercise3/c8s4e3';
import { c8s4e4 } from './subcategory4/exercise4/c8s4e4';
import { c8s5e1 } from './subcategory5/exercise1/c8s5e1';
import { c8s5e2 } from './subcategory5/exercise2/c8s5e2';
import { c8s5e3 } from './subcategory5/exercise3/c8s5e3';
import { c8s5e4 } from './subcategory5/exercise4/c8s5e4';
import { c8s5e5 } from './subcategory5/exercise5/c8s5e5';
import { c8s5e6 } from './subcategory5/exercise6/c8s5e6';
import { c8s5e7 } from './subcategory5/exercise7/c8s5e7';
import { c8s5e8 } from './subcategory5/exercise8/c8s5e8';
import { c8s5e9 } from './subcategory5/exercise9/c8s5e9';

export const category8: Category = {
  number: 8,
  name: CategoryName.CATEGORY_8,
  exercises: [],
  subCategories: [
    {
      number: 1,
      name: CategoryName.SUBCATEGORY_8_1,
      exercises: [
        c8s1e1,
        c8s1e2,
        c8s1e3,
        c8s1e4,
        c8s1e5,
        c8s1e6,
        c8s1e7,
        c8s1e8,
        c8s1e9,
      ],
    },
    {
      number: 2,
      name: CategoryName.SUBCATEGORY_8_2,
      exercises: [c8s2e1, c8s2e2, c8s2e3, c8s2e4, c8s2e5],
    },
    {
      number: 3,
      name: CategoryName.SUBCATEGORY_8_3,
      exercises: [c8s3e1, c8s3e2, c8s3e3, c8s3e4, c8s3e5, c8s3e6, c8s3e7],
    },
    {
      number: 4,
      name: CategoryName.SUBCATEGORY_8_4,
      exercises: [c8s4e1, c8s4e2, c8s4e3, c8s4e4],
    },
    {
      number: 5,
      name: CategoryName.SUBCATEGORY_8_5,
      exercises: [
        c8s5e1,
        c8s5e2,
        c8s5e3,
        c8s5e4,
        c8s5e5,
        c8s5e6,
        c8s5e7,
        c8s5e8,
        c8s5e9,
      ],
    },
  ],
};
