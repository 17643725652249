import {
  CustomMatrix,
  Exercise,
  ExerciseName,
  ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};

const step2 = {
  number: 2,
  description:
    'Statek wpłynął do portu o północy we wtorek. Po zaplanowanym postoju odpłynie w sobotę również o północy. Pomóż kapitanowi policzyć, ile dni statek będzie cumował w porcie',
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};

const step3 = {
  number: 3,
  description:
    'Połóżcie na podstawkach odpowiednią liczbę żółtych klocków w taki sposób, aby każdy żółty klocek ilustrował jeden dzień pobytu w porcie',
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};

const step5 = {
  number: 5,
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};

const step6 = {
  number: 6,
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};

const step7 = {
  number: 7,
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};

const step8 = {
  number: 8,
  description:
    'Inny statek wpłynął do portu w środę w południe i wypłynął dokładnie po 9 dniach w południe. W jaki dzień tygodnia statek opuścił port?',
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};

const step9 = {
  number: 9,
  description:
    'Ułóżcie na podstawce 9 klocków i odliczcie kolejno dni tygodnia, rozpoczynając od czwartku, bo wtedy minie pierwsza pełna doba pobytu w porcie',
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};
const step11 = {
  number: 11,
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};
const step12 = {
  number: 12,
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};
const step13 = {
  number: 13,
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};
const step14 = {
  number: 14,
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};
const step15 = {
  number: 15,
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};
const step16 = {
  number: 16,
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};
const step17 = {
  number: 17,
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};
const step18 = {
  number: 18,
  customMatrix: CustomMatrix.EXERCISE_C7_E2,
};

export const c7e2: Exercise = {
  number: 2,
  name: ExerciseName.EXERCISE_2,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'LIMITED_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
    step17,
    step18,
  ],
};
