import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import Button from 'src/components/Button';
import { storage } from 'src/config/firebase.config';
import { UserAuth } from 'src/context/AuthContext';
import { downloadFile } from 'src/utils/file';
import { ButtonSize, ButtonTypes } from 'src/utils/types';

export default function MenuKitContent() {
  const [kitContentUrl, setKitContentUrl] = useState('');
  const { selectedProduct } = UserAuth();
  const fileType = selectedProduct === 'Szkoła' ? 'jpeg' : 'pdf';

  useEffect(() => {
    const kitContentRef = ref(
      storage,
      `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/zestaw/${selectedProduct}/zawartosc-zestawu.${fileType}`
    );
    getDownloadURL(kitContentRef).then((url) => {
      setKitContentUrl(url);
    });
  }, [selectedProduct]);

  return (
    <div className='relative flex h-full w-full justify-center'>
      <div className='h-[410px] w-full overflow-hidden rounded-lg border md:h-full md:pb-[80px]'>
        <div className='flex h-full w-full justify-center'>
          {selectedProduct === 'Szkoła' && kitContentUrl && (
            <img src={kitContentUrl} className='h-full max-w-full' />
          )}
          {selectedProduct === 'Przedszkole' && kitContentUrl && (
            <iframe
              id='frame'
              src={kitContentUrl}
              className='h-full w-full'
              frameBorder='0'
              title='przewodnik'
            />
          )}
        </div>
      </div>
      <div className='absolute bottom-0 mt-[10px] w-full md:mt-0 md:flex'>
        <a
          target='_blank'
          href={kitContentUrl}
          className='mt-[10px] md:mr-[10px] md:mt-0 md:w-[50%]'
        >
          <Button buttonSize={ButtonSize.BIG} variant={ButtonTypes.secondary}>
            OTWÓRZ ZAWARTOŚĆ ZESTAWU EKOMATIK W NOWEJ KARCIE
          </Button>
        </a>
        <Button
          customClassName='md:w-[50%] md:ml-[10px] mt-[10px] md:mt-0 uppercase'
          buttonSize={ButtonSize.BIG}
          variant={ButtonTypes.primary}
          onClick={() =>
            downloadFile(
              `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/zestaw/${selectedProduct}/zawartosc-zestawu.${fileType}`
            )
          }
        >
          POBIERZ ZAWARTOŚĆ ZESTAWU EKOMATIK
        </Button>
      </div>
    </div>
  );
}
