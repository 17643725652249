import {
  Corner,
  Exercise,
  ExerciseName,
  ExerciseType,
  MatrixPart,
  MiddleLine,
  Position,
} from '../../exerciseTypes';

const step1 = {
  number: 1,
  description: 'Pokażcie górny brzeg siatki',
};

const step2 = {
  number: 2,
  markBorders: [Position.UP],
};

const step3 = {
  number: 3,
  description: 'Pokażcie prawy brzeg siatki',
};

const step4 = {
  number: 4,
  markBorders: [Position.RIGHT],
};

const step5 = {
  number: 5,
  description:
    'Pokażcie dolny brzeg siatki. Przeczytajcie wypisane tam liczby. Co jeszcze widzicie na dolnym brzegu siatki (pozioma strzałka w prawo)',
};

const step6 = {
  number: 6,
  markBorders: [Position.DOWN],
};

const step7 = {
  number: 7,
  description:
    'Pokażcie lewy brzeg siatki. Przeczytajcie wypisane tam liczby. Co jeszcze widzicie na lewym brzegu siatki (pionowa strzałka do góry)',
};

const step8 = {
  number: 8,
  markBorders: [Position.LEFT],
};

const step9 = {
  number: 9,
  description: 'Pokażcie lewy górny róg siatki',
};

const step10 = {
  number: 10,
  markCorner: Corner.UPPER_LEFT,
};

const step11 = {
  number: 11,
  description: 'Pokażcie prawy górny róg siatki',
};

const step12 = {
  number: 12,
  markCorner: Corner.UPPER_RIGHT,
};

const step13 = {
  number: 13,
  description: 'Pokażcie lewy dolny róg siatki',
};

const step14 = {
  number: 14,
  markCorner: Corner.LOWER_LEFT,
};

const step15 = {
  number: 15,
  description: 'Pokażcie prawy dolny róg siatki',
};

const step16 = {
  number: 16,
  markCorner: Corner.LOWER_RIGHT,
};

const step17 = {
  number: 17,
  description: 'Co jeszcze widzicie na siatce?',
};

const step18 = {
  number: 18,
  markMiddleLine: [MiddleLine.VERTICAL],
};

const step19 = {
  number: 19,
  markMiddleLine: [MiddleLine.HORIZONTAL],
};

const step20 = {
  number: 20,
  description: 'Pokażcie kwadrat w lewym górnym rogu siatki',
};

const step21 = {
  number: 21,
  markMatrixParts: [MatrixPart.UPPER_LEFT],
};

const step22 = {
  number: 22,
  description: 'Pokażcie kwadrat w prawym górnym rogu siatki',
};

const step23 = {
  number: 23,
  markMatrixParts: [MatrixPart.UPPER_RIGHT],
};

const step24 = {
  number: 24,
  description: 'Pokażcie kwadrat w lewym dolnym rogu siatki',
};

const step25 = {
  number: 25,
  markMatrixParts: [MatrixPart.LOWER_LEFT],
};

const step26 = {
  number: 26,
  description: 'Pokażcie kwadrat w prawym dolnym rogu siatki',
};

const step27 = {
  number: 27,
  markMatrixParts: [MatrixPart.LOWER_RIGHT],
};

const step28 = {
  number: 28,
  description: 'Pokażcie kwadraty w dolnej części siatki',
};

const step29 = {
  number: 29,
  markMatrixParts: [MatrixPart.DOWN],
};

const step30 = {
  number: 30,
  description: 'Pokażcie kwadraty w górnej części siatki',
};

const step31 = {
  number: 31,
  markMatrixParts: [MatrixPart.UP],
};

const step32 = {
  number: 32,
  description: 'Pokażcie kwadraty w lewej części siatki',
};

const step33 = {
  number: 33,
  markMatrixParts: [MatrixPart.LEFT],
};

const step34 = {
  number: 34,
  description: 'Pokażcie kwadraty w prawej części siatki',
};

const step35 = {
  number: 35,
  markMatrixParts: [MatrixPart.RIGHT],
};

const steps = [
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  step9,
  step10,
  step11,
  step12,
  step13,
  step14,
  step15,
  step16,
  step17,
  step18,
  step19,
  step20,
  step21,
  step22,
  step23,
  step24,
  step25,
  step26,
  step27,
  step28,
  step29,
  step30,
  step31,
  step32,
  step33,
  step34,
  step35,
];

export const c1e1: Exercise = {
  number: 1,
  name: ExerciseName.EXERCISE_1,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: steps,
  availability: 'FULL_ACCESS',
};
