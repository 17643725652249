import { Link } from 'react-router-dom';
import Button from 'src/components/Button';
import Dialog from 'src/components/Dialog';
import { label } from 'src/utils/labels';
import { customPath } from 'src/utils/paths';
import { ButtonSize, ButtonTypes } from 'src/utils/types';

export default function RegisterSuccess() {
  return (
    <Dialog
      title={label.registerSuccessStep.title}
      description={label.registerSuccessStep.description}
      secondDescription={label.registerSuccessStep.secondDescription}
      withClose
    >
      <Link to={customPath.singIn}>
        <Button
          variant={ButtonTypes.primary}
          customClassName='w-full'
          buttonSize={ButtonSize.BIG}
        >
          <span>{label.registerSuccessStep.buttons.signIn}</span>
        </Button>
      </Link>
    </Dialog>
  );
}
