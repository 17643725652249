import clsx from 'clsx';

type Props = {
  stepNumber: number;
};

export default function CustomMatrixC5E5({ stepNumber }: Props) {
  function bigBlock(vertical: boolean) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[150px]': !vertical,
            'h-[150px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>d</div>
      </div>
    );
  }

  function mediumBlock(vertical: boolean) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[100px]': !vertical,
            'h-[100px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>s</div>
      </div>
    );
  }

  function smallBlock(vertical: boolean) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[50px]': !vertical,
            'h-[50px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>m</div>
      </div>
    );
  }

  if (stepNumber === 1 || stepNumber === 2) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-center justify-center border border-solid p-[20px]'></div>
        </div>
      </div>
    );
  }

  if (stepNumber === 3 || stepNumber === 4 || stepNumber === 5) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-end justify-center border border-solid p-[20px]'>
            <div className='relative mx-[50px]'>
              {[...Array(2)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-red-default' />
              ))}
            </div>
            <div className='relative mx-[50px]'>
              {[...Array(5)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-yellow-block' />
              ))}
            </div>
            <div className='relative mx-[50px]'>
              {[...Array(8)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-green-block' />
              ))}
            </div>
            <div className='relative mx-[50px]'>
              {[...Array(11)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-blue-default' />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber > 5) {
    return (
      <div className='relative flex h-full w-full items-center justify-center p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='relative flex h-full max-h-[700px] w-full max-w-[700px] items-end justify-center border border-solid p-[20px]'>
            <div className='absolute top-[40px] left-[40px] flex w-full'>
              <div className='mr-[20px]'>{bigBlock(false)}</div>
              <div className='mr-[20px]'>{mediumBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
            <div className='relative mx-[50px]'>
              {[...Array(2)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-red-default' />
              ))}
              <div
                className={clsx('absolute left-[-25px]', {
                  hidden: stepNumber !== 8,
                  'bottom-0': stepNumber === 8,
                })}
              >
                {mediumBlock(true)}
              </div>
            </div>
            <div className='relative mx-[50px]'>
              {[...Array(5)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-yellow-block' />
              ))}
              <div
                className={clsx('absolute left-[-25px]', {
                  hidden: stepNumber < 9 || stepNumber > 12,
                  'bottom-0': stepNumber === 9,
                  'bottom-0 mb-[100px]': stepNumber === 10,
                  'bottom-0 mb-[200px]': stepNumber === 11 || stepNumber === 12,
                })}
              >
                {stepNumber === 12 ? smallBlock(true) : mediumBlock(true)}
              </div>
            </div>
            <div className='relative mx-[50px]'>
              {[...Array(8)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-green-block' />
              ))}
              <div
                className={clsx('absolute left-[-25px]', {
                  hidden: stepNumber < 13 || stepNumber > 16,
                  'bottom-0': stepNumber === 13,
                  'bottom-0 mb-[100px]': stepNumber === 14,
                  'bottom-0 mb-[200px]': stepNumber === 15,
                  'bottom-0 mb-[300px]': stepNumber === 16,
                })}
              >
                {mediumBlock(true)}
              </div>
            </div>
            <div className='relative mx-[50px]'>
              {[...Array(11)].map(() => (
                <div className='h-[50px] w-[50px] border border-solid bg-blue-default' />
              ))}
              <div
                className={clsx('absolute left-[-25px]', {
                  hidden: stepNumber < 17 || stepNumber > 23,
                  'bottom-0': stepNumber === 17,
                  'bottom-0 mb-[100px]': stepNumber === 18,
                  'bottom-0 mb-[200px]': stepNumber === 19,
                  'bottom-0 mb-[300px]': stepNumber === 20,
                  'bottom-0 mb-[400px]': stepNumber === 21,
                  'bottom-0 mb-[500px]': stepNumber === 22 || stepNumber === 23,
                })}
              >
                {stepNumber === 23 ? smallBlock(true) : mediumBlock(true)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div></div>;
}
