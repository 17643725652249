import { Menu, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LockIcon } from 'src/assets/icons/lock.svg';
import { ReactComponent as LogoutIcon } from 'src/assets/icons/logout.svg';
import { ReactComponent as UserIcon } from 'src/assets/icons/user.svg';

import { label } from 'src/utils/labels';
import { customPath } from 'src/utils/paths';
import { ButtonSize, ButtonTypes } from 'src/utils/types';
import Button from '../Button';

type Props = {
  login: string;
  logout: () => void;
  isLoading: boolean;
};

export default function AccountDropdown({ login, logout, isLoading }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  function handleOpen() {
    setIsOpen(!isOpen);
  }
  return (
    <Menu as='div' className='relative text-left'>
      <Menu.Button as='div' onClick={handleOpen}>
        <Button
          variant={ButtonTypes.secondary}
          buttonSize={ButtonSize.BIG}
          isLoading={isLoading}
        >
          <div className='flex'>
            <UserIcon className='left-0 h-[25px] w-[25px]' />
            <div className='mx-[4px] leading-[24px]'>{login}</div>
            {isOpen && <div className='right-0 mt-[-2px]'>&#9650;</div>}
            {!isOpen && <div className='right-0 mt-[-2px]'>&#9660;</div>}
          </div>
        </Button>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-10 w-full text-[15px] focus:outline-none'>
          <div className='rounded border-2 border-solid border-green-aquamarine bg-white-default'>
            <Link to={customPath.accountSettings}>
              <Menu.Item>
                <div className='flex h-[50px] cursor-pointer items-center p-[12px] text-black-default'>
                  <LockIcon className='mr-[10px] h-[24px] w-[24px]' />
                  Ustawienia konta
                </div>
              </Menu.Item>
            </Link>
            <div className='mx-[12px] h-[1px] bg-gray-default' />
            <Menu.Item>
              <div
                onClick={logout}
                className='flex h-[50px] cursor-pointer items-center p-[12px] text-black-default'
              >
                <LogoutIcon className='mr-[10px] ml-[4px] h-[24px] w-[24px]' />
                {label.homepage.header.buttons.logout}
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
