import { Category, CategoryName } from '../exerciseTypes';
import { c6s1e1 } from './subcategory1/exercise1/c6s1e1';
import { c6s1e2 } from './subcategory1/exercise2/c6s1e2';
import { c6s1e3 } from './subcategory1/exercise3/c6s1e3';
import { c6s2e1 } from './subcategory2/exercise1/c6s2e1';
import { c6s2e2 } from './subcategory2/exercise2/c6s2e2';
import { c6s2e3 } from './subcategory2/exercise3/c6s2e3';
import { c6s2E4 } from './subcategory2/exercise4/c6s2e4';
import { c6s3e1 } from './subcategory3/exercise1/c6s3e1';
import { c6s3e2 } from './subcategory3/exercise2/c6s3e2';
import { c6s4e1 } from './subcategory4/exercise1/c6s4e1';
import { c6s4e2 } from './subcategory4/exercise2/c6s4e2';
import { c6s5e1 } from './subcategory5/exercise1/c6s5e1';
import { c6s5e2 } from './subcategory5/exercise2/c6s5e2';
import { c6s6e1 } from './subcategory6/exercise1/c6s6e1';
import { c6s6e2 } from './subcategory6/exercise2/c6s6e2';
import { c6s7e1 } from './subcategory7/exercise1/c6s7e1';
import { c6s7e2 } from './subcategory7/exercise2/c6s7e2';

export const category6: Category = {
  number: 6,
  name: CategoryName.CATEGORY_6,
  exercises: [],
  subCategories: [
    {
      number: 1,
      name: CategoryName.SUBCATEGORY_6_1,
      exercises: [c6s1e1, c6s1e2, c6s1e3],
    },
    {
      number: 2,
      name: CategoryName.SUBCATEGORY_6_2,
      exercises: [c6s2e1, c6s2e2, c6s2e3, c6s2E4],
    },
    {
      number: 3,
      name: CategoryName.SUBCATEGORY_6_3,
      exercises: [c6s3e1, c6s3e2],
    },
    {
      number: 4,
      name: CategoryName.SUBCATEGORY_6_4,
      exercises: [c6s4e1, c6s4e2],
    },
    {
      number: 5,
      name: CategoryName.SUBCATEGORY_6_5,
      exercises: [c6s5e1, c6s5e2],
    },
    {
      number: 6,
      name: CategoryName.SUBCATEGORY_6_6,
      exercises: [c6s6e1, c6s6e2],
    },
    {
      number: 7,
      name: CategoryName.SUBCATEGORY_6_7,
      exercises: [c6s7e1, c6s7e2],
    },
  ],
};
