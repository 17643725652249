import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.EXERCISE_C6_S6_E2,
  description: 'Wyciągnijcie z pudełka taką samą drewnianą podkładkę',
};

const step2 = {
  number: 2,
  description:
    'Połóżcie na niej kartkę papieru, a na niej w pozycji „leżącej” model stożka',
  customMatrix: CustomMatrix.EXERCISE_C6_S6_E2,
};

const step3 = {
  number: 3,
  description:
    'Wytnijcie z papieru pasek o takiej szerokości, jak długość boku, na którym położony jest leżący stożek. Następnie wytnijcie z tego paska trójkąt równoramienny i owińcie nim stożek tak, aby boki trójkąta dotykały się. Obetnijcie niepotrzebne części kartki',
  customMatrix: CustomMatrix.EXERCISE_C6_S6_E2,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C6_S6_E2,
};

const step5 = {
  number: 5,
  description: 'Jaki kształt ma wycięta z papieru figura?',
  customMatrix: CustomMatrix.EXERCISE_C6_S6_E2,
};
export const c6s6e2: Exercise = {
  number: 2,
  name: ExerciseName.EXERCISE_2,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: [step1, step2, step3, step4, step5],
  availability: 'FULL_ACCESS',
};
