type Props = {
  children: string;
  className?: string;
  withExclamation?: boolean;
};

export default function InputErrorMessage({
  className,
  children,
  withExclamation,
}: Props) {
  return (
    <div className={className}>
      {withExclamation && (
        <div className='flex items-center bg-red-strong px-[10px] text-[40px]'>
          !
        </div>
      )}
      <div className='flex items-center p-[10px]'>{children}</div>
    </div>
  );
}
