import {
  Corner,
  Exercise,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseName,
  ExerciseType,
} from '../../exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Połóżcie klocek czerwony w takim miejscu, jak pokazuje to rysunek na ekranie',
};

const step2 = {
  number: 2,
  blocks: [
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step3 = {
  number: 3,
  description:
    'Ułóżcie czerwone klocki od górnego lewego rogu do prawego dolnego rogu na siatce po ukosie',
  blocks: [
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  number: 4,
  blocks: [
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 5,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 6,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 2,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 10,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const steps = [step1, step2, step3, step4];

export const c1e5: Exercise = {
  number: 5,
  name: ExerciseName.EXERCISE_5,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  steps: steps,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'maxNumberOfBlocks',
      label: 'Ilość klocków',
      placeholder: 'Wybierz ilość klocków',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
    {
      name: 'blocksAcross',
      label: 'Klocki po skosie zaczynając od rogu',
      placeholder: 'Wybierz róg',
      options: [Corner.LOWER_LEFT, Corner.UPPER_LEFT],
    },
  ],
};
