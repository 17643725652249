import clsx from 'clsx';
import { Corner, ExerciseStep, Position } from 'src/database/exerciseTypes';
import BottomAxis from '../AxisTypes/BottomAxis';
import LeftAxis from '../AxisTypes/LeftAxis';
import LeftAxisAlternative from '../AxisTypes/LeftAxisAlternative';
import TopAxis from '../AxisTypes/TopAxis';
import TopAxisAlternative from '../AxisTypes/TopAxisAlternative';
import Block from '../BlockTypes/Block';
import Blocks from '../BlockTypes/Blocks';

type Props = {
  step: ExerciseStep | undefined;
  lettersOnAxis: boolean | undefined;
  exerciseStarted: boolean | undefined;
};
export default function Matrix({
  step,
  lettersOnAxis,
  exerciseStarted,
}: Props) {
  const height = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
  const width = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  function getLeftHelpMatrixWidth() {
    if (typeof step?.leftHelpMatrix !== 'undefined') {
      const maxIdx = Math.max.apply(
        0,
        step.leftHelpMatrix.map((block) => block.idx)
      );
      return Array.from({ length: maxIdx }, (_, i) => i + 1);
    }
    return [];
  }

  function getRightHelpMatrixWidth() {
    if (typeof step?.rightHelpMatrix !== 'undefined') {
      const maxIdx = Math.max.apply(
        0,
        step.rightHelpMatrix.map((block) => block.idx)
      );
      return Array.from({ length: maxIdx }, (_, i) => i + 1);
    }
    return [];
  }

  const zeroBlock =
    exerciseStarted &&
    step?.axisBlocks?.find((block) => block.idx === 0 && block.idy === 0);

  return (
    <div className='relative flex h-full w-full items-center justify-center'>
      {step?.leftHelpMatrix && (
        <Blocks
          width={getLeftHelpMatrixWidth()}
          height={height}
          step={step}
          wrapperClassName='px-[20px] items-center'
          withoutLines
          blocksWithoutBorders
          leftHelpMatrix
          exerciseStarted={exerciseStarted}
        />
      )}
      <div
        className={clsx('relative shadow shadow-gray-shadow', {
          'p-[26px]': !lettersOnAxis && !step?.numbersOnAxis,
          'p-[14px]': lettersOnAxis || step?.numbersOnAxis,
        })}
      >
        <div
          className={clsx('absolute', {
            'bottom-0 left-0 h-[4px] w-full bg-blue-default':
              step?.markBorders?.includes(Position.DOWN),
            'bottom-0 top-0 left-0 h-[4px] w-full bg-blue-default':
              step?.markBorders?.includes(Position.UP),
            'right-0 top-0 h-full w-[4px] bg-blue-default':
              step?.markBorders?.includes(Position.RIGHT),
            'left-0 top-0 h-full w-[4px] bg-blue-default':
              step?.markBorders?.includes(Position.LEFT),
          })}
        />
        <div
          className={clsx({
            'absolute z-10 h-[20px] w-[20px] rounded-[50%] bg-blue-default':
              typeof step?.markCorner !== 'undefined',
            'left-[-10px] bottom-[-10px]':
              step?.markCorner === Corner.LOWER_LEFT,
            'left-[-10px] top-[-10px]': step?.markCorner === Corner.UPPER_LEFT,
            'right-[-10px] bottom-[-10px]':
              step?.markCorner === Corner.LOWER_RIGHT,
            'right-[-10px] top-[-10px]':
              step?.markCorner === Corner.UPPER_RIGHT,
          })}
        />
        {(lettersOnAxis || step?.numbersOnAxis) && (
          <TopAxisAlternative withNumbers={!!step?.numbersOnAxis} />
        )}
        <div className='flex justify-center text-[24px]'>
          {zeroBlock && (
            <div className='absolute bottom-[26px] left-[26px] z-10 xl:h-[40px] xl:w-[40px]'>
              <Block block={zeroBlock} />
            </div>
          )}
          {!lettersOnAxis && !step?.numbersOnAxis && (
            <LeftAxis height={height} step={step} />
          )}
          {(lettersOnAxis || step?.numbersOnAxis) && (
            <LeftAxisAlternative withNumbers={!!step?.numbersOnAxis} />
          )}
          <div>
            {step?.withDecimals && <TopAxis />}
            <Blocks
              width={width}
              height={height}
              step={step}
              withoutLines={step?.numbersOnAxis}
              exerciseStarted={exerciseStarted}
            />
          </div>
        </div>
        {!lettersOnAxis && !step?.numbersOnAxis && (
          <BottomAxis width={width} step={step} />
        )}
      </div>
      {step?.rightHelpMatrix && (
        <Blocks
          width={getRightHelpMatrixWidth()}
          height={height}
          step={step}
          wrapperClassName='px-[20px] items-center'
          blocksWithoutBorders
          rightHelpMatrix
          withoutLines
          exerciseStarted={exerciseStarted}
        />
      )}
    </div>
  );
}
