import * as yup from 'yup';

const emptyField = 'To pole nie może być puste.';
const login = yup
  .string()
  .email('Adres e-mail jest nieprawidłowy.')
  .trim()
  .required(emptyField);

const password = yup
  .string()
  .trim()
  .matches(
    new RegExp(
      '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\\s/!@#$%^&*()]{8,}$'
    ),
    'Hasło musi zawierać przynajmniej 8 znaków, jedną cyfrę i jedną wielką literę.'
  )
  .required(emptyField);

const passwordConfirm = yup
  .string()
  .trim()
  .oneOf([yup.ref('password'), null], 'Podane hasła są różne.')
  .required(emptyField);

const agreementsConfirm = yup
  .boolean()
  .required('Musisz zapoznać się z regulaminem.')
  .oneOf([true], 'Musisz zapoznać się z regulaminem.');

const report = yup
  .string()
  .trim()
  .min(30, 'Zgłoszenie musi mieć co najmniej 30 znaków.')
  .max(1000, 'Zgłoszenie musi mieć maksymalnie 1000 znaków.')
  .required(emptyField);

const code = yup.string();

const notEmpty = yup.string().trim().required(emptyField);

export const signInSchema = yup
  .object({
    login: notEmpty,
    password: notEmpty,
  })
  .required();

export const registerSchema = yup
  .object({
    login,
    password,
    passwordConfirm,
    agreementsConfirm,
    code,
  })
  .required();

export const recoverPasswordSchema = yup
  .object({
    login: notEmpty,
  })
  .required();

export const changePasswordSchema = yup
  .object({
    password: password,
    passwordConfirm: passwordConfirm,
  })
  .required();

export const extendAccessSchema = yup.object({
  code: yup.string().trim().required(emptyField),
});

export const reportSchema = yup.object({
  report: report,
});

export const newVariantSchema = yup.object({
  blocksColor: yup.lazy((value) =>
    value === undefined ? yup.mixed().notRequired() : notEmpty
  ),
  blockCorner: yup.lazy((value) =>
    value === undefined ? yup.mixed().notRequired() : notEmpty
  ),
  maxNumberOfBlocks: yup.lazy((value) =>
    value === undefined ? yup.mixed().notRequired() : notEmpty
  ),
  alongTheSide: yup.lazy((value) =>
    value === undefined ? yup.mixed().notRequired() : notEmpty
  ),
  blocksAcross: yup.lazy((value) =>
    value === undefined ? yup.mixed().notRequired() : notEmpty
  ),
  figure: yup.lazy((value) =>
    value === undefined ? yup.mixed().notRequired() : notEmpty
  ),
  blockXShift: yup.lazy((value) =>
    value === undefined ? yup.mixed().notRequired() : notEmpty
  ),
  blockYShift: yup.lazy((value) =>
    value === undefined ? yup.mixed().notRequired() : notEmpty
  ),
  firstNumber: yup.lazy((value) =>
    value === undefined ? yup.mixed().notRequired() : notEmpty
  ),
  secondNumber: yup.lazy((value) =>
    value === undefined ? yup.mixed().notRequired() : notEmpty
  ),
});

export function getAddNumbersSchema(
  firstMaxNumber: number,
  secondMaxNumber: number
) {
  return yup
    .object({
      firstNumber: yup
        .number()
        .typeError('Podana wartość musi być liczbą')
        .max(firstMaxNumber, `Maksymalna wartość to ${firstMaxNumber}`)
        .min(0, 'Minimalna wartość to 0'),
      secondNumber: yup
        .number()
        .typeError('Podana wartość musi być liczbą')
        .max(secondMaxNumber, `Maksymalna wartość to ${secondMaxNumber}`)
        .min(0, 'Minimalna wartość to 0'),
    })
    .test(
      'global-ok',
      'Suma liczb nie może być większa niż 9999',
      function ({ firstNumber, secondNumber }) {
        if (!firstNumber || !secondNumber) {
          return true;
        }
        return firstNumber + secondNumber < 9999;
      }
    );
}

export function getSubtractionNumbersSchema(
  firstMaxNumber: number,
  secondMaxNumber: number
) {
  return yup.object({
    firstNumber: yup
      .number()
      .typeError('Podana wartość musi być liczbą')
      .max(firstMaxNumber, `Maksymalna wartość to ${firstMaxNumber}`)
      .min(1, 'Minimalna wartość to 1')
      .test(
        'global-ok',
        'Druga liczba nie może być mniejsza od pierwszej',
        function () {
          if (!this.parent.firstNumber || !this.parent.secondNumber) {
            return true;
          }
          return this.parent.firstNumber >= this.parent.secondNumber;
        }
      ),
    secondNumber: yup
      .number()
      .typeError('Podana wartość musi być liczbą')
      .max(secondMaxNumber, `Maksymalna wartość to ${secondMaxNumber}`)
      .min(1, 'Minimalna wartość to 1')
      .test(
        'global-ok',
        'Druga liczba nie może być mniejsza od pierwszej',
        function () {
          if (!this.parent.firstNumber || !this.parent.secondNumber) {
            return true;
          }
          return this.parent.firstNumber >= this.parent.secondNumber;
        }
      ),
  });
}

export function getDivideNumbersSchema() {
  return yup.object({
    numberToDivide: yup
      .number()
      .typeError('Minimalna wartość to 1')
      .test(
        'global-ok',
        'W jednym kwadracie może być maksymalnie 25 klocków',
        function () {
          if (this.parent.numberDivide === 3) {
            return this.parent.numberToDivide < 76;
          } else if (this.parent.numberDivide === 2) {
            return this.parent.numberToDivide < 51;
          } else if (this.parent.numberDivide === 1) {
            return this.parent.numberToDivide < 26;
          }
          return true;
        }
      )
      .required(emptyField),
    numberDivide: yup
      .number()
      .typeError('Minimalna wartość to 1')
      .required(emptyField),
  });
}
