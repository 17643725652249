import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MenuOption from 'src/components/MenuOption';
import { label } from 'src/utils/labels';
import { HomepageOption } from 'src/utils/types';

type Props = {
  onOptionSelect: (option: HomepageOption) => void;
  isSelected: boolean;
};

export default function Report({ onOptionSelect, isSelected }: Props) {
  const { option } = useParams();
  const navigate = useNavigate();

  function onReportSelect() {
    if (isSelected) {
      navigate('/');
      onOptionSelect(HomepageOption.NONE);
      return;
    } else {
      onOptionSelect(HomepageOption.REPORT);
      navigate('/opinia');
    }
  }

  useEffect(() => {
    if (option === 'opinia') {
      onReportSelect();
    }
  }, []);

  return (
    <MenuOption
      isSelected={isSelected}
      markAsSelected={isSelected}
      text={label.homepage.content.menu.report.label}
      onOptionSelect={() => onReportSelect()}
    />
  );
}
