import {
  CustomMatrix,
  Exercise,
  ExerciseName,
  ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.EXERCISE_C6_S7_E1,
  description: 'Wyciągnijcie z pudełka taką samą drewnianą podkładkę',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.EXERCISE_C6_S7_E1,
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.EXERCISE_C6_S7_E1,
};

const step4 = {
  number: 4,
  description:
    'Czy wiecie, jak nazywa się ta figura, którą trzymacie teraz w rękach?',
  customMatrix: CustomMatrix.EXERCISE_C6_S7_E1,
};

const step5 = {
  number: 5,
  description:
    'Popatrzcie uważnie na położone figury i powiedźcie, jakie są różnice między kulą a pozostałymi figurami',
  customMatrix: CustomMatrix.EXERCISE_C6_S7_E1,
};

const step6 = {
  number: 6,
  description:
    'Odłóżcie do pudełka ułożone bryły i połóżcie teraz obok kuli modele walca i stożka. Popatrzcie uważnie na położone figury i powiedzcie, jakie są różnice między kulą a pozostałymi figurami?',
  customMatrix: CustomMatrix.EXERCISE_C6_S7_E1,
};

const step7 = {
  number: 7,
  description:
    'Czy można wyciąć z papieru powierzchnię kuli tak, jak to robiliście w przypadku walca i stożka?',
  customMatrix: CustomMatrix.EXERCISE_C6_S7_E1,
};

export const c6s7e1: Exercise = {
  number: 1,
  name: ExerciseName.EXERCISE_1,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'LIMITED_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
  ],
};
