import { Combobox, Listbox, RadioGroup, Transition } from '@headlessui/react';
import clsx from 'clsx';
import delve from 'dlv';
import { InputHTMLAttributes, useState } from 'react';
import { ControllerProps, useFormContext } from 'react-hook-form';
import InputErrorMessage from '../InputErrorMessage';

type Props = InputHTMLAttributes<HTMLInputElement | HTMLSelectElement> & {
  name: string;
  label?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  initialValue?: string;
  placeholder?: string;
  isAutocomplete?: boolean;
  isSelect?: boolean;
  checkbox?: boolean;
  options?: string[] | number[];
  registerOptions?: ControllerProps['rules'];
  withCapitalization?: boolean;
  withExclamation?: boolean;
  textarea?: boolean;
  maxLength?: number;
  radioOptions?: string[];
  tooltipText?: string;
  children?: React.ReactNode;
};

export default function Input({
  type = 'text',
  name,
  label,
  disabled,
  wrapperClassName,
  labelClassName,
  placeholder,
  isAutocomplete,
  checkbox,
  isSelect,
  options,
  defaultValue,
  initialValue,
  registerOptions,
  withCapitalization,
  withExclamation = true,
  textarea,
  tooltipText,
  radioOptions,
  maxLength,
  children,
  ...rest
}: Props) {
  const { register, formState, setValue, watch, getValues } = useFormContext();
  const errorObject = delve(formState.errors, name);
  const currentValue = watch(name);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  function handleCheckbox() {
    setValue(name, !getValues(name));
  }

  function handleRadio(clientType: string) {
    setValue(name, clientType);
  }

  const inputClassName = clsx(
    'mt-[6px] box-border min-h-[60px] w-full p-[10px] text-[16px] placeholder:text-[16px] placeholder:font-bold active:bg-white-default outline-none',
    {
      'border-red-strong outline-0': typeof errorObject !== 'undefined',
      'focus:outline-green-aquamarine focus:border-none':
        typeof errorObject === 'undefined',
    }
  );

  if (checkbox) {
    return (
      <>
        <div className={clsx('flex w-full items-center', wrapperClassName)}>
          <input type='checkbox' {...register(name)} className='hidden' />
          <div
            className='h-[40px] w-[40px] min-w-[40px] cursor-pointer border border-solid p-[4px]'
            onClick={handleCheckbox}
          >
            <div
              className={clsx('h-full w-full', {
                'bg-green-aquamarine': getValues(name),
              })}
            />
          </div>
          {typeof label !== 'undefined' && (
            <div
              className={clsx(
                'ml-[10px] text-[16px] font-bold',
                labelClassName
              )}
            >
              {label}
            </div>
          )}
          {typeof children !== 'undefined' && (
            <div
              className={clsx(
                'mx-[10px] text-[16px] font-bold',
                labelClassName
              )}
            >
              {children}
            </div>
          )}
          {!getValues(name) && errorObject && (
            <InputErrorMessage
              withExclamation={withExclamation}
              className='border-1 mt-[-2px] hidden min-h-[60px] border-solid border-red-strong bg-red-default md:ml-auto md:flex'
            >
              {errorObject.message}
            </InputErrorMessage>
          )}
        </div>
        {!getValues(name) && errorObject && (
          <InputErrorMessage
            withExclamation={withExclamation}
            className='border-1 flex min-h-[60px] border-solid border-red-strong bg-red-default md:ml-auto md:hidden'
          >
            {errorObject.message}
          </InputErrorMessage>
        )}
      </>
    );
  }

  if (radioOptions) {
    return (
      <RadioGroup
        value={getValues(name)}
        onChange={handleRadio}
        defaultValue={'Indywidualny'}
      >
        <input type='text' {...register(name)} className='hidden' />
        <div className='items-center space-y-4 md:flex md:space-x-4 md:space-y-0'>
          <div className='md:mr-[6px]'>Wybierz typ uzytkownika:</div>
          {radioOptions.map((option) => (
            <RadioGroup.Option
              key={option}
              value={option}
              className={({ active, checked }) =>
                `${
                  active
                    ? 'ring-white ring-offset-sky-300 ring-2 ring-opacity-60 ring-offset-2'
                    : ''
                }
                  ${
                    checked
                      ? 'bg-green-aquamarine bg-opacity-75 text-white-default'
                      : 'bg-white'
                  }
                    relative flex h-[60px] cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
              }
            >
              {({ checked }) => (
                <>
                  <div className='flex w-full items-center justify-between'>
                    <div className='flex items-center'>
                      <div className='text-sm'>
                        <RadioGroup.Label
                          as='p'
                          className={`font-medium  ${
                            checked ? 'text-white' : 'text-gray-900'
                          }`}
                        >
                          {option}
                        </RadioGroup.Label>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    );
  }

  if (textarea) {
    return (
      <div className={clsx('w-full')}>
        <input type='hidden' {...register(name)} />
        <textarea
          maxLength={maxLength}
          placeholder={placeholder}
          className={clsx(
            'mt-0 mb-[-5px] resize-none font-arial',
            inputClassName,
            wrapperClassName
          )}
          onChange={(event) => {
            setValue(name, event.target.value);
          }}
        />

        {typeof errorObject !== 'undefined' && (
          <InputErrorMessage
            withExclamation={withExclamation}
            className='border-1 mt-[-2px] flex min-h-[60px] border-solid border-red-strong bg-red-default'
          >
            {errorObject.message}
          </InputErrorMessage>
        )}
        <div className='text-right text-gray-default'>
          {currentValue?.length ?? 0}/{maxLength}
        </div>
      </div>
    );
  }

  if (isAutocomplete) {
    return (
      <div className={clsx('relative w-full', wrapperClassName)}>
        <input type='hidden' {...register(name)} />
        <Combobox
          value={currentValue}
          onChange={(value) => {
            if (typeof value !== 'undefined') {
              setValue(name, value);
            }
          }}
          name={name}
          disabled={disabled}
        >
          {({ open }) => (
            <>
              {typeof label !== 'undefined' && (
                <div
                  className={clsx(
                    'mb-[6px] w-full text-[16px] font-bold',
                    labelClassName
                  )}
                >
                  {label}
                </div>
              )}
              <Combobox.Button as='div'>
                <div className='relative'>
                  <Combobox.Input
                    placeholder='Wpisz lub wybierz'
                    className={clsx('mt-0', inputClassName)}
                    displayValue={currentValue}
                    disabled={
                      typeof disabled !== 'undefined' && disabled === true
                    }
                    onChange={(event) => {
                      setValue(name, event.target.value);
                    }}
                  />
                  <div className='absolute right-0 top-0 mt-[16px] mr-[10px]'>
                    {open && <div className='  text-[18px]'>&#9650;</div>}
                    {!open && <div className=' text-[18px]'>&#9660;</div>}
                  </div>
                </div>
              </Combobox.Button>
              {!disabled && (
                <>
                  <Combobox.Options
                    className={clsx(
                      'absolute z-10 mt-0 max-h-[200px] w-full list-none overflow-y-scroll rounded border-solid border-green-aquamarine bg-white-default px-[10px] placeholder:text-[16px] placeholder:font-bold'
                    )}
                    as='div'
                  >
                    {typeof options !== 'undefined' &&
                      options.map((option) => (
                        <Combobox.Option
                          key={option}
                          value={option}
                          className='my-[10px] cursor-pointer p-[6px] font-arial text-[16px] font-normal hover:bg-green-aquamarine hover:text-white-default'
                        >
                          {option}
                        </Combobox.Option>
                      ))}
                  </Combobox.Options>
                </>
              )}
              {typeof errorObject !== 'undefined' && (
                <InputErrorMessage
                  withExclamation={withExclamation}
                  className='border-1 mt-[-2px] flex min-h-[60px] border-solid border-red-strong bg-red-default'
                >
                  {errorObject.message}
                </InputErrorMessage>
              )}
            </>
          )}
        </Combobox>
      </div>
    );
  }

  if (isSelect) {
    return (
      <div className={clsx('relative w-full', wrapperClassName)}>
        <input type='hidden' {...register(name)} />

        <Listbox
          value={currentValue}
          onChange={(value) => {
            if (typeof value !== 'undefined') {
              setValue(name, value);
            }
          }}
          name={name}
          disabled={disabled}
        >
          {({ open }) => (
            <>
              {typeof label !== 'undefined' && (
                <div
                  className={clsx(
                    'mb-[6px] w-full text-[16px] font-bold',
                    labelClassName
                  )}
                >
                  {label}
                </div>
              )}
              <Listbox.Button as='div'>
                <div className='relative'>
                  <input
                    className={clsx(
                      'flex cursor-pointer items-center',
                      inputClassName
                    )}
                    onChange={() => null}
                    value={
                      currentValue === 0 ? '0' : currentValue || placeholder
                    }
                  />
                  <div className='absolute right-0 top-0 mt-[16px] mr-[10px]'>
                    {open && <div className='  text-[18px]'>&#9650;</div>}
                    {!open && <div className=' text-[18px]'>&#9660;</div>}
                  </div>
                </div>
              </Listbox.Button>
              {!disabled && (
                <>
                  <Listbox.Options
                    className={clsx(
                      'absolute z-10 mt-0 max-h-[200px] w-full list-none overflow-y-auto rounded border-solid border-green-aquamarine bg-white-default px-[10px] placeholder:text-[16px] placeholder:font-bold'
                    )}
                  >
                    {typeof options !== 'undefined' &&
                      options.map((option, index) => (
                        <Listbox.Option
                          key={index}
                          value={option}
                          className='my-[10px] cursor-pointer p-[6px] font-arial text-[16px] font-normal hover:bg-green-aquamarine hover:text-white-default'
                        >
                          {option}
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </>
              )}
              {typeof errorObject !== 'undefined' && (
                <InputErrorMessage
                  withExclamation={withExclamation}
                  className='border-1 mt-[-2px] flex min-h-[60px] border-solid border-red-strong bg-red-default'
                >
                  {errorObject.message}
                </InputErrorMessage>
              )}
            </>
          )}
        </Listbox>
      </div>
    );
  }

  return (
    <div className={clsx('relative w-full', wrapperClassName)}>
      {typeof label !== 'undefined' && (
        <label
          className={clsx('w-full text-[16px] font-bold ', labelClassName)}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <input
        id={name}
        type={type}
        disabled={disabled}
        defaultValue={initialValue || defaultValue || ''}
        className={inputClassName}
        placeholder={placeholder}
        autoComplete='new'
        {...register(name)}
        {...rest}
      />

      {tooltipText && (
        <>
          <div
            onClick={() => setIsTooltipOpen((isTooltipOpen) => !isTooltipOpen)}
            className='absolute right-0 top-0 mt-[46px] mr-[10px]'
          >
            <div className='flex h-[25px] w-[25px] cursor-pointer items-center justify-center rounded-[50%] border-solid border-green-aquamarine'>
              i
            </div>
          </div>
          <Transition
            show={isTooltipOpen}
            enter='transition-opacity duration-75'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity duration-150'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='absolute right-0 top-0 z-10 bg-white-default  text-green-aquamarine'>
              {tooltipText}
            </div>
          </Transition>
        </>
      )}
      {typeof errorObject !== 'undefined' && (
        <InputErrorMessage
          withExclamation={withExclamation}
          className='border-1 mt-[-2px] flex min-h-[60px] border-solid border-red-strong bg-red-default'
        >
          {errorObject.message}
        </InputErrorMessage>
      )}
    </div>
  );
}
