import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MenuOption from 'src/components/MenuOption';
import { HomepageOption } from 'src/utils/types';

type Props = {
  isSelected: boolean;
  onOptionSelect: (option: HomepageOption) => void;
};
export default function KitContent({ isSelected, onOptionSelect }: Props) {
  const { option } = useParams();
  const navigate = useNavigate();

  function onKitContentSelect() {
    if (isSelected) {
      navigate('/');
      onOptionSelect(HomepageOption.NONE);
      return;
    }
    onOptionSelect(HomepageOption.KIT_CONTENT);
    navigate('/zawartosc-zestawu');
  }

  useEffect(() => {
    if (option === 'zawartosc-zestawu') {
      onKitContentSelect();
    }
  }, []);

  return (
    <MenuOption
      isSelected={isSelected}
      markAsSelected={isSelected}
      text='Zawartość zestawu Ekomatik'
      onOptionSelect={() => onKitContentSelect()}
    />
  );
}
