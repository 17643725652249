import { UserAuth } from 'src/context/AuthContext';
import { HomepageMenu } from 'src/context/HomepageMenuContext';
import { HomepageOption } from 'src/utils/types';
import CompetenceTests from './CompetenceTests';
import Exercises from './Exercises';
import Guide from './Guide';
import KitContent from './KitContent';
import Presentations from './Presentations';
import Report from './Report';

export default function MenuLeft() {
  const { selectedOption, onOptionSelect } = HomepageMenu();
  const { selectedProduct } = UserAuth();

  return (
    <div className='m-auto w-full p-[20px]'>
      {selectedProduct === 'Szkoła' && (
        <Exercises
          isSelected={selectedOption === HomepageOption.EXERCISES}
          onOptionSelect={onOptionSelect}
        />
      )}
      <Guide
        isSelected={selectedOption === HomepageOption.GUIDE}
        onOptionSelect={onOptionSelect}
      />
      <Presentations
        isSelected={selectedOption === HomepageOption.PRESENTATIONS}
        onOptionSelect={onOptionSelect}
      />
      <CompetenceTests
        isSelected={selectedOption === HomepageOption.COMPETENCE_TESTS}
        onOptionSelect={onOptionSelect}
      />
      <KitContent
        isSelected={selectedOption === HomepageOption.KIT_CONTENT}
        onOptionSelect={onOptionSelect}
      />
      <Report
        isSelected={selectedOption === HomepageOption.REPORT}
        onOptionSelect={onOptionSelect}
      />
    </div>
  );
}
