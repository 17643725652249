import Footer from 'src/components/Footer';
import { label } from 'src/utils/labels';

export default function Page404() {
  return (
    <div className='h-screen w-screen'>
      <div className='flex h-full items-center justify-center'>
        <div className='text-center'>
          <div className='text-[100px] text-green-aquamarine md:text-[200px]'>
            {label.page404.title}
          </div>
          <div className='text-[20px] md:text-[40px]'>
            {label.page404.description}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
