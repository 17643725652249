type Props = {
  stepNumber: number;
};

export default function CustomMatrixC6S1E1({ stepNumber }: Props) {
  if (stepNumber === 1) {
    return null;
  }

  if (stepNumber === 1 || stepNumber === 2) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/emptyBoard.png`}
            className='h-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 3 || stepNumber === 4 || stepNumber === 5) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s1e1emptyBlock.png`}
            className='w-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 6 || stepNumber === 7 || stepNumber === 8) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s1e1blockside.png`}
            className='w-full'
          />
        </div>
      </div>
    );
  }

  if (
    stepNumber === 9 ||
    stepNumber === 10 ||
    stepNumber === 11 ||
    stepNumber === 12
  ) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s1e1blockLine.png`}
            className='w-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 13 || stepNumber === 14) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s1e1blockCorner.png`}
            className='w-full'
          />
        </div>
      </div>
    );
  }
  
  return <div></div>;
}
