import {
  Exercise,
  ExerciseColor,
  ExerciseName,
  ExerciseType
} from 'src/database/exerciseTypes';
import { getBlocksInGivenIdx } from 'src/database/exerciseUtil';

// length = number of blocks + empty iterations ( each 10, 100, 1000)
// 358 + 35 + 3 = 395
// const blocksArray: number[] = Array.from({ length: 396 }, (_, i) => i + 1);

const step1 = {
  number: 1,
  withDecimals: true,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jak pokazana na obrazku na ekranie. Ułóżcie na tej siatce liczbę 358',
};

// const steps = blocksArray.map((_element, index) => {
//   let blocks: ExerciseStepBlock[] = [];
//   let lastGreenBlockY = 1;
//   let lastRedBlockY = 1;
//   let lastBlueBlockY = 1;
//   Array.from(Array(index + 1).keys()).forEach(() => {
//     if (lastRedBlockY > 10) {
//       lastRedBlockY = 1;
//       lastGreenBlockY = 0;
//       blocks = blocks.filter((b) => b.idx === 2);
//       blocks.push({
//         idx: 2,
//         idy: lastBlueBlockY,
//         color: ExerciseColor.NIEBIESKI,
//         content: ExerciseBlockContent.BLANK,
//       });
//       lastBlueBlockY = lastBlueBlockY + 1;
//     }
//     if (lastGreenBlockY > 10) {
//       lastGreenBlockY = 0;
//       blocks = blocks.filter((b) => b.idx === 3 || b.idx === 2);
//       blocks.push({
//         idx: 3,
//         idy: lastRedBlockY,
//         color: ExerciseColor.CZERWONY,
//         content: ExerciseBlockContent.BLANK,
//       });
//       lastRedBlockY = lastRedBlockY + 1;
//     }

//     blocks.push({
//       idx: 4,
//       idy: lastGreenBlockY,
//       color: ExerciseColor.ZIELONY,
//       content: ExerciseBlockContent.BLANK,
//     });
//     lastGreenBlockY = lastGreenBlockY + 1;
//   });
//   return {
//     number: index + 2,
//     blocks: blocks,
//   };
// });

const step2 = {
  number: 2,
  withDecimals: true,
  blocks: [...getBlocksInGivenIdx(3, 2, ExerciseColor.NIEBIESKI)],
};

const step3 = {
  number: 3,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(3, 2, ExerciseColor.NIEBIESKI),
    ...getBlocksInGivenIdx(5, 3, ExerciseColor.CZERWONY),
  ],
};

const step4 = {
  number: 4,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(3, 2, ExerciseColor.NIEBIESKI),
    ...getBlocksInGivenIdx(5, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(8, 4, ExerciseColor.ZIELONY),
  ],
};

export const c2e16: Exercise = {
  number: 16,
  name: ExerciseName.EXERCISE_16,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  steps: [step1, step2, step3, step4],
  availability: 'LIMITED_ACCESS',
  variant: [
    {
      name: 'thousands',
      label: 'Liczba tysięcy',
      placeholder: 'Wybierz liczbę tysięcy',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
    {
      name: 'hundreds',
      label: 'Liczba setek',
      placeholder: 'Wybierz liczbę setek',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
    {
      name: 'dozens',
      label: 'Liczba dziesiątek',
      placeholder: 'Wybierz liczbę dziesiątek',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
    {
      name: 'units',
      label: 'Liczba jedności',
      placeholder: 'Wybierz liczbę jedności',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
  ],
};
