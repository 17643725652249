export default function MenuNotSelected() {
  return (
    <div className='flex h-full w-full items-center justify-center'>
      <img
        src={`${process.env.PUBLIC_URL}/zestaw-ekomatik.png`}
        className='max-h-full max-w-full'
      />
    </div>
  );
}
