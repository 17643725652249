type Props = {
    stepNumber: number;
  };
  
  export default function CustomMatrixC6S4E1({ stepNumber }: Props) {
    if (stepNumber === 1) {
      return null;
    }
  
    if (stepNumber === 1 || stepNumber === 2) {
      return (
        <div className='relative h-full w-full p-[20px]'>
          <div className='flex h-full w-full items-center justify-center p-[20px]'>
            <img
              src={`${process.env.PUBLIC_URL}/emptyBoard.png`}
              className='h-full'
            />
          </div>
        </div>
      );
    }
  
    if (stepNumber === 3 || stepNumber === 4) {
      return (
        <div className='relative h-full w-full p-[20px]'>
          <div className='flex h-full w-full items-center justify-center p-[20px]'>
            <img
              src={`${process.env.PUBLIC_URL}/c6s4e1blank.png`}
              className='w-full'
            />
          </div>
        </div>
      );
    }
  
    if (stepNumber === 5) {
      return (
        <div className='relative h-full w-full p-[20px]'>
          <div className='flex h-full w-full items-center justify-center p-[20px]'>
            <img
              src={`${process.env.PUBLIC_URL}/c6s4e1side.png`}
              className='w-full'
            />
          </div>
        </div>
      );
    }
  
    if (stepNumber === 6) {
      return (
        <div className='relative h-full w-full p-[20px]'>
          <div className='flex h-full w-full items-center justify-center p-[20px]'>
            <img
              src={`${process.env.PUBLIC_URL}/c6s4e1bottom.png`}
              className='w-full'
            />
          </div>
        </div>
      );
    }
  
    if (stepNumber === 7 || stepNumber === 8) {
      return (
        <div className='relative h-full w-full p-[20px]'>
          <div className='flex h-full w-full items-center justify-center p-[20px]'>
            <img
              src={`${process.env.PUBLIC_URL}/c6s4e1line.png`}
              className='w-full'
            />
          </div>
        </div>
      );
    }

    if (stepNumber === 9) {
        return (
          <div className='relative h-full w-full p-[20px]'>
            <div className='flex h-full w-full items-center justify-center p-[20px]'>
              <img
                src={`${process.env.PUBLIC_URL}/c6s4e1corner.png`}
                className='w-full'
              />
            </div>
          </div>
        );
      }
  
    return <div></div>;
  }
  