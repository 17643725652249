import { getBlob, ref } from 'firebase/storage';
import { storage } from 'src/config/firebase.config';

export function downloadFile(fileLocation: string) {
  const fileRef = ref(storage, fileLocation);
  getBlob(fileRef).then((blob) => {
    const a = document.createElement('a');
    a.download = fileRef.name;
    a.href = window.URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.click();
    a.remove();
  });
}

export function getFileNameWithoutExtension(fileName: string) {
  return fileName.split('.')[0];
}
