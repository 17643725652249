import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import Footer from 'src/components/Footer';
import { useRegister } from './hooks';
import RegisterFirstStep from './RegisterFirstStep';
import RegisterSecondStep from './RegisterSecondStep';
import RegisterSuccess from './RegisterSuccess';

export default function Register() {
  const { form, handleRegister, successfullyCreated, isLoading } =
    useRegister();
  const [step, setStep] = useState<RegisterStepEnum>(
    RegisterStepEnum.firstStep
  );

  useEffect(() => {
    if (successfullyCreated) {
      setStep(RegisterStepEnum.successStep);
    }
  }, [successfullyCreated]);

  return (
    <FormProvider {...form}>
      <form
        className='h-full'
        onSubmit={form.handleSubmit(handleRegister)}
        noValidate
        autoComplete='off'
      >
        <>
          {step === RegisterStepEnum.firstStep && (
            <RegisterFirstStep setStep={setStep} form={form} />
          )}
          {step === RegisterStepEnum.secondStep && (
            <RegisterSecondStep setStep={setStep} isLoading={isLoading} />
          )}
          {step === RegisterStepEnum.successStep && <RegisterSuccess />}
        </>
      </form>
      <Footer />
    </FormProvider>
  );
}

export enum RegisterStepEnum {
  firstStep = 1,
  secondStep = 2,
  successStep = 3,
}
