import { getBlocksInGivenIdx } from 'src/database/exerciseUtil';
import {
    Exercise,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../exerciseTypes';

const step1 = {
  number: 1,
  withDecimals: true,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie. Pooglądajcie ją i powiedźcie, co ciekawego zauważyliście',
};

const step2 = {
  number: 2,
  withDecimals: true,
  description:
    'Wyjmijcie z pudełka 25 zielonych klocków i połóżcie je obok siatki tak, aby nie były widoczne wypisane na nich cyfry',
};

const step3 = {
  number: 3,
  withDecimals: true,
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step4 = {
  number: 4,
  withDecimals: true,
  description:
    'Układajcie razem ze mną zielone klocki w kolumnie z napisem Jedności',
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(10, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step5 = {
  number: 5,
  withDecimals: true,
  blocks: getBlocksInGivenIdx(1, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(9, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step6 = {
  number: 6,
  withDecimals: true,
  blocks: getBlocksInGivenIdx(2, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(8, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step7 = {
  number: 7,
  withDecimals: true,
  blocks: getBlocksInGivenIdx(3, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(7, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step8 = {
  number: 8,
  withDecimals: true,
  blocks: getBlocksInGivenIdx(4, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(6, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step9 = {
  number: 9,
  withDecimals: true,
  blocks: getBlocksInGivenIdx(5, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(5, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step10 = {
  number: 10,
  withDecimals: true,
  blocks: getBlocksInGivenIdx(6, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(4, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step11 = {
  number: 11,
  withDecimals: true,
  blocks: getBlocksInGivenIdx(7, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(3, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step12 = {
  number: 12,
  withDecimals: true,
  blocks: getBlocksInGivenIdx(8, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(2, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step13 = {
  number: 13,
  withDecimals: true,
  blocks: getBlocksInGivenIdx(9, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [
    ...getBlocksInGivenIdx(1, 2, ExerciseColor.ZIELONY),
    ...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step14 = {
  number: 14,
  withDecimals: true,
  blocks: getBlocksInGivenIdx(10, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step15 = {
  number: 15,
  withDecimals: true,
  description:
    'Gdy ustawimy kolumnę, która ma 10 klocków zielonych, i już nie ma miejsca na dalsze klocki, całą kolumnę 10 klocków zamieniamy na jeden czerwony klocek, który oznacza, że mamy jedną dziesiątkę. Czerwony klocek układamy w kolumnie Dziesiątki. Pozostałe klocki zielone układamy znowu w kolumnie Jedności. Gdy ustawimy kolumnę składającą się z 10 klocków zielonych, całą kolumnę zamieniamy na jeden czerwony klocek, który oznacza, że mamy drugą dziesiątkę. Pozostałe klocki zielone układamy znowu w kolumnie Jedności. Teraz możemy zobaczyć, jak wygląda liczba 25. Taką liczbę zapisujemy',
  blocks: getBlocksInGivenIdx(10, 4, ExerciseColor.ZIELONY),
  leftHelpMatrix: [...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step16 = {
  number: 16,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(0, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(10, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step17 = {
  number: 17,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(1, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(9, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step18 = {
  number: 18,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(2, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(8, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step19 = {
  number: 19,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(3, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(7, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step20 = {
  number: 20,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(4, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(6, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step21 = {
  number: 21,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(5, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step22 = {
  number: 22,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(6, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(4, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step23 = {
  number: 23,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(7, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(3, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step24 = {
  number: 24,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(8, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(2, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step25 = {
  number: 25,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(9, 4, ExerciseColor.ZIELONY),
  ],
  leftHelpMatrix: [...getBlocksInGivenIdx(1, 1, ExerciseColor.ZIELONY)],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step26 = {
  number: 26,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(1, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(10, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step27 = {
  number: 27,
  withDecimals: true,
  blocks: [...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY)],
  rightHelpMatrix: getBlocksInGivenIdx(5, 1, ExerciseColor.ZIELONY),
};

const step28 = {
  number: 28,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(1, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(4, 1, ExerciseColor.ZIELONY),
};

const step29 = {
  number: 29,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(2, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(3, 1, ExerciseColor.ZIELONY),
};

const step30 = {
  number: 30,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(3, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(2, 1, ExerciseColor.ZIELONY),
};

const step31 = {
  number: 31,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(4, 4, ExerciseColor.ZIELONY),
  ],
  rightHelpMatrix: getBlocksInGivenIdx(1, 1, ExerciseColor.ZIELONY),
};

const step32 = {
  number: 32,
  withDecimals: true,
  blocks: [
    ...getBlocksInGivenIdx(2, 3, ExerciseColor.CZERWONY),
    ...getBlocksInGivenIdx(5, 4, ExerciseColor.ZIELONY),
  ],
};

const steps = [
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  step9,
  step10,
  step11,
  step12,
  step13,
  step14,
  step15,
  step16,
  step17,
  step18,
  step19,
  step20,
  step21,
  step22,
  step23,
  step24,
  step25,
  step26,
  step27,
  step28,
  step29,
  step30,
  step31,
  step32,
];

export const c2e13: Exercise = {
  number: 13,
  name: ExerciseName.EXERCISE_13,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  steps: steps,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'thousands',
      label: 'Liczba tysięcy',
      placeholder: 'Wybierz liczbę tysięcy',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
    {
      name: 'hundreds',
      label: 'Liczba setek',
      placeholder: 'Wybierz liczbę setek',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
    {
      name: 'dozens',
      label: 'Liczba dziesiątek',
      placeholder: 'Wybierz liczbę dziesiątek',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
    {
      name: 'units',
      label: 'Liczba jedności',
      placeholder: 'Wybierz liczbę jedności',
      options: Array.from({ length: 11 }, (_, i) => i),
    },
  ],
};
