import { NewVariant } from 'src/pages/NewVariant/hooks';
import { Corner, ExerciseColor, ExerciseName } from '../exerciseTypes';
import { getC2E1VariantSteps } from './exercise1/c2e1v';
import { getC2E12VariantSteps } from './exercise12/c2e12v';
import { getC2E13VariantSteps } from './exercise13/c2e13v';
import { getC2E14VariantSteps } from './exercise14/c2e14v';
import { getC2E15VariantSteps } from './exercise15/c2e15v';
import { getC2E16VariantSteps } from './exercise16/c2e16v';
import { getC2E17VariantSteps } from './exercise17/c2e17v';
import { getC2E2VariantSteps } from './exercise2/c2e2v';
import { getC2E3VariantSteps } from './exercise3/c2e3v';
import { getC2E4VariantSteps } from './exercise4/c2e4v';
import { getC2E5VariantSteps } from './exercise5/c2e5v';

export function getExerciseCategory2(exercise: string, inputs: NewVariant) {
  if (exercise === ExerciseName.EXERCISE_1) {
    return getC2E1VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.maxNumberOfBlocks || 10,
      inputs.reverse || false
    );
  }
  if (exercise === ExerciseName.EXERCISE_2) {
    return getC2E2VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.maxNumberOfBlocks || 10,
      inputs.reverse || false
    );
  }
  if (exercise === ExerciseName.EXERCISE_3) {
    return getC2E3VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.maxNumberOfBlocks || 10,
      inputs.reverse || false
    );
  }
  if (exercise === ExerciseName.EXERCISE_4) {
    return getC2E4VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.maxNumberOfBlocks || 10,
      inputs.reverse || false
    );
  }
  if (exercise === ExerciseName.EXERCISE_5) {
    return getC2E5VariantSteps(
      inputs.blocksColor || ExerciseColor.CZERWONY,
      inputs.maxNumberOfBlocks || 25,
      inputs.blockCorner || Corner.LOWER_LEFT
    );
  }
  if (exercise === ExerciseName.EXERCISE_12) {
    return getC2E12VariantSteps(
      inputs.thousands || 0,
      inputs.hundreds || 0,
      inputs.dozens || 0,
      inputs.units || 0
    );
  }
  if (exercise === ExerciseName.EXERCISE_13) {
    return getC2E13VariantSteps(
      inputs.thousands || 0,
      inputs.hundreds || 0,
      inputs.dozens || 0,
      inputs.units || 0
    );
  }
  if (exercise === ExerciseName.EXERCISE_14) {
    return getC2E14VariantSteps(
      inputs.thousands || 0,
      inputs.hundreds || 0,
      inputs.dozens || 0,
      inputs.units || 0
    );
  }
  if (exercise === ExerciseName.EXERCISE_15) {
    return getC2E15VariantSteps(
      inputs.thousands || 0,
      inputs.hundreds || 0,
      inputs.dozens || 0,
      inputs.units || 0
    );
  }
  if (exercise === ExerciseName.EXERCISE_16) {
    return getC2E16VariantSteps(
      inputs.thousands || 0,
      inputs.hundreds || 0,
      inputs.dozens || 0,
      inputs.units || 0
    );
  }
  if (exercise === ExerciseName.EXERCISE_17) {
    return getC2E17VariantSteps(
      inputs.thousands || 0,
      inputs.hundreds || 0,
      inputs.dozens || 0,
      inputs.units || 0
    );
  }
  return [];
}
