import { FormProvider } from 'react-hook-form';
import Button from 'src/components/Button';
import Input from 'src/components/Input';
import { ButtonSize, ButtonTypes } from 'src/utils/types';
import { useReport } from './hooks';

export default function MenuReport() {
  const { form, sendReport, isReportSent, isLoading, setImageUpload } =
    useReport();

  return (
    <div className='relative h-full w-full'>
      {!isReportSent ? (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(sendReport)} noValidate={true}>
            <Input
              textarea
              name='report'
              maxLength={1000}
              placeholder='Dodaj opinię lub zgłoś błąd'
              wrapperClassName='h-[370px]'
            />
            <div>
              <input
                type='file'
                className='bg-white-default'
                onChange={(event) => {
                  if (event.target.files) {
                    setImageUpload(event.target.files[0]);
                  }
                }}
              />
            </div>
            <Button
              type='submit'
              buttonSize={ButtonSize.BIG}
              variant={ButtonTypes.primary}
              customClassName='w-full absolute bottom-0'
              isLoading={isLoading}
            >
              WYŚLIJ
            </Button>
          </form>
        </FormProvider>
      ) : (
        <div className='flex h-full items-center justify-center text-[24px]'>
          Dziękujemy za zgłoszenie.
        </div>
      )}
    </div>
  );
}
