import {
  CustomMatrix,
  Exercise,
  ExerciseName,
  ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.EXERCISE_C6_S3_E1,
  description: 'Wyciągnijcie z pudełka taką samą drewnianą podkładkę',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.EXERCISE_C6_S3_E1,
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.EXERCISE_C6_S3_E1,
};

const step4 = {
  number: 4,
  description: 'Czy wiecie, jak nazywa się taka figura?',
  customMatrix: CustomMatrix.EXERCISE_C6_S3_E1,
};

const step5 = {
  number: 5,
  description:
    'Czy wszystkie ściany graniastosłupa są prostokątami? \n Jaki kształt ma podstawa górna graniastosłupa? \n Jaki kształt ma podstawa dolna graniastosłupa?',
  customMatrix: CustomMatrix.EXERCISE_C6_S3_E1,
};

const step6 = {
  number: 6,
  description:
    'To jest krawędź graniastosłupa. Pokażcie ją na swoich klockach i policzcie, ile graniastosłup ma krawędzi',
  customMatrix: CustomMatrix.EXERCISE_C6_S3_E1,
};

const step7 = {
  number: 7,
  description:
    'Wyjmijcie z pudełka miarkę centymetrową i zmierzcie krawędzie graniastosłupa. Zapiszcie wyniki pomiarów w zeszytach. Co ciekawego zauważyliście?',
  customMatrix: CustomMatrix.EXERCISE_C6_S3_E1,
};

const step8 = {
  number: 8,
  description:
    'To jest wierzchołek prostopadłościanu. Pokażcie go na swoich klockach i policzcie, ile prostopadłościan ma wierzchołków. Ile krawędzi wychodzi z jednego wierzchołka?',
  customMatrix: CustomMatrix.EXERCISE_C6_S3_E1,
};

export const c6s3e1: Exercise = {
  number: 1,
  name: ExerciseName.EXERCISE_1,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  steps: [step1, step2, step3, step4, step5, step6, step7, step8],
  availability: 'LIMITED_ACCESS',
};
