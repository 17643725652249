import {
  Corner,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseStep,
  ExerciseStepBlock,
  Figure,
  getBlockColor,
} from 'src/database/exerciseTypes';
import { getPluralColor } from 'src/database/exerciseUtil';

export function getC1E7VariantSteps(
  color: ExerciseColor,
  figure: Figure,
  blocksColor: Corner
): ExerciseStep[] {
  let emptyBlocks: { idx: number; idy: number }[] = [];
  let cornerDescription = '';

  if (blocksColor === Corner.LOWER_LEFT) {
    cornerDescription = 'lewym dolnym';
    if (figure === Figure.RECTANGLE) {
      emptyBlocks = [
        {
          idx: 1,
          idy: 1,
        },
        {
          idx: 1,
          idy: 2,
        },
        {
          idx: 2,
          idy: 1,
        },
        {
          idy: 2,
          idx: 2,
        },
        {
          idy: 2,
          idx: 3,
        },
        {
          idy: 1,
          idx: 3,
        },
      ];
    }
    if (figure === Figure.SQUARE) {
      emptyBlocks = [
        {
          idx: 1,
          idy: 1,
        },
        {
          idx: 1,
          idy: 2,
        },
        {
          idx: 2,
          idy: 1,
        },
        {
          idy: 2,
          idx: 2,
        },
      ];
    }
    if (figure === Figure.TRIANGLE) {
      emptyBlocks = [
        {
          idx: 2,
          idy: 2,
        },
        {
          idx: 1,
          idy: 1,
        },
        {
          idx: 2,
          idy: 1,
        },
        {
          idy: 1,
          idx: 3,
        },
      ];
    }
  }
  if (blocksColor === Corner.UPPER_LEFT) {
    cornerDescription = 'lewym górnym';
    if (figure === Figure.RECTANGLE) {
      emptyBlocks = [
        {
          idx: 1,
          idy: 10,
        },
        {
          idx: 1,
          idy: 9,
        },
        {
          idx: 2,
          idy: 10,
        },
        {
          idx: 2,
          idy: 9,
        },
        {
          idx: 3,
          idy: 9,
        },
        {
          idx: 3,
          idy: 10,
        },
      ];
    }
    if (figure === Figure.SQUARE) {
      emptyBlocks = [
        {
          idx: 1,
          idy: 10,
        },
        {
          idx: 1,
          idy: 9,
        },
        {
          idx: 2,
          idy: 10,
        },
        {
          idx: 2,
          idy: 9,
        },
      ];
    }
    if (figure === Figure.TRIANGLE) {
      emptyBlocks = [
        {
          idx: 2,
          idy: 10,
        },
        {
          idx: 1,
          idy: 9,
        },
        {
          idx: 2,
          idy: 9,
        },
        {
          idx: 3,
          idy: 9,
        },
      ];
    }
  }
  if (blocksColor === Corner.LOWER_RIGHT) {
    cornerDescription = 'prawym dolnym';
    if (figure === Figure.RECTANGLE) {
      emptyBlocks = [
        {
          idx: 10,
          idy: 1,
        },
        {
          idx: 10,
          idy: 2,
        },
        {
          idx: 9,
          idy: 1,
        },
        {
          idx: 9,
          idy: 2,
        },
        {
          idx: 8,
          idy: 1,
        },
        {
          idx: 8,
          idy: 2,
        },
      ];
    }
    if (figure === Figure.SQUARE) {
      emptyBlocks = [
        {
          idx: 10,
          idy: 1,
        },
        {
          idx: 10,
          idy: 2,
        },
        {
          idx: 9,
          idy: 1,
        },
        {
          idx: 9,
          idy: 2,
        },
      ];
    }
    if (figure === Figure.TRIANGLE) {
      emptyBlocks = [
        {
          idx: 10,
          idy: 1,
        },
        {
          idx: 9,
          idy: 1,
        },
        {
          idx: 8,
          idy: 1,
        },
        {
          idx: 9,
          idy: 2,
        },
      ];
    }
  }
  if (blocksColor === Corner.UPPER_RIGHT) {
    cornerDescription = 'prawym górnym';
    if (figure === Figure.RECTANGLE) {
      emptyBlocks = [
        {
          idx: 10,
          idy: 10,
        },
        {
          idx: 10,
          idy: 9,
        },
        {
          idx: 9,
          idy: 10,
        },
        {
          idx: 9,
          idy: 9,
        },
        {
          idx: 8,
          idy: 9,
        },
        {
          idx: 8,
          idy: 10,
        },
      ];
    }
    if (figure === Figure.SQUARE) {
      emptyBlocks = [
        {
          idx: 10,
          idy: 10,
        },
        {
          idx: 10,
          idy: 9,
        },
        {
          idx: 9,
          idy: 9,
        },
        {
          idx: 9,
          idy: 10,
        },
      ];
    }
    if (figure === Figure.TRIANGLE) {
      emptyBlocks = [
        {
          idx: 9,
          idy: 10,
        },
        {
          idx: 9,
          idy: 9,
        },
        {
          idx: 10,
          idy: 9,
        },
        {
          idx: 8,
          idy: 9,
        },
      ];
    }
  }

  const blocks: ExerciseStepBlock[] = emptyBlocks.map((b) => ({
    ...b,
    color: getBlockColor(color),
    content: ExerciseBlockContent.BLANK,
  }));

  const colorDescription = getPluralColor(getBlockColor(color), 8);

  return [
    {
      number: 1,
      description: `Ułóż ${figure.toLowerCase()} z ${colorDescription} klocków w ${cornerDescription} rogu siatki`,
    },
    {
      number: 2,
      blocks: blocks,
    },
  ];
}
