type Props = {
  stepNumber: number;
};

export default function CustomMatrixC6S5E2({ stepNumber }: Props) {
  if (stepNumber === 1) {
    return null;
  }

  if (stepNumber === 1 || stepNumber === 2 || stepNumber === 3) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/emptyBoard.png`}
            className='h-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 4 || stepNumber === 5) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='h-[200px] w-[300px] border border-solid bg-brown-light' />
        </div>
      </div>
    );
  }

  return <div></div>;
}
