import {
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../../exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka 4 zielone klocki i połóżcie je obok siatki tak, aby cyfry na klockach nie były widoczne',
};

const step2 = {
  number: 2,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step3 = {
  number: 3,
  description: 'Rozłóżcie te klocki po równo w dwóch kwadratach',
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  number: 4,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  number: 5,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step6 = {
  number: 6,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step7 = {
  number: 7,
  blocks: [
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step8 = {
  number: 8,
  description: 'Ile klocków jest w każdym kwadracie?',
  blocks: [
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step9 = {
  number: 9,
  description:
    'Mieliśmy 4 klocki, rozłożyliśmy je tak, że w każdym kwadracie jest 1 klocek, czyli 4 klocki podzielone na 4 równe części daje po 1 klocku',
  blocks: [
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step10 = {
  number: 10,
  description: 'Zapiszcie w zeszytach wykonywanych czynności',
  blocks: [
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 7,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 7,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

export const c3s4e2: Exercise = {
  number: 2,
  name: ExerciseName.EXERCISE_2,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'numberToDivide',
      label: 'Wybierz pierwszy składnik dzielenia',
      placeholder: 'Wybierz liczbę klocków',
      options: Array.from({ length: 100 }, (_, i) => i + 1),
    },
    {
      name: 'numberDivide',
      label: 'Wybierz drugi składnik dzielenia',
      placeholder: 'Wybierz ilość kwadratów',
      options: Array.from({ length: 4 }, (_, i) => i + 1),
    },
  ],
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
  ],
};
