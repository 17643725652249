import {
  CustomMatrix,
  Exercise,
  ExerciseName,
  ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
};

const step2 = {
  number: 2,
  description:
    'Ułóżcie 9 czerwonych klocków w jednym rzędzie tak, aby klocki stykały się ścianami',
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
  description:
    'Wyjmijcie z pudełka klocki fioletowe, które posłużą nam do mierzenia długości figur',
};

const step5 = {
  number: 5,
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
};

const step6 = {
  number: 6,
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
  description:
    'Teraz zmierzymy długość figury za pomocą małego klocka oznaczonego literą m',
};

const step7 = {
  number: 7,
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
};

const step8 = {
  number: 8,
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
};

const step9 = {
  number: 9,
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
};

const step11 = {
  number: 11,
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
};

const step12 = {
  number: 12,
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
};

const step13 = {
  number: 13,
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
};

const step14 = {
  number: 14,
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
};

const step15 = {
  number: 15,
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
};

const step16 = {
  number: 16,
  customMatrix: CustomMatrix.EXERCISE_C5_E3,
  description: 'Zapiszcie w swoich zeszytach wynik pomiaru',
};

export const c5e3: Exercise = {
  number: 3,
  name: ExerciseName.EXERCISE_3,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
  ],
};
