import { useEffect } from 'react';
import { Provider as AlertProvider } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import CustomAlert, { alertConfig } from './components/CustomAlert/CustomAlert';
import { AuthContextProvider } from './context/AuthContext';
import { HomepageMenuContextProvider } from './context/HomepageMenuContext';
import './index.css';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem('user') &&
      process.env.REACT_APP_WITH_BASIC_AUTH === 'true'
    ) {
      if (window.location.pathname !== '/basic-auth') {
        navigate('/basic-auth');
      }
    }
  }, []);

  return (
    <AlertProvider template={CustomAlert} {...alertConfig}>
      <AuthContextProvider>
        <HomepageMenuContextProvider>
          <AppRoutes />
        </HomepageMenuContextProvider>
      </AuthContextProvider>
    </AlertProvider>
  );
}

export default App;
