import clsx from 'clsx';

type Props = {
  stepNumber: number;
};

export default function CustomMatrixC6S1E2({ stepNumber }: Props) {
  function bigBlock(vertical: boolean) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[150px]': !vertical,
            'h-[150px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>d</div>
      </div>
    );
  }

  function mediumBlock(vertical: boolean) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[100px]': !vertical,
            'h-[100px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>s</div>
      </div>
    );
  }

  function smallBlock(vertical: boolean) {
    return (
      <div
        className={clsx(
          'flex items-center justify-center border border-solid bg-purple-default',
          {
            'h-[25px] w-[50px]': !vertical,
            'h-[50px] w-[25px]': vertical,
          }
        )}
      >
        <div className={clsx({ '-rotate-90': vertical })}>m</div>
      </div>
    );
  }

  if (stepNumber === 1) {
    return null;
  }

  if (stepNumber === 2 || stepNumber === 3) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/emptyBoard.png`}
            className='h-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 4 || stepNumber === 5) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <img
            src={`${process.env.PUBLIC_URL}/c6s1e2figure.png`}
            className='w-full'
          />
        </div>
      </div>
    );
  }

  if (stepNumber >= 6 && stepNumber <= 8) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='flex h-[200px] w-[200px] bg-brown-block'></div>
        </div>
      </div>
    );
  }

  if (stepNumber === 9) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='absolute flex w-full'>
          <div className='mr-[20px]'>{bigBlock(false)}</div>
          <div className='mr-[20px]'>{mediumBlock(false)}</div>
          <div>{smallBlock(false)}</div>
        </div>

        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div className='flex h-[200px] w-[200px] bg-brown-block'></div>
        </div>
      </div>
    );
  }

  if (stepNumber === 10) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='absolute flex w-full'>
          <div className='mr-[20px]'>{bigBlock(false)}</div>
          <div className='mr-[20px]'>{mediumBlock(false)}</div>
          <div>{smallBlock(false)}</div>
        </div>

        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div>
            <div className='relative h-[200px] w-[200px] bg-brown-block'></div>
            <div className='absolute'>{bigBlock(false)}</div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 11) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='absolute flex w-full'>
          <div className='mr-[20px]'>{bigBlock(false)}</div>
          <div className='mr-[20px]'>{mediumBlock(false)}</div>
          <div>{smallBlock(false)}</div>
        </div>

        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div>
            <div className='relative h-[200px] w-[200px] bg-brown-block'></div>
            <div className='absolute flex'>
              <div>{bigBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (stepNumber === 12) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='absolute flex w-full'>
          <div className='mr-[20px]'>{bigBlock(false)}</div>
          <div className='mr-[20px]'>{mediumBlock(false)}</div>
          <div>{smallBlock(false)}</div>
        </div>

        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div>
            <div className='relative h-[200px] w-[200px] bg-brown-block' />
            <div className='absolute flex'>
              <div>{bigBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
          </div>
          <div className='absolute mt-[50px] ml-[225px]'>{bigBlock(true)}</div>
        </div>
      </div>
    );
  }
  if (stepNumber === 13) {
    return (
      <div className='relative h-full w-full p-[20px]'>
        <div className='absolute flex w-full'>
          <div className='mr-[20px]'>{bigBlock(false)}</div>
          <div className='mr-[20px]'>{mediumBlock(false)}</div>
          <div>{smallBlock(false)}</div>
        </div>

        <div className='flex h-full w-full items-center justify-center p-[20px]'>
          <div>
            <div className='relative h-[200px] w-[200px] bg-brown-block' />
            <div className='absolute flex'>
              <div>{bigBlock(false)}</div>
              <div>{smallBlock(false)}</div>
            </div>
          </div>
          <div className='absolute ml-[225px]'>
            <div>{smallBlock(true)}</div>
            <div>{bigBlock(true)}</div>
          </div>
        </div>
      </div>
    );
  }
  return <div></div>;
}
