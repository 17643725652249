import {
    getBlocksAfterCount,
    getBlocksBeforeCount
} from 'src/database/category3/category3Util';
import {
    CustomMatrix,
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseStepBlock,
    ExerciseType
} from '../../exerciseTypes';

function getRandomBlocks(
  numberOfBlocks: number,
  color: ExerciseColor,
  triple: boolean,
  blocksBeforeAdd?: ExerciseStepBlock[]
) {
  const allBlocks: ExerciseStepBlock[] = blocksBeforeAdd || [];
  let x = 0;
  let y = 0;
  const blocks = triple ? numberOfBlocks * 4 : numberOfBlocks * 3;
  for (let i = 0; allBlocks.length < blocks; i++) {
    x = Math.floor(Math.random() * 12) + 2;
    y = Math.floor(Math.random() * 13) + 2;
    const block = allBlocks.find(
      (b) =>
        (b.idx === x && b.idy === y) ||
        (b.idx === x - 1 && b.idy === y) ||
        (b.idx === x + 1 && b.idy === y) ||
        (b.idx === x && b.idy === y - 1)
    );
    if (typeof block === 'undefined') {
      allBlocks.push(
        {
          idx: x,
          idy: y,
          color: color,
          content: ExerciseBlockContent.BLANK,
        },
        {
          idx: x - 1,
          idy: y,
          color: ExerciseColor.CZERWONY,
          content: ExerciseBlockContent.BLANK,
        },
        {
          idx: x + 1,
          idy: y,
          color: ExerciseColor.CZERWONY,
          content: ExerciseBlockContent.BLANK,
        }
      );
      if (triple) {
        allBlocks.push({
          idx: x,
          idy: y - 1,
          color: ExerciseColor.CZERWONY,
          content: ExerciseBlockContent.BLANK,
        });
      }
    }
  }
  return allBlocks;
}

const doubleBlocks = getRandomBlocks(11, ExerciseColor.ŻÓŁTY, false);
const doubleWithTripleBlocks = getRandomBlocks(
  15,
  ExerciseColor.ZIELONY,
  true,
  [...doubleBlocks]
);

const step1 = {
  withDecimals: true,
  number: 1,
  description: 'Wyciągnijcie podstawki jak na ekranie',
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
  blocksWithoutPaddings: true,
};

const step2 = {
  withDecimals: true,
  number: 2,
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
  blocksWithoutPaddings: true,
  description:
    'Podstawka ilustruje jezioro, po którym mogą rowerki wodne, reprezentowane u nas przez klocki zielone i żółte',
};

const step3 = {
  withDecimals: true,
  number: 3,
  description:
    'Po jeziorze pływa 11 rowerów wodnych, a na każdym rowerze siedzi dwoje ludzi oraz 4 rowery, na których siedzi po troje ludzi',
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
  blocksWithoutPaddings: true,
};

const step4 = {
  withDecimals: true,
  number: 4,
  description:
    'Połóżcie na podstawkach odpowiednią liczbę klocków w taki sposób, aby nie było widać cyfr wypisanych na ściankach',
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
  blocksWithoutPaddings: true,
};

const step5 = {
  withDecimals: true,
  number: 5,
  blocksWithoutPaddings: true,
  blocks: doubleWithTripleBlocks,
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
};

const step6 = {
  withDecimals: true,
  number: 6,
  description: 'Ile osób pływa po jeziorze?',
  customMatrix: CustomMatrix.BLANK_10x10,
  blocksWithoutPaddings: true,
  blocks: doubleWithTripleBlocks,
  matrixWidth: 14,
  matrixHeight: 14,
};

const step7 = {
  withDecimals: true,
  number: 7,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step8 = {
  withDecimals: true,
  number: 8,
  description:
    'Ułóżcie na nich odpowiednich klocków ilustrujących podane w historyjce liczby żaglówek',
};

const step9 = {
  withDecimals: true,
  number: 9,
  blocks: [...getBlocksBeforeCount(22, 12)],
};

const step10 = {
  withDecimals: true,
  number: 10,
  description: 'Posługując się klockami, dodajcie podane liczby',
  blocks: [...getBlocksBeforeCount(22, 12)],
};

const step11 = {
  withDecimals: true,
  number: 11,
  blocks: [...getBlocksAfterCount(34)],
};

export const c4e5: Exercise = {
  number: 5,
  name: ExerciseName.EXERCISE_5,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
  ],
};
