import { NewVariant } from 'src/pages/NewVariant/hooks';
import { CategoryName, ExerciseName, getBlockColor } from '../exerciseTypes';
import { getC3S1E1VariantSteps } from './subcategory1/exercise1/c3s1e1v';
import { getC3S1E2VariantSteps } from './subcategory1/exercise2/c3s1e2v';
import { getC3S1E3VariantSteps } from './subcategory1/exercise3/c3s1e3v';
import { getC3S1E4VariantSteps } from './subcategory1/exercise4/c3s1e4v';
import { getC3S1E5VariantSteps } from './subcategory1/exercise5/c3s1e5v';
import { getC3S1E6VariantSteps } from './subcategory1/exercise6/c3s1e6v';
import { getC3S1E7VariantSteps } from './subcategory1/exercise7/c3s1e7v';
import { getC3S1E8VariantSteps } from './subcategory1/exercise8/c3s1e8v';

export function getExerciseCategory3(
  exercise: string,
  inputs: NewVariant,
  subcategory: string
) {
  const firstNumber = inputs.firstNumber || 0;
  const secondNumber = inputs.secondNumber || 0;
  const blocksColor = inputs.blocksColor || ExerciseColor.NIEBIESKI;
  const xShift = inputs.blockXShift || 0;
  const yShift = inputs.blockYShift || 0;

  if (subcategory === CategoryName.SUBCATEGORY_3_1) {
    if (exercise === ExerciseName.EXERCISE_1) {
      return getC3S1E1VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_2) {
      return getC3S1E2VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_3) {
      return getC3S1E3VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_4) {
      return getC3S1E4VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_5) {
      return getC3S1E5VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_6) {
      return getC3S1E6VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_7) {
      return getC3S1E7VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_8) {
      return getC3S1E8VariantSteps(firstNumber, secondNumber);
    }
  }

  if (subcategory === CategoryName.SUBCATEGORY_3_2) {
    if (exercise === ExerciseName.EXERCISE_1) {
      return getC3S2E1VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_2) {
      return getC3S2E2VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_3) {
      return getC3S2E3VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_4) {
      return getC3S2E4VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_5) {
      return getC3S2E5VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_6) {
      return getC3S2E6VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_7) {
      return getC3S2E7VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_8) {
      return getC3S2E8VariantSteps(firstNumber, secondNumber);
    }
    if (exercise === ExerciseName.EXERCISE_9) {
      return getC3S2E9VariantSteps(firstNumber, secondNumber);
    }
  }

  if (subcategory === CategoryName.SUBCATEGORY_3_3) {
    if (exercise === ExerciseName.EXERCISE_1) {
      return getC3S3E1VariantSteps(blocksColor, xShift, yShift);
    }
    if (exercise === ExerciseName.EXERCISE_2) {
      return getC3S3E2VariantSteps(xShift, yShift);
    }
    if (exercise === ExerciseName.EXERCISE_3) {
      return getC3S3E3VariantSteps(xShift, yShift);
    }
    if (exercise === ExerciseName.EXERCISE_4) {
      return getC3S3E4VariantSteps(xShift, yShift);
    }
    if (exercise === ExerciseName.EXERCISE_5) {
      return getC3S3E5VariantSteps(xShift, yShift);
    }
    if (exercise === ExerciseName.EXERCISE_6) {
      return getC3S3E6VariantSteps(xShift, yShift);
    }
    if (exercise === ExerciseName.EXERCISE_7) {
      return getC3S3E7VariantSteps(xShift, yShift);
    }
  }

  if (subcategory === CategoryName.SUBCATEGORY_3_4) {
    if (exercise === ExerciseName.EXERCISE_1) {
      return getC3S4E1VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
    if (exercise === ExerciseName.EXERCISE_2) {
      return getC3S4E2VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
    if (exercise === ExerciseName.EXERCISE_3) {
      return getC3S4E3VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
    if (exercise === ExerciseName.EXERCISE_4) {
      return getC3S4E4VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
    if (exercise === ExerciseName.EXERCISE_5) {
      return getC3S4E5VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
    if (exercise === ExerciseName.EXERCISE_6) {
      return getC3S4E6VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
    if (exercise === ExerciseName.EXERCISE_7) {
      return getC3S4E7VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
    if (exercise === ExerciseName.EXERCISE_8) {
      return getC3S4E8VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
    if (exercise === ExerciseName.EXERCISE_9) {
      return getC3S4E9VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
    if (exercise === ExerciseName.EXERCISE_10) {
      return getC3S4E10VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
    if (exercise === ExerciseName.EXERCISE_11) {
      return getC3S4E11VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
    if (exercise === ExerciseName.EXERCISE_12) {
      return getC3S4E12VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
    if (exercise === ExerciseName.EXERCISE_13) {
      return getC3S4E13VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
    if (exercise === ExerciseName.EXERCISE_14) {
      return getC3S4E14VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
    if (exercise === ExerciseName.EXERCISE_15) {
      return getC3S4E15VariantSteps(
        blocksColor,
        inputs.numberToDivide || 0,
        inputs.numberDivide || 0
      );
    }
  }

  return [];
}

import {
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseStepBlock,
} from 'src/database/exerciseTypes';
import { getC3S2E1VariantSteps } from './subcategory2/exercise1/c3s2e1v';
import { getC3S2E2VariantSteps } from './subcategory2/exercise2/c3s2e2v';
import { getC3S2E3VariantSteps } from './subcategory2/exercise3/c3s2e3v';
import { getC3S2E4VariantSteps } from './subcategory2/exercise4/c3s2e4v';
import { getC3S2E5VariantSteps } from './subcategory2/exercise5/c3s2e5v';
import { getC3S2E6VariantSteps } from './subcategory2/exercise6/c3s2e6v';
import { getC3S2E7VariantSteps } from './subcategory2/exercise7/c3s2e7v';
import { getC3S2E8VariantSteps } from './subcategory2/exercise8/c3s2e8v';
import { getC3S2E9VariantSteps } from './subcategory2/exercise9/c3s2e9v';
import { getC3S3E1VariantSteps } from './subcategory3/exercise1/c3s3e1v';
import { getC3S3E2VariantSteps } from './subcategory3/exercise2/c3s3e2v';
import { getC3S3E3VariantSteps } from './subcategory3/exercise3/c3s3e3v';
import { getC3S3E4VariantSteps } from './subcategory3/exercise4/c3s3e4v';
import { getC3S3E5VariantSteps } from './subcategory3/exercise5/c3s3e5v';
import { getC3S3E6VariantSteps } from './subcategory3/exercise6/c3s3e6v';
import { getC3S3E7VariantSteps } from './subcategory3/exercise7/c3s3e7v';
import { getC3S4E1VariantSteps } from './subcategory4/exercise1/c3s4e1v';
import { getC3S4E10VariantSteps } from './subcategory4/exercise10/c3s4e10v';
import { getC3S4E11VariantSteps } from './subcategory4/exercise11/c3s4e11v';
import { getC3S4E12VariantSteps } from './subcategory4/exercise12/c3s4e12v';
import { getC3S4E13VariantSteps } from './subcategory4/exercise13/c3s4e13v';
import { getC3S4E14VariantSteps } from './subcategory4/exercise14/c3s4e14v';
import { getC3S4E15VariantSteps } from './subcategory4/exercise15/c3s4e15v';
import { getC3S4E2VariantSteps } from './subcategory4/exercise2/c3s4e2v';
import { getC3S4E3VariantSteps } from './subcategory4/exercise3/c3s4e3v';
import { getC3S4E4VariantSteps } from './subcategory4/exercise4/c3s4e4v';
import { getC3S4E5VariantSteps } from './subcategory4/exercise5/c3s4e5v';
import { getC3S4E6VariantSteps } from './subcategory4/exercise6/c3s4e6v';
import { getC3S4E7VariantSteps } from './subcategory4/exercise7/c3s4e7v';
import { getC3S4E8VariantSteps } from './subcategory4/exercise8/c3s4e8v';
import { getC3S4E9VariantSteps } from './subcategory4/exercise9/c3s4e9v';

export function getUnitsBlocks(
  number: number,
  leftSideMatrix: boolean
): ExerciseStepBlock[] {
  let units = 0;
  let dozens = 0;
  let hundreds = 0;
  let thousands = 0;

  if (number < 10) {
    units = number;
  } else if (number > 9 && number < 100) {
    units = Math.floor(number % 10);
    dozens = Math.floor(number / 10);
  } else if (number > 99 && number < 1000) {
    hundreds = Math.floor(number / 100);
    dozens = Math.floor((number - hundreds * 100) / 10);
    units = Math.floor((number - dozens * 10) % 10);
  } else {
    thousands = Math.floor(number / 1000);
    hundreds = Math.floor((number - thousands * 1000) / 100);
    dozens = Math.floor((number - (hundreds * 100 + thousands * 1000)) / 10);
    units = Math.floor((number - dozens * 10) % 10);
  }
  const allBlocks: ExerciseStepBlock[] = [];

  if (units > 0) {
    const unitsArray = Array.from({ length: units }, (_, i) => i + 1);
    unitsArray.forEach((u) => {
      allBlocks.push({
        idx: leftSideMatrix ? 4 : 10,
        idy: u,
        color: ExerciseColor.ZIELONY,
        content: ExerciseBlockContent.BLANK,
      });
    });
  }
  if (dozens > 0) {
    const unitsArray = Array.from({ length: dozens }, (_, i) => i + 1);
    unitsArray.forEach((u) => {
      allBlocks.push({
        idx: leftSideMatrix ? 3 : 9,
        idy: u,
        color: ExerciseColor.CZERWONY,
        content: ExerciseBlockContent.BLANK,
      });
    });
  }
  if (hundreds > 0) {
    const unitsArray = Array.from({ length: hundreds }, (_, i) => i + 1);
    unitsArray.forEach((u) => {
      allBlocks.push({
        idx: leftSideMatrix ? 2 : 8,
        idy: u,
        color: ExerciseColor.NIEBIESKI,
        content: ExerciseBlockContent.BLANK,
      });
    });
  }
  if (thousands > 0) {
    const unitsArray = Array.from({ length: thousands }, (_, i) => i + 1);
    unitsArray.forEach((u) => {
      allBlocks.push({
        idx: leftSideMatrix ? 1 : 7,
        idy: u,
        color: ExerciseColor.ŻÓŁTY,
        content: ExerciseBlockContent.BLANK,
      });
    });
  }
  return allBlocks;
}

export function getBlocksBeforeCount(
  firstNumber: number,
  secondNumber: number
): ExerciseStepBlock[] {
  const leftSideBlocks = getUnitsBlocks(firstNumber, true);

  const rightSideBlocks = getUnitsBlocks(secondNumber, false);

  return [...leftSideBlocks, ...rightSideBlocks];
}

export function getBlocksAfterCount(
  result: number,
  leftSide = true
): ExerciseStepBlock[] {
  const leftSideBlocks = getUnitsBlocks(result, leftSide);

  return [...leftSideBlocks];
}

export function getDividedBlocks(
  color: ExerciseColor,
  numberOfBlocks: number,
  numberOfCorners: number
): ExerciseStepBlock[] {
  const blocks: ExerciseStepBlock[] = [];
  if (numberOfCorners == 1) {
    const numbers = Array.from({ length: numberOfBlocks }, (_, i) => i + 1);
    let x = 1;
    let y = 1;
    numbers.forEach(() => {
      blocks.push({
        idx: x,
        idy: y,
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      });
      x = x + 1;
      if (x > 5) {
        y = y + 1;
        x = 1;
      }
    });
  }
  if (numberOfCorners == 2) {
    const numbers = Array.from(
      { length: (numberOfBlocks / 2) | 0 },
      (_, i) => i + 1
    );

    let q1x = 1;
    let q1y = 1;
    let q2x = 6;
    let q2y = 6;

    numbers.forEach(() => {
      if (blocks.length === numberOfBlocks) {
        return;
      }
      blocks.push({
        idx: q1x,
        idy: q1y,
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      });

      q1x = q1x + 1;
      if (q1x > 5) {
        q1y = q1y + 1;
        q1x = 1;
      }

      if (blocks.length === numberOfBlocks) {
        return;
      }

      blocks.push({
        idx: q2x,
        idy: q2y,
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      });

      q2x = q2x + 1;
      if (q2x > 10) {
        q2y = q2y + 1;
        q2x = 1;
      }
    });
  }

  if (numberOfCorners == 3) {
    const numbers = Array.from(
      { length: (numberOfBlocks / 3) | 0 },
      (_, i) => i + 1
    );

    let q1x = 1;
    let q1y = 1;
    let q2x = 1;
    let q2y = 6;
    let q3x = 6;
    let q3y = 6;

    numbers.forEach(() => {
      if (blocks.length === numberOfBlocks) {
        return;
      }

      blocks.push({
        idx: q1x,
        idy: q1y,
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      });

      q1x = q1x + 1;
      if (q1x > 5) {
        q1y = q1y + 1;
        q1x = 1;
      }

      if (blocks.length === numberOfBlocks) {
        return;
      }

      blocks.push({
        idx: q2x,
        idy: q2y,
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      });

      q2x = q2x + 1;
      if (q2x > 5) {
        q2y = q2y + 1;
        q2x = 1;
      }

      if (blocks.length === numberOfBlocks) {
        return;
      }

      blocks.push({
        idx: q3x,
        idy: q3y,
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      });

      q3x = q3x + 1;
      if (q3x > 10) {
        q3y = q3y + 1;
        q3x = 6;
      }
    });
  }
  if (numberOfCorners == 4) {
    const numbers = Array.from(
      { length: (numberOfBlocks / 4) | 0 },
      (_, i) => i + 1
    );

    let q1x = 1;
    let q1y = 1;
    let q2x = 1;
    let q2y = 6;
    let q3x = 6;
    let q3y = 6;
    let q4x = 6;
    let q4y = 1;

    numbers.forEach(() => {
      if (blocks.length === numberOfBlocks) {
        return;
      }

      blocks.push({
        idx: q1x,
        idy: q1y,
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      });

      q1x = q1x + 1;
      if (q1x > 5) {
        q1y = q1y + 1;
        q1x = 1;
      }

      if (blocks.length === numberOfBlocks) {
        return;
      }

      blocks.push({
        idx: q2x,
        idy: q2y,
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      });

      q2x = q2x + 1;
      if (q2x > 5) {
        q2y = q2y + 1;
        q2x = 1;
      }

      if (blocks.length === numberOfBlocks) {
        return;
      }

      blocks.push({
        idx: q3x,
        idy: q3y,
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      });

      q3x = q3x + 1;
      if (q3x > 10) {
        q3y = q3y + 1;
        q3x = 6;
      }

      if (blocks.length === numberOfBlocks) {
        return;
      }

      blocks.push({
        idx: q4x,
        idy: q4y,
        color: getBlockColor(color),
        content: ExerciseBlockContent.BLANK,
      });

      q4x = q4x + 1;
      if (q4x > 10) {
        q4y = q4y + 1;
        q4x = 6;
      }
    });
  }
  return blocks;
}
