import {
  Exercise,
  ExerciseColor,
  ExerciseName,
  ExerciseType,
} from '../../../exerciseTypes';

const step1 = {
  numbersOnAxis: true,
  number: 1,
  description:
    'Wybierzcie z pudełka te czerwone klocki, zielone oraz żółte, na których widoczne są liczby mniejsze lub równe 16. Ułóżcie je obok siatki',
};

const step2 = {
  numbersOnAxis: true,
  number: 2,
  description:
    'Ja będę wymieniał liczby (nauczyciel wymienia liczby z 4. kolumny), a waszym zadaniem będzie ułożenie klocków z odpowiednimi liczbami na siatce, przy czym klocki czerwone układajcie przy lewym brzegu siatki, zielone przy górnym brzegu siatki, a żółte na przekątnej siatki',
};

const step3 = {
  numbersOnAxis: true,
  number: 3,
  blocks: [
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 16,
    },
  ],
};

const step4 = {
  numbersOnAxis: true,
  number: 4,
  description:
    'Popatrzcie na ułożoną czwartą kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 16,
    },
  ],
};

const step5 = {
  numbersOnAxis: true,
  number: 5,
  description: 'Co ciekawego możecie zauważyć patrząc na wypisane liczby?',
  blocks: [
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 16,
    },
  ],
};

const step6 = {
  numbersOnAxis: true,
  number: 6,
  description:
    'Popatrzcie na ułożoną trzecią kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 16,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
  ],
};

const step7 = {
  numbersOnAxis: true,
  number: 7,
  description:
    'Popatrzcie na ułożoną drugą kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 16,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 2,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 8,
    },
  ],
};

const step8 = {
  numbersOnAxis: true,
  number: 8,
  description:
    'Popatrzcie na ułożoną pierwszą kolumnę klocków. Przeczytajcie zamieszczone w niej liczby od najmniejszej do największej i zapiszcie je w zeszytach',
  blocks: [
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 16,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 2,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 8,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 3,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 4,
    },
  ],
};

export const c3s3e4: Exercise = {
  number: 4,
  name: ExerciseName.EXERCISE_4,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'blockXShift',
      label: 'Liczba kolumn',
      placeholder: 'Podaj liczbę kolumn',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
    {
      name: 'blockYShift',
      label: 'Liczba wierszy',
      placeholder: 'Podaj liczbę wierszy',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
  ],
  steps: [step1, step2, step3, step4, step5, step6, step7, step8],
};
