import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
  description: 'Wyciągnijcie z pudełka taką samą drewnianą podkładkę',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
  description:
    'Ułóżcie na niej taki sam prostopadłościan, jak na obrazku na ekranie',
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};

const step4 = {
  number: 4,
  description:
    'Wyszukajcie w pudełku figurę, która ma kształt podobny do największej ściany prostopadłościanu, i połóżcie ją na podkładce',
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};

const step5 = {
  number: 5,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};

const step6 = {
  number: 6,
  description: 'Czy ktoś wie, jak nazywa się taka figura?',
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};

const step7 = {
  number: 7,
  description:
    'Pokażcie wierzchołki prostokąta. Ile wierzchołków ma ten prostokąt?',
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};

const step8 = {
  number: 8,
  description:
    'Wyjmijcie z pudełka średni fioletowy klocek i zmierzcie nim długość wszystkich boków prostokąta',
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};

const step9 = {
  number: 9,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};

const step10 = {
  number: 10,
  description:
    'Co ciekawego możecie powiedzieć o długościach boków prostokąta?  Obliczcie obwód prostokąta (obwód to łączna długość wszystkich boków prostokąta). Sposób obliczania obwodu zapiszcie w zeszytach',
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};

const step11 = {
  number: 11,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};

const step12 = {
  number: 12,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};

const step13 = {
  number: 13,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};
const step14 = {
  number: 14,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};
const step15 = {
  number: 15,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};
const step16 = {
  number: 16,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};
const step17 = {
  number: 17,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};
const step18 = {
  number: 18,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};
const step19 = {
  number: 19,
  customMatrix: CustomMatrix.EXERCISE_C6_S2_E2,
};

export const c6s2e2: Exercise = {
  number: 2,
  name: ExerciseName.EXERCISE_2,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
    step17,
    step18,
    step19,
  ],
};
