import {
  Exercise,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseName,
  ExerciseType
} from '../../../exerciseTypes';

const step1 = {
  numbersOnAxis: true,
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step2 = {
  numbersOnAxis: true,
  number: 2,
  description:
    'Ułóżcie z niebieskich klocków figurę składającą się z 3 wierszy, a każdy wiersz z 4 klocków',
};

const step3 = {
  numbersOnAxis: true,
  number: 3,
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  numbersOnAxis: true,
  number: 4,
  description:
    'Z ilu klocków składa się zbudowana figura? \n\n Jak można obliczyć liczbę klocków?',
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  numbersOnAxis: true,
  number: 5,
  description: 'Zapiszcie w zeszytach wykonywane czynności',
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step6 = {
  numbersOnAxis: true,
  number: 6,
  description: 'Co ciekawego można zaobserwować, patrząc na zapisane równości?',
  blocks: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 1,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 2,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 4,
      idy: 3,
      color: ExerciseColor.NIEBIESKI,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

export const c3s3e1: Exercise = {
  number: 1,
  name: ExerciseName.EXERCISE_1,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  availability: 'LIMITED_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'blockXShift',
      label: 'Liczba kolumn',
      placeholder: 'Podaj liczbę kolumn',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
    {
      name: 'blockYShift',
      label: 'Liczba wierszy',
      placeholder: 'Podaj liczbę wierszy',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
  ],
  steps: [step1, step2, step3, step4, step5, step6],
};
