import { getX, getY } from 'src/database/exerciseUtil';
import {
  Exercise,
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseName,
  ExerciseType
} from '../../exerciseTypes';

const blocksArray: number[] = Array.from({ length: 36 }, (_, i) => i);

const step1 = {
  number: 1,
  description: 'Policzcie głośno razem ze mną pokazane na ekranie kwadraciki',
};

function getBlocksAsc(number: number, allElements: number) {
  const blocks = [];
  for (let i = 1; i <= allElements; i++) {
    blocks.push({
      idx: getX(i),
      idy: getY(i),
      color: ExerciseColor.ŻÓŁTY,
      content: i <= number ? i : ExerciseBlockContent.BLANK,
    });
  }
  return blocks;
}

function getBlocksDesc(number: number, allElements: number) {
  const blocks = [];
  for (let i = allElements; i >= 0; i--) {
    blocks.push({
      idx: getX(i),
      idy: getY(i),
      color: ExerciseColor.ŻÓŁTY,
      content: i <= number ? i : ExerciseBlockContent.BLANK,
    });
  }
  return blocks;
}

const stepsAsc = blocksArray.map((i) => ({
  number: i + 2,
  blocks: getBlocksAsc(i, 35),
}));

const stepsDesc = blocksArray.reverse().map((i, idx) => ({
  number: idx + 38,
  blocks: getBlocksDesc(i, 35),
}));

export const c2e3: Exercise = {
  number: 3,
  name: ExerciseName.EXERCISE_3,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  steps: [step1, ...stepsAsc, ...stepsDesc],
  availability: 'LIMITED_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'maxNumberOfBlocks',
      label: 'Ilość klocków',
      placeholder: 'Wybierz ilość klocków',
      options: Array.from({ length: 100 }, (_, i) => i + 1),
    },
    {
      name: 'reverse',
      label: 'Liczenie od końca',
      checkbox: true,
    },
  ],
};
