import {
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../../exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step2 = {
  number: 2,
  description:
    'Wyjmijcie z pudełka 18 czerwonych klocków i połóżcie je obok siatki tak, aby cyfry na klockach nie były widoczne',
};

const step3 = {
  number: 3,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  number: 4,
  description: 'Rozłóżcie je w dowolnych kwadratach po 4 klocki',
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  number: 5,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step6 = {
  number: 6,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step7 = {
  number: 7,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step8 = {
  number: 8,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 3,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 4,
      color: ExerciseColor.CZERWONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

export const c3s4e15: Exercise = {
  number: 15,
  name: ExerciseName.EXERCISE_15,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'numberToDivide',
      label: 'Wybierz pierwszy składnik dzielenia',
      placeholder: 'Wybierz liczbę klocków',
      options: Array.from({ length: 100 }, (_, i) => i + 1),
    },
    {
      name: 'numberDivide',
      label: 'Wybierz drugi składnik dzielenia',
      placeholder: 'Wybierz ilość kwadratów',
      options: Array.from({ length: 4 }, (_, i) => i + 1),
    },
  ],
  steps: [step1, step2, step3, step4, step5, step6, step7, step8],
};
