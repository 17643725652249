import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import Button from 'src/components/Button';
import Dialog from 'src/components/Dialog';
import Input from 'src/components/Input';
import { useDatabase } from 'src/database/database';
import { label } from 'src/utils/labels';
import { customPath } from 'src/utils/paths';
import { ButtonSize, ButtonTypes } from 'src/utils/types';
import { RegisterRequest } from './hooks';
import { RegisterStepEnum } from './Register';

type Props = {
  setStep: (s: RegisterStepEnum) => void;
  form: UseFormReturn<RegisterRequest, object>;
};

export default function RegisterFirstStep({ setStep, form }: Props) {
  const { isLoginUsed } = useDatabase();
  const [isLoading, setIsLoading] = useState(false);

  async function handleOnClick() {
    setIsLoading(true);

    const isNextStepAvailable =
      typeof form.formState.errors.login === 'undefined' &&
      typeof form.formState.errors.password === 'undefined' &&
      typeof form.formState.errors.passwordConfirm === 'undefined' &&
      form.getValues('login') &&
      form.getValues('password') &&
      form.getValues('passwordConfirm');

    if (form.getValues('login')) {
      await isLoginUsed(form.getValues('login')).then((isUsed) => {
        if (isUsed) {
          form.setError('login', {
            type: 'custom',
            message: 'Użytkownik o tym loginie juz istnieje',
          });
        } else {
          if (isNextStepAvailable) {
            form.clearErrors();
            setStep(RegisterStepEnum.secondStep);
          }
        }
      });
    }
    setIsLoading(false);
  }

  return (
    <Dialog
      title='Rejestracja krok 1 z 2'
      backUrl={customPath.singIn}
      withClose
    >
      <Input
        name='login'
        label={label.registerFirstStep.login.label}
        placeholder={label.registerFirstStep.login.placeholder}
        wrapperClassName='mb-[20px]'
      />
      <Input
        name='password'
        type='password'
        label={label.registerFirstStep.password.label}
        placeholder={label.registerFirstStep.password.placeholder}
        wrapperClassName='mb-[20px]'
        tooltipText='Przynajmniej 8 znaków, jedna cyfra i jedną wielka litera.'
      />
      <Input
        name='passwordConfirm'
        type='password'
        label={label.registerFirstStep.passwordConfirm.label}
        placeholder={label.registerFirstStep.passwordConfirm.placeholder}
        wrapperClassName='mb-[20px]'
      />

      <div>
        <Button
          variant={ButtonTypes.primary}
          customClassName='w-full'
          buttonSize={ButtonSize.BIG}
          onClick={handleOnClick}
          isLoading={isLoading}
        >
          <span>{label.registerFirstStep.buttons.nextStep}</span>
        </Button>
      </div>
    </Dialog>
  );
}
