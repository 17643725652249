import {
  CustomMatrix,
  Exercise,
  ExerciseName,
  ExerciseType
} from 'src/database/exerciseTypes';

// 1
const step1 = {
  number: 1,
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E1,
  description: 'Wyciągnijcie z pudełka taką samą drewnianą podkładkę',
};

const step2 = {
  number: 2,
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E1,
};

// 2
const step3 = {
  number: 3,
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E1,
};

const step4 = {
  number: 4,
  description: 'Czy wiecie, jak nazywa się taka figura?',
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E1,
};

// 3
const step5 = {
  number: 5,
  description: 'To jest podstawa dolna walca. Pokażcie ją na swoich klockach.',
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E1,
};

const step6 = {
  number: 6,
  description:
    'Połóżcie walec na kartce w swoich w zeszytach i obrysujcie ołówkiem jego podstawę dolną, a następnie podpiszcie narysowaną figurę',
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E1,
};

const step7 = {
  number: 7,
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E1,
};

const step8 = {
  number: 8,
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E1,
};

const step9 = {
  number: 9,
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E1,
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E1,
};

const step11 = {
  number: 11,
  customMatrix: CustomMatrix.EXERCISE_C6_S5_E1,
};

export const c6s5e1: Exercise = {
  number: 1,
  name: ExerciseName.EXERCISE_1,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'LIMITED_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
  ],
};
