import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { UserAuth } from 'src/context/AuthContext';
import { useDatabase } from 'src/database/database';
import { reportSchema } from 'src/utils/validation';

type ReportRequest = {
  report: string;
};
export function useReport() {
  const [isReportSent, setIsReportSent] = useState(false);
  const [imageUpload, setImageUpload] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = UserAuth();
  const { saveReport, uploadFile } = useDatabase();

  async function sendReport(inputs: ReportRequest) {
    setIsLoading(true);
    if (imageUpload) {
      await uploadImage().then((url) => {
        if (user !== null && user.email) {
          saveReport(inputs.report, user.email, url);
          setIsReportSent(true);
        }
      });
    } else {
      if (user !== null && user.email) {
        await saveReport(inputs.report, user.email);
        setIsReportSent(true);
      }
    }
    setIsLoading(false);
  }

  async function uploadImage() {
    if (imageUpload == null || user === null || user.email === null) {
      return;
    }
    return uploadFile(imageUpload, user.email);
  }

  const form = useForm<ReportRequest>({
    mode: 'onTouched',
    resolver: yupResolver(reportSchema),
  });

  return {
    sendReport,
    form,
    isReportSent,
    isLoading,
    setImageUpload,
    imageUpload,
  };
}
