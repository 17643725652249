import {
    CustomMatrix,
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseStepBlock,
    ExerciseType
} from '../../exerciseTypes';

function getRandomBlocks(
  numberOfBlocks: number,
  color: ExerciseColor,
  blocksBeforeAdd?: ExerciseStepBlock[]
) {
  const allBlocks: ExerciseStepBlock[] = blocksBeforeAdd || [];
  let x = 0;
  let y = 0;
  for (let i = 0; allBlocks.length < numberOfBlocks * 5; i++) {
    x = Math.floor(Math.random() * 12) + 2;
    y = Math.floor(Math.random() * 12) + 4;
    const block = allBlocks.find(
      (b) =>
        (b.idx === x && b.idy === y) ||
        (b.idx === x - 1 && b.idy === y) ||
        (b.idx === x + 1 && b.idy === y) ||
        (b.idx === x && b.idy === y - 1)
    );
    if (typeof block === 'undefined') {
      allBlocks.push(
        {
          idx: x,
          idy: y,
          color: color,
          content: ExerciseBlockContent.BLANK,
        },
        {
          idx: x - 1,
          idy: y - 1,
          color: ExerciseColor.ŻÓŁTY,
          content: ExerciseBlockContent.BLANK,
        },
        {
          idx: x,
          idy: y - 1,
          color: ExerciseColor.ŻÓŁTY,
          content: ExerciseBlockContent.BLANK,
        },
        {
          idx: x + 1,
          idy: y - 1,
          color: ExerciseColor.ŻÓŁTY,
          content: ExerciseBlockContent.BLANK,
        },
        {
          idx: x - 1,
          idy: y - 2,
          color: ExerciseColor.ŻÓŁTY,
          content: ExerciseBlockContent.BLANK,
        },
        {
          idx: x,
          idy: y - 2,
          color: ExerciseColor.ŻÓŁTY,
          content: ExerciseBlockContent.BLANK,
        },
        {
          idx: x + 1,
          idy: y - 2,
          color: ExerciseColor.ŻÓŁTY,
          content: ExerciseBlockContent.BLANK,
        }
      );
    }
  }
  return allBlocks;
}

const redBuss = getRandomBlocks(2, ExerciseColor.CZERWONY);
const redAndBlueBuss = getRandomBlocks(3, ExerciseColor.NIEBIESKI, [
  ...redBuss,
]);

const step1 = {
  withDecimals: true,
  number: 1,
  description: 'Wyciągnijcie podstawki jak na ekranie',
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
  blocksWithoutPaddings: true,
};

const step2 = {
  withDecimals: true,
  number: 2,
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
  blocksWithoutPaddings: true,
  description:
    'Podstawka ilustruje miejsce dla uczestników pewnej wycieczki: żółte – uczniowie, niebieskie – nauczyciele, czerwone – opiekunowie',
};

const step3 = {
  withDecimals: true,
  number: 3,
  description:
    'Na przystań wodną jeziora przyjechał autobus z wycieczką szkolną. Było w niej 18 uczniów, nauczyciel i dwoje dorosłych opiekunów. Ile łódek muszą wynająć, aby wszyscy uczestnicy mogli pływać i na każdej łódce był jeden dorosły uczestnik wycieczki?',
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
  blocksWithoutPaddings: true,
};

const step4 = {
  withDecimals: true,
  number: 4,
  description:
    'Połóżcie na podstawkach odpowiednią liczbę klocków w taki sposób, aby nie było widać cyfr wypisanych na ściankach',
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
  blocksWithoutPaddings: true,
};

const step5 = {
  withDecimals: true,
  number: 5,
  blocksWithoutPaddings: true,
  blocks: redAndBlueBuss,
  customMatrix: CustomMatrix.BLANK_10x10,
  matrixWidth: 14,
  matrixHeight: 14,
};

export const c4e7: Exercise = {
  number: 7,
  name: ExerciseName.EXERCISE_7,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [step1, step2, step3, step4, step5],
};
