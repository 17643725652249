import {
    Exercise,
    ExerciseBlockContent,
    ExerciseColor,
    ExerciseName,
    ExerciseType
} from '../../../exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka 18 zielonych klocków i połóżcie je obok siatki tak, aby cyfry na klockach nie były widoczne',
};

const step2 = {
  number: 2,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step3 = {
  number: 3,
  description: 'Rozłóżcie te klocki po równo w trzech kwadratach',
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step4 = {
  number: 4,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step5 = {
  number: 5,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step6 = {
  number: 6,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step7 = {
  number: 7,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step8 = {
  number: 8,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step9 = {
  number: 9,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step10 = {
  number: 10,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step11 = {
  number: 11,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step12 = {
  number: 12,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step13 = {
  number: 13,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step14 = {
  number: 14,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step15 = {
  number: 15,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 6,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step16 = {
  number: 16,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 5,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step17 = {
  number: 17,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step18 = {
  number: 18,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step19 = {
  number: 19,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 1,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step20 = {
  number: 20,
  leftHelpMatrix: [
    {
      idx: 1,
      idy: 1,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step21 = {
  number: 21,
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step22 = {
  number: 22,
  description: 'Ile klocków jest w każdym kwadracie?',
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step23 = {
  number: 23,
  description:
    'Mieliśmy 18 klocków, rozłożyliśmy je tak, że w każdym kwadracie jest 6 klocków, czyli 18 klocków podzielone na 3 równe części daje po 6 klocków',
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

const step24 = {
  number: 24,
  description: 'Zapiszcie w zeszytach wykonywanych czynności',
  blocks: [
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 8,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 8,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 2,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 4,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 9,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 3,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },

    {
      idx: 9,
      idy: 7,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
    {
      idx: 3,
      idy: 2,
      color: ExerciseColor.ZIELONY,
      content: ExerciseBlockContent.BLANK,
    },
  ],
};

export const c3s4e5: Exercise = {
  number: 5,
  name: ExerciseName.EXERCISE_5,
  ownVariantOption: true,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'numberToDivide',
      label: 'Wybierz pierwszy składnik dzielenia',
      placeholder: 'Wybierz liczbę klocków',
      options: Array.from({ length: 100 }, (_, i) => i + 1),
    },
    {
      name: 'numberDivide',
      label: 'Wybierz drugi składnik dzielenia',
      placeholder: 'Wybierz ilość kwadratów',
      options: Array.from({ length: 4 }, (_, i) => i + 1),
    },
  ],
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
    step17,
    step18,
    step19,
    step20,
    step21,
    step22,
    step23,
    step24,
  ],
};
