import {
  Exercise,
  ExerciseColor,
  ExerciseName,
  ExerciseType,
} from '../../../exerciseTypes';

const step1 = {
  numbersOnAxis: true,
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
};

const step2 = {
  numbersOnAxis: true,
  number: 2,
  description:
    'Wybierzcie z pudełka te czerwone klocki, zielone oraz żółte, na których widoczne są liczby mniejsze lub równe 16. Ułóżcie je obok siatki',
};

const step3 = {
  numbersOnAxis: true,
  number: 3,
  description:
    'Ja będę wymieniać liczby, a waszym zadaniem będzie ułożenie klocków z odpowiednimi liczbami na siatce, przy czym czerwone klocki układajcie przy lewym brzegu siatki, zielone przy górnym brzegu siatki, a żółte na przekątnej siatki',
};

const step4 = {
  numbersOnAxis: true,
  number: 4,
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 8,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 3,
    },
    {
      idx: 2,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 16,
    },
  ],
};

const step5 = {
  numbersOnAxis: true,
  number: 5,
  description:
    'Popatrzcie na umieszczone na siatce liczby i powiedźcie, jakie własności posiada ułożona tablica liczb?',
  blocks: [
    {
      idx: 2,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 6,
    },
    {
      idx: 4,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 12,
    },
    {
      idx: 4,
      idy: 8,
      color: ExerciseColor.ZIELONY,
      content: 12,
    },
    {
      idx: 1,
      idy: 9,
      color: ExerciseColor.CZERWONY,
      content: 2,
    },
    {
      idx: 1,
      idy: 10,
      color: ExerciseColor.ŻÓŁTY,
      content: 1,
    },
    {
      idx: 1,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 4,
    },
    {
      idx: 3,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 6,
    },
    {
      idx: 2,
      idy: 7,
      color: ExerciseColor.CZERWONY,
      content: 8,
    },
    {
      idx: 3,
      idy: 8,
      color: ExerciseColor.ŻÓŁTY,
      content: 9,
    },
    {
      idx: 1,
      idy: 8,
      color: ExerciseColor.CZERWONY,
      content: 3,
    },
    {
      idx: 2,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 2,
    },
    {
      idx: 3,
      idy: 10,
      color: ExerciseColor.ZIELONY,
      content: 3,
    },
    {
      idx: 2,
      idy: 9,
      color: ExerciseColor.ŻÓŁTY,
      content: 4,
    },
    {
      idx: 4,
      idy: 9,
      color: ExerciseColor.ZIELONY,
      content: 8,
    },
    {
      idx: 4,
      idy: 7,
      color: ExerciseColor.ŻÓŁTY,
      content: 16,
    },
  ],
};

export const c3s3e3: Exercise = {
  number: 3,
  name: ExerciseName.EXERCISE_3,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  variant: [
    {
      name: 'blocksColor',
      label: 'Kolor',
      placeholder: 'Wybierz kolor',
      options: Object.keys(ExerciseColor).filter(
        (c) => c !== ExerciseColor.BRĄZOWY
      ),
    },
    {
      name: 'blockXShift',
      label: 'Liczba kolumn',
      placeholder: 'Podaj liczbę kolumn',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
    {
      name: 'blockYShift',
      label: 'Liczba wierszy',
      placeholder: 'Podaj liczbę wierszy',
      options: Array.from({ length: 10 }, (_, i) => i + 1),
    },
  ],
  steps: [step1, step2, step3, step4, step5],
};
