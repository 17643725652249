import { FirebaseError } from '@firebase/util';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from 'src/context/AuthContext';
import { label } from 'src/utils/labels';
import { customPath } from 'src/utils/paths';
import { FirebaseErrorCodes } from 'src/utils/types';
import { signInSchema } from 'src/utils/validation';

type LoginRequest = {
  login: string;
  password: string;
};
export function useLogin() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { signIn } = UserAuth();
  const alert = useAlert();

  async function handleLogin(inputs: LoginRequest) {
    setIsLoading(true);
    try {
      await signIn(inputs.login, inputs.password).then((userCredentials) => {
        navigate(customPath.homepage);
        if (userCredentials.user?.emailVerified) {
          alert.success(label.alerts.success.signIn);
        } else {
          alert.error(
            'Przed zalogowaniem musisz potwierdzić konto linkiem aktywacyjnym wysłanym na e-mail'
          );
        }
      });
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        if (error.code === FirebaseErrorCodes.AUTH_OR_USER_NOT_FOUND) {
          form.setError('login', {
            type: 'custom',
            message: label.alerts.error.authOrUserNotFound,
          });
        } else if (error.code === FirebaseErrorCodes.WRONG_PASSWORD) {
          form.setError('password', {
            type: 'custom',
            message: label.alerts.error.wrongPassword,
          });
        } else {
          alert.error(label.alerts.error.common);
        }
      }
    }
    setIsLoading(false);
  }

  const form = useForm<LoginRequest>({
    mode: 'onTouched',
    resolver: yupResolver(signInSchema),
  });

  return {
    form,
    isLoading,
    handleLogin,
  };
}
