import {
    CustomMatrix,
    Exercise,
    ExerciseName,
    ExerciseType
} from 'src/database/exerciseTypes';

const step1 = {
  number: 1,
  description:
    'Wyjmijcie z pudełka taką samą siatkę, jaka jest pokazana na obrazku na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step2 = {
  number: 2,
  description:
    'Ułóżcie z klocków 4 kolumny takie same, jak pokazuje obrazek na ekranie',
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step3 = {
  number: 3,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step4 = {
  number: 4,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
  description:
    'Która kolumna jest najniższa? A która najwyższa? \n Wymieńcie kolejno kolumny od najniższej do najwyższej. \n Wymieńcie kolejno kolumny od najwyższej do najniższej. \n Które kolumny są wyższe od żółtej? \n Które kolumny są niższe od czerwonej? \n Jakiego koloru kolumna jest druga po prawej? \n Jakiego koloru kolumna jest pierwsza od lewej?',
};

const step5 = {
  number: 5,
  description:
    'Wyjmijcie z pudełka fioletowe klocki, które posłużą nam do mierzenia wysokości kolumn',
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step6 = {
  number: 6,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step7 = {
  number: 7,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
  description:
    'Najpierw zmierzymy długość poszczególnych kolumn za pomocą najkrótszego klocka oznaczonego literą m',
};

const step8 = {
  number: 8,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step9 = {
  number: 9,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step10 = {
  number: 10,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step11 = {
  number: 11,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step12 = {
  number: 12,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step13 = {
  number: 13,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step14 = {
  number: 14,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step15 = {
  number: 15,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step16 = {
  number: 16,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step17 = {
  number: 17,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step18 = {
  number: 18,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step19 = {
  number: 19,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step20 = {
  number: 20,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step21 = {
  number: 21,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step22 = {
  number: 22,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step23 = {
  number: 23,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step24 = {
  number: 24,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step25 = {
  number: 25,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step26 = {
  number: 26,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step27 = {
  number: 27,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step28 = {
  number: 28,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step29 = {
  number: 29,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step30 = {
  number: 30,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step31 = {
  number: 31,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step32 = {
  number: 32,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step33 = {
  number: 33,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
};

const step34 = {
  number: 34,
  customMatrix: CustomMatrix.EXERCISE_C5_E4,
  description: 'Co można powiedzieć o otrzymanych liczbach?',
};

export const c5e4: Exercise = {
  number: 4,
  name: ExerciseName.EXERCISE_4,
  ownVariantOption: false,
  type: ExerciseType.DEFAULT,
  availability: 'FULL_ACCESS',
  steps: [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
    step17,
    step18,
    step19,
    step20,
    step21,
    step22,
    step23,
    step24,
    step25,
    step26,
    step27,
    step28,
    step29,
    step30,
    step31,
    step32,
    step33,
    step34,
  ],
};
