import { Fragment, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MenuOption from 'src/components/MenuOption';
import { UserAuth } from 'src/context/AuthContext';
import { HomepageMenu } from 'src/context/HomepageMenuContext';
import { getCategoryDisplayName } from 'src/database/categoriesUtil';
import { Category } from 'src/database/exerciseTypes';
import { label } from 'src/utils/labels';
import { HomepageOption } from 'src/utils/types';

type Props = {
  isSelected: boolean;
  onOptionSelect: (
    option: HomepageOption,
    unselectAllOptions?: boolean
  ) => void;
};

export default function Exercises({ isSelected, onOptionSelect }: Props) {
  const {
    onCategorySelect,
    onSubCategorySelect,
    selectedCategory,
    selectedSubCategory,
    categories,
  } = HomepageMenu();

  const { option, element, subElement } = useParams();
  const navigate = useNavigate();
  const { user } = UserAuth();

  function onExercisesSelect(clicked?: boolean) {
    if (isSelected && clicked) {
      navigate('/');
      onOptionSelect(HomepageOption.NONE);
      return;
    }
    onOptionSelect(HomepageOption.EXERCISES);
    if (element) {
      const category = categories.find(
        (p) => p.name === decodeURIComponent(element)
      );
      if (category) {
        navigate(`/zadania/${category.name}`);
        onCategorySelect(category);
        if (subElement && category.subCategories) {
          const subCategory = category.subCategories.find(
            (p) => p.name === decodeURIComponent(subElement)
          );
          if (typeof subCategory !== 'undefined') {
            navigate(`/zadania/${category.name}/${subCategory?.name}`);
            subCategory.categoryName = category.name;
            onSubCategorySelect(subCategory);
          }
        }
      }
    } else {
      navigate('/zadania');
    }
  }

  useEffect(() => {
    if (option === 'zadania') {
      onExercisesSelect();
    }
  }, []);

  return (
    <>
      <MenuOption
        isSelected={isSelected}
        markAsSelected={
          isSelected &&
          typeof selectedCategory === 'undefined' &&
          typeof selectedSubCategory === 'undefined'
        }
        text={label.homepage.content.menu.exercises.label}
        onOptionSelect={() => onExercisesSelect(true)}
        isDropdown={!!user}
      />
      {isSelected && !!user && (
        <>
          {categories.map((category: Category) => {
            return (
              <Fragment key={category.name}>
                <MenuOption
                  text={getCategoryDisplayName(category.name)}
                  optionClassName='ml-[30px]'
                  onOptionSelect={() => onCategorySelect(category)}
                  markAsSelected={
                    selectedCategory?.name === category.name &&
                    typeof selectedSubCategory === 'undefined'
                  }
                  isSelected={
                    typeof selectedCategory !== 'undefined' &&
                    category.name === selectedCategory.name
                  }
                  isDropdown={
                    typeof category.subCategories !== 'undefined' &&
                    category.subCategories.length > 0
                  }
                >
                  <>
                    {typeof selectedCategory !== 'undefined' &&
                      typeof selectedCategory.subCategories !== 'undefined' &&
                      selectedCategory.subCategories.length > 0 &&
                      category.subCategories?.[0].name ===
                        selectedCategory.subCategories[0].name && (
                        <div className='mb-[20px]'>
                          {selectedCategory.subCategories.map((subcategory) => (
                            <Fragment key={subcategory.name}>
                              <MenuOption
                                text={getCategoryDisplayName(subcategory.name)}
                                optionClassName='ml-[60px]'
                                onOptionSelect={() =>
                                  onSubCategorySelect(subcategory)
                                }
                                markAsSelected={
                                  typeof selectedSubCategory !== 'undefined' &&
                                  subcategory.name === selectedSubCategory.name
                                }
                                isSelected={
                                  typeof selectedSubCategory !== 'undefined' &&
                                  subcategory.name === selectedSubCategory.name
                                }
                              />
                            </Fragment>
                          ))}
                        </div>
                      )}
                  </>
                </MenuOption>
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );
}
