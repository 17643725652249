import {
  ExerciseBlockContent,
  ExerciseColor,
  ExerciseStep,
  getBlockColor,
} from 'src/database/exerciseTypes';
import { getX, getY } from 'src/database/exerciseUtil';

export function getC2E1VariantSteps(
  color: ExerciseColor,
  numberOfBlocks: number,
  reverse: boolean
): ExerciseStep[] {
  let blocksArray: number[] = Array.from(
    { length: numberOfBlocks },
    (_, i) => i + 1
  );

  if (reverse) {
    blocksArray = blocksArray.reverse();
  }

  const step1 = {
    number: 1,
    description: 'Policzcie głośno razem ze mną pokazane na ekranie kwadraciki',
  };

  const step2 = {
    number: 2,
    blocks: blocksArray.map((i) => ({
      idx: getY(i),
      idy: getX(i),
      color: getBlockColor(color),
      content: ExerciseBlockContent.BLANK,
    })),
  };

  const steps = blocksArray.map((_element, index) => {
    const allBlocks = blocksArray.map((i, indexSecond) => {
      if (reverse ? indexSecond > index : i - 1 > index) {
        return {
          idx: getY(reverse ? indexSecond + 1 : i),
          idy: getX(reverse ? indexSecond + 1 : i),
          color: getBlockColor(color),
          content: ExerciseBlockContent.BLANK,
        };
      } else {
        return {
          idx: getY(reverse ? indexSecond + 1 : i),
          idy: getX(reverse ? indexSecond + 1 : i),
          color: getBlockColor(color),
          content: i,
        };
      }
    });
    return {
      number: index + 3,
      blocks: allBlocks,
    };
  });

  return [step1, step2, ...steps];
}
